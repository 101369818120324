import styled from "styled-components";

export const MainHeader = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 220px);
    h1 {
        line-height: 48px;
        font-weight: 300;
        color: #650360;
        font-size: 36px;
        margin-bottom: 3.5rem;
    }
    p {
        font-size: 24px;
        margin-bottom: 14px;
        line-height: 30px;
        color: #505050;
    }
    a {
        color: #006899;
        text-decoration: none;
        
    }
`;


