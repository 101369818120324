import allytm from '@allysf/allytm'
 
// This method only needs to be called once before your page calls any of the window.allytm methods
allytm();
window.digitalData = {
    pageInstanceId: '',
    delayPageLoadEvent: true,
    loadRules: {
      adobeAnalytics: true,
      adobeAudienceManager: true,
      adobeTarget: false,
      celebrus: false,
      confirmit: false,
      googleAnalytics: false,
      livePerson: true,
    },
    page: {
      pageInfo: [
        {
          pageID: '',
          pageName: '',
          destinationURL: window.location.href,
          referringURL: document.referrer,
          sysEnv: window.orientation ? 'mobile' : 'desktop',
          language: document.getElementsByTagName('html').item(0) ? document.getElementsByTagName('html').item(0).lang.substring(0, 2) : 'Unknown',
        },
      ],
      category: {
        primaryCategory: '',
        site: 'CSG',
        section: '',
        subsection: '',
        lineOfBusiness: 'Auto',
        categoryOfBusiness: 'Consumer',
          
      },
      attributes: {
        pixelTags: [] as string[],
        isSPA: true,
        Ember: true,
      },
    },
    product: [] as string[],
    cart: {},
    transaction: [] as string[],
    event: [] as string[],
    component: [] as string[],
    user: {},
    privacy: {},
    version: 'Ally_1.4',
  };
var script = document.createElement('script');
script.type = 'text/javascript';
script.src = process.env.REACT_APP_ANALYTIC_URL;
script.async = true;
document.head.appendChild(script);
export {}; 
