import { createContext, useContext, RefObject } from 'react'
import { Row } from 'react-table'
import { PublicRowStateProps } from '../types'

export interface TableDrawerHookValues<
  D extends Record<string, any>,
  R = unknown,
> extends PublicRowStateProps<R> {
  closeDrawer: () => void
  row: Row<D>
  targetRef: RefObject<HTMLElement>
}

const DrawerContext = createContext<TableDrawerHookValues<any, any>>(
  null as unknown as TableDrawerHookValues<any, any>,
)

export const DrawerProvider = DrawerContext.Provider

export const useTableDrawer = <
  D extends Record<string, any>,
  R = undefined,
>(): TableDrawerHookValues<D, R> => useContext(DrawerContext)
