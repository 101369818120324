import {useQuery} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { ReadDeliveryGroup, deliveryGroupDtoToModel } from "../../model/delivery-group.model";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { multipleDeliveryGroupsKey } from "../../utils/react-query-key-factory";

async function getDeliveryGroupsFromBackend(get:<Result>(url:string) => Promise<Result|null>,
  dealershipNumber: string, customerId?: string): Promise<ReadDeliveryGroup[]>{
  
  let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups`;
  if(customerId){
    dgEndpoint += `?customerId=${encodeURIComponent(customerId)}`;
  }
  const res = await get<any>(dgEndpoint);
  return res ? res.map((d: any) => deliveryGroupDtoToModel(d)): [];
}

export function useGetDeliveryGroups(dealershipNumber: string, customerId?: string){
  const {get} = useHttp();
  const analytics = useAnalytics();

  return useQuery(
    multipleDeliveryGroupsKey( dealershipNumber, customerId),
    () => getDeliveryGroupsFromBackend(get, dealershipNumber, customerId),
    {  
      onError(error){
        console.error("Oops something went wrong. Please try again later ", error);
        analytics(emitErrorEvent, (error as any).message ?? "get delivery groups error", (error as any).status ?? 500);
      },
    }
  );
}
