import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { Customer, customerDtoToModel } from "../../model/customer.model";
import { useOktaAuth } from '@okta/okta-react';
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { multipleCustomersKey, singleCustomerKey } from "../../utils/react-query-key-factory";

async function getCustomersFromBackend(get: <Result>(url: string) => Promise<Result[]|null>,
  dealershipNumber: string): Promise<Customer[]>{
  
  const customersEndpoint = `dealership/${dealershipNumber}/customers`;
  const res = await get<any>(customersEndpoint);
  return res ? res.map((v: any) => customerDtoToModel(v)): [];
}

export function useGetCustomers(dealershipNumber: string, select?){
  const { get } = useHttp();
  const { authState } = useOktaAuth();
  const queryClient = useQueryClient();
  const secureRoutesDisabled = process.env.REACT_APP_SECURE_ROUTES !== 'true'
  const enableQuery = (secureRoutesDisabled || !!authState?.accessToken) && !!dealershipNumber;
  const analytics = useAnalytics();

  return useQuery(
    multipleCustomersKey(dealershipNumber), 
    () => getCustomersFromBackend(get, dealershipNumber),
    {
      onError(error){
        console.error("Oops something went wrong. Please try again later ", error);
        analytics(emitErrorEvent, (error as any).message ?? "get customers error", (error as any).status ?? 500);
      },
      select,
      onSuccess: (data: Customer[]) => {
        data.forEach((customer: Customer) => {
          const customerKey = singleCustomerKey(dealershipNumber, customer.tax_id);
          queryClient.setQueryData(customerKey, customer);
        });
      },
      enabled: enableQuery
    });
}
