import { useEffect, useState } from "react";
import { Identifiable } from "../../model/identifiable";

export function useCheckboxState(data?: Identifiable[]) {
  const [selected, setSelected] = useState({} as any);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const newData = {} as any;
    if (data) {
      data.forEach(x => newData[x.id] = selected[x.id] ?? false);
      newData["-1"] = selected["-1"];
    }
    setSelected(newData);
    setIsReady(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setSelected]);

  function toggleCheckbox(id: string): void {
    if (!id) {
      return;
    }
    const newData = {
      ...selected
    }
    const toggleStatus = !selected[id];
    newData[id] = toggleStatus
    if (id === "-1") {
      Object.keys(selected).forEach(id => {
        newData[id] = toggleStatus;
      });
    }

    let isAllTrue = true;
    for (const [key, value] of Object.entries(newData)) {
      if (key !== '-1' && value === false) {
        isAllTrue = false;
      }
    }

    if (isAllTrue) {
      newData['-1'] = true;
    } else {
      newData['-1'] = false;
    }
    setSelected(newData);
  }

  function unCheckedAll(): void {
    const newData = {
      ...selected
    }
    Object.keys(selected).forEach(id => {
      newData[id] = false;
    });
    newData["-1"] = false;
    setSelected(newData);
  }

  function isChecked(id: string): boolean {
    return selected[id];
  }

  return { toggleCheckbox, selected, isChecked, unCheckedAll, isReady };
}
