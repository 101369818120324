
import { Identifiable } from "../../model/identifiable";
import { ActiveTabs } from "../../constants/enums";
import { createContext, useState, useRef, useEffect, ReactNode, useCallback} from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import { singleCustomerPageRoute } from "../../routes/RouteFactory";

export const TabContext = createContext<ContextType | null>(null);

export interface ISelection {
  sourceTab?: ActiveTabs;
  destinationTab: ActiveTabs; // context in which action occurs, ie: vehicles or delivery group
}

export type ContextType = {
  selectionGroup: ISelection;
  saveSelection: (selection: ISelection) => void;
  vehicleIdsToAddToDg: Identifiable[];
  handleManualTabClick: (destinationTab: ActiveTabs, action: string, sourceTab?: ActiveTabs) => void;
  saveVehicleIdsToAddToDg: (vehicleIds: Identifiable[]) => void;
  displaySuccessfulAction: string | null;
  saveDisplaySuccessfulAction: (value: string | null) => void;
  activeDeliveryGroupId: string | undefined;
  saveActiveDeliveryGroupId: (value: string | undefined) => void;
  activeDeliveryGroupName: string | null;
  saveActiveDeliveryGroupName: (value: string | null) => void;
  isContractCreationRequestPending: boolean;
  saveIsContractCreationRequestPending: (isSet: boolean) => void;
  hasContractInitiallyTransitionedToSuccess: boolean;
  saveHasContractInitiallyTransitionedToSuccess: (isSet: boolean) => void;
  isErrorRoute: boolean;
  saveIsErrorRoute: (isSet: boolean) => void;
};

const TabContextProvider: React.FC<ReactNode> = ({ children }) => { 
  const history = useHistory();
  const location = useLocation();
  const dgMatch = matchPath(location.pathname, {
    path: "/customer/:id/delivery-group/:dgId",
    strict: false
  });
  const noDgMatch = matchPath(location.pathname, {
    path: "/customer/:id/delivery-group/",
    strict: false
  });
  
  const params = (dgMatch?.params || noDgMatch?.params || {}) as any;
  const customerId = params.id;
  const deliveryGroupId = params.dgId;

  //controls displaying of specific tab and tab pannel also uses this for decisions 
  const [selectionGroup, setSelectionGroup] = useState<ISelection>(
    {
      destinationTab: ActiveTabs.VEHICLES,
      sourceTab: ActiveTabs.VEHICLES
    }
  );
  const saveSelection = (selection?: ISelection) => {
    if(!selection){
      setSelectionGroup({
        destinationTab: ActiveTabs.VEHICLES,
        sourceTab: ActiveTabs.VEHICLES
      });
    }

    setSelectionGroup((prev) => {
      return {
        ...selection,
        sourceTab: prev.destinationTab
      }
    });
  };


  const [vehicleIdsToAddToDg, setVehicleIdsToAddToDg] = useState<Identifiable[]>([]);
  const saveVehicleIdsToAddToDg = (vehicleIds: Identifiable[]) => {
    setVehicleIdsToAddToDg(vehicleIds);
  }

  const [displaySuccessfulAction, setDisplaySuccessfulAction] = useState<string | null>(null);
  const saveDisplaySuccessfulAction = useCallback((value: string | null) => {
    setDisplaySuccessfulAction(value);
  }, []);

  const saveActiveDeliveryGroupId = (value: string | undefined) => {
    history.push({
      pathname: singleCustomerPageRoute(customerId, value)
    });
  }
  const prevActiveDgId = useRef<string | undefined>(undefined);

  const handleManualTabClick = (destinationTab: ActiveTabs, action: string, sourceTab?: ActiveTabs) => {
    if(sourceTab === ActiveTabs.DELIVERY_GROUPS 
      && action === "create" 
      && destinationTab === ActiveTabs.VEHICLES){
      saveActiveDeliveryGroupId(undefined);
    }
    else if( sourceTab !== destinationTab){
      const newSelection = {
        ...selectionGroup,  
        destinationTab
      };
      saveSelection(newSelection);
    }
  }

  const [activeDeliveryGroupName, setActiveDeliveryGroupName] = useState<string | null>(null);
  const saveActiveDeliveryGroupName = useCallback((value: string | null) => {
    setActiveDeliveryGroupName(value);
  }, []);

  const [isContractCreationRequestPending, setIsContractCreationRequestPending] = useState<boolean>(false);
  const saveIsContractCreationRequestPending = (value: boolean) => {
    setIsContractCreationRequestPending(value);
  }
  const [hasContractInitiallyTransitionedToSuccess, setHasContractInitiallyTransitionedToSuccess] = useState<boolean>(false);
  const saveHasContractInitiallyTransitionedToSuccess = (value: boolean) => {
    setHasContractInitiallyTransitionedToSuccess(value);
  }

  const [isErrorRoute, setIsErrorRoute] = useState<boolean>(false);
  const saveIsErrorRoute = (value: boolean) => {
    setIsErrorRoute(value);
  }

  //keep everything driven by id change
  useEffect(() => {
    const prevVal = prevActiveDgId.current;
    if(prevVal !== undefined && deliveryGroupId === undefined){
      saveActiveDeliveryGroupName(null);
      setHasContractInitiallyTransitionedToSuccess(false);
    }
    if(prevVal !== undefined && prevVal !== deliveryGroupId){//if we switch dg ids then blank out action msg, except when set from falsey to value since this indicates crete DG usecase
      saveDisplaySuccessfulAction(null);
      setHasContractInitiallyTransitionedToSuccess(false);
    }
    prevActiveDgId.current = deliveryGroupId;
  }, [deliveryGroupId, saveActiveDeliveryGroupName, saveDisplaySuccessfulAction, setHasContractInitiallyTransitionedToSuccess]);

  return (
    <TabContext.Provider value={{ 
      selectionGroup,
      saveSelection,
      vehicleIdsToAddToDg,
      handleManualTabClick,
      saveVehicleIdsToAddToDg,
      displaySuccessfulAction,
      saveDisplaySuccessfulAction,
      activeDeliveryGroupId: deliveryGroupId,
      saveActiveDeliveryGroupId,
      activeDeliveryGroupName,
      saveActiveDeliveryGroupName,
      isContractCreationRequestPending,
      saveIsContractCreationRequestPending,
      hasContractInitiallyTransitionedToSuccess,
      saveHasContractInitiallyTransitionedToSuccess,
      isErrorRoute,
      saveIsErrorRoute
    }}>
      {children}
    </TabContext.Provider>
  );
};

export default TabContextProvider;
