import { SortingRule } from 'react-table'
import { SortableColumn } from '../types'

type SortBy<D extends Record<string, any>> = {
  sortBy: SortingRule<D>[]
} | null

const getSortBy = <D extends Record<string, any>>(
  columns: SortableColumn<D>[],
): SortBy<D> => {
  const sortedColumn = columns.find(
    ({ isSortable, initialSort }) => isSortable && initialSort,
  )

  if (!sortedColumn) return null

  const getId = (): string | null => {
    const { id, accessor } = sortedColumn

    if (typeof id === 'string') return id

    if (typeof accessor === 'string') return accessor

    return null
  }

  const id = getId()

  if (!id) return null

  return {
    sortBy: [
      {
        id,
        desc: sortedColumn.initialSort === 'desc',
      },
    ],
  }
}

export default getSortBy
