import { User } from "./User";

export function emitAssetContractError(user: User, aggregatedErrors: string){
  if(aggregatedErrors.length > 0){
    window.allytm.event("customError", aggregatedErrors, null, {user, isFormError: true});
  }
}
export function emitAssetContractSection2Error(user: User, errorMsg: string){
  window.allytm.event("customError", "form section 2 error: " + errorMsg, "400", {user, isFormError: true});
}
