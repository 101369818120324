import { useQuery } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { Customer, customerDtoToModel } from "../../model/customer.model";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { singleCustomerKey } from "../../utils/react-query-key-factory";
import { useHistory } from "react-router";





export function useGetCustomer(dealershipNumber: string, customerId: string) {
  const { get } = useHttp();
  const analytics = useAnalytics();
  const history = useHistory();

  async function getCustomerFromBackend(get: <Result>(url: string) => Promise<Result | null>,
    dealershipNumber: string, customerId: string): Promise<Customer | null> {

    try {
      let customerEndpoint = `dealership/${dealershipNumber}/customers/${encodeURIComponent(customerId)}`;
      const res = await get<any>(customerEndpoint);
      return customerDtoToModel(res);
    } catch (err) {
      const errorString = err + '';
      if (errorString.includes('404')) {
        history.push('/not-found');
      }
    }
  }
  return useQuery(
    singleCustomerKey(dealershipNumber, customerId),
    () => getCustomerFromBackend(get, dealershipNumber, customerId),
    {
      onError(error) {
        console.error("Oops something went wrong. Please try again later ", error);
        analytics(emitErrorEvent, (error as any).message ?? "get customer error", (error as any).status ?? 500);
      },
    });
}
