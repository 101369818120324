import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Row } from 'react-table'
import { muiChevronDown, muiChevronUp } from '@ally/metronome-icons'
import { TableCardListProps } from '.'

import {
  UseDrawerProps,
  DrawerProvider,
  getPublicRowStateProps,
} from '../utils'
import { getBoxShadow } from '../shared'
import { Button, get } from '@ally/metronome-ui'

interface CardDrawerProps<D extends Record<string, any>>
  extends Pick<TableCardListProps<D>, 'drawer' | 'mdDownDrawerName'>,
    Omit<UseDrawerProps, 'drawerTriggerRef'> {
  row: Row<D>
}

const StyledDrawer = styled.div`
  && {
    padding: ${get.space('xs')};
    border-top: 1px solid ${get.colors('grey-80')};
    background-color: ${get.colors('light-gray')};
  }
`

const StyledToggle = styled.div`
  && {
    padding: ${get.space('xs')};
    ${getBoxShadow(true)}
    border-top: 1px solid ${get.colors('grey-80')};
    background-color: ${get.colors('white')};
    text-align: center;
  }
`

const CardDrawer = <D extends Record<string, any>>({
  mdDownDrawerName,
  drawer,
  toggleDrawer,
  openDrawerWithToggle,
  closeDrawerAndFocus,
  isDrawerOpen,
  drawerToggleRef,
  drawerTargetRef,
  row,
}: CardDrawerProps<D>): ReactElement => {
  return (
    <>
      {isDrawerOpen && (
        <StyledDrawer>
          <DrawerProvider
            value={{
              row,
              ...getPublicRowStateProps<D>(row),
              closeDrawer: closeDrawerAndFocus,
              targetRef: drawerTargetRef,
            }}
          >
            {drawer}
          </DrawerProvider>
        </StyledDrawer>
      )}
      <StyledToggle>
        <Button
          variant="link"
          text={mdDownDrawerName}
          icon={{
            map: isDrawerOpen ? muiChevronUp : muiChevronDown,
            position: 'right',
          }}
          onClick={(): void => {
            if (!isDrawerOpen) {
              openDrawerWithToggle()
            } else {
              toggleDrawer(false)
            }
          }}
          aria-expanded={isDrawerOpen ? 'true' : 'false'}
          ref={drawerToggleRef}
          data-track-elem="button"
          data-track-name={mdDownDrawerName}
          data-track-state={isDrawerOpen ? 'opened' : 'closed'}
        />
      </StyledToggle>
    </>
  )
}

export default CardDrawer
