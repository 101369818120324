import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ValidationError } from "../adaptorHooks/validationError";
import { EDIT_DELIVERY_GROUP_EMAIL_MUTATION_KEY_ROOT, allAssetContractsForDealershipKey, multipleCustomersKey, multipleDeliveryGroupsKey, singleCustomerKey, singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";
import { EmailType } from "../../components/Tab/DeliveryGroupTabViews/VerifyEmails";

async function putDgEmail(put: <In, Out>(url: string, body: In) => Promise<Out | null>,
  dealershipNumber: string, buyerSignerEmail: string, cobuyerEmail: string, sellerEmail: string, deliveryGroupId?: string): Promise<void | null> {
  let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/update-email`;
  let body = {buyerSignerEmail, cobuyerEmail, sellerEmail}

  return put<any, void>(dgEndpoint, body);
}

type UpdateDgEmail = {
    onUpdate: (id: number) => void;
  onError: () => void;
    emailType: EmailType;
    email: string;
}

export function useUpdateDeliveryGroupEmail( dealershipNumber: string, customerId: string, deliveryGroupId?: string) {
    const { put } = useHttp();
    const queryClient = useQueryClient();
    const analytics = useAnalytics();
    const dgCollectionQueryKey = multipleDeliveryGroupsKey(dealershipNumber, customerId);

    return useMutation([EDIT_DELIVERY_GROUP_EMAIL_MUTATION_KEY_ROOT],
        async (dgEmail: UpdateDgEmail) => {
          let buyerSignerEmail, cobuyerEmail, sellerEmail;

          switch(dgEmail.emailType){
            case EmailType.buyerSigner: 
              buyerSignerEmail = dgEmail.email
              break;
            case EmailType.cobuyer: 
              cobuyerEmail = dgEmail.email
              break;
            case EmailType.seller:
              sellerEmail = dgEmail.email
              break;
          }

          await putDgEmail(put, dealershipNumber, buyerSignerEmail, cobuyerEmail, sellerEmail, deliveryGroupId)},
        {
          onSuccess: (data, updateDgEmail, context) => {
            updateDgEmail.onUpdate(data! as any);
          },
          onSettled: (data, error, dgEmail: UpdateDgEmail) => {
            queryClient.invalidateQueries(dgCollectionQueryKey);
            queryClient.invalidateQueries(singleDeliveryGroupsKey(dealershipNumber, deliveryGroupId));
            queryClient.invalidateQueries(singleCustomerKey(dealershipNumber, customerId));
            queryClient.invalidateQueries(multipleCustomersKey(dealershipNumber));
            queryClient.invalidateQueries(allAssetContractsForDealershipKey(dealershipNumber));
          },
          onError: (error, updateDgEmail) => {
            updateDgEmail.onError()
            if (error instanceof ValidationError) {
              if (error.validationErrors.length > 0) {
                error.validationErrors.forEach((errorString) =>
                  analytics(emitErrorEvent, errorString.message, error.status)
                );
              }
            }
            else {
              analytics(emitErrorEvent, (error as any).message ?? "update delivery group email error", (error as any).status ?? 500);
            }
          },
          retry: 2 // put is indempotent as an upsert so retry should be okay
        });
    }

