
import styled from "styled-components";

export const MainHeader = styled.main`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 220px);
    h1 {
        line-height: 34px;
        font-weight: 700;
        color: black;
        font-size: 30px;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #2A2A2A;
        max-width: 665px;
    }
`;


