import {
  Box,
  ButtonGroup,
  FormText,
  Icon,
} from "@ally/metronome-ui";
import styled from "styled-components";

export const BoxStyle = styled(Box)`
  padding: 0;
  div[data-testid="mui-dejargonator-trigger"] {
    pointer-events: none;
    cursor: default;
  }
  label {
    margin-top: 21px;
  }
`;

export const DeliveryFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-bottom: 21px;
  margin-top: 20px;

  label {
    height: 20px;
    width: 91px;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 1px;
  }

  div {
    outline: none;
  }

  div > span > input {
    background-color: #f9f9f9;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 4px 4px 0 0;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 35px;
`;

export const StyledIcon = styled(Icon)`
  margin-bottom: 8px;
  margin-left: 8px;
  height: 10px;
`;

export const StyledInfoIcon = styled(Icon)`
  margin: 4px 8px 0 0;
`;

export const SubMenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: #0071c4;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding-right: 10px;
  outline: none;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:focus {
    outline: none;
  }
  link {
    outline: none;
  }
  &:hover {
  }
`;

export const SubMenuText = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
  max-width: 900px;
  max-height: 40px;
`;

export const IconStoryWrapper = styled.div`
  margin-top: 16px;
  display: flex;
`;
