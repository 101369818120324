import { useContext, useEffect } from "react";
import { TabContext, ContextType } from "../TabContext";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import ContractedDeliveryGroupDetails from "./DeliveryGroupDetails/ContractedDeliveryGroupDetails";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { Link } from "@ally/metronome-ui";
import { ThanksWrapper, StyledIcon, InfoWrapper, DetailsWrapper } from "./ContractFullyCompletedStyled";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { DownloadContractButton } from "./DownloadContractButton";
import { muiCheckCircleFill } from "@ally/metronome-icons";

type Props = {
  isActiveTab: boolean;
};
const ContractFullyCompleted: React.FC<Props> = ({ isActiveTab }) => {
  const analytics = useAnalytics();

  const {
    saveActiveDeliveryGroupName,
  } = useContext(TabContext) as ContextType;
  const finalDgMessage = "We'll remove your delivery group details from your dashboard after 60 days."
  const progressTracker = <ContractProgressTracker stepNumber={5} />;

  useEffect(() => {
    if (isActiveTab) {
      analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_COMPLETE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);

  return (
    <>
      <DeliveryGroupHeader />
      <ContractedDeliveryGroupDetails
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}
        deliveryGroupStatus={"FUNDING_CONFIRMED"}
        deliveryGroupStatusMessage={finalDgMessage}
      />
      <DownloadContractButton />
      <DetailsWrapper>
        <StyledIcon
          icon={muiCheckCircleFill}
          size="xl"
          fill="success-green" 
          ariaHidden
        />
        <ThanksWrapper>
          <h4> Thank you for using Ally CSG.</h4>
          <InfoWrapper>
            If you have any questions after your contract is funded, you can email Ally CSG at <Link
              text=" AllyCSG@ally.com"
              href='mailto:AllyCSG@ally.com'
              variant="link"
              allytmln="open email allycsg@ally.com"
            />
            , or call the Ally Dealer Service Center at 1-888-919-2559,
            Monday – Friday, 9:30 am – 6:30 pm ET and Saturday 9 am – 6 pm ET.
          </InfoWrapper>
        </ThanksWrapper>
      </DetailsWrapper>
    </>
  );
};

export default ContractFullyCompleted;
