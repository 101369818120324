import { ContractedDeliveryGroupStatus } from "../model/asset-contract.model";
import { Customer } from "../model/customer.model";
import { ReadDeliveryGroup } from "../model/delivery-group.model";
import { TableCustomer } from "../model/display/table-customer";
import { TableDeliveryGroup } from "../model/display/table-delivery-group";
import { TableVehicle } from "../model/display/table-vehicle";
import { ReadVehicle } from "../model/vehicle.model";

export function convertDeliveryGroupWithoutContractToTableData(deliveryGroup: ReadDeliveryGroup, deliveryGroupStatus: "OPEN" | "READY_FOR_CONTRACT"): TableDeliveryGroup {
  return {
    id: deliveryGroup.id,
    customer_id: deliveryGroup.customer_id,
    customer_name: deliveryGroup.customer_name,
    created_on: convertDateToTwoDigitString(deliveryGroup.created_on),
    group_name: deliveryGroup.group_name,
    delivery_status: convertDeliveryStatusToString(deliveryGroupStatus),
    contract_date: convertDateToTwoDigitString(deliveryGroup.contract_date?.toLocaleString()),
    first_payment_due_date: convertDateToTwoDigitString(deliveryGroup.first_payment_due_date?.toLocaleString())
  };
}

export function convertDeliveryGroupWithContractToTableData(deliveryGroup: ReadDeliveryGroup, contractStatus: ContractedDeliveryGroupStatus): TableDeliveryGroup {
  return {
    id: deliveryGroup.id,
    customer_id: deliveryGroup.customer_id,
    customer_name: deliveryGroup.customer_name,
    created_on: convertDateToTwoDigitString(deliveryGroup.created_on),
    group_name: deliveryGroup.group_name,
    delivery_status: convertContractStatusToString(contractStatus),
    contract_date: convertDateToTwoDigitString(deliveryGroup.contract_date?.toLocaleString()),
    first_payment_due_date: convertDateToTwoDigitString(deliveryGroup.first_payment_due_date?.toLocaleString())
  };
}

export function convertCustomersToTableData(customers: Customer[]): TableCustomer[] {
  const tableCustomers = customers.map(customer => {
    return {
      company: customer.company,
      tax_id: customer.tax_id,
      address: customer.address,
      city: customer.city,
      county: customer.county,
      state: customer.state,
      zip: customer.zip,
      totalApps: customer.totalApps,
      last_activity: customer.last_activity?.toISOString()
    } as TableCustomer;
  });

  tableCustomers.sort((a, b) => a.company.localeCompare(b.company));

  for (let i = 0; i < tableCustomers.length; i += 1) {
    tableCustomers[i].alphabeticalIndex = i;
  }

  return tableCustomers;
}
export function convertVehiclesToCloneTableData(vehicles: ReadVehicle[] | undefined, isCloned: Set<string>): TableVehicle[] {
  return vehicles?.map(vehicle => {
    return {
      id: vehicle.id,
      appId: vehicle.appId,
      customerId: vehicle.customerId,
      appDecision: convertAppDecisionToString(vehicle.appDecision),
      vin: vehicle.vin?.toUpperCase() ?? "-",
      dealStatus: convertCloneDealStatusToString(vehicle.dealStatus, isCloned.has(vehicle.appId)),
      isReadyForContract: isReadyForContract(vehicle.dealStatus),
      condition: vehicle.condition,
      yearMakeModel: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
      lastUpdatedDate: vehicle.lastUpdatedDate?.toISOString(),
      year: vehicle.year,
      odometer: vehicle.odometer,
      model: vehicle.model.trim(),
      vehicleMake: vehicle.make.trim(),
      grossWeight: vehicle.grossWeight
    };
  }) ?? [];
}
export function convertVehiclesToTableData(vehicles?: ReadVehicle[]): TableVehicle[] {
  return vehicles?.map(vehicle => {
    return {
      id: vehicle.id,
      appId: vehicle.appId,
      customerId: vehicle.customerId,
      appDecision: convertAppDecisionToString(vehicle.appDecision),
      vin: vehicle.vin?.toUpperCase() ?? "-",
      dealStatus: convertDealStatusToString(vehicle.dealStatus),
      isReadyForContract: isReadyForContract(vehicle.dealStatus),
      condition: vehicle.condition,
      yearMakeModel: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
      lastUpdatedDate: vehicle.lastUpdatedDate?.toISOString(),
      year: vehicle.year,
      odometer: vehicle.odometer,
      model: vehicle.model.trim(),
      vehicleMake: vehicle.make.trim(),
      grossWeight: vehicle.grossWeight
    };
  }) ?? [];
}

function convertDateToTwoDigitString(date?: string | null | Date): string {
  if (!date) {
    return "";
  }

  return date.toLocaleString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}

function convertCloneDealStatusToString(dealStatus: string, isCloned: boolean): string {
  if(dealStatus === "READY_FOR_CONTRACT"){
    return isCloned ? "Ready for Contract - Cloned" : "Ready for Contract"
  }
  else{
    return isCloned ? "In Progress - Cloned" : "In Progress"
  }

}

function convertDealStatusToString(dealStatus: string): string {
  switch(dealStatus) {
    case "READY_FOR_CONTRACT":
      return "Ready for Contract";
    default:
      return "In Progress";
  }
}
function isReadyForContract(dealStatus: string): boolean {
  return dealStatus === "READY_FOR_CONTRACT";
}

function convertAppDecisionToString(appDecision: string): string {
  switch(appDecision) {
    case "CONDITIONAL":
      return "Conditional";
    default:
      return "Approved";
  }
}

function convertDeliveryStatusToString( deliveryGroupStatus: "OPEN" | "READY_FOR_CONTRACT"): string {
  return deliveryGroupStatus === "OPEN" ? "Open" : "Contract Ready";
}

function convertContractStatusToString( contractStatus: ContractedDeliveryGroupStatus): string {
  switch(contractStatus) {
    case "CONTRACT_CREATED":
      return "Contract Created";
    case "CONTRACT_FINALIZED":
      return "Funding In-Progress";
    case "FUNDING_CONFIRMED":
      return "Funding Complete";
    default:
      return "Unknown";
  }
}
