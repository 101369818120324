import React from 'react'
import { HeaderGroup } from 'react-table'

import { StyledTableCard, Header, HeaderLabel } from './shared'
import { TableSpinner } from '../TableSpinner'

interface EmptyCardProps<D extends Record<string, any>> {
  headerGroups: HeaderGroup<D>[]
  isLoading?: boolean
}

const EmptyCard = <D extends Record<string, any>>({
  headerGroups,
  isLoading,
}: EmptyCardProps<D>): React.ReactElement => {
  const { render } = headerGroups[0].headers[0]
  return (
    <StyledTableCard tabIndex={-1}>
      <Header>
        <HeaderLabel>{render('Header')}</HeaderLabel>
      </Header>
      {isLoading ? <TableSpinner /> : null}
    </StyledTableCard>
  )
}

export default EmptyCard
