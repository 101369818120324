import React from "react";
import {
  TextHeading,
  useModalContext,
} from "@ally/metronome-ui";
import "moment-timezone";
import { LinkButtonStyle } from "../Form/Action-DeliveryGroup/ActionDeliveryGroupStyles";
import { CancelContractCreationButtonStyle, ContinueContractActionButtonStyle, ContractActionModalBody, ContractModalActionButtonContainer, ExitFinalizeErrorButton } from "./ContractModalsStyled";
import { CancelContractActionButtonStyled, ContractActionButtonStyled } from "../Tab/DeliveryGroupTabViews/DeliveryGroupDetails/DeliveryGroupDetailsStyled";
import { finalizeStepProcessNumber } from "../Tab/DeliveryGroupTabViews/ContractFinalizing";
import { useAtom } from "jotai";

type ContractActionType = "Fund" | "Finalize" | "Cancel";
export const FinalizeContractModalTrigger: React.FC<{
  initModal: Function;
  setOpenFinalizeContract: any;
}> = ({ initModal, setOpenFinalizeContract }) => {
  const [, setFinalizeStepProcessNumber] = useAtom(finalizeStepProcessNumber)

  return (
    <ContractActionButtonStyled
      variant="primary"
      text="Finalize Contract"
      id="Finalize_contract_button"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        initModal();
        setOpenFinalizeContract(true);
        setFinalizeStepProcessNumber(3);
      }}
      aria-label="Finalize Contract"
      allytmln="finalize-contract"
    />
  );
};

export const FundContractModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();  
  return (
    <ContractActionButtonStyled
      variant="primary"
      text="Confirm"
      allytmln="confirm-contract-funding" 
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
      aria-label="Confirm"
    />
  );
};

export const CancelContractModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();
  return (
    <CancelContractActionButtonStyled
      variant="link" 
      text="Edit Contract"
      allytmln="edit-contract" 
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
      aria-label="Edit Contract Group"
    />
  );
};

export const CancelButtonModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();
  return <LinkButtonStyle text="Cancel" variant="link" onClick={() => setOpen(true)} />;
};

export type CancelModalContentProps = {

  headingText?: string;
};

export type ContractActionModalContentProps = {
  contractAction: (closeModal? : () => void) => void;
  actionType: ContractActionType;
  isContractActionPending: boolean;
  errors: string[]
  closeModal?: () => void
  setFinalizeStepProcessNumber?: (number) => void;
  
};

const initContractActionModalContent = ({
  contractAction,
  actionType,
  isContractActionPending,
  setFinalizeStepProcessNumber,
  closeModal
}: ContractActionModalContentProps) => {

  let headerBanner;
  let headerBody;
  let buttonText;

  if(actionType === "Finalize"){    
    buttonText = "Continue";
    headerBanner = <TextHeading size="sm" tag="h1" focusOnMount>
      Finalize Contract
    </TextHeading>;
    headerBody = <ContractActionModalBody size="md" tag="span">
      <p>
        Send us your signed documents so we can start the funding process.
      </p>
    </ContractActionModalBody>;
  }
  else if(actionType === "Fund"){
    buttonText = "Confirm";
    headerBanner = <TextHeading size="sm" tag="h1" focusOnMount>
      Confirm Funding
    </TextHeading>;
    headerBody = <ContractActionModalBody size="md" tag="span">
      <p>
        Make sure your dealership has received funding for all the vehicles included in your contract 
        before selecting <b>Confirm</b>. Selecting <b>Confirm</b> will remove your contract from your dashboard.
      </p>
    </ContractActionModalBody>;
  }
  else if(actionType === "Cancel"){
    buttonText = "Continue";
    headerBanner = <TextHeading size="sm" tag="h1" focusOnMount>
      Are you sure you want to edit the contract?
    </TextHeading>;
    headerBody = <ContractActionModalBody size="md" tag="span">
      <p>
        If you select <b>Continue</b>, you'll go to the Create 
        Contract step where you can edit your vehicle information.
      </p>
    </ContractActionModalBody>;
  }
  //TS isn't smart enough to tell this won't happen :(
  else{
    buttonText = "Continue";
    headerBanner = <TextHeading size="sm" tag="h1" focusOnMount>
      Contract Action
    </TextHeading>;
    headerBody = <ContractActionModalBody size="md" tag="span">
      <p>
        Continue to perform action on contract
      </p>
    </ContractActionModalBody>;
  }
  const confirmAllyTM = `confirm-${actionType.toLocaleLowerCase()}-contract`;
  const cancelAllyTM = `undo-${actionType.toLocaleLowerCase()}-contract`;
  return (
    <>
      {headerBanner}
      {headerBody}
      <ContractModalActionButtonContainer>
        <ContinueContractActionButtonStyle
          aria-label="continue button"
          text={buttonText}
          variant="primary"
          allytmln={confirmAllyTM}
          loading={isContractActionPending}
          onClick={() => {contractAction(closeModal)}}
        />
        <CancelContractCreationButtonStyle
          aria-label="Go Back"
          text="Go Back"
          allytmln={cancelAllyTM}
          variant="secondary"
          onClick={() => {
            setFinalizeStepProcessNumber(2);
            closeModal();
          }}
        />
      </ContractModalActionButtonContainer>

    </>
  );
}
const errorModal = ({ closeModal, errors, actionType }: ContractActionModalContentProps) => {
  if(actionType === "Finalize"){
    return (
      <>
        <TextHeading size="sm" tag="h1" focusOnMount>
        Can&#39;t Finalize Contract
        </TextHeading>
        <ContractActionModalBody size="md" tag="span">
          <>
          <p>You need to wait until your contract is created and signed before selecting <b>Finalize Contract</b>.</p>
          </>
        </ContractActionModalBody>
        <ExitFinalizeErrorButton
          aria-label="Go back"
          text="Go Back"
          variant="primary"
          allytmln="exit-finalize-modal-error"
          onClick={closeModal}
        />
      </>
    );
  }
  else if(actionType === "Fund"){
    return (
      <>
        <TextHeading size="sm" tag="h1" focusOnMount>
        Can&#39;t Mark Contract as Funding Complete
        </TextHeading>
        <ContractActionModalBody size="md" tag="span">
          <>
          <p>Some issues occured while trying to fund the given contract</p>
          {errors.map( (e,i) => <p key={i}>{e}</p>)}
          </>
        </ContractActionModalBody>
      </>
    );
  }
  //TS isn't smart enough to tell this won't happen :(
  else{
    return (
      <>
        <TextHeading size="sm" tag="h1" focusOnMount>
        Something went wrong
        </TextHeading>
        <ContractActionModalBody size="md" tag="span">
          <>
          <p>Some issues occured while trying to fund the given contract</p>
          {errors.map( (e,i) => <p key={i}>{e}</p>)}
          </>
        </ContractActionModalBody>
      </>
    );
  }
}

export const ContractActionModalContent: React.FC<ContractActionModalContentProps> = ({
  contractAction,
  actionType,
  isContractActionPending,
  errors,
}) => {
  const [, setFinalizeStepProcessNumber] = useAtom(finalizeStepProcessNumber);
  const { setOpen } = useModalContext();
  const closeModal = () => setOpen(false);
  return (errors && errors.length > 0)
    ? errorModal({closeModal, actionType, contractAction, isContractActionPending, errors})
    : initContractActionModalContent({actionType, contractAction, closeModal, isContractActionPending, errors, setFinalizeStepProcessNumber});
};
