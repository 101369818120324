import { Button } from "@ally/metronome-ui";
import styled from "styled-components";

export const MailContractOuter = styled.div`
    max-width: 67%;
    margin-bottom: 80px;
`

export const MailDocumentsStyled = styled.div`
    width: 618px;
    h3 {
        line-height: 28px;
        font-size: 20px;
        margin-bottom: 8px;
    };
    p {
        margin-bottom: 8px;
    };
    ul {
        line-height: 24px;
        margin-left: 20px;
        span {
            margin-left: -5px;
        }
    }
`

export const MailingOptionsOuter = styled.div`
    margin: 32px 0px 32px 0px;
    line-height: 20px;
    width: 660px;
`

export const MailingOptionsInner = styled.div`
    display: flex;
    column-gap: 132px;
    div {
        margin-top: 8px;
        width: 180px;
        line-height: 24px;
        p {
            margin-top: 8px;
            white-space: pre-wrap;
        }
    };
    p{
        margin: 32px 0px 32px 0px; 
    }
`

export const ConfirmButton = styled(Button)`
  width: 106px;
  height: 44px;
`
export const SigningOptionsButton = styled.div`

`

export const EditContractLink = styled.div`
    button {
        margin-top: 12px;
        margin-left: -1px;
    }
`

