import React, { useEffect } from "react";
import { StepStyle, StepperBox, StepperContainer } from './ContractProgressTrackerStyled';
import { useFlags } from "launchdarkly-react-client-sdk";
import { StepLabel } from "@material-ui/core";


type Props = {
  stepNumber: number;
};

export const ContractProgressTracker: React.FC<Props> = ({ stepNumber }) => {

  const [activeStep, setActiveStep] = React.useState(0);
  //TODO need to integrate setSkipped or remove variable to avoid warning
  //eslint-disable-next-line
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const { ffDeliveryGroupsDocusignOptions } = useFlags();
  const { ffCsgDdsFileSubmission } = useFlags();

  const steps = (ffDeliveryGroupsDocusignOptions && ffCsgDdsFileSubmission)
    ? ['Create group', 'Create contract', 'Signing options', 'Gather signatures', 'Send contract']
    : (ffDeliveryGroupsDocusignOptions)
      ? ['Create group', 'Create contract', 'Signing options', 'Gather signatures', 'Mail contract']
      : ['Create Group', 'Create Contract', 'Signing Options', 'Finalize Contract', 'Funding Complete'];

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  useEffect(() => {
    setActiveStep(stepNumber);

  }, [stepNumber])

  return (
    <StepperBox sx={{ width: '100%' }}>
      <StepperContainer activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <StepStyle key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </StepStyle>
          );
        })}
      </StepperContainer>
    </StepperBox>
  );
}

