import {
  Box,
  FormText,
} from "@ally/metronome-ui";
import styled from "styled-components";

export const BoxStyle = styled(Box)`
  padding: 0;
  div[data-testid="mui-dejargonator-trigger"] {
    cursor: default;
    pointer-events: none;
  }
  label {
    margin-top: 21px;
  }
  #Yes {
    margin-top: 20px;
  }
  #No {
    margin-top: 0px;
  }
`;
export const DeliveryFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-bottom: 21px;
  margin-top: 20px;

  label {
    height: 20px;
    width: 91px;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 1px;
  }

  div {
    outline: none;
  }

  div > span > input {
    background-color: #f9f9f9;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 4px 4px 0 0;
  }
`;

export const OtherFeeWrapper = styled.div`
  margin-top: 27px;
  color: #2a2a2a;
  h4 {
    font-size: 16px;
    line-height: 16px;
  }
  .other_fee {
    border-left: 1px solid #8391af;
    padding-left: 20px;
  }

  .trash_container {
    display: flex;
  }

  .trash_icon {
    margin-left: 20px;
  }
`;

export const StyledRadioLabel = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  color: #000000;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -21px;
`;
