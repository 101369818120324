import React, { ReactElement } from 'react'
import { Row, Cell } from 'react-table'
import styled from 'styled-components'
import { TableCardListProps } from '.'
import { Box } from '../../Box'
import CardDrawer from './CardDrawer'
import { CardLabel, CardValue } from './shared'
import { CellDrawerProps, UseDrawerProps } from '../utils'
import { get } from '@ally/metronome-ui'

interface CardDataProps<D extends Record<string, any>>
  extends Pick<
    TableCardListProps<D>,
    'showFromMdDown' | 'drawer' | 'mdDownDrawerName' | 'mdDownColumnFullWidth'
  > {
  row: Row<D>
  drawerProps: UseDrawerProps
  cellDrawerProps: CellDrawerProps
}

const StyledCardData = styled.div<{ mdDownColumnFullWidth: boolean }>`
  && {
    padding: ${get.space('xs')} ${get.space('xs')} 0 ${get.space('xs')};
    color: ${get.colors('slate-5')};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const getDisplayedCells = <D extends Record<string, any>>(
  showFromMdDown: string[],
  row: Row<D>,
): Cell<D>[] => {
  const base = row.cells.slice(1)
  const showAll = !showFromMdDown.length

  // if nothing is included in the `showFromMdDown` array, then return all the
  // cells
  if (showAll) {
    return base
  }

  // otherwise, only return those cells that have a matching header name in
  // the `showFromMdDown` array
  return base.reduce((acc: Cell<D>[], cell) => {
    const { column } = cell
    const shouldShow =
      typeof column.Header === 'string' &&
      showFromMdDown.includes(column.Header)

    if (shouldShow) {
      return [...acc, cell]
    }
    return acc
  }, [])
}

const CardData = <D extends Record<string, any>>({
  row,
  showFromMdDown = [],
  drawer,
  drawerProps,
  cellDrawerProps,
  mdDownDrawerName = 'Details',
  mdDownColumnFullWidth = false,
}: CardDataProps<D>): ReactElement => {
  const displayedCells = getDisplayedCells<D>(showFromMdDown, row)
  return (
    <>
      <StyledCardData mdDownColumnFullWidth={mdDownColumnFullWidth}>
        {displayedCells.map((cell): React.ReactElement | null => {
          const { column } = cell

          return (
            <Box
              key={column.id}
              display="flex"
              flexDirection="column"
              flex={`0 ${mdDownColumnFullWidth ? '100%' : '50%'}`}
              mb="xs"
            >
              <CardLabel flex="0 50%">{column.render('Header')}</CardLabel>
              <CardValue flex="0 50%" alignItems="flex-end">
                {cell.render('Cell', cellDrawerProps)}
              </CardValue>
            </Box>
          )
        })}
      </StyledCardData>
      {drawer && (
        <CardDrawer
          mdDownDrawerName={mdDownDrawerName}
          drawer={drawer}
          row={row}
          {...drawerProps}
        />
      )}
    </>
  )
}

export default CardData
