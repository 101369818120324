import React, { ReactElement } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { TableInstance } from 'react-table'
import { LgUpProps, SharedProps } from '../Table'
import { TableSpinner } from '../TableSpinner'
import Caption from './Caption'
import Header from './Header'
import Footer from './Footer'
import TableRow from './TableRow'
import { getBoxShadow } from '../shared'
import { get } from '@ally/metronome-ui'

export interface LgUpTableProps<D extends Record<string, any>>
  extends LgUpProps<D>,
    SharedProps<D> {
  tableInstance: TableInstance<D>
}

interface StyledTableProps {
  container?: boolean
  fullWidth?: boolean
}

const StyledTable = styled.table<StyledTableProps>`
  font-family: ${get.fonts('regular')};
  border: 1px solid ${get.colors('grey-80')};

  ${({ container }): FlattenSimpleInterpolation =>
    container ? getBoxShadow() : css``}

  ${({ fullWidth }): string => {
    if (fullWidth) return 'width: 100%'

    return ''
  }}
`

const StyledThead = styled.thead`
  background-color: ${get.colors('yacht-rock')};
  border-bottom: 1px solid ${get.colors('grey-80')};
`

const StyledTbody = styled.tbody`
  overflow: visible;
`

const StyledTfoot = styled.tfoot`
  background-color: ${get.colors('white')};
`

const StyledTRow = styled.tr`
  background-color: ${get.colors('white')};
`

const Spinner = <D extends Record<string, any>>({
  headerGroups,
}: Pick<TableInstance<D>, 'headerGroups'>): React.ReactElement => {
  return (
    <StyledTRow>
      <td colSpan={headerGroups[0].headers.length}>
        <TableSpinner />
      </td>
    </StyledTRow>
  )
}

const LgUpTableRefForwarding = <D extends Record<string, any>>(
  {
    tableInstance,
    className,
    container,
    captionText,
    isCaptionVisible = false, // hide caption from visual users, but it is still readable by screen readers
    fullWidth,
    withFooter = false,
    isLoading,
    ...rowProps
  }: LgUpTableProps<D>,
  ref: React.Ref<HTMLTableElement>,
): ReactElement => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    preExpandedRows,
    prepareRow,
  } = tableInstance

  return (
    <StyledTable
      {...getTableProps()}
      className={className}
      container={container}
      fullWidth={fullWidth}
      ref={ref}
      tabIndex={-1}
    >
      <Caption {...{ captionText, isCaptionVisible }} />
      <StyledThead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <Header key={column.id} column={column} />
            ))}
          </tr>
        ))}
      </StyledThead>
      {isLoading ? (
        <Spinner headerGroups={headerGroups} />
      ) : (
        <StyledTbody {...getTableBodyProps()}>
          {preExpandedRows.map((row, index) => {
            prepareRow(row)

            return (
              <TableRow
                key={row.id}
                {...{
                  row,
                  index,
                  tableInstance,
                  ...rowProps,
                }}
              />
            )
          })}
        </StyledTbody>
      )}
      {withFooter && (
        <StyledTfoot>
          {footerGroups.map(group => (
            <tr {...group.getFooterGroupProps()}>
              {group.headers.map(column => (
                <Footer key={column.id} column={column} />
              ))}
            </tr>
          ))}
        </StyledTfoot>
      )}
    </StyledTable>
  )
}

const LgUpTable = React.forwardRef(LgUpTableRefForwarding) as <
  D extends Record<string, any>,
>(
  props: LgUpTableProps<D> & { ref?: React.Ref<HTMLTableElement> },
) => React.ReactElement

export default LgUpTable
