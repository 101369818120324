import { FFHeading, IconContainer, OverlaySection, FFParagraphContent } from "./SplashStyles";
import { useEffect } from "react";
import { useAnalytics } from "../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../analytics/pageViews";

export const UpdatedStateNotSupported = () => {
  const stateNotSupported = (
    <>
    The Commercial Services Group multi asset contract is <b>not currently available</b> in AK, DC, HI, KS, ME, MS, MT, ND, NM, NV, RI, SD, VT, WV, and WY. To proceed, you'll need to create a retail installment contract.
    </>
  )
  const title = "Commercial customer dashboard";
  const analytics = useAnalytics();

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.STATE_NOT_SUPPORTED_LANDING_PAGE);
    //TODO address analytics missing in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return(
    <OverlaySection>
      <FFHeading>{title}</FFHeading>
      <IconContainer>
        <FFParagraphContent>
            {stateNotSupported}
        </FFParagraphContent>
      </IconContainer>
    </OverlaySection>
  );
};

export default UpdatedStateNotSupported;
