import { useOktaAuth } from "@okta/okta-react";
import { User } from "../../analytics/User";
import { useDealershipInfo } from "../useDealershipInfo";
import { useMemo } from "react";

export function useUser(): User | null{
  const { authState } = useOktaAuth();
  const [dealershipInfo] = useDealershipInfo();

  const user = useMemo(() => ({
    dealershipName: dealershipInfo?.dealershipName,
    pdn: dealershipInfo?.dealershipNumber,
    dealerUserId: authState?.accessToken?.claims?.uid?.toString(),
    userType: authState?.accessToken?.claims?.userType?.toString(),
  }), [dealershipInfo, authState]);

  return user;
}
