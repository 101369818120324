import { Button, Link as MetroLink} from "@ally/metronome-ui";
import styled from "styled-components";

export const CancelFinalizeContractButton = styled(Button)`
  min-width: 108px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 12px;
  border-radius: 4px;
  margin-left: 20px;
  font-weight: bold;
`;

export const FundingSpecificWrapper = styled.div`
  max-width: 67%;
  line-height: 24px;
  font-size: 14px;
`;
export const ContractLinksWrapper = styled.div`
    display: flex;
  flex-direction: column;
`
export const FundingInfoWrapper = styled.div`
  margin: 20px 0 10px 0;
`;
export const Link = styled(MetroLink)`
  dispaly: block;
`;
