import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import {
  DealershipInformation as DealerInfoModel,
  ReadAssetContract,
  WriteAssetContract,
  WriteAssetContractSections,
  WriteAssetContractSectionsNames
} from "../../../model/asset-contract.model";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import { BasicTextField, EmailField, ZipcodeField } from "../Form-Components/TextFields";
import {
  BoxStyle,
  IconContainer,
  StyledIcon,
  WarningParagraph,
} from "./DealershipInformationStyles";
import { muiWarningFill } from "@ally/metronome-icons";

type Props = {
  customerId: string;
  dealershipNumber: any;
  assetContract: ReadAssetContract | WriteAssetContract;
  deliveryGroup: ReadDeliveryGroup | null;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames) => void;
};

type FormValues = DealerInfoModel;

const DealershipInformation: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const saveVehicle = useSaveVehicleForm(    
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  const [dealershipInfo] = useDealershipInfo();
  const markAsCompleteRef = useRef(false);
  const dealershipInformation = assetContract.dealershipInformation;
  const form = useForm<FormValues>({
    defaultValues: {
      sellerName: dealershipInformation?.sellerName,
      sellerAddress: dealershipInformation?.sellerAddress ?? dealershipInfo?.dealershipAddress,
      sellerCity: dealershipInformation?.sellerCity ?? dealershipInfo?.dealershipCity,
      sellerState: dealershipInformation?.sellerState,
      sellerZip: dealershipInformation?.sellerZip ?? dealershipInfo?.dealershipZip,
      sellerSignedRepName: dealershipInformation?.sellerSignedRepName,
      sellerSignedRepTitle: dealershipInformation?.sellerSignedRepTitle,
      sellerEmail: dealershipInformation?.sellerEmail,
      isComplete: dealershipInformation?.isComplete
    },
  });

  const { control, handleSubmit, formState} = form;
  const { errors } = formState;
  useEditVehicleFormErrors("Section 8", errors);
  
  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    updateToBackEnd(data, markedAsComplete);
  };

  const updateToBackEnd = (data: FormValues, markedAsComplete?: boolean) => {
    const onSucc = (patch: WriteAssetContractSections) => {
      updateAssetContractState(patch, 'dealershipInformation');
    };

    const patch = {
      dealershipInformation: {
        ...data,
        isComplete: markedAsComplete
      }
    };
    
    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";
    
    saveVehicle.mutate({datas: [patch], saveType, onSucc});
  };

  const onBlurHandle = (e) => {};
  const { setPrevStepActive } = useSteppedProcess();

  return (
    <form allytm-form-tagged="true" 
      onSubmit={(e) => {
        markAsCompleteRef.current = false;
        return handleSubmit((data) => onSubmitData(data))(e);
      }}
    >
      <IconContainer>
        <StyledIcon icon={muiWarningFill} size="xl" fill="warning" ariaHidden />
        <WarningParagraph>
          This information will apply to all vehicles in this delivery group
          once you select <b>Mark as Complete.</b>
        </WarningParagraph>
      </IconContainer>
      
      <BoxStyle p={["sm", "md"]} height="100%">
        <div data-private>
          <BasicTextField 
          control={control} errors={errors} requiredRef={markAsCompleteRef} name="sellerName" 
          labelContent="Seller name" onBlurHandle={onBlurHandle} minCharCount={2}
        />
        </div>
        <div data-private>
          <BasicTextField 
            control={control} errors={errors} requiredRef={markAsCompleteRef} name="sellerAddress" 
            labelContent="Seller mailing address" onBlurHandle={onBlurHandle} minCharCount={5}
          />
        </div>
        <div data-private>
          <BasicTextField 
            control={control} name="sellerCity" requiredRef={markAsCompleteRef} errors={errors} 
            labelContent="Seller city" onBlurHandle={onBlurHandle} minCharCount={2}
          />
        </div>
        <div className="test">
          <ReadOnlyField
            label="Seller state"
            value={dealershipInformation?.sellerState ?? ""}
          />
        </div>
        <div data-private>
          <ZipcodeField 
          control={control} errors={errors} requiredRef={markAsCompleteRef} name="sellerZip" 
          labelContent="Seller ZIP" onBlurHandle={onBlurHandle} minCharCount={5}
        />
        </div>
        <div data-private>
          <BasicTextField 
            control={control} errors={errors} requiredRef={markAsCompleteRef} name="sellerSignedRepName" 
            labelContent="Dealer representative name" onBlurHandle={onBlurHandle} minCharCount={2}
          />
        </div>
        <div data-private>
          <BasicTextField 
            control={control} errors={errors} requiredRef={markAsCompleteRef} name="sellerSignedRepTitle"
            labelContent="Dealer representative title" onBlurHandle={onBlurHandle} minCharCount={2}
          />
        </div>
        <div data-private>
          <EmailField 
            control={control} requiredRef={markAsCompleteRef} errors={errors} name="sellerEmail"
            labelContent="Dealer representative email address" onBlurHandle={onBlurHandle} minCharCount={5}
          />
        </div>
        <NonTerminalFormButtons 
          setPrevStepActive={setPrevStepActive}
          buttonCallStatus={
            {
              isPending: saveVehicle.isPending,
              actionType: saveVehicle.saveType
            }
          }
          sectionNameForAnalytics="dealership-information"
          isComplete={dealershipInformation.isComplete}
          markAsComplete={(e) => {
            markAsCompleteRef.current = true;
            return handleSubmit((data) =>
              onSubmitData(data, true)
            )(e);
          }}
        />
      </BoxStyle>
    </form>
  );
};

export default DealershipInformation;
