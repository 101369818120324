import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background: #650360;
  height: 68px;
  position: relative;
  top: 0;
  z-index: 3;
`;

export const ChangeDealershipStyle = styled.a`
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
  color: #0071c4;
  margin-top: 12px;
  margin-left: 16px;
  cursor: pointer;
`;


export const Logo = styled.div`
  margin-left: 20px;
  font-size: 1.5em;
  margin-top: 15px;
  margin-bottom: auto;
`;

export const LogoImg = styled.img`
  align-items: center;
  height: 37px;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const MenuItem = styled.div`
  outline: none;
  margin-right: 15px;
  font-size: 1em;
`;

export const LogoLink = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const MenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 17px;
  padding-right: 10px;
  &:hover {
    color: #f9f9f9;
  }'
`;

export const SubMenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 17px;
  padding-right: 10px;
  outline: none;
  display: flex;
  justify-content: right;
  &:focus {
    outline: none;
  }
  link {
    outline: none;
  }
  &:hover {
    color: #f9f9f9;
  }
  &.menu-link{
    text-align: right;
    padding-right: 0px;
    margin-top: 26px;
  }
`;
