
import { useCallback, useRef } from "react";

export function useErrorDeduper() {
  const errorsSeen = useRef<Set<string>>(new Set());
  const getUnseenErrors = useCallback((errorKeys: string[]) =>{
    const newErrors = [];
    errorKeys.forEach(e => {
      if(!errorsSeen.current.has(e)){
        errorsSeen.current.add(e);
        newErrors.push(e);
      }
    });
    return newErrors;
  }, [errorsSeen] );
  const resetErrorRecords = () => {
    errorsSeen.current = new Set();
  }

  return {getUnseenErrors, resetErrorRecords };
}
