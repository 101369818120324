import {
  RadioButton,
} from "@ally/metronome-ui";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { emitClickEvent } from "../../../analytics/clickEvents";
import { useCalculateAssetContract } from "../../../hooks/assetContractHooks/useCalculateAssetContract";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import {
  ReadAssetContract,
  WriteAssetContractSections,
  WriteAssetContractSectionsNames,
} from "../../../model/asset-contract.model";
import {
  EditDeliveryGroupContractData,
  ReadDeliveryGroup,
} from "../../../model/delivery-group.model";
import { toCurrency } from "../../../utils/currency";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { tradeInCashDownConfig } from "../../../validation-config/asset-contracts/trade-in-cash-down";
import { getAllFieldsForStateInSection } from "../../../validation-config/field-config-utils";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import {
  FeeDescriptionStyle,
  OptionalFormFieldContainer,
  OptionalFormFieldStyle,
  OtherFeeFormFieldStyle,
  TrashIconStyled,
} from "./FormSectionStyles";
import {
  BoxStyle,
  DeliveryFormFieldStyle,
  OtherFeeWrapper,
  RadioButtonWrapper,
  StyledRadioLabel,
} from "./TradeInCashDownStyles";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import OptionalFieldsDropdown from "./OptionalFieldsDropdown";
import { muiTrash } from "@ally/metronome-icons";

type Props = {
  customerId: any;
  dealershipNumber: any;
  assetContract: ReadAssetContract;
  deliveryGroup: ReadDeliveryGroup;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames, calc?: any) => void;
};
//TODO: should not have form values as primatives, should have complex type w/ formatting logic per each underlying type
type BooleanFormValues = {
  hasTradeIn: string;
};

type NumericFormValues = {
  tradeInYear: number | null;
};

function isNumericFormType(keyInput: any): keyInput is NumericFormValues {
  return ["tradeInYear"].includes(keyInput);
}
type MoneyFormValues = {
  grossTradeInAllowance: number | null;
  lessPayoffMadeBySeller: number | null; //tradeInPayoffAmount
  cash: number | null;
  rebate: number | null;
  tradeInCreditAgreementBenefit: number | null;
  tradeInCashDownOtherValue: number | null;
};
function isMoneyFormType(keyInput: any): keyInput is MoneyFormValues {
  return [
    "grossTradeInAllowance",
    "lessPayoffMadeBySeller",
    "cash",
    "rebate",
    "tradeInCreditAgreementBenefit",
    "tradeInCashDownOtherValue",
  ].includes(keyInput);
}

type TextFormValues = {
  tradeInMake: string | null;
  tradeInModel: string | null;
  lessPayoffMadeBySellerTo: string | null; //tradeInPayoffLenderName
  tradeInCashDownOtherLabel: string | null;
};
function isTextFormType(keyInput: any): keyInput is MoneyFormValues {
  return [
    "tradeInMake",
    "tradeInModel",
    "lessPayoffMadeBySellerTo",
    "tradeInCashDownOtherLabel",
  ].includes(keyInput);
}
type FormValues = BooleanFormValues &
  NumericFormValues &
  MoneyFormValues &
  TextFormValues;
//Net Trade-in, total down payment and unpaid balance are in seperate usestate since derived values

const TradeInCashDown: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const { setPrevStepActive } = useSteppedProcess();
  const markAsCompleteRef = useRef(false);
  const [dealershipInfo] = useDealershipInfo();
  const fieldConfigurations = getAllFieldsForStateInSection(
    tradeInCashDownConfig,
    dealershipInfo.dealershipState
  );
  const optionalFields = fieldConfigurations.optionalFields;

  const tradeInCashDown = assetContract.tradeInCashDown;
  if (!tradeInCashDown.tradeInYear) {
    tradeInCashDown.tradeInYear = null;
  }

  const form = useForm<FormValues>({
    defaultValues: {
      hasTradeIn: tradeInCashDown?.hasTradeIn ? "Yes" : "No",
      tradeInYear: tradeInCashDown?.tradeInYear,
      tradeInMake: tradeInCashDown?.tradeInMake,
      tradeInModel: tradeInCashDown?.tradeInModel,
      lessPayoffMadeBySellerTo: tradeInCashDown?.lessPayoffMadeBySellerTo,
      tradeInCashDownOtherLabel: tradeInCashDown?.tradeInCashDownOtherLabel,
      grossTradeInAllowance: tradeInCashDown?.grossTradeInAllowance,
      lessPayoffMadeBySeller: tradeInCashDown?.lessPayoffMadeBySeller,
      cash: tradeInCashDown?.cash,
      rebate: tradeInCashDown?.rebate,
      tradeInCreditAgreementBenefit:
        tradeInCashDown?.tradeInCreditAgreementBenefit,
      tradeInCashDownOtherValue: tradeInCashDown?.tradeInCashDownOtherValue,
    },
  });

  const [
    { netTradeIn, totalDownPayment, unpaidBalanceOfCashPrice },
    setCalculatedValues,
  ] = useState({
    netTradeIn: formatNumberToCurrency(tradeInCashDown?.equalsNetTradeIn),
    totalDownPayment: formatNumberToCurrency(assetContract.totalDownPayment),
    unpaidBalanceOfCashPrice: formatNumberToCurrency(
      assetContract.unpaidBalanceOfCashPrice
    ),
  });
  const { control, handleSubmit, formState, getValues, setValue, watch } = form;
  const { errors } = formState;
  const watchHasTradeIn = watch("hasTradeIn");

  const analytics = useAnalytics();
  useEditVehicleFormErrors("Section 5", errors);

  const saveVehicle = useSaveVehicleForm(
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  const calculateAssetContract = useCalculateAssetContract(dealershipNumber);
  const onCalculated = (calculatedAssetContract: ReadAssetContract | null) => {
    const netTradeIn = formatNumberToCurrency(
      calculatedAssetContract?.tradeInCashDown?.equalsNetTradeIn
    );
    const totalDownPayment = formatNumberToCurrency(
      calculatedAssetContract?.totalDownPayment
    );
    const unpaidBalanceOfCashPrice = formatNumberToCurrency(
      calculatedAssetContract?.unpaidBalanceOfCashPrice
    );

    setCalculatedValues({
      netTradeIn: netTradeIn,
      totalDownPayment: totalDownPayment,
      unpaidBalanceOfCashPrice: unpaidBalanceOfCashPrice,
    });
  };

  const [dropdownText, setDropdownText] = useState(
    "Select additional credits (optional)"
  );

  const [selectedField, setSelectedField] = useState({
    cash: {
      selected: false,
      label: "Cash (optional)",
      displayValue: isNullUndefinedOrEmpty(tradeInCashDown?.cash)
        ? ""
        : formatNumberToCurrency(tradeInCashDown.cash),
      isValidForDealerState: optionalFields.includes("cash"),
    },
    rebate: {
      selected: false,
      label: "Rebate (optional)",
      displayValue: isNullUndefinedOrEmpty(tradeInCashDown?.rebate)
        ? ""
        : formatNumberToCurrency(tradeInCashDown.rebate),
      isValidForDealerState: optionalFields.includes("rebate"),
    },
    tradeInCreditAgreementBenefit: {
      selected: false,
      label: "Trade-in credit agreement benefit (optional)",
      displayValue: isNullUndefinedOrEmpty(
        tradeInCashDown?.tradeInCreditAgreementBenefit
      )
        ? ""
        : formatNumberToCurrency(tradeInCashDown.tradeInCreditAgreementBenefit),
      isValidForDealerState: optionalFields.includes(
        "tradeInCreditAgreementBenefit"
      ),
    },
    otherCredit: {
      selected: false,
      label: "Other credits (optional)",
      displayLabel: tradeInCashDown?.tradeInCashDownOtherLabel,
      displayValue: isNullUndefinedOrEmpty(
        tradeInCashDown?.tradeInCashDownOtherValue
      )
        ? ""
        : formatNumberToCurrency(tradeInCashDown.tradeInCashDownOtherValue),
      isValidForDealerState: optionalFields.includes(
        "tradeInCashDownOtherValue"
      ),
    },
  });

  const onBlurHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    reCalculateFields: boolean
  ) => {
    if (reCalculateFields) {
      onBlurCalculateValues();
    }
  };

  const onBlurCalculateValues = () => {
    const values = getValues();
    submitData(values, onCaluclateFields);
  };

  function formatNumberToCurrency(value?: number | null | string): string {
    if (value === undefined || value === null) {
      return "";
    }
    if (typeof value === "string") {
      value = Number(value.replace(/[$,]/g, ""));
    }
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  const submitData = (
    data: FormValues,
    submitAction: (data: FormValues, markedAsComplete?: boolean) => void,
    markedAsComplete?: boolean
  ) => {
    // converts values for api to accept including removing masking and defaulting
    Object.keys(data).forEach((key) => {
      const v = data[key];
      if (
        isMoneyFormType(key) &&
        typeof v === "string" &&
        v !== undefined &&
        v !== null
      ) {
        const numVal = Number(v.replace(/[$,]/g, ""));
        data[key] = isNaN(numVal) ? null : numVal;
      } else if (isTextFormType(key) && isNullUndefinedOrEmpty(data[key])) {
        data[key] = null;
      } else if (isNumericFormType(key)) {
        const numVal = Number(v);
        data[key] = isNaN(numVal) || numVal === 0 ? null : numVal;
      }
    });
    submitAction(data, markedAsComplete);
  };

  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    submitData(data, updateToBackEnd, markedAsComplete);
  };

  const onCaluclateFields = (data: FormValues) => {
    const payload = {
      ...assetContract,
      tradeInCashDown: {
        ...tradeInCashDown,
        ...data,
        hasTradeIn: data.hasTradeIn === "Yes" ? true : false,
      },
    };
    calculateAssetContract.mutate(
      {
        assetContract: payload,
      },
      { onSuccess: (data) => onCalculated(data) }
    );
  };

  const updateToBackEnd = (data: FormValues, markedAsComplete?: boolean) => {
    const onSucc = (patch: WriteAssetContractSections, calc?: any) => {
      updateAssetContractState(patch, 'tradeInCashDown', calc);
    };

    const patches = [
      {
        tradeInCashDown: {
          ...data,
          hasTradeIn: data.hasTradeIn === "Yes" ? true : false,
          isComplete: markedAsComplete,
        },
      },
      { dealershipInformation: assetContract.dealershipInformation },
    ];
    let updateDeliveryGroupContractData = undefined;
    if (deliveryGroup) {
      updateDeliveryGroupContractData = {
        dgType: "edit-contract-data",
        tradeInCashDownContractData: {
          tradeInCashDownOtherLabel: data.tradeInCashDownOtherLabel,
        },
      } as EditDeliveryGroupContractData;
    }
    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";

    saveVehicle.mutate({
      datas: patches,
      saveType,
      onSucc,
      updateDeliveryGroupContractData,
      assetContract,
      deliveryGroup
    });
  };

  const handleClick = (name: string): void => {
    setDropdownText(selectedField[name].label);
    setSelectedField((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          selected: true,
        },
      };
    });
  };

  useEffect(() => {
    for (const name in selectedField) {
      if (
        !isNullUndefinedOrEmpty(selectedField[name].displayValue) ||
        !isNullUndefinedOrEmpty(selectedField[name].displayLabel)
      ) {
        setSelectedField((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              selected: true,
            },
          };
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeOptionalField = (
    event: any,
    value: keyof FormValues,
    name: keyof typeof selectedField,
    label?: keyof FormValues
  ) => {
    setDropdownText("Select additional credits (optional)");
    let displayOverwrites = {
      displayValue: "",
      selected: false,
    };

    setValue(value, null);

    if (label) {
      setValue(label, null);
      displayOverwrites["displayLabel"] = "";
    }

    setSelectedField((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          ...displayOverwrites,
        },
      };
    });
    onBlurCalculateValues();
  };

  const hasTradeInOptions = ["Yes", "No"];

  return (
    <>
      <form allytm-form-tagged="true" 
        onSubmit={(e) => {
          markAsCompleteRef.current = false;
          return handleSubmit((data) => onSubmitData(data))(e);
        }}
      >
        <BoxStyle p={["sm", "md"]} height="100%">
          <StyledRadioLabel>
            Do you have a trade-in associated with this VIN?
          </StyledRadioLabel>
          <Controller
            control={control}
            name="hasTradeIn"
            render={({ field: { value, onChange } }) => {
              return (
                <RadioButtonWrapper>
                  {hasTradeInOptions.map((option, index) => (
                    <RadioButton
                      key={index}
                      label={option}
                      value={option}
                      onChange={(e) => {
                        onChange(e);
                        analytics(emitClickEvent, {
                          tagName: "RadioButton",
                          allytmln: `has-trade-in-selected-${option}`,
                          href: "#",
                        });
                      }}
                      selectedOption={value}
                    />
                  ))}
                </RadioButtonWrapper>
              );
            }}
          />
          {watchHasTradeIn === "Yes" && (
            <>
              <Controller
                control={control}
                name="tradeInYear"
                rules={{
                  validate: (value) => {
                    if (
                      watchHasTradeIn === "Yes" &&
                      markAsCompleteRef.current &&
                      isNullUndefinedOrEmpty(value)
                    ) {
                      return "Please provide trade-in year to continue";
                    }
                  },
                  minLength: {
                    value: 4,
                    message: "Please enter four digits.",
                  },
                }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DeliveryFormFieldStyle
                      variant="input"
                      inputType="text"
                      inputWidth="320px"
                      labelContent="Trade-in year"
                      value={
                        isNullUndefinedOrEmpty(value) ? "" : value.toString()
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        return onChange(e.target.value.replace(/\D/g, ""));
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBlurHandle(e, false);
                      }}
                      maxLength={4}
                      errorNotification={{
                        message: errors.tradeInYear?.message,
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="tradeInMake"
                rules={{
                  validate: (value) => {
                    if (
                      watchHasTradeIn === "Yes" &&
                      markAsCompleteRef.current &&
                      isNullUndefinedOrEmpty(value)
                    ) {
                      return "Please provide trade-in make to continue";
                    }
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter 2 or more characters",
                  },
                }}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <DeliveryFormFieldStyle
                      variant="input"
                      inputType="text"
                      inputWidth="320px"
                      labelContent="Trade-in make"
                      value={value ?? ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        // cant have more than 2 spaces in a row, must start with a Word and everything else must be word or space
                        return onChange(
                          e.target.value.replace(
                            / {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g,
                            ""
                          )
                        );
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBlurHandle(e, false);
                      }}
                      maxLength={100}
                      errorNotification={{
                        message: errors.tradeInMake?.message,
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="tradeInModel"
                rules={{
                  validate: (value) => {
                    if (
                      watchHasTradeIn === "Yes" &&
                      markAsCompleteRef.current &&
                      isNullUndefinedOrEmpty(value)
                    ) {
                      return "Please provide trade-in model to continue";
                    }
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter 2 or more characters",
                  },
                }}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <DeliveryFormFieldStyle
                      variant="input"
                      inputType="text"
                      inputWidth="320px"
                      labelContent="Trade-in model"
                      value={value ?? ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        return onChange(
                          e.target.value.replace(
                            / {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g,
                            ""
                          )
                        );
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBlurHandle(e, false);
                      }}
                      maxLength={100}
                      errorNotification={{
                        message: errors.tradeInModel?.message,
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="grossTradeInAllowance"
                rules={{
                  validate: (value) => {
                    if (
                      watchHasTradeIn === "Yes" &&
                      markAsCompleteRef.current &&
                      isNullUndefinedOrEmpty(value)
                    ) {
                      return "Please provide gross trade-in allowance to continue";
                    }
                  },
                }}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DeliveryFormFieldStyle
                      variant="input"
                      inputType="text"
                      inputWidth="320px"
                      labelContent="Gross trade-in allowance"
                      value={
                        isNullUndefinedOrEmpty(value)
                          ? ""
                          : formatNumberToCurrency(value)
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(toCurrency(e.target.value, "."));
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBlurHandle(e, true);
                      }}
                      maxLength={11}
                      errorNotification={{
                        message: errors.grossTradeInAllowance?.message,
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="lessPayoffMadeBySeller"
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <DeliveryFormFieldStyle
                      variant="input"
                      inputType="text"
                      inputWidth="320px"
                      labelContent="Trade-in payoff amount (optional)"
                      value={
                        isNullUndefinedOrEmpty(value)
                          ? ""
                          : formatNumberToCurrency(value)
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(toCurrency(e.target.value, "."));
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBlurHandle(e, true);
                      }}
                      maxLength={11}
                      errorNotification={{
                        message: errors.lessPayoffMadeBySeller?.message,
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name="lessPayoffMadeBySellerTo"
                rules={{
                  minLength: {
                    value: 2,
                    message: "Please enter 2 or more characters",
                  },
                }}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <DeliveryFormFieldStyle
                      variant="input"
                      inputType="text"
                      inputWidth="320px"
                      labelContent="Trade-in payoff lender name (optional)"
                      value={value ?? ""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        return onChange(
                          e.target.value.replace(
                            / {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g,
                            ""
                          )
                        );
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onBlurHandle(e, false);
                      }}
                      maxLength={100}
                      errorNotification={{
                        message: errors.lessPayoffMadeBySellerTo?.message,
                      }}
                    />
                  );
                }}
              />
            </>
          )}
          <p id={watchHasTradeIn}>You can select multiple credits from the list.</p>
          <OptionalFieldsDropdown
            selectedField={selectedField}
            titleText={dropdownText}
            handleClick={handleClick}
            fieldType="credits"
          />
          {(selectedField.otherCredit.selected ||
            !!getValues("tradeInCashDownOtherLabel") ||
            !!getValues("tradeInCashDownOtherValue")) && (
            <OtherFeeWrapper>
              <div className="trash_container">
                <h4>Other (optional)</h4>
                <TrashIconStyled
                  icon={muiTrash}
                  size="lg"
                  className="trash_icon"
                  id="trash-icon"
                  onClick={(e) =>
                    removeOptionalField(
                      e,
                      "tradeInCashDownOtherValue",
                      "otherCredit",
                      "tradeInCashDownOtherLabel"
                    )
                  } 
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      removeOptionalField(
                        e,
                        "tradeInCashDownOtherValue",
                        "otherCredit",
                        "tradeInCashDownOtherLabel"
                      )
                    }
                  }
                  }
                />
              </div>
              <div className="other_fee">
                <FeeDescriptionStyle>
                  <Controller
                    control={control}
                    name="tradeInCashDownOtherLabel"
                    rules={{
                      validate: (value) => {
                        if (
                          !value &&
                          markAsCompleteRef.current &&
                          getValues("tradeInCashDownOtherValue")
                        ) {
                          return "Please add a fee description to continue.";
                        }
                      },
                      minLength: {
                        value: 2,
                        message: "Please provide at least two characters.",
                      },
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <OtherFeeFormFieldStyle
                          variant="input"
                          inputType="text"
                          inputWidth="300px"
                          labelContent="Fee Description"
                          maxLength={50}
                          value={value ?? ""}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            // cant have more than 2 spaces in a row, must start with a Word and everything else must be word or space
                            return onChange(
                              e.target.value.replace(
                                / {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g,
                                ""
                              )
                            );
                          }}
                          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onBlurHandle(e, false);
                          }}
                          errorNotification={{
                            message: errors.tradeInCashDownOtherLabel?.message,
                          }}
                        />
                      );
                    }}
                  />
                </FeeDescriptionStyle>
                <Controller
                  control={control}
                  name="tradeInCashDownOtherValue"
                  rules={{
                    minLength: {
                      value: 2,
                      message: "Please provide at least two characters.",
                    },
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <OtherFeeFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="300px"
                        labelContent="Amount"
                        maxLength={11}
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onBlurHandle(e, true);
                        }}
                      />
                    );
                  }}
                />
              </div>
            </OtherFeeWrapper>
          )}

          {(selectedField.cash.selected || !!getValues("cash")) &&
            optionalFields.includes("cash") && (
              <Controller
                control={control}
                name="cash"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.cash.label}
                            <TrashIconStyled
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "cash",
                                "cash"
                              )
                            }
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "cash",
                                    "cash"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onBlurHandle(e, true);
                        }}
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.rebate.selected || !!getValues("rebate")) &&
            optionalFields.includes("rebate") && (
              <Controller
                control={control}
                name="rebate"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.rebate.label}
                            <TrashIconStyled
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "rebate",
                                "rebate"
                              )
                              }
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "rebate",
                                    "rebate"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onBlurHandle(e, true);
                        }}
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.tradeInCreditAgreementBenefit.selected ||
            !!getValues("tradeInCreditAgreementBenefit")) &&
            optionalFields.includes("tradeInCreditAgreementBenefit") && (
              <Controller
                control={control}
                name="tradeInCreditAgreementBenefit"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.tradeInCreditAgreementBenefit.label}
                            <TrashIconStyled
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              onClick={(e) =>
                                removeOptionalField(
                                  e,
                                  "tradeInCreditAgreementBenefit",
                                  "tradeInCreditAgreementBenefit"
                                )
                              }
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "tradeInCreditAgreementBenefit",
                                    "tradeInCreditAgreementBenefit"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onBlurHandle(e, true);
                        }}
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          <ReadOnlyField label="Net trade-in" value={netTradeIn} />
          <ReadOnlyField label="Total down payment" value={totalDownPayment} />
          <ReadOnlyField
            label="Unpaid balance of cash price"
            value={unpaidBalanceOfCashPrice}
          />

          <NonTerminalFormButtons
            setPrevStepActive={setPrevStepActive}
            buttonCallStatus={{
              isPending: saveVehicle.isPending,
              actionType: saveVehicle.saveType,
            }}
            sectionNameForAnalytics="trade-in-cash-down"
            isComplete={tradeInCashDown.isComplete}
            markAsComplete={(e) => {
              markAsCompleteRef.current = true;
              return handleSubmit((data) => onSubmitData(data, true))(e);
            }}
          />
        </BoxStyle>
      </form>
    </>
  );
};

export default TradeInCashDown;
