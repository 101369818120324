
import { useEffect } from "react";
import { useAnalytics } from "./useAnalytics";
import { useErrorDeduper } from "./useErrorDeduper";
import { emitAssetContractError } from "../../analytics/assetContractErrors";

export function useEditVehicleFormErrors(sectionTitle: string, errors: Record<string, any>) {
  const analytics = useAnalytics();
  const {getUnseenErrors, resetErrorRecords} = useErrorDeduper();
  const joinedErrorKeys = Object.keys(errors).join(",");
  useEffect(() => {
    const errorKeys = Object.keys(errors);
    const newErrors = getUnseenErrors(errorKeys);
    const aggregatedErrors = newErrors.map((key: keyof typeof errors) => {
      return `${sectionTitle}: ${key}: ${errors[key].message}`;
    });

    analytics(emitAssetContractError, aggregatedErrors);
    
    return () => {
      /*somewhat at the mercy of Form lib error object. 
        This removes old erros and register current ones.
        Helps when an error is fixed and recreated. We should re-emit an analytic error for it
      */
      resetErrorRecords();
      getUnseenErrors(errorKeys);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedErrorKeys]); 
}
