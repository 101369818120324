import { Button, Icon } from "@ally/metronome-ui";
import styled from "styled-components";


export const DetailsWrapper = styled.div`
  display: flex;
`;

export const ThanksWrapper = styled.div`
  max-width: 66%;
`;

export const StyledIcon = styled(Icon)`
  margin: 0 10px 0 0;
`;

export const InfoWrapper = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 10px 0 0 0;
`

export const ContractButton = styled(Button)`
background: none;
border: 1px solid;
margin: 12px 24px 24px 0px;
width: 235px;
`
