import { useQuery } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { useAnalytics } from "../analytics/useAnalytics";
import { envelopeStatusKey } from "../../utils/react-query-key-factory";

async function getStatusFromDocuSign(get: <Result>(url: string) => Promise<Result | null>,
    dealershipNumber: string, customerId: string, deliveryGroupId?: string): Promise<any> {
    if (!deliveryGroupId || !customerId) {
        return null;
    }
    const returnUrl = window.location.href;
    const dgEndpoint = `/dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/envelope-status?returnUrl=${returnUrl}`;
    const res = await get<any>(dgEndpoint);

    return res;
}

export function useGetEnvelopeStatus(dealershipNumber: string, customerId: string, deliveryGroupId?: string) {
    const { get } = useHttp();
    const analytics = useAnalytics();

    return useQuery(
        envelopeStatusKey(dealershipNumber, deliveryGroupId),
        () => getStatusFromDocuSign(get, dealershipNumber, customerId, deliveryGroupId),
        {
            onError(error) {
                console.error("Oops something went wrong. Please try again later ", error);
                analytics(emitErrorEvent, "get docusign envelope status error", (error as any).status ?? 500);
            },
            enabled: deliveryGroupId !== undefined
        }
    );
}
