import { useGetDealerships } from "./useGetDealerships";
import { useFlags } from "launchdarkly-react-client-sdk";

export function useFilterStateSupportedDealerships() {
  const secureRoutesDisabled = process.env.REACT_APP_SECURE_ROUTES !== 'true'
  const supportedStates = process.env.REACT_APP_SUPPORTED_STATES;
  const { isSuccess: dealershipsFetched, data: dealerships } = useGetDealerships();
  let supportedStatesArray = supportedStates.split(",").map(state => state.trim());

  let stateSupportedDealerships = [];
  let options = [];
  let dealershipsReady = false;
  if (dealershipsFetched) {
    dealershipsReady = true;
    stateSupportedDealerships = dealerships!.filter(d => {
      return secureRoutesDisabled ? dealerships :
        supportedStatesArray?.includes(d.state);
    });
    options = stateSupportedDealerships!.map(d => {
      return {
        value: d.dealershipNumber,
        label: `${d.dealershipNumber} ${d.name}`
      };
    });
  }
  return { stateSupportedDealerships, options, dealershipsReady };
}
