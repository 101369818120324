import { TextBody, TextHeading, useModalContext } from "@ally/metronome-ui";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ContextType, TabContext } from "../../../../Tab/TabContext";
import {
  ButtonStyle,
  RemovedModalStyled,
  LinkButtonStyle,
  OptionHeading,
  RemoveButtonStyle,
  CancelButtonStyle,
  ModalButtonContainer,
} from "./OptionsStickyNavStyles";
import { VehicleMetadata } from "./OptionsStickyNav";
import { clonePageRoute, editVehiclePageRoute } from "../../../../../routes/RouteFactory";
import { Identifiable } from "../../../../../model/identifiable";
import { useRemoveAssetContractFromDeliveryGroup } from "../../../../../hooks/assetContractHooks/useRemoveAssetFromDeliveryGroup";
import { useDealershipInfo } from "../../../../../hooks/useDealershipInfo";

type Props = {
  selectedVehicles: VehicleMetadata[];
  hasValidCloneTargets: boolean; 
  handleCancel: (arg: string) => void;
};
type RemoveModalContentProps = {
  customerId: string,
  dealershipNumber: string,
  activeDeliveryGroupId: string,
  headingText?: string;
  selectedVehicles: Identifiable[];
};

const RemoveModalContent: React.FC<RemoveModalContentProps> = ({
  customerId,
  dealershipNumber,
  activeDeliveryGroupId,
  selectedVehicles,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const {
    mutateAsync: removeAcsToDg,
  } = useRemoveAssetContractFromDeliveryGroup(dealershipNumber);
  const { setOpen } = useModalContext();
  const closeModal = () => setOpen(false);

  const removeFromGroup = () => {
    const appIds = selectedVehicles.map((i) => i.id);
    if (activeDeliveryGroupId) {
      setIsRemoving(true);
      removeAcsToDg({
        applicationIds: appIds,
        customerId,
        deliveryGroupId: activeDeliveryGroupId,
      });
    }
  };

  return (
    <>
      {selectedVehicles.length < 2 && (
        <>
          <TextHeading size="sm" tag="h1" tabIndex={-1}>
            Are you sure you want to remove this vehicle?
          </TextHeading>
          <TextBody size="sm" tag="span" tabIndex={0}>
            <p>
              If you remove this vehicle from your delivery group, it will
              return to your list of available vehicles.
            </p>
            <p>
              Any changes you made to the vehicle’s profile will revert back to
              the information on your original credit application.
            </p>
          </TextBody>
        </>
      )}
      {selectedVehicles.length >= 2 && (
        <>
          <TextHeading size="sm" tag="h1" tabIndex={-1}>
            Are you sure you want to remove these vehicles?
          </TextHeading>
          <TextBody size="sm" tag="span" tabIndex={0}>
            <p>
              If you remove these vehicles from your delivery group, they will
              return to your list of available vehicles.
            </p>
            <p>
              Any changes you made to the vehicles' profile will revert back to
              the information on your original credit application.
            </p>
          </TextBody>
        </>
      )}
      <ModalButtonContainer>
        <RemoveButtonStyle
          aria-label="remove"
          text="Remove"
          variant="destructive"
          allytmln="confirm-remove-vehicle"
          loading={isRemoving}
          onClick={removeFromGroup}
        />
        <CancelButtonStyle
          aria-label="Cancel"
          text="Cancel"
          variant="secondary"
          allytmln="cancel-remove-vehicle"
          onClick={closeModal}
        />
      </ModalButtonContainer>
    </>
  );
};
const RemoveButtonModalTrigger: React.FC = () => {
  const buttonRef = useRef(null);
  const { setOpen } = useModalContext();
  useEffect(() => {
    buttonRef?.current?.focus();
  }, []);

  return (
    <ButtonStyle
      ref={buttonRef}
      aria-label="Remove from Group"
      text="Remove from Group"
      variant="primary"
      allytmln="remove-vehicle"
      onClick={() => setOpen(true)}
    />
  );
};

export const AssignedVehicleStickyNav: React.FC<Props> = ({
  selectedVehicles,
  hasValidCloneTargets,
  handleCancel,
}) => {
  const {
    activeDeliveryGroupId,
    activeDeliveryGroupName, 
  } = React.useContext(TabContext) as ContextType;
  const history = useHistory();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id: customerId } = useParams() as any;
  const totalRowCount = selectedVehicles.length;

  const cloneVehicle = () => {
    history.push({
      pathname: clonePageRoute(customerId, activeDeliveryGroupId, selectedVehicles[0].id),
    });
  };
  
  return (
    <>
        {totalRowCount < 2 && selectedVehicles[0].isReadyForContract && (
          <OptionHeading>
            Options available for a single vehicle that is <b>Ready for Contract</b>. 
            Select the vehicle you want to edit, clone, or remove from the {activeDeliveryGroupName} delivery group.
          </OptionHeading>
        )}
        {totalRowCount < 2 && !selectedVehicles[0].isReadyForContract && (
          <OptionHeading>
            Options available for a single vehicle. 
            Select the vehicle you want to edit or remove from the {activeDeliveryGroupName} delivery group.
          </OptionHeading>
        )}
        {totalRowCount >= 2 && (
          <OptionHeading>
            Options available for <b>multiple vehicles [{totalRowCount}]</b>
            . Select the vehicle you want to remove from the{" "}
            {activeDeliveryGroupName} delivery group.
          </OptionHeading>
        )}

        {totalRowCount < 2 && (
          <ButtonStyle
            aria-label="Edit Contract Details"
            text="Edit Contract Details"
            variant="primary"
            allytmln="edit-vehicle"
            onClick={(e) => {
              history.push({
                pathname: editVehiclePageRoute(selectedVehicles[0].id),
                state: { selectedVehicles: selectedVehicles },
              });
            }}
          />
        )}

        {hasValidCloneTargets && (
          <ButtonStyle
            aria-label="clone vehicle"
            text="Clone Vehicle"
            variant="primary"
            allytmln="clone-vehicle"
            onClick={cloneVehicle}
          />
        )}

        <RemovedModalStyled
          role="dialog"
          trigger={<RemoveButtonModalTrigger />}
          content={
            <RemoveModalContent 
              selectedVehicles={selectedVehicles} 
              customerId={customerId}
              dealershipNumber={dealershipNumber}
              activeDeliveryGroupId={activeDeliveryGroupId}
            />
          }
        />
        <LinkButtonStyle
          text="Cancel"
          variant="link"
          allytmln="cancel-vehicle-actions-sticky-nav"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCancel("")}
        />

    </>
  );
};
