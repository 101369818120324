import { FieldConfigurationSection, ValidationType } from "../field-configuration";

export const tradeInCashDownConfig: FieldConfigurationSection = {
  name: "tradeInCashDown",
  fieldConfigurations: [
    {
      fieldName: "tradeInYear",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "tradeInMake",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "tradeInModel",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "grossTradeInAllowance",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "lessPayoffMadeBySeller",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "lessPayoffMadeBySellerTo",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "cash",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "rebate",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "tradeInCreditAgreementBenefit",
      validationType: ValidationType.OPTIONAL,
      states: ["TX"]
    },
    {
      fieldName: "tradeInCashDownOtherValue",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
  ]
}
