import React from 'react'
import styled from 'styled-components'
import { useSteppedProcess } from '../context'
import { BaseSharedComponentProps } from '../types'

const BaseHorizontalRule = styled.div<{
  isOutsideBounds: boolean
  isLastStep: boolean
}>`
  ${({ isOutsideBounds, isLastStep, theme: { colors, space } }): string => `
    display: flex;
    flex: 1;
    height: 1px;
    margin: 0 ${space.xs};
    background: ${colors['slate-2']};

    ${isOutsideBounds || isLastStep ? 'display: none;' : ''}
  `}
`

const HorizontalRule: React.FC<BaseSharedComponentProps> = ({ stepIndex }) => {
  const { stepsMetadata } = useSteppedProcess()

  const isLastStep = stepIndex === stepsMetadata.length - 1
  const isOutsideBounds = stepIndex < 0 || stepIndex >= stepsMetadata.length

  return (
    <BaseHorizontalRule
      isLastStep={isLastStep}
      isOutsideBounds={isOutsideBounds}
      data-testid={`mui-sp-hr-${stepIndex}`}
    />
  )
}

export { HorizontalRule }
