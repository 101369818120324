import React from 'react'
import styled from 'styled-components'
import { useSteppedProcess } from '../context'
import { BaseSharedComponentProps } from '../types'

const VerticalRuleWrapper = styled.div<{ isLastStep: boolean }>`
  ${({ isLastStep, theme: { space } }): string => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-height: ${isLastStep ? '0' : '24px'};
    margin-right: ${space.sm};
  `}
`

const BaseVerticalRule = styled.div<{ isLastStep: boolean }>`
  ${({ isLastStep, theme: { colors } }): string => `
    width: 1px;
    height: 100%;
    background: ${colors['slate-2']};

    ${isLastStep ? 'display: none;' : ''}
  `}
`

const VerticalRule: React.FC<BaseSharedComponentProps> = ({ stepIndex }) => {
  const { stepsMetadata } = useSteppedProcess()

  const isLastStep = stepIndex === stepsMetadata.length - 1

  return (
    <VerticalRuleWrapper isLastStep={isLastStep}>
      <BaseVerticalRule
        isLastStep={isLastStep}
        data-testid={`mui-sp-vr-${stepIndex}`}
      />
    </VerticalRuleWrapper>
  )
}

export { VerticalRule }
