import { QueryClient } from "@tanstack/react-query";
import { ASSET_CONTRACT_QUERY_KEY_ROOT, CONTRACT_DOWNLOAD_QUERY_KEY_ROOT, CONTRACT_SNAPSHOT_OF_ASSET_CONTRACT_QUERY_KEY_ROOT, CONTRACT_STATUS_QUERY_KEY_ROOT, DEALERSHIP_INFO_QUERY_KEY_ROOT, DOCUSIGN_CONTRACT_DOWNLOAD_QUERY_KEY_ROOT, MULTI_CUSTOMERS_QUERY_KEY_ROOT, MULTI_DELIVERY_GROUPS_QUERY_KEY_ROOT, SINGLE_CUSTOMER_QUERY_KEY_ROOT, SINGLE_DELIVERY_GROUP_QUERY_KEY_ROOT, VEHICLE_QUERY_KEY_ROOT } from "./utils/react-query-key-factory";
import { noPendingTime, pendingTime } from "./hooks/contractHooks/useGetContractStatus";
import { timeToPollForUrlWhenNoExists } from "./hooks/contractHooks/useGetDownloadUrl";

export function configureQueryClient(queryClient: QueryClient) {
  queryClient.setQueryDefaults(
    [ASSET_CONTRACT_QUERY_KEY_ROOT],
    {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [CONTRACT_SNAPSHOT_OF_ASSET_CONTRACT_QUERY_KEY_ROOT],
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [VEHICLE_QUERY_KEY_ROOT],
    {
      staleTime: 60 * 2000,
      cacheTime: 60 * 5000,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [CONTRACT_STATUS_QUERY_KEY_ROOT],
    {
      refetchInterval(data, query) {
        return data?.status === "PENDING"
          ? pendingTime
          : noPendingTime;
      },
      staleTime: 60 * 1000,
      cacheTime: 60 * 1000,
      refetchIntervalInBackground: true,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [CONTRACT_DOWNLOAD_QUERY_KEY_ROOT],
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchIntervalInBackground: true,
      keepPreviousData: false,
      refetchInterval(data, query) {
        return data
          ? (data.ttlInMs ? (data.ttlInMs * .9) : 1) //.9 to refresh before expiration
          : timeToPollForUrlWhenNoExists;
      },
    }
  );

  queryClient.setQueryDefaults(
    [DOCUSIGN_CONTRACT_DOWNLOAD_QUERY_KEY_ROOT],
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      refetchOnMount: "always",
      refetchOnReconnect: "always",
      refetchIntervalInBackground: true,
      keepPreviousData: false,
      refetchInterval(data, query) {
        return data
          ? (data.ttlInMs ? (data.ttlInMs * .9) : 1) //.9 to refresh before expiration
          : timeToPollForUrlWhenNoExists;
      },
    }
  );

  queryClient.setQueryDefaults(
    [SINGLE_CUSTOMER_QUERY_KEY_ROOT],
    {
      staleTime: 60 * 3000,
      cacheTime: 60 * 5000,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [MULTI_CUSTOMERS_QUERY_KEY_ROOT],
    {
      staleTime: 60 * 5000,
      cacheTime: 60 * 10000,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [DEALERSHIP_INFO_QUERY_KEY_ROOT],
    {
      staleTime: 60 * 5000,
      cacheTime: 60 * 10000,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [SINGLE_DELIVERY_GROUP_QUERY_KEY_ROOT],
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: false,
    }
  );

  queryClient.setQueryDefaults(
    [MULTI_DELIVERY_GROUPS_QUERY_KEY_ROOT],
    {
      staleTime: 60 * 2000,
      cacheTime: 60 * 5000,
      keepPreviousData: false,
    }
  );
}
