import {
  Box,
} from "@ally/metronome-ui";
import styled from "styled-components";

export const BoxStyle = styled(Box)`
  padding: 0;
  div[data-testid="mui-dejargonator-trigger"] {
    cursor: default;
    pointer-events: none;
  }
  label {
    margin-top: 20px;
  }
  .amount_fin {
    div {
      margin-top: 20px;  
    }
  }
`;
export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
  max-width: 900px;
  max-height: 40px;
`;

export const IconStoryWrapper = styled.div`
  margin-top: 16px;
  display: flex;
`;
