import {useMutation} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { UseInfo } from "./useAddBuyerInfo";
import { COBUYER_INFO_MUTATION_KEY_ROOT } from "../../utils/react-query-key-factory";

async function putAddCobuyerInfo(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string, applicationId: string): Promise<void|null>{
  const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/use-cobuyer-info/${encodeURIComponent(applicationId)}`;
  
  return put<any, void>(endpoint, {});
}

export function useAddCobuyerInfo(dealershipNumber: string) {
  const { put } = useHttp();
   
  return useMutation(
    [COBUYER_INFO_MUTATION_KEY_ROOT],
    async (useInfo: UseInfo) => await putAddCobuyerInfo(put, dealershipNumber, useInfo.deliveryGroupId, useInfo.applicationId)
    );
}
