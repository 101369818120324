import { Spinner, Modal } from "@ally/metronome-ui";
import styled from "styled-components";

export const AddInsurancePolicyDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    span{
     font-size: 16px;
    };
    margin-left: 20px;
    margin-bottom: 22px;
    button{
        margin-left: 4px;
    };
`

export const InsurancePolicyDivOuter = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-bottom: 24px;
`
export const InsurancePolicyDivInner = styled.div`
    display: flex;
    flex-direction: row;
`
export const InsuranceHeader = styled.p`
    line-height: 24px;
`
export const InsuranceInfo = styled.p`
    line-height: 24px;
    font-size: 20px;
    margin-right: 24px;
`
export const TrashDiv = styled.div`
    cursor: pointer;
    margin-top: 2px;
    svg {
        cursor: pointer;
    }
`
export const InsuranceModalStyled = styled(Modal)`
    width: 564px;
    h2 {
    line-height: 28px;
    font-size: 20px;
    }
    p {
    line-height: 24px;
    font-size: 14px;
    }
`
export const InsuranceModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
export const ButtonDiv = styled.div`
    margin-top: 8px;
    display: flex;
    gap: 8px;
`
export const StyledSpinner = styled(Spinner)`
    margin-left: 24px;
    margin-bottom: 24px;
`
