import { Button, Icon, Alert, Box } from "@ally/metronome-ui";
import styled from "styled-components";

export const AlertStyled = styled(Alert)`
  width: 85%;
  padding-left: 12px;
`;
export const BoxStyled = styled(Box)`
  ul {
    max-height: 196px;
    width: 240px;
  }
  button {
    cursor: pointer;
  }
`;
export const StyledDiv = styled.div`
  ul {
    height: 200px;
    width: 240px;
  }
`;
export const StyledIcon = styled(Icon)`
  padding-left: 5px;
  padding-top: 0px;
  height: 10px;
`;

export const SubMenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: #0071c4;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding-right: 10px;
  outline: none;
  margin-bottom: 100px;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:focus {
    outline: none;
  }
  link {
    outline: none;
  }
  &:hover {
  }
`;
export const SubMenuText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  margin-left: 9px;
`;

export const ContactStyle = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 115px;
  margin-left: 9px;
`;

export const ButtonStyle = styled(Button)`
  min-width: 106px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 24px;
  border-radius: 4px;
`;

export const CancelButtonStyle = styled(Button)`
  min-width: 124px;
  color: #0071c4;
  background-color: #e7f6fd;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;
