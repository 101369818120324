import { IdType } from 'react-table'

type Expanded<D extends Record<string, any>> = Record<IdType<D>, boolean>

const getRowToExpand = <D extends Record<string, any>>(
  expanded: Expanded<D>,
  prevExpanded: Expanded<D> | undefined,
): IdType<D> | null => {
  if (!prevExpanded) return null

  const ids: IdType<D>[] = Object.keys(expanded)

  if (ids.length < 2) return null

  const rowToExpand = ids.find(id => !(id in prevExpanded))

  return rowToExpand || null
}

export default getRowToExpand
