import { Spinner } from "@ally/metronome-ui";
import { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useGetDeliveryGroup } from "../../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import {
  convertDeliveryGroupWithContractToTableData,
} from "../../../../utils/table-utils";
import { TabContext, ContextType } from "../../TabContext";
import {
  DeliveryGroupDetailTitle,
  DetailContainer,
  DetailRowContent,
  DetailRowContentName,
  DetailRowHeader,
  NoAssetOutContainer,
  StyledAlert,
  MessageStyle,
  AlertContainer
} from "./DeliveryGroupDetailsStyled";
import { formatNumberToCurrency } from "../../../../utils/currency";
import { useContractSnapshotAssetContracts } from "../../../../hooks/assetContractHooks/useContractSnapshotAssetContracts";
import { ContractedDeliveryGroupStatus } from "../../../../model/asset-contract.model";

type DgProps = {
  actionBar?: JSX.Element; //idk how to type this correctly
  progressTracker: JSX.Element;
  updateDeliveryGroupName: (deliveryGroupName: string) => void;
  deliveryGroupStatusMessage?: string;
  deliveryGroupStatus: ContractedDeliveryGroupStatus;
}

const ContractedDeliveryGroupDetails = ({ actionBar, progressTracker, updateDeliveryGroupName, deliveryGroupStatusMessage, deliveryGroupStatus: contractStatus }: DgProps) => {
  const displayAlertRef = useRef(null);
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id } = useParams() as any;
  const {
    displaySuccessfulAction,
    saveDisplaySuccessfulAction,
    activeDeliveryGroupId,
    saveHasContractInitiallyTransitionedToSuccess,
    hasContractInitiallyTransitionedToSuccess
  } = useContext(TabContext) as ContextType;

  const {
    data: contractSnapshot,
  } = useContractSnapshotAssetContracts(dealershipNumber, id, activeDeliveryGroupId);

  const {
    isSuccess: isDeliveryGroupSuccess,
    isLoading: isDeliveryGroupLoading,
    data: deliveryGroup,
  } = useGetDeliveryGroup(dealershipNumber, activeDeliveryGroupId);

  // sync state with DeliveryGroup component
  //TODO: idk if this is even needed/used anymore
  useEffect(() => {
    if (deliveryGroup?.group_name) {
      updateDeliveryGroupName(deliveryGroup.group_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryGroup?.group_name])

  useEffect(() => {
    if (hasContractInitiallyTransitionedToSuccess) {
      saveDisplaySuccessfulAction("created the contract for your delivery group");
      saveHasContractInitiallyTransitionedToSuccess(false);
    }
  }, [hasContractInitiallyTransitionedToSuccess, saveHasContractInitiallyTransitionedToSuccess, saveDisplaySuccessfulAction])

  const totalAmountFinanced = (contractSnapshot?.assetContracts ?? []).reduce((acc, curr) => acc + curr.amountFinanced, 0);

  if (isDeliveryGroupSuccess && deliveryGroup) {
    const tableDeliveryGroup = convertDeliveryGroupWithContractToTableData(deliveryGroup, contractStatus);

    const convertDateToTwoDigitString = (date?: string): string => {
      const newDate = date?.split("T")[0]
      if (!date) {
        return "";
      }
      const [year, month, day] = newDate!.split('-');
      const result = `${month}/${day}/${year}`;
      return result;
    }

    return (
      <AlertContainer>
        {displaySuccessfulAction && (
          <StyledAlert
            variant="success"
            cancellable
            ref={displayAlertRef}
            handleClose={() => {
              saveHasContractInitiallyTransitionedToSuccess(false);
              saveDisplaySuccessfulAction(null);
            }
            }
            body={<p
              tabIndex={0}
              aria-label={`Info Icon. You successfully ${displaySuccessfulAction}.`}>
              {`You successfully ${displaySuccessfulAction}.`}
            </p>}
            focusOnMount={true} />
        )}
        {progressTracker}
        <DeliveryGroupDetailTitle>
          Delivery group details
        </DeliveryGroupDetailTitle>

        {deliveryGroupStatusMessage &&
          <MessageStyle>{deliveryGroupStatusMessage}</MessageStyle>
        }
        <NoAssetOutContainer>
          <DetailContainer>
            <div>
              <DetailRowHeader>Group name</DetailRowHeader>
              <DetailRowContentName>
                {tableDeliveryGroup.group_name}
              </DetailRowContentName>
            </div>
            <div>
              <DetailRowHeader>Delivery status</DetailRowHeader>
              <DetailRowContent>
                {tableDeliveryGroup.delivery_status}
              </DetailRowContent>
            </div>
            <div>
              <DetailRowHeader>Contract date</DetailRowHeader>
              <DetailRowContent>
                {convertDateToTwoDigitString(tableDeliveryGroup.contract_date)}
              </DetailRowContent>
            </div>
            <div>
              <DetailRowHeader>Total number of vehicles</DetailRowHeader>
              <DetailRowContent>{contractSnapshot?.assetContracts.length ?? 0}</DetailRowContent>
            </div>
            <div>
              <DetailRowHeader>Total selling price</DetailRowHeader>
              <DetailRowContent>{`${formatNumberToCurrency(totalAmountFinanced)}`}</DetailRowContent>
            </div>
          </DetailContainer>
          {actionBar &&
            actionBar
          }
        </NoAssetOutContainer>
      </AlertContainer>
    );
  } else if (isDeliveryGroupLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return <></>;
  }
};

export default ContractedDeliveryGroupDetails;
