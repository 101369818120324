import React from "react";
import {
  Button,
  Space,
  TextBody,
  TextHeading,
  useModalContext,
} from "@ally/metronome-ui";
import "moment-timezone";
import { InfoHeader } from "./CloneVehicleStyles";
import {
  ButtonContainer,
  CancelButtonStyle,
  GotItButtonContainer,
  ListedItemStyle,
  ButtonStyle,
  ButtonLinkStyle,
  ConfirmCloneModalStyle
} from "./CloneDetailsModalStyles";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { emitClickEvent } from "../../../analytics/clickEvents";

export const CloneInfoModalTrigger: React.FC = () => {
  const analytics = useAnalytics();
  const { setOpen } = useModalContext();
  return (
    <InfoHeader tabIndex={0} onClick={(e: React.MouseEvent) => {
      analytics(emitClickEvent, {
        tagName: 'p',
        allytmln: 'how-cloning-works-modal',
        href: '#'
      });
      setOpen(true);
    }
    }>How does cloning vehicle details work?</InfoHeader>
  );
};
export type CloneInfoModalProps = {
    closeModal?: () => void;
};

export const CloneDetailsModalTrigger: React.FC<{
  hideButton: boolean;
  disableButton: boolean;
}> = ({ hideButton, disableButton }) => {
  const { setOpen } = useModalContext();
  return (
    <>
      {!hideButton && (
        <ButtonStyle
          aria-label="Continue"
          text="Continue"
          variant={"primary"}
          allytmln="clone-start-button"
          disabled={disableButton}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
        />
      )}
    </>
  );
};

export const CloneContinueModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();
    return (
      <ButtonStyle
        aria-label="Continue"
        text="Continue"
        variant={"primary"}
        allytmln="confirm-clone-list-button"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
      />
    );
  };

const confirmModal = (closeModal: () => void, numSuccessfulClones: number, runClone: () => void, cancelClone: () => void, isLoading: boolean ) => {
  function handleCancelClick(){
    cancelClone();
    closeModal();
  }

  async function handleCloneClick(){
    await runClone();
    closeModal();
  }

  return (
      <>
        <Space pb="lg">
        <TextHeading size="sm" tag="h1" focusOnMount tabIndex={-1}>
            Cloned details added to  {numSuccessfulClones}  of your vehicles. 
          </TextHeading>
        </Space>
        <TextBody size="md" tag="span">
          <p>This might take a few seconds. Don’t refresh your screen or you’ll interrupt the process. If you don’t want to continue cloning your vehicle information, select <b>Cancel.</b></p>
        </TextBody>
        <ButtonContainer>
          <ButtonLinkStyle
            aria-label="Continue"
            text="Continue"
            variant={"link"}
            allytmln="clone-complete-button"
            loading={isLoading}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCloneClick()}
          />
          <CancelButtonStyle
            aria-label="Cancel"
            text="Cancel"
            variant={"secondary"}
            allytmln="clone-cancel-button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCancelClick()}
          />
        </ButtonContainer>
      </>
    );
  }

export type CloneDetailsModalContentProps = {
  setOpen?: (input: boolean) => void;
  numSuccessfulClones?: number;
  runClone?: () => void;
  cancelClone?: () => void;
  isLoading?: boolean;
};

const initCreateModalContent = ({ setOpen, numSuccessfulClones, runClone, cancelClone, isLoading }: CloneDetailsModalContentProps) => {
  const closeModal = () => setOpen(false);
  return (
    <>
      <Space pb="lg">
        <TextHeading size="sm" tag="h1" focusOnMount tabIndex={-1}>
          Make sure your list is ready to clone.
        </TextHeading>
      </Space>
      <TextBody size="md" tag="span">
        <p>
          Once your details are cloned, you'll have to edit each individual vehicle's details if you need to make any changes.
          Double check your list and select <b>Continue.</b>
        </p>
      </TextBody>
      
      <ButtonContainer>
        <ConfirmCloneModalStyle
          trigger={<CloneContinueModalTrigger />}
          content={confirmModal(closeModal, numSuccessfulClones, runClone, cancelClone, isLoading)}
          dataTrackRegion="confirm-clone-modal"
          disableFullScreenSmDown={true}
          hideCloseButton={true}
          disableCloseOnOverlayClick={true}
        />
        <CancelButtonStyle
          text="Cancel"
          aria-label="Cancel"
          allytmln="cancel-clone-vehicle"
          variant="secondary"
          onClick={closeModal}
        />
      </ButtonContainer>
    </>
  );
}

export const CloneDetailsModalContent: React.FC<CloneDetailsModalContentProps> = ({
  numSuccessfulClones, runClone, cancelClone, isLoading
}) => {
  const { setOpen } = useModalContext();
  return initCreateModalContent({ setOpen, numSuccessfulClones, runClone, cancelClone, isLoading });
};

export const CloneInfoModalContent: React.FC<CloneDetailsModalContentProps> = () => {
  const { setOpen } = useModalContext();
    return (
      <>
        <Space pb="sm">
          <TextHeading size="sm" tag="h1" tabIndex={-1}>
          Cloning Vehicle Details
          </TextHeading>
        </Space>
        <TextBody size="md" tag="p">
          Cloning saves you time by automatically filling in the following details for multiple vehicles of the same year, make and model:
        </TextBody>
        <ListedItemStyle>
          <ul role="menu">
            <li role="menuitem" tabIndex={0}>&emsp;Cash price</li><br/>
            <li role="menuitem" tabIndex={0}>&emsp;Cash down</li><br/>
            <li role="menuitem" tabIndex={0}>&emsp;Seller and governmental fees</li><br/>
            <li role="menuitem" tabIndex={0}>&emsp;Aftermarket products</li><br/>
            <li role="menuitem" tabIndex={0}>&emsp;Customer buy rate</li><br/>
          </ul>
        </ListedItemStyle>
        <GotItButtonContainer>
          <Button
            aria-label="Got It"
            text="Got It"
            variant={"primary"}
            allytmln="got-it-button"
            onClick={() => setOpen(false)}
          />
        </GotItButtonContainer>
      </>
    );
  };
