import { CustomColumnOptions } from '../types'

const getFlexAlignment = (
  alignment: CustomColumnOptions['alignment'],
): string | undefined => {
  switch (alignment) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return undefined
  }
}

export default getFlexAlignment
