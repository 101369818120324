import { useMutation, useQueryClient } from "@tanstack/react-query";
import { States } from "../../constants/enums";
import { WriteAssetContract, assetContractWriteModelToDto } from "../../model/asset-contract.model";
import { useHttp } from "../adaptorHooks/useHttp";
import { useAddBuyerInfo } from "../deliveryGroupHooks/useAddBuyerInfo";
import { useDealershipInfo } from "../useDealershipInfo";
import { useAddCobuyerInfo } from "../deliveryGroupHooks/useAddCobuyerInfo";
import { useAddDealerInfo } from "../deliveryGroupHooks/useAddDealerInfo";
import { ValidationError } from "../adaptorHooks/validationError";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { assetContractKey, UPSERT_ASSET_CONTRACT_MUTATION_KEY_ROOT, assignedVehiclesKey } from "../../utils/react-query-key-factory";

async function putAssetContract(put: <In, Out>(url: string, body: In) => Promise<Out | null>,
  dealershipNumber: string, assetContract: WriteAssetContract, shouldClone?: boolean): Promise<ReadyForContractStatus | void | null> {
  const assetContractDto = assetContractWriteModelToDto(assetContract);
  let assetEndpoint = `dealership/${dealershipNumber}/asset-contracts/${encodeURIComponent(assetContract.appId)}`;
  if (shouldClone) {
    assetEndpoint += "?shouldClone=true"
  }

  return put<any, ReadyForContractStatus>(assetEndpoint, assetContractDto);
}
type ReadyForContractStatus = {
  isReadyForContract: boolean;
}
type ValidationMsg = { parameter: string, message: string };

type EditAc = {
  onSuccess?: (data?: ReadyForContractStatus) => void;
  onError?: (error: ValidationMsg[]) => void;
  assetContract: WriteAssetContract,
  deliveryGroupId?: string | null
}

export function useMutateAssetContract(dealershipNumber: string, shouldClone?: boolean) {
  const { put } = useHttp();
  const queryClient = useQueryClient();
  const useBuyerInfo = useAddBuyerInfo(dealershipNumber);
  const useCobuyerInfo = useAddCobuyerInfo(dealershipNumber);
  const useDealerInfo = useAddDealerInfo(dealershipNumber);
  const [dealershipInfo] = useDealershipInfo();
  const analytics = useAnalytics();

  return useMutation([UPSERT_ASSET_CONTRACT_MUTATION_KEY_ROOT],
    async (ac: EditAc) => {
      // Always pull the dealership state from dealership info and send with asset contract
      const dealerState = States[dealershipInfo.dealershipState!];
      const dealershipInformation = ac.assetContract?.dealershipInformation
        ? ac.assetContract.dealershipInformation
        : {};
      dealershipInformation.sellerState = dealerState ?? dealershipInformation.sellerState;

      const assetContractWithDealerState = {
        ...ac.assetContract,
        dealershipInformation
      };

      return putAssetContract(put, dealershipNumber, assetContractWithDealerState, shouldClone);
    },
    {
      //Look at DeliveryGroup mutate hooks for example of optimistic updates 
      onSettled: (data, error, req) => {
        const acKey = assetContractKey(dealershipNumber, req.assetContract.appId);
        const vehiclesKey = assignedVehiclesKey(dealershipNumber, req.assetContract.customerId, req.deliveryGroupId);
        queryClient.invalidateQueries(acKey);
        queryClient.invalidateQueries(vehiclesKey);
      },
      onSuccess: async (data, mutateAc, context) => {
        mutateAc.onSuccess((data instanceof Object) ? data : undefined);

        // Set delivery group information for section 1, 2, and 8 if applicable.
        // (These are the only sections that are marked as complete using a whole section.)
        const buyerInfoMarkedComplete = mutateAc.assetContract.buyerInformation?.isComplete;
        const cobuyerInfoMarkedComplete = mutateAc.assetContract.coBuyerInformation?.isComplete;
        const dealershipInfoMarkedComplete = mutateAc.assetContract.dealershipInformation?.isComplete;
        const deliveryGroupId = mutateAc.deliveryGroupId

        if (deliveryGroupId && (buyerInfoMarkedComplete || cobuyerInfoMarkedComplete || dealershipInfoMarkedComplete)) {
          const useInfo = {
            deliveryGroupId: deliveryGroupId,
            applicationId: mutateAc.assetContract.appId,
            customerId: mutateAc.assetContract.customerId
          }

          const contractDataTasks = [];

          if (buyerInfoMarkedComplete) {
            contractDataTasks.push(useBuyerInfo.mutateAsync(useInfo));
          }

          if (cobuyerInfoMarkedComplete) {
            contractDataTasks.push(useCobuyerInfo.mutateAsync(useInfo));
          }

          if (dealershipInfoMarkedComplete) {
            contractDataTasks.push(useDealerInfo.mutateAsync(useInfo));
          }

          await Promise.all(contractDataTasks);
        }

      },
      onError(error, req: EditAc, context) {
        if (error instanceof ValidationError && req.onError) {
          req.onError(error.validationErrors);
          if (error.validationErrors.length > 0) {
            error.validationErrors.forEach((errorString) =>
              analytics(emitErrorEvent, errorString.message, error.status)
            );
          }
        } else {
          analytics(emitErrorEvent, (error as any).message ?? "edit asset contract error", (error as any).status ?? 500);
        }
      },
      retry: 0 // put is indempotent as an upsert so retry should be okay
    });
}
