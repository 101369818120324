import styled from "styled-components";
export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    ul {
    max-height: 196px;
    min-width: 345px;
    };
  & :hover {
    cursor: pointer;
  }
`

export const OptionalFieldsDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;
  margin-top: 10px;
 &.cash-price {
  margin-top: 20px}
`;

export const DejargonatorBodyStyle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #BBBBBB;
`
