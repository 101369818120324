import styled from "styled-components";
import { Box, Button, FormText, Select } from "@ally/metronome-ui";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  min-height: 80px;
  padding-top: 20px;
  margin-right: 10px;
  max-width: 1330px;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: row;
  
`;

export const Heading = styled.h2`
  display: flex;
  max-width: 564px;
  padding-top: 30px;
  margin-left: 22px;
  color: #650360;
  font-weight: bold;
  line-height: 54px;
  font-size: 36px;
`;

export const SubHeader = styled.p`
  margin-top: 16px;
  font-weight: normal;
  line-height: 21px;
  font-size: 14px;
  color: #2a2a2a;
`;

export const BoxStyle = styled(Box)``;

export const BoxStyleClearButton = styled(Box)`
  align-self: center;
`;

export const VehicleSearchFieldStyle = styled(FormText)`
  min-width: 230px;
  label {
    height: 20px;
    width: 91px;
    color: #000000;
    font-size: 11px;
    font-weight: bold;
    line-height: 19.8px;
    margin-bottom: 1px;
  }

  div {
    outline: none;
  }
  div > span > input {
    background: #f9f9f9;
  }
`;

export const ButtonStyle = styled(Button)`
  width: 30px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  position: center;
  margin-right: 16px;
  border-radius: 4px;
  text-align: center;
`;

export const SelectedDropdownTriggerYear = styled(Select)`
  min-width: 140px;
  margin-left: 15px;
  box-sizing: border-box;
  color: #959595;
  box-shadow: none;

  select {
    background-color: #f9f9f9;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #2a2a2a;
    font-style: normal;
  }
`;

export const SelectedDropdownTriggerMake = styled(Select)`
  min-width: 205px;
  margin-left: 15px;
  box-sizing: border-box;
  color: #959595;
  box-shadow: none;

  select {
    background-color: #f9f9f9;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #2a2a2a;
    font-style: normal;
  }
`;

export const SelectedDropdownTriggerModel = styled(Select)`
  min-width: 215px;
  margin-left: 15px;
  box-sizing: border-box;
  color: #959595;
  box-shadow: none;

  select {
    background-color: #f9f9f9;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #2a2a2a;
    font-style: normal;
  }
`;

export const LabelStyle = styled.div`
  margin-bottom: 4px;
  margin-left: 15px;
  font-size: 12px;
`;

export const VehicleFormFieldStyle = styled.div`
  min-width: 245px;
  color: #f9f9f9;
  margin-bottom: 21px;

  div {
    outline: none;
  }

  div > span {
    margin-top: -4px;
    padding-top: 0;
    padding-bottom: 2px: 
  }

  span[data-testid="mui-input-select-container"] {
    position: absolute;
    top: 44px;
    left: 0px;
  }

`;

export const LinkButtonStyle = styled(Button)`
  letter-spacing: 0;
  line-height: 16px;
  color: #1b88d3;
  margin-left: 16px;
  margin-top: 25px;
  font-weight: bold;
  min-height: 80px;
`;
