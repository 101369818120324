import { Alert, Box, ButtonGroup, Icon } from "@ally/metronome-ui";
import styled from "styled-components";

export const BoxStyle = styled(Box)`
  padding: 0;
  label {
    margin-top: 21px;
  }
  .mob_select {
    margin-bottom: -8px;
  }
`;

export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 17px;
  font-size: 14px;
  color: #2a2a2a;
  max-width: 65%;
  max-height: 51px;
  margin-bottom:24px;
`;

export const LabelStyle = styled.div`
  font-size: 12px;
  color: #2a2a2a;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 29px;
  margin-bottom: 35px;
`;

export const StyledIcon = styled(Icon)`
  padding-top: 0px;
`;

export const IconContainer = styled.div`
  margin-top: 10px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;

export const WarningParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  max-width: 65%;
`;

export const StyledSelect = styled.div`
  max-width: 320px;
  color: #ffffff;
  margin-bottom: 21px;
  margin-top: 20px;

  div {
    outline: none;
  }
`;

export const StyledAlert = styled(Alert)`
  margin: 18px 0px;
  max-width: 65%;
  width: 90%;
`;
