import { useContext, useState } from "react";
import { useParams } from "react-router";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { ContextType, TabContext } from "../TabContext";
import {
    ButtonGroupStyled,
    Divider,
    DocUploadStyled,
    ErrorMessageWrapper,
    OuterContainer,
    PreviewButton,
    TitleAndDescriptionWrapper
} from "./SendContractStyled";
import { Button } from "@ally/metronome-ui";
import { ChangeSigningOptionsModal } from "../../Modal/ChangeSigningOptionsModal";
import { useGetDocuSignDownloadUrl } from "../../../hooks/docusignHooks/useGetDocusignDownloadUrl";
import { useFundContract } from "../../../hooks/contractHooks/useFundContract";

type SendContractProps = {
    isActiveTab: boolean,
    dealershipNumber: string,
    deliveryGroupId: string,
    setHideRecontractForDG: (string: string) => void
}

const SendContract: React.FC<SendContractProps> = ({ dealershipNumber, deliveryGroupId, setHideRecontractForDG }) => {
    const progressTracker = <ContractProgressTracker stepNumber={4} />;
    const {
        saveActiveDeliveryGroupName,
        activeDeliveryGroupId
    } = useContext(TabContext) as ContextType;
    const { id: customerId } = useParams() as any;
    const {
        data: docusignContractUrls,
        isLoading
    } = useGetDocuSignDownloadUrl(dealershipNumber, deliveryGroupId);
    const [fileTypeError, setFileTypeError] = useState(null);
    const [attachedFiles, setAttachedFiles] = useState([]);
    const acceptedFileTypes = [".heic", "image/heic", ".jpeg", "image/jpeg", ".jpg", "image/jpg", ".png", "image/png", ".pdf", "application/pdf"];
    const fundContract = useFundContract(dealershipNumber);

    const validateFileType = async (files: File[]) => {
        const filesNotSupported = files.filter((file) => !acceptedFileTypes.includes(file.type));
        if (filesNotSupported.length > 0) {
            setFileTypeError('Upload a supported file type to continue.')
            return true;
        } else {
            setFileTypeError(null);
            return false;
        }
    };

    const fundContractAction = async (files: File[]) => {
        const onSuccess = () => {
        }
        const onError = (errs: string[]) => {
            errs.map((err) => console.warn(err))
        }
        const error = await validateFileType(files);
        if (!error) {
            //Will need to change this to include new hook that includes combining pdfs, here temporarily for testing to move to next page
            fundContract.mutate({
                deliveryGroupId: deliveryGroupId!,
                customerId: customerId,
                onSuccess,
                onError
            });
            setHideRecontractForDG(activeDeliveryGroupId);
        }
    };

    return (
        <>
            <DeliveryGroupHeader />
            <ContractedDeliveryGroupDetailsNew
                progressTracker={progressTracker}
                updateDeliveryGroupName={saveActiveDeliveryGroupName}
                deliveryGroupStatus={"CONTRACT_FINALIZED"}
            />
            <Divider />
            <OuterContainer>
                <h2>Gather documents to submit for funding</h2>
                <TitleAndDescriptionWrapper>
                    <h4>1. Signed DocuSign contract package</h4>
                    <p>Review a compilation of DocuSign documents with signatures, including your contract.</p>
                </TitleAndDescriptionWrapper>
                <PreviewButton
                    text={isLoading ? "Processing" : "Preview"}
                    loading={isLoading}
                    variant="secondary"
                    ariaLabel="View signed DocuSign contract package (PDF)"
                    ariaLiveLoading="Processing signed DocuSign contract package (PDF)"
                    onClick={() => {
                        if (docusignContractUrls) {
                            window.open(docusignContractUrls.view, "_blank")
                        }
                    }}
                />
                <TitleAndDescriptionWrapper>
                    <h4>2. Additional documents for funding (optional)</h4>
                    <div>
                        <p>Upload additional required documents such as invoice, book-out sheet and stipulations.</p>
                        <p>We only accept <b>HEIC, JPEG, JPG, PNG,</b> and <b>PDF</b> files up to <b>20MB</b> total.</p>
                    </div>
                </TitleAndDescriptionWrapper>
                <DocUploadStyled
                    onChange={(files: File[]) => {
                        setAttachedFiles(files);
                        setFileTypeError(null);
                    }}
                    accept=".heic,image/heic,.jpeg,image/jpeg,.jpg,image/jpg,.png,image/png,.pdf,application/pdf"
                    uploadFileButtonLabel="Upload File"
                    uploadAdditionalFileButtonLabel="Upload File"
                    maxTotalFileSizeMb={20}
                    ariaLabelDocList="Additional documents to be sent for funding."
                    getAriaLiveRemoveFile={(fileName: string) => `${fileName} removed`}
                />

            </OuterContainer>
            {fileTypeError ? <ErrorMessageWrapper>{fileTypeError}</ErrorMessageWrapper> : null}
            <ButtonGroupStyled>
                <Button
                    variant="primary"
                    text="Submit for Funding"
                    onClick={() => fundContractAction(attachedFiles)}
                    loading={fundContract.isLoading}
                />
                <ChangeSigningOptionsModal buttonType="secondary" />
                <Button
                    variant="link"
                    text="Save for later"
                />
            </ButtonGroupStyled>
        </>
    )
};

export default SendContract;
