import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { BrowserRouter } from "react-router-dom";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID,
  });
  
  ReactDOM.render(
    <BrowserRouter>
      <LDProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </LDProvider>
    </BrowserRouter>,
    document.getElementById("root")
  );

})();
