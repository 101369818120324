import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { BatchJoinIds } from "./useAddAssetToDeliveryGroup";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { UNJOIN_AC_MUTATION_KEY_ROOT, assetContractKey, assignedVehiclesKey, unassignedVehiclesKey } from "../../utils/react-query-key-factory";

async function unjoinAcsWithDg(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, applicationIds: string[]): Promise<number[]>{
    const batchRes = applicationIds.map(applicationId => {
      let unjoinEndpoint = `dealership/${dealershipNumber}/asset-contracts/${encodeURIComponent(applicationId)}/remove-from`;
      return put<any, number>(unjoinEndpoint, {}).finally( () => applicationId);
    });
    const results = await Promise.allSettled(batchRes)
    //TS isn't smart for filtering out fulfilled before mapping to work
    return results.map(res => {
        return res.status === "fulfilled" 
          ? res.value
          : null
      }).filter(appId => appId !== null) as number[];

}

export function useRemoveAssetContractFromDeliveryGroup(dealershipNumber: string){
  const {put} = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation(
    [UNJOIN_AC_MUTATION_KEY_ROOT],
    async (ids:BatchJoinIds) => await unjoinAcsWithDg(put, dealershipNumber, ids.applicationIds),
    {    
      onMutate: async (ids: BatchJoinIds) => {
        const vehiclesKey = assignedVehiclesKey(dealershipNumber, ids.customerId, ids.deliveryGroupId);
        await queryClient.cancelQueries(vehiclesKey)
      },
      onSettled: (data, error, ids: BatchJoinIds) => {
        const vehiclesForDgKey = assignedVehiclesKey(dealershipNumber, ids.customerId, ids.deliveryGroupId);
        const vehiclesForNoDgKey = unassignedVehiclesKey(dealershipNumber, ids.customerId);
        queryClient.invalidateQueries(vehiclesForDgKey);
        queryClient.invalidateQueries(vehiclesForNoDgKey);

        ids.applicationIds.forEach(applicationId => {
          queryClient.invalidateQueries(assetContractKey(dealershipNumber, applicationId));
        });
      },
      onError(error){
        analytics(emitErrorEvent, (error as any).message ?? "remove asset contract from delivery group error", (error as any).status ?? 500);
      }
    }
  );

}

