import styled from "styled-components";
import { Button, TextBody, Modal } from "@ally/metronome-ui";


export const ContractModalStyled = styled(Modal)`
  width: 490px;
  max-height: 400px;

  padding: 24px, 24px, 24px, 21px;
  div > h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 14px;
    line-height: 20px;
    margin: 19px 0 8px 0;
    font-family: Lato Regular;
  }
`;

export const ContractModalActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;

`;

export const ContinueContractActionButtonStyle = styled(Button)`
  min-width: 120px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 12px;
  border-radius: 4px;
`;

export const ExitFinalizeErrorButton = styled(Button)`
  min-width: 120px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 17px;
  border-radius: 4px;
`;

export const CancelContractCreationButtonStyle = styled(Button)`
  min-width: 108px;
  color: #0071c4;
  background-color: #e7f6fd;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 12px;
  border-radius: 4px;
  margin-left: 8px;
`;

export const ContractActionModalBody = styled(TextBody)`
  p {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TextBodyStyle = styled(TextBody)`
  p {
    white-space: pre-line;
  }
`

