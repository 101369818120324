import React from "react";
import { Container, DivStyled, OpenDrawer } from "./AssetContractCardStyled";
import { ContractSummaryCardModel } from "../../model/display/contract-summary-asset-card";

type Props = {
  cardData: ContractSummaryCardModel
};

const AssetContractCard: React.FC<Props> = ({
  cardData
}) => {
  
  return (
    <DivStyled>
      <Container>
        <p>
          Vehicle {cardData.count}: {cardData.year}{" "}
          {cardData.make}{" "}
          {cardData.model}
        </p>
      </Container>
      <OpenDrawer>
        <div className="container">
          <div className="detail_container">
            <h4 className="firstColumn">Ally App ID:</h4>
            <p>{cardData.appId}</p>
          </div>
          <div className="detail_container">
            <h4 className="firstColumn">VIN: </h4>
            <p>{cardData.vin}</p>
          </div>
          <div className="detail_container">
            <h4 className="firstColumn">App Decision: </h4>
            <p>{cardData.appDecision}</p>
          </div>
          <div className="detail_container">
            <h4 className="firstColumn">Last Updated: </h4>
            <p>{cardData.lastUpdatedDate}</p>
          </div>
        </div>
        <div className="container">
          <div className="detail_container">
            <h4 className="secondColumn">Vehicle Cash Price:</h4>
            <p>
              ${cardData.cashPrice}
            </p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Taxes and Fees: </h4>
            <p>
            ${cardData.taxesAndFees}
            </p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Total Down Payment: </h4>
            <p>${cardData.totalDownPayment}</p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Total Other Charges: </h4>
            <p>
            ${cardData.totalOtherCharges}
            </p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Amount Financed: </h4>
            <p>${cardData.amountFinanced}</p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Term: </h4>
            <p>{cardData.numberOfPayments}</p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">APR: </h4>
            <p>{cardData.annualPercentageRate}%</p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Trade-In: </h4>
            <p className="secondColumn">{cardData.tradeInStatus}</p>
          </div>
          <div className="detail_container">
            <h4 className="secondColumn">Monthly Payment: </h4>
            <p>${cardData.monthlyPayment}</p>
          </div>
        </div>
      </OpenDrawer>
    </DivStyled>
  );
};

export default AssetContractCard;
