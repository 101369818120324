import {useOktaAuth} from '@okta/okta-react';
import { ValidationError } from './validationError';
import { CustomError } from './customError';

const base_url = process.env.REACT_APP_MULTI_ASSET_API_BASE_URL;

export function useHttp(){
  const { authState } = useOktaAuth();
  const token = authState?.accessToken?.accessToken ?? "hereForTestsWithoutAUthEnabled";
  
  return{
    get: tokenify(token,get) as <Result>(url:string) => Promise<Result | null>, 
    put: tokenify(token,put) as <BodyType, Result>(url:string, body: BodyType ) => Promise<Result | null>,
    post: tokenify(token,post) as <BodyType, Result>(url:string, body: BodyType) => Promise<Result | null>,
    deleteMethod: tokenify(token, deleteMethod) 
  };
}
const tokenify = (token: string, func: (token: string, ...args: any[]) => Promise<any>) => {
  return (...args: any[]) => {
    return func(token, ...args);
  }
}

//delete is a keyword
async function deleteMethod(token: string, url:string): Promise<void> {
  const controller = new AbortController();
  const options = {
    method: 'DELETE',
    signal: controller.signal,
    headers: {
      "Authorization": token
    }
  } as RequestInit

  let timeoutId = undefined;
  try{
    const endpoint = `${base_url}/${url}`;
    const res = await fetch(endpoint, options);
    timeoutId = setTimeout(() => controller.abort(), 4000);
    if(!res.ok){
      throw new CustomError(`DELETE Failure for ${endpoint} with result ${res.status} : ${res.statusText}`, res.status);
    }
  }
  finally{
    clearTimeout(timeoutId);
  }
}
async function get<Result>(token: string, url:string): Promise<Result | null>{
  const controller = new AbortController();
  const options = {
    method: 'GET',
    signal: controller.signal,
    headers: {
      "Authorization": token
    }
  } as RequestInit

  let timeoutId = undefined;
  try{
    const endpoint = `${base_url}/${url}`;
    const res = await fetch(endpoint, options);
    timeoutId = setTimeout(() => controller.abort(), 25000);
    if(!res.ok){
      throw new CustomError(`GET Failure for ${endpoint} with result ${res.status} : ${res.statusText}`, res.status);
    }
    return res.status === 200 
      ? (res.json() as unknown) as Result
      : null;
  }
  finally{
    clearTimeout(timeoutId);
  }
}

async function put<BodyType>(token: string, url:string, body: BodyType): Promise<unknown>{ 
  return await mutate<BodyType, unknown>(token, url, body, 'PUT');
}

async function post<BodyType>(token: string, url:string, body: BodyType, uniqueKey?: string): Promise<unknown | null>{ 
 return mutate<BodyType, unknown>(token, url, body, 'POST', uniqueKey);
}

async function mutate<Input, Output>(token: string, url:string, body: Input, method: string, uniqueKey?: string): Promise<Output | null>{
  const controller = new AbortController();
  const options = {
    method,
    signal: controller.signal,
    headers: {
      "Authorization": token,
      "Content-Type": 'application/json',
    },
    body: JSON.stringify(body),
  } as RequestInit
  let timeoutId = undefined;

  try{
    timeoutId = setTimeout(() => controller.abort(), 25000);
    const endpoint = `${base_url}/${url}`;
    const res = await fetch(endpoint, options);

    if(res.ok){
      if(res.status === 204){
        return null;
      }
      return await (res.json() as any);
    }
  
    const output = await (res.json() as any);
    if(output && output.errors && output.details){
      throw new ValidationError(output.details, output.errors, res.status);
    }
    throw new CustomError(`${method} Failure for ${endpoint} with result ${res.status} : ${res.statusText}`, res.status);

  }
  finally{
    clearTimeout(timeoutId);
  }
}
