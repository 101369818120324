import * as React from 'react'

/**
 * Get the previous value of props, state, or any other calculated value.
 * This hook is implemented in the React docs and may eventually ship with React.
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 *
 * @example
 * const [count, setCount] = useState(0)
 * const prevCount = usePrevious(count)
 */

const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>()

  React.useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export default usePrevious
