import { Button, Icon, useModalContext } from "@ally/metronome-ui";
import { AddInsurancePolicyDiv, InsuranceModalContent } from "../Tab/DeliveryGroupTabViews/InsurancePolicyStyled";
import { muiAdd } from "@ally/metronome-icons";
import InsurancePolicyForm from "./InsurancePolicyForm";
import { UseMutationResult } from "@tanstack/react-query";
import { ReadDeliveryGroup } from "../../model/delivery-group.model";
import { useAnalytics } from "../../hooks/analytics/useAnalytics";
import { emitModalViewEvent, ModalViews } from "../../analytics/modalViews";
import { useEffect } from "react";

export const AddInsuranceModalTrigger: React.FC = () => {
    const { setOpen } = useModalContext();


    return (
        <AddInsurancePolicyDiv className="add-insurance-button" onClick={() => setOpen(true)} >
            <Icon icon={muiAdd} size="xl" />
            <Button
                aria-label="Add insurance policy"
                text="Add insurance policy"
                variant="link"
                allytmln="open-insurance-policy-modal"
            />
        </AddInsurancePolicyDiv>
    )
};

type ContentProps = {
    closeModal?: () => void,
    editDeliveryGroup: UseMutationResult,
    setInsuranceFields: React.Dispatch<React.SetStateAction<Object>>,
    deliveryGroup: ReadDeliveryGroup,
    setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>
};

export const AddInsuranceModalContent: React.FC<ContentProps> = ({
    editDeliveryGroup,
    deliveryGroup,
    setShowContractModal
}) => {
    const { setOpen } = useModalContext(); 

    const analytics = useAnalytics();
    useEffect(() => {
        analytics(emitModalViewEvent, ModalViews.ADD_INSURANCE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <InsuranceModalContent tabIndex={-1}>
            <h2>Detail insurance coverage</h2>
            <InsurancePolicyForm
                closeModal={() => setOpen(false)}
                deliveryGroupInfo={deliveryGroup}
                editDeliveryGroup={editDeliveryGroup}
                modalType="add"
                setShowContractModal={setShowContractModal}
            />
        </InsuranceModalContent>
    )
}
