export enum ValidationType {
  REQUIRED,
  OPTIONAL
}

export type FieldConfiguration = {
  fieldName: string;
  validationType: ValidationType;
  states: string[];
}

export type FieldConfigurationSection = {
  name: string;
  fieldConfigurations: FieldConfiguration[];
}

export type SectionFieldSummary = {
  name: string;
  requiredFields: string[];
  optionalFields: string[];
}
