import { Box, ButtonGroup, Icon } from "@ally/metronome-ui";
import styled from "styled-components";

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 35px;
`;

export const BoxStyle = styled(Box)`
  padding: 0;
  label {
    margin-top: 21px;
  }
  .test {
    div {
      margin-top: 20px;
    }
  }
`;

export const StyledIcon = styled(Icon)`
  padding-top: 0px;
`;

export const IconContainer = styled.div`
  margin-top: 10px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
`;

export const WarningParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
`;
