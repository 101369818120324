import { useFocus } from '@ally/metronome-ui'
import { RefObject, useState, useEffect, useRef, MutableRefObject } from 'react'
import { Row } from 'react-table'

export interface UseDrawerProps {
  toggleDrawer: (open?: boolean) => void
  openDrawerWithToggle: () => void
  closeDrawerAndFocus: () => void
  isDrawerOpen: boolean
  // drawerTriggerRef is mutable so that consumers can have granular control if
  // there are multiple trigger buttons
  drawerTriggerRef: MutableRefObject<HTMLButtonElement | null>
  drawerToggleRef: RefObject<HTMLButtonElement>
  drawerTargetRef: RefObject<HTMLHeadingElement>
}

// these props are passed to the cell render function and useTableCell hook
export type CellDrawerProps = Pick<
  UseDrawerProps,
  'isDrawerOpen' | 'toggleDrawer' | 'drawerTriggerRef'
>

type DrawerStates = 'Closed' | 'OpenedWithToggle' | 'OpenedWithTrigger'

const useDrawer = <D extends Record<string, any>>(
  row: Row<D>,
): UseDrawerProps => {
  const [drawerState, setDrawerState] = useState<DrawerStates>('Closed')
  const [drawerToggleRef, setToggleFocus] = useFocus<HTMLButtonElement>()
  const [drawerTargetRef, setTargetFocus] = useFocus()
  const drawerTriggerRef = useRef<HTMLButtonElement | null>(null)

  /**
   * setTriggerFocus is performed as an effect since there is a table pattern
   * where the trigger buttons are disabled when the drawer opens. Disabled
   * buttons cannot be focused, so using an effect allows the trigger button to
   * be enabled before focus is set.
   */
  const [isReadyToFocus, setIsReadyToFocus] = useState(false)
  const setTriggerFocus = (): void => {
    setIsReadyToFocus(true)
  }

  useEffect(() => {
    if (isReadyToFocus) {
      drawerTriggerRef.current?.focus()
      setIsReadyToFocus(false)
    }
  }, [isReadyToFocus])

  useEffect(() => {
    // drawer was closed
    if (!row.isExpanded) {
      setDrawerState('Closed')
      return
    }

    // drawer was opened
    if (drawerState !== 'OpenedWithToggle') {
      setDrawerState('OpenedWithTrigger')
    }

    setTargetFocus()
  }, [row.isExpanded])

  const setFocus = (): void => {
    switch (drawerState) {
      case 'OpenedWithToggle':
        setToggleFocus()
        break
      case 'OpenedWithTrigger':
      default:
        setTriggerFocus()
    }
  }

  const toggleDrawer = (open?: boolean): void => {
    row.toggleRowExpanded(open)
  }

  const openDrawerWithToggle = (): void => {
    setDrawerState('OpenedWithToggle')
    row.toggleRowExpanded(true)
  }

  const closeDrawerAndFocus = (): void => {
    row.toggleRowExpanded(false)
    setFocus()
  }

  return {
    toggleDrawer,
    openDrawerWithToggle,
    closeDrawerAndFocus,
    drawerTriggerRef,
    drawerToggleRef,
    drawerTargetRef,
    isDrawerOpen: row.isExpanded,
  }
}

export default useDrawer
