export function singleCustomerPageRoute(customerId: string, deliveryGroupId?: string){
  return  deliveryGroupId === undefined
    ? `/customer/${customerId}/delivery-group/`
    : `/customer/${customerId}/delivery-group/${deliveryGroupId}`
}

export function clonePageRoute(customerId: string, deliveryGroupId: string, sourceAppId: string){
  return `/clone-vehicle/customer/${customerId}/delivery-group/${deliveryGroupId}/source-vehicle/${sourceAppId}`;
}

export function customersDashBoardRoute(){
  return "/";
}

export function editVehiclePageRoute(appId: string){
  return `/edit-vehicle/${appId}`;
}

export function contractSummaryRoute(dealershipNumber: string, customerId: string, dgId: string){
  return `/dealership/${dealershipNumber}/customer/${customerId}/delivery-group/${dgId}/summary`;
}
