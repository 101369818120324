import { FieldConfigurationSection, ValidationType } from "../field-configuration";

export const sellerGovFeesConfig: FieldConfigurationSection = {
  name: "sellerGovFees",
  fieldConfigurations: [
    {
      fieldName: "dealerDocumentaryFee",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "deputyServiceFeeToSeller",
      validationType: ValidationType.OPTIONAL,
      states: ["TX"]
    },
    {
      fieldName: "sellersInventoryTax",
      validationType: ValidationType.OPTIONAL,
      states: ["TX"]
    },
    {
      fieldName: "miscCharges",
      validationType: ValidationType.OPTIONAL,
      states: ["CA"]
    },
    {
      fieldName: "documentProcessingCharge",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "emissionsTestingCharge",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "evChargingStationCharge",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "evRegisterTransferCharge",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "ertFee",
      validationType: ValidationType.OPTIONAL,
      states: ["IL"]
    },
    {
      fieldName: "officialFeesPaidToGovAgencies",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "govTaxesNotIncludedInPrice",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "govLicenseFee",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "govRegistrationFee",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "govCertificateOfTitleFee",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "securityInterestRecordingFee",
      validationType: ValidationType.OPTIONAL,
      states: ["MD"]
    },
    {
      fieldName: "plateTransferFee",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "govDocStampTax",
      validationType: ValidationType.REQUIRED,
      states: ["FL"]
    },
    {
      fieldName: "govWasteTireManagementFee",
      validationType: ValidationType.REQUIRED,
      states: ["NY"]
    },
    {
      fieldName: "govVehicleInspectionFee",
      validationType: ValidationType.OPTIONAL,
      states: ["TX"]
    },
    {
      fieldName: "lienNotationFee",
      validationType: ValidationType.OPTIONAL,
      states: ["OH"]
    },
    {
      fieldName: "supplementalTitleFee",
      validationType: ValidationType.OPTIONAL,
      states: ["NJ"]
    },
    {
      fieldName: "vehicleTireFee",
      validationType: ValidationType.REQUIRED,
      states: ["CA", "NJ", "LA"]
    },
    {
      fieldName: "stateEmissionsCertExemptFee",
      validationType: ValidationType.OPTIONAL,
      states: ["CA"]
    },
    {
      fieldName: "totalOfficialFeesPaidToGovAgencies",
      validationType: ValidationType.OPTIONAL,
      states: ["WA"]
    },
    {
      fieldName: "forPriorCreditOrLeaseBalance",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "forPriorCreditOrLeaseBalanceTo",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "toSellerForTradeInCreditAgreement",
      validationType: ValidationType.OPTIONAL,
      states: ["TX"]
    },
    {
      fieldName: "securityInterestRecordingFeeCash",
      validationType: ValidationType.OPTIONAL,
      states: ["OH"]
    },
    {
      fieldName: "electronicLienAndTitleFeeTo",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "electronicLienAndTitleFee",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
  ]
}
