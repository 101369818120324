import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import {TabContext, ContextType } from "../../Tab/TabContext";
import BuyerInformation from "./BuyerInformation";
import CobuyerInformation from "./CobuyerInformation";
import VehicleInformation from "./VehicleInformation";
import CashPrice from "./CashPrice";
import TradeInCashDown from "./TradeInCashDown";
import SellerAndGovFees from "./SellerAndGovFees";
import DealershipInformation from "./DealershipInformation";
import AftermarketProducts from "./AftermarketProducts";
import ContractSummary from "./ContractSummary";
import {
  SteppedProcess,
} from "../../../library/SteppedProcess";
import { ActiveTabs } from "../../../constants/enums";
import { WriteAssetContractSectionsNames, WriteAssetContractSections, ReadAssetContract, TradeInCashDown as AssetContractTradeInCashDown } from "../../../model/asset-contract.model";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { singleCustomerPageRoute } from "../../../routes/RouteFactory";
import { muiCheckmark, muiIndividual } from "@ally/metronome-icons";

type EditVehicleFormSectionProps = {
  assetContract: ReadAssetContract;
  deliveryGroup: ReadDeliveryGroup;
  sectionsMarkedAsComplete: number[] | null;
  macNewSection: (macSec: WriteAssetContractSectionsNames) => void;
}
const EditVehicleFormSection = ({
  assetContract, deliveryGroup, sectionsMarkedAsComplete, macNewSection
}: EditVehicleFormSectionProps) => {

  const history = useHistory();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const appId = assetContract.appId;
  const customerId = assetContract.customerId;
  const [assetContractState, setAssetContractState] = useState(assetContract);

  useEffect(() => {
    setAssetContractState(assetContract);
  }, [assetContract, assetContract.appId]);

  const {
    saveSelection
   } = useContext(TabContext) as ContextType;

  const handleRedirectToDeliveryGroup = () => {
    saveSelection({
      destinationTab: ActiveTabs.DELIVERY_GROUPS
    });
    
    history.push({
      pathname: singleCustomerPageRoute(customerId, deliveryGroup.id),
    });
  }
 
  const tradeInCashDown = assetContractState?.tradeInCashDown;

  const updateAssetContractState = (assetContractSection: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames, calc?: any) => {
    const isMac = assetContractSection[sectionName].isComplete;
    if(isMac){
      macNewSection(sectionName);
    }
    setAssetContractState((prev) => {
      const totalCashPrice = calc?.totalCashPrice ?? prev.totalCashPrice;
      const totalDownPayment = calc?.totalDownPayment ?? prev.totalDownPayment;

      if (sectionName === 'buyerInformation') {
        assetContractSection['buyerInformation'].taxId = prev.buyerInformation.taxId;
      }

      const tradeInCashDown = sectionName === 'tradeInCashDown'
        ? (assetContractSection['tradeInCashDown'] as AssetContractTradeInCashDown)
        : prev.tradeInCashDown;
      
      if (tradeInCashDown) {
        tradeInCashDown.equalsNetTradeIn = calc?.tradeInCashDown?.equalsNetTradeIn ?? tradeInCashDown.equalsNetTradeIn;
      }

      const unpaidBalanceOfCashPrice = calc?.unpaidBalanceOfCashPrice ?? prev.unpaidBalanceOfCashPrice;
      const totalOtherCharges = calc?.totalOtherCharges ?? prev.totalOtherCharges;
      const amountFinanced = calc?.amountFinanced ?? prev.amountFinanced;
      const totalOptionalBenefits = calc?.totalOptionalBenefits ?? prev.totalOptionalBenefits;
      const monthlyPayment = calc?.monthlyPayment ?? prev.monthlyPayment;

      const ac = {
        ...prev,
        ...assetContractSection,
        totalCashPrice,
        totalDownPayment,
        tradeInCashDown,
        unpaidBalanceOfCashPrice,
        totalOtherCharges,
        amountFinanced,
        totalOptionalBenefits,
        monthlyPayment
      };

      return ac;
    });
  };

  const allSectionsCompleted = sectionsMarkedAsComplete.length >= 8 ? true : false;

  const currentActiveStepIndexFn = () => {
    const totalNumberOfSection = 9;
    let returnActiveIndex = 0;
    for (let i = 0; i < totalNumberOfSection; i++) {
      if (sectionsMarkedAsComplete[i] !== i) {
        returnActiveIndex = i;
        break;
      } else {
        returnActiveIndex = sectionsMarkedAsComplete.length - 1;
      }
    }  
    return returnActiveIndex;
  }

  return (
    <SteppedProcess
      variant="vertical"
      steps={[
        {
          id: "buyer-information",
          title: { text: "Buyer information" },
          content: (
            <BuyerInformation
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: [
              {
                text: assetContractState.buyerInformation?.name ?? "",
                icon: muiIndividual,
                ariaLabel: "Step 1 summary",
              },
            ],
          },
        },
        {
          id: "co-buyer-information",
          title: { text: "Co-buyer or guarantor information" },
          content: (
            <CobuyerInformation
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: [
              {
                text: !!assetContractState.coBuyerInformation?.name
                  ? assetContractState.coBuyerInformation?.type === "GUARANTOR"
                    ? "Guarantor"
                    : "Co-buyer"
                  : "No co-buyer or guarantor",
                icon: muiCheckmark,
                ariaLabel: "Step 2 summary",
              },
              {
                text: !!assetContractState.coBuyerInformation?.name
                  ? `Name: ${assetContract?.coBuyerInformation?.name}`
                  : "",
                ariaLabel: "Step 2 summary",
              },
            ],
            editStepButton: {
              isHidden: assetContractState.coBuyerInformation?.name
                ? false
                : true,
            },
          },
        },
        {
          id: "vehicle-information",
          title: { text: "Vehicle information" },
          content: (
            <VehicleInformation
              appId={appId}
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: [
              {
                text: `${assetContractState.vehicleDetails.year} ${assetContractState.vehicleDetails.make} ${assetContractState.vehicleDetails.model}`,
                icon: muiCheckmark,
                ariaLabel: "Step 3 summary",
              },
            ],
          },
        },
        {
          id: "cash-price",
          title: { text: "Cash price" },
          content: (
            <CashPrice
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: [
              {
                text: "Cash price and taxes",
                icon: muiCheckmark,
                ariaLabel: "Step 4 summary",
              },
            ],
          },
        },
        {
          id: "trade-in-cash-down",
          title: { text: "Trade-in or cash down" },
          content: (
            <TradeInCashDown
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: [
              {
                text: tradeInCashDown.hasTradeIn
                  ? "Trade In"
                  : "No trade-in",
                icon: muiCheckmark,
                ariaLabel: "Step 5 summary",
              },
            ],
          },
        },
        {
          id: "seller-and-governmental",
          title: { text: "Seller and governmental" },
          content: (
            <SellerAndGovFees
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: [
              {
                text: "Seller amounts",
                icon: muiCheckmark,
                ariaLabel: "Step 6 summary",
              },
            ],
          },
        },
        {
          id: "aftermarket-products",
          title: { text: "Aftermarket products" },
          content: (
            <AftermarketProducts
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
            />
          ),
          summary: {
            items: assetContractState.auxProducts?.auxProducts?.map(auxProduct => {
              return {
                text: auxProduct.typeOfBenefit,
                icon: muiCheckmark,
                ariaLabel: "Step 7 summary",
              };
            }),
          },
        },
        {
          id: "dealership-information",
          title: { text: "Dealership information" },
          content: (
            <DealershipInformation
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
              set
            />
          ),
          summary: {
            items: [
              {
                text:
                  assetContractState.dealershipInformation?.sellerName ??
                  "",
                icon: muiCheckmark,
                ariaLabel: "Step 8 summary",
              },
            ],
          },
        },
        {
          id: "contract-summary",
          title: { text: "Contract summary" },
          content: (
            <ContractSummary
              dealershipNumber={dealershipNumber}
              customerId={customerId}
              assetContract={assetContractState}
              deliveryGroup={deliveryGroup}
              updateAssetContractState={updateAssetContractState}
              handleRedirectToDeliveryGroup={handleRedirectToDeliveryGroup}
              allSectionsCompleted={allSectionsCompleted}
            />
          ),
          summary: {
            items: [
              {
                text: "Contract Summary",
                icon: muiCheckmark,
                ariaLabel: "Step 9 summary",
              },
            ],
          },
        },
      ]}
      initialState={{
        completedStepIndices: sectionsMarkedAsComplete,
        activeStepIndex: currentActiveStepIndexFn()
      }}
      ariaLabel="edit your asset contract"
    />
  );
};

export default EditVehicleFormSection;
