import { MetronomeProvider, muiBankTheme } from "@ally/metronome-ui";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { BodyContainer, FooterBand, FooterContainer, HeaderBand, HeaderContainer, SectionContainer, SubHeader, SubHeaderContainer } from "./AppStyled";
import "./analytics/csgAnalytics"; //init ally analytic library
import Footer from "./components/Footer/Footer";
import DealershipHeader from "./components/Header/DealershipHeader";
import Header from "./components/Header/Header";
import TabContextProvider from "./components/Tab/TabContext";
import { configureQueryClient } from "./globalReactQueryConfigs";
import "./logrocket";
import Routes from "./routes/Routes";
import GlobalStyle from "./styles/global";



const queryClient = new QueryClient({
  defaultOptions: { queries: { cacheTime: 0 } },
})
configureQueryClient(queryClient);
const App: React.FC = (props: any) => {
  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri:
      window.location.origin + process.env.REACT_APP_OKTA_REDIRECT_SUFFIX,
    pkce: true,
  });

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  const [modalState, setModalState] = useState("closed");
  const updateState = (arg: string) => {
    setModalState(arg);
  };
  
  return (
    <>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <MetronomeProvider theme={muiBankTheme}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <HeaderBand>
              <HeaderContainer>
                <Header updateModal={updateState}></Header>
              </HeaderContainer>
            </HeaderBand>
            <BodyContainer>
            <TabContextProvider>
              <SubHeaderContainer >
                <SubHeader role="complementary">
                  <DealershipHeader />
                </SubHeader>
              </SubHeaderContainer>
              <SectionContainer>
                <Routes isUnsecure={process.env.REACT_APP_SECURE_ROUTES === 'false'}/>
              </SectionContainer>
              </TabContextProvider>
            </BodyContainer>
            <FooterBand>
              <FooterContainer>    
                <Footer modal={modalState} />
              </FooterContainer>
            </FooterBand>
          </Security>
        </MetronomeProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
