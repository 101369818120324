import { Button, Icon } from "@ally/metronome-ui";
import styled from "styled-components";

export const ESignOuter = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 100%;    
    h3 {
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        font-size: 1.25rem;
        line-height: 1.75rem; /* 140% */
        font-weight: 700;
      }
      p {
        font-family: Lato;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
      }
      h4 {
        margin-bottom: 0.5rem;
        margin-top: 1rem;
        font-weight: 500;
        font-size: 20px;
      }
`

export const ESignInner = styled.div`
    display: flex;
    flex-direction: column;

`

export const InstructionsStyled = styled.div`
      width: 597px;
      p {
        font-size: 16px;
        line-height: 24px;
      }
      ol{
        margin-left: 25px;
        line-height: 24px;
        p {
          margin-left: 15px;
        }
      }
`

export const StatusStyled = styled.div`
  width: 650px;
  margin-top: 16px;
  margin-bottom: 8px;
    h3 {
      margin-bottom: 0.5rem;
      line-height: 1.75rem;
      font-size: 1.25rem;
    }
    p {
      line-height: 24px;
    }
`

export const StyledIcon = styled(Icon)`
  margin-right: 0.25rem;
`;
export const ReviewDocumentsOuter = styled.div`
    max-width: 305px;
    height: 200px;
    margin-top: 1.5rem;
    border: 1px solid #dddddd;
    padding-left: 16px; 
    padding-top: 22px;
    h4 {
        font-size: 20px;
        margin-top: 0px;
    };
    p {
        font-size: 14px;
    };

`
export const LinkWrapper = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 6x;
  margin-top: 8px;
  margin-left: -4px;
  div {
    display: flex;
    align-items: center;
  }
  a {
    font-size: 14px;
};
svg {
    height: 18px;
  }
  span {
    margin-right: 0.5rem
  }

`
export const FinalizeSignaturesButton = styled(Button)`
      width: 171px;
      height: 44px;
      border-radius: 4px;
      margin-right: 8px;
      margin-top: 20px;
`

export const CancelButtonStyle = styled(Button)`
    width: 166px;
    color: #0071c4;
    margin-left: 16px;
    font-size: 16px;
    line-height: 21px;

`;

export const OpenDocusignButton = styled(Button)`
      width: 160px;
      height: 44px;
      border-radius: 4px;
`
export const FinalizeSignaturesError = styled.p`
  color: #D63440;
  font-weight: bold;
  line-height: 24px;
  margin-bottom: 12px;
`
