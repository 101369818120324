import { Route, Switch, useHistory } from "react-router-dom";
import { LoginCallback, SecureRoute} from '@okta/okta-react';
import React, { memo } from "react";
import CustomDashboard from "../components/Dashboards/CustomerDashboard";
import { DashDealerLoginCallback } from "../components/Redirects/DashDealerLoginCallback";
import EditVehicleDetail from "../components/Form/Edit-VehicleDetail/EditVehicleDetail";
import SingleCustomerPage from "../components/Tab/SingleCustomerPage";
import ContractSummary from "../components/ContractSummary/ContractSummary";
import CloneVehicle from "../components/Form/Clone/CloneVehicle";
import NoMatchPage from "./NoMatchPage";
import UpdatedStateNotSupported from "../components/Splash/UpdatedStateNotSupported";
import { useFlags } from "launchdarkly-react-client-sdk";
import Maintenance from "../components/MaintenanceMode/MaintenancePage";
import ServerErrorPage from "../components/MaintenanceMode/ServerErrorPage";

const NoMatch = () => {
  const history = useHistory();
  history.push('/not-found');
  return <></>
};

type Props = {
  isUnsecure: boolean
}

const Routes: React.FC<Props> = ({isUnsecure}) => {  
  const { ffMaintenanceMode } = useFlags();  
  const paths = ffMaintenanceMode ? [
    {exact:true, path:'*', component: Maintenance},
  ]
  :[
    {exact:true, path:'/', component: CustomDashboard},
    {exact:true, path:'/customer/:id/delivery-group/:dgId', component:  SingleCustomerPage},
    {exact:true, path:'/customer/:id/delivery-group', component:  SingleCustomerPage},
    {exact:true, path:'/edit-vehicle/:id', component: EditVehicleDetail},
    {exact:true, path:'/clone-vehicle/customer/:id/delivery-group/:dgId/source-vehicle/:vehicleId', component: CloneVehicle},
    {exact:true, path: '/dealership/:dealershipNumber/customer/:id/delivery-group/:dgId/summary', component: ContractSummary},
      { exact: true, path: '/not-supported', component: UpdatedStateNotSupported },
    {exact:true, path: '/not-found', component: NoMatchPage},
    {exact:true, path: '/server-error', component: ServerErrorPage},
    {component: NoMatch},
  ];

  //WARNING: okta redirect route must be unprotected since it is used as part of the auth process
  return (
    /*
      TabContextProvider needs history and params but since it isn't in the React Router tree 
      it cant use the params hook since Switch requires it's children be a Route or Redirect.
      Switching <Switch> to <Router> would work but idk how to make the default NoMatch logic work. 
      For v6 Switch is removed so i'm guessing it's possible but Okta SDK needs v5.

      TLDR: 
        TabContextProvider uses React Router's matchPath func to parse the SingleCustomerPage params  
    */
      <Switch>
        <Route path="/okta/redirect" component={LoginCallback}/>
        <Route path="/implicit/callback" component={LoginCallback}/>
        <Route path='/dash' component={DashDealerLoginCallback}/>
        {paths.map((path,index) => {
          return isUnsecure 
            ? <Route key={index} {...path}/> 
            : <SecureRoute key={index} {...path}/>})
        } 
      </Switch>
  );
};
export default memo(Routes);
