import { Box, Icon, Link as MuiLink } from "@ally/metronome-ui";
import styled from "styled-components";

export const StyledBox = styled(Box)`
  margin-left: 22px;
`;

export const Heading = styled.h1`
  display: flex;
  margin-bottom: 17px;
  margin-top: 20px;
  color: #650360;
  font-weight: bold;
  line-height: 54px;
  font-size: 36px;
`;

export const GoBackStyle = styled.div`
  box-sizing: border-box
  height: 55px;
  padding-top: 14px;
  padding-bottom: 14px;
  margin-top: 21px;
  color: #0071C4;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  cursor: pointer;
`;

export const StyledLink = styled(MuiLink)`
  color: #0071c4;
  font-size: 16px;
  font-weight: bold;
`;
export const StyledIcon = styled(Icon)`
    margin-top: -1px;
    margin-right: -2px;
`

export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
  max-width: 65%;
  max-height: 40px;
`;

export const StyledSection = styled.section`
  line-height: 20px;
  font-size: 14px;
  max-width: 977px;
  color: #2a2a2a;
`;

export const Text = styled.div``;

export const SteppedProcessWrapper = styled.div`
  section > div > div > h2 {
    font-size: 18px;
    line-height: 27px;
  }

  // format summary text for section 4
  section > div:nth-child(4) li > span {
    text-transform: lowercase;
  }

  section > div:nth-child(4) li > span:first-letter {
    text-transform: uppercase;
  }

  // format summary text for section 2
  section > div:nth-child(2) li > span {
    text-transform: lowercase;

  }

  section > div:nth-child(2) li > span:first-letter {
    text-transform: uppercase;
  }

  section > div:nth-child(2) > div:nth-child(2) li:nth-child(2) > span {
    text-transform: none;
  }
`;

