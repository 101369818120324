import { ReadOnlyFieldContainer, ReadOnlyLabel, ReadOnlyText } from "./ReadOnlyFieldStyles";

type Props = {
  label: string
  value: string
};

const ReadOnlyField: React.FC<Props> = ({
  label, 
  value
}: Props) => { 

return (
  <ReadOnlyFieldContainer tabIndex={0}>
    <ReadOnlyLabel>
      {label}
    </ReadOnlyLabel>
    <ReadOnlyText>
      {value}
    </ReadOnlyText>
  </ReadOnlyFieldContainer>
)};

export default ReadOnlyField;
