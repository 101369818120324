import styled from "styled-components";

export const StatusOuter = styled.div`
    width: 609px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 20px;
`
export const StatusInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
export const StatusRowStyled = styled.div`
    display: flex;
`
export const NameStyled = styled.p`
    flex: 1;
    line-height: 24px;
`
export const StatusStyled = styled.div`
    div[data-testid="mui-dejargonator-trigger"] {
        pointer-events: none;
        cursor: default;
    };
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 8px;
    p{
        line-height: 24px;
        font-size: 16px;
    };
    svg{
        margin-top: 4px;
    };
`

export const DejargonatorBodyStyle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #BBBBBB;

`
