import React, { useContext } from "react";
import { SigningOption } from "../../../constants/enums";
import { useGetDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import { ContextType, TabContext } from "../TabContext";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import EmbeddedSending from "./EmbeddedSending";
import EmbeddedSigning from "./EmbeddedSigning";
import PrintAndSign from "./PrintAndSign";

type CardSelectedProps = {
  contractStatus?: "SUCCESS" | "PENDING" | "ERROR";
  isActiveTab: boolean;
  finalizedPage?: boolean;
};

const CardSelected: React.FC<CardSelectedProps> = ({
  contractStatus,
}) => {
  const [dealershipInfo] = useDealershipInfo();
  const { dealershipNumber } = dealershipInfo || {};

  const {
    saveActiveDeliveryGroupName,
    activeDeliveryGroupId,
  } = useContext(TabContext) as ContextType;

  const {
    data: deliveryGroup,
  } = useGetDeliveryGroup(dealershipNumber, activeDeliveryGroupId);

  const signingOption = deliveryGroup?.signingOption;

  let progressTracker = (
    // previously was set to progressTrackerStep
    <ContractProgressTracker stepNumber={3} />
  );

  return (
    <>
      <DeliveryGroupHeader />
      <ContractedDeliveryGroupDetailsNew
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}
        deliveryGroupStatus={"CONTRACT_CREATED"}
      />

      {(signingOption === SigningOption.INK_SIGN) && (
        <PrintAndSign
          contractStatus={contractStatus}
        />
      )}

      {(signingOption === SigningOption.EMBEDDED_SEND) && (
        <EmbeddedSending
          contractStatus={contractStatus}
        />
      )}

      {(signingOption === SigningOption.EMBEDDED_SIGN) && (
        <EmbeddedSigning
          contractStatus={contractStatus}
        />
      )}
    </>
  );
};

export default CardSelected;
