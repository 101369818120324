import React, { useContext, useEffect } from "react";
import { Spinner } from "@ally/metronome-ui";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { TabContext, ContextType } from "../TabContext";
import { useParams } from "react-router";
import {
    ESignInner,
    ESignOuter,
    StatusStyled,
    InstructionsStyled
} from "./EmbeddedStyled";
import { useGetEnvelopeStatus } from "../../../hooks/docusignHooks/useGetEnvelopeStatus";
import EmbeddedSigning from "./EmbeddedSigning";
import { LeavingAllyModal } from "../../Modal/LeavingAllyModal";
import { ChangeSigningOptionsModal } from "../../Modal/ChangeSigningOptionsModal";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import ReviewDocuments from "./ReviewDocuments";

export type EmbeddedSendingProps = {
    contractStatus: "SUCCESS" | "PENDING" | "ERROR";
};

const EmbeddedSending: React.FC<EmbeddedSendingProps> = ({
    contractStatus,
}) => {
    const [dealershipInfo] = useDealershipInfo();
    const { dealershipNumber } = dealershipInfo || {};
    const { id: customerId } = useParams<{ id: string }>();
    const { activeDeliveryGroupId } = useContext(TabContext) as ContextType;
    const {
        isLoading: isEnvelopeLoading,
        data: envelopeStatuses
    } = useGetEnvelopeStatus(dealershipNumber, customerId, activeDeliveryGroupId);
    const analytics = useAnalytics();

    useEffect(() => {
        analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_GATHER_SIGNATURES);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const EnvelopeNotSent = (<div>
        <ESignOuter>
            <ESignInner>
                <InstructionsStyled>
                    <h3>DocuSign instructions</h3>
                    <p>Your contract hasn’t been sent to the customer via DocuSign yet.</p>
                    <ol>
                        <li>
                            You can add the following documents before the contract is sent for signatures:
                            <p>- Ally approved credit application</p>
                            <p>- Title application for all vehicles</p>
                            <p>- Any additional documents needed to be signed or reviewed by the customer</p>
                        </li>
                        <li>Select <b>Open DocuSign</b> to add documents and send the contract to the customer.</li>
                    </ol>
                </InstructionsStyled>

            </ESignInner>
            <ReviewDocuments
                dealershipNumber={dealershipNumber}
                customerId={customerId}
                activeDeliveryGroupId={activeDeliveryGroupId}
                contractStatus={contractStatus}
            />
        </ESignOuter>
        <div>
            <LeavingAllyModal />
            <ChangeSigningOptionsModal buttonType={"link"} />
        </div>
        <StatusStyled>
            <h3>DocuSign status</h3>
            <p>
                Refresh the page to see the DocuSign status once you’ve sent the contract.
            </p>
        </StatusStyled>
    </div>)

    return (
        <>
            {(!envelopeStatuses?.isEnvelopeSent && isEnvelopeLoading === true) && <Spinner />}
            {(!envelopeStatuses?.isEnvelopeSent && !isEnvelopeLoading) && <>{EnvelopeNotSent}</>}
            {(envelopeStatuses?.isEnvelopeSent === true && !isEnvelopeLoading) &&
                <EmbeddedSigning
                    contractStatus={contractStatus}
                />}
        </>
    );

}

export default EmbeddedSending;

