import React from 'react'

import { Box } from '../Box'
import { Spinner } from '@ally/metronome-ui'

export const TableSpinner: React.FC = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      my="xs"
      backgroundColor="white"
    >
      <Spinner size="small" altText="table content is loading" />
    </Box>
  )
}
