import React, { useState } from "react";
import { VehicleDashboard } from "./VehicleDashboard/VehicleDashboard";
import { SearchVehicles } from "./Search-Vehicles/SearchVehicles";
import { Description, SectionContainer } from "./SingleCustomerDashboardStyles";
import { ActiveTabs } from "../../../constants/enums";

export type VehicleSearch = {
  ids: string[];
  make: string;
  model: string;
  year: string;
}

export const SingleCustomerDashboard: React.FC<{tabType: ActiveTabs}> = ({tabType}) => {
  const [searchCriteria, setSearchCriteria] = useState<VehicleSearch>({ids: [], model: "", make: "", year: ""});
  const [key, setKey] = useState(Math.random());
  const emitVehicleSearchCriteria = (args: any) => {
    setSearchCriteria(args);
  };
  const clear = () => {
    setKey(Math.random());
  }
  
  return (
    <SectionContainer>
      <Description>
        Select up to 100 vehicles to add to your delivery group. If you want to 
        add more than 100 vehicles, you'll need to create additional groups.
      </Description>
      <SearchVehicles key={key} clear={clear} emitVehicleSearchCriteria={emitVehicleSearchCriteria} />
      <VehicleDashboard searchCriteria={searchCriteria} tab={tabType} />
    </SectionContainer>
  );
};
