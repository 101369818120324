import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  height: 60px;
  max-width: 67%;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.16),
    0 5px 8px -4px rgba(0, 0, 0, 0.16);
  p {
    margin: 18px 18px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #2a2a2a;
    font-weight: bold;
  }
`;

export const Line = styled.div`
 border: 1px solid #dddddd;
 margin: 0 18px 0 18px;
`;
export const OpenDrawer = styled.div`
  box-sizing: border-box;
  max-width: 67%;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
  font-size: 14px;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
  
  .step3_column_container {
    font-weight: normal;
    margin: 10px 0 10px 18px;
    line-height: 20px;
    justify-content: space-between;
    display: flex; 

    .column_one {
      margin-top:15px;
      width: 47%;
      flex-direction: row;
    }
    .column_two {
      margin-top:15px;
      width: 53%;
      flex-direction: row;
    }
  }

  .step_content_container {
    font-weight: normal;
    margin: 10px 0 10px 18px;
    line-height: 24px;

    h4 {
      font-weight: bold;
      margin: 20px 0 0 0;
    }

    .option_header {
      
      margin: 0 0 20px 30px;
      display: flex;

      .option_content {
        margin: 0 0 0 15px;
        
        p {
          font-weight: bold;
          margin: 3px 0 0 0;
          line-height: 20px;
          letter-spacing: 0;
        }

        .buyer_info{
          margin: 20px 0 5px 0;
        }
      }
    }
    ul {
      margin: 10px 0 16px 20px;
      font-weight: normal;
    }
    li span {
      position: relative;
      left: 10px;
    }
    p{
      margin: 16px 0 0 0;
    }
  }
  .step1_column_container {
    display: flex;
    color: #2a2a2a;
    letter-spacing: 0;
    line-height: 24px;
    margin: 10px 18px 10px 18px;
    justify-content: space-between;
    h4 {
      font-weight: bold;
      width: 140px;
    }
  }
  
`;
