import { DeliveryGroupStatus } from "./asset-contract.model";
import { Identifiable } from "./identifiable";

export type CreateDeliveryGroup = {
  group_name: string;
  customer_id: string;
  customer_name: string;
  delivery_status: DeliveryGroupStatus;
  contract_date: string;
  first_payment_due_date: string;
  dgType: "create";
}

export type EditDeliveryGroup =
  Omit<CreateDeliveryGroup, "dgType"> & Identifiable & {
    dgType: "edit";
    insurancePolicy?: InsurancePolicyData;
  }

export type EditDeliveryGroupContractData = {
  dgType: "edit-contract-data";
  cashPriceContractData?: CashPriceContractData;
  tradeInCashDownContractData?: TradeInCashDownContractData;
  sellerGovernmentFeesContractData?: SellerGovernmentFeesContractData;
}

export type ReadDeliveryGroup =
  Omit<EditDeliveryGroup, "dgType"> & {
    created_on: Date;
    last_updated: Date;
    dgType: "read";
    contract_status: string;
    dealerState: string;
    coBuyerName?: string;
    guarantorName?: string;
    buyerName: string;
    sellerName?: string;
    sellerEmail?: string;
    signingOption?: string;
    customer_email?: string;
    coBuyerOrGuarantorEmail?: string;
    coBuyerOrGuarantorType?: string;
    insurancePolicy?: InsurancePolicyData;
  }

export type DeliveryGroupError = {
  errorMsg: string;
}

export function deliveryGroupModelToDto(dg: CreateDeliveryGroup | EditDeliveryGroup | EditDeliveryGroupContractData): any {
  if (dg.dgType === "create") {
    return {
      customerId: dg.customer_id,
      name: dg.group_name,
      customerName: dg.customer_name,
      status: dg.delivery_status,
      contractDate: dg.contract_date,
      firstPaymentDueDate: dg.first_payment_due_date,
    };
  } else if (dg.dgType === "edit") {
    return {
      id: dg.id,
      customerId: dg.customer_id,
      name: dg.group_name,
      customerName: dg.customer_name,
      contractDate: dg.contract_date,
      firstPaymentDueDate: dg.first_payment_due_date,
      insurancePolicy: dg.insurancePolicy
    }
  } else if (dg.dgType === "edit-contract-data") {
    return {
      cashPriceContractData: dg.cashPriceContractData,
      tradeInCashDownContractData: dg.tradeInCashDownContractData,
      sellerGovernmentFeesContractData: dg.sellerGovernmentFeesContractData,
    }
  }

  throw Error("Can't map non Writable DG to DTO");
}

export function deliveryGroupDtoToModel(json: any): ReadDeliveryGroup {
  if (!json) {
    throw new Error("Oh no! Delivery Group dto mapping failed");
  }

  let coBuyerName = undefined;
  let coBuyerOrGuarantorEmail = undefined;
  let guarantorName = undefined;

  if (json.coBuyerInformation) { // if this is falsey then both value should be undefined
    coBuyerName = json.coBuyerInformation.type === "CO_BUYER" ? json.coBuyerInformation?.name : undefined;
    guarantorName = json.coBuyerInformation.type === "GUARANTOR" ? json.coBuyerInformation?.name : undefined;
    coBuyerOrGuarantorEmail = json.coBuyerInformation?.email;
  }
  return {
    id: json.id,
    customer_id: json.customerId,
    customer_name: json.customerName,
    customer_email: json.buyerInformation.buyerSignerEmail,
    buyerName: json.buyerInformation?.name ?? "",
    coBuyerOrGuarantorType: json.coBuyerInformation.type,
    coBuyerName,
    guarantorName,
    coBuyerOrGuarantorEmail,
    dealerState: json.dealershipInformation.sellerState,
    sellerName: json.dealershipInformation.sellerName,
    sellerEmail: json.dealershipInformation.sellerEmail,
    created_on: new Date(json.createdAt),
    last_updated: new Date(json.lastUpdated),
    group_name: json.name,
    delivery_status: json.status,
    contract_date: json.contractDate,
    first_payment_due_date: json.firstPaymentDueDate,
    contract_status: json.status,
    signingOption: json.signingOption,
    insurancePolicy: json.insurancePolicy,
    dgType: "read"
  };
}

export type CashPriceContractData = {
  cashPriceOtherOneLabel?: string;
  cashPriceOtherTwoLabel?: string;
}

export type TradeInCashDownContractData = {
  tradeInCashDownOtherLabel?: string;
}

export type SellerGovernmentFeesContractData = {
  documentProcessingChargeTo?: string;
  emissionsTestingChargeTo?: string;
  evChargingStationChargeTo?: string;
  evRegisterTransferChargeTo?: string;
  electronicLienAndTitleFeeTo?: string;
  ertFeeTo?: string;
  officialFeesPaidToGovAgenciesTo?: string;
  officialFeesPaidToGovAgenciesFor?: string;
  forPriorCreditOrLeaseBalanceTo?: string;
}

export type InsurancePolicyData = {
  name?: string;
  policyId?: string;
}
