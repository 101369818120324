type SelectedFields = {
  selected: boolean
  label: string;
  optionalLabel: string;
  isValidForDealerState: boolean;
  displayValue?: string;
  displayFeeDescription?: keyof DropdownTextWithLabelFormValues;
  displayFeeDescriptionValue?: string;
  displayForField?: keyof DropdownTextWithLabelFormValues;
  displayForFieldValue?: string;
  multipleField?: boolean;
  toolTip?: boolean;
  dejarg?: any;
  disabledFeatureFlag?: boolean;
}

export function determineSelectedFieldType(field: SelectedFields): "NotSelected" | "OptionalMoney" | "OptionalMoneyWithDejarg" | "MoneyWithLabels" {
  if (
    (field.selected ||
      field.displayValue.length > 0) &&
    !field.multipleField &&
    !field.toolTip
  ){
    return "OptionalMoney";
  }
  else if (
    (field.selected ||
      field.displayValue.length > 0) &&
    !field.multipleField &&
    field.toolTip
  ){
    return "OptionalMoneyWithDejarg";
  }
  else if (
    field.selected ||
    !!field.displayValue ||
    !!field.displayFeeDescriptionValue
  ) {
    return "MoneyWithLabels";
  }
  return "NotSelected";
}

type DropdownMoneyFormValues = {
  dealerDocumentaryFee: number| null;
  deputyServiceFeeToSeller: number | null;
  sellersInventoryTax: number | null;
  govTaxesNotIncludedInPrice: number | null;
  govLicenseFee: number | null;
  govRegistrationFee: number | null;
  govCertificateOfTitleFee: number | null;
  plateTransferFee: number | null;
  govVehicleInspectionFee: number | null;
  miscCharges: number | null;
  securityInterestRecordingFee: number | null;
  lienNotationFee: number | null;
  supplementalTitleFee: number | null;
  stateEmissionsCertExemptFee: number | null;
  totalOfficialFeesPaidToGovAgencies: number | null;
  securityInterestRecordingFeeCash: number | null;
  toSellerForTradeInCreditAgreement: number | null;
}

type DropdownMoneyWithLabelFormValues = {
  documentProcessingCharge: number | null;
  emissionsTestingCharge: number | null;
  evChargingStationCharge: number | null;
  evRegisterTransferCharge: number | null;
  electronicLienAndTitleFee: number | null;
  officialFeesPaidToGovAgencies: number | null;
  forPriorCreditOrLeaseBalance: number | null;
  ertFee: number | null;
}
export type DropdownTextWithLabelFormValues = {
  documentProcessingChargeTo: string | null;
  emissionsTestingChargeTo: string | null;
  evChargingStationChargeTo: string | null;
  evRegisterTransferChargeTo: string | null;
  electronicLienAndTitleFeeTo: string | null;
  ertFeeTo: string | null;
  officialFeesPaidToGovAgenciesTo: string | null;
  officialFeesPaidToGovAgenciesFor: string | null;
  forPriorCreditOrLeaseBalanceTo: string | null;
}

type DropDownSelectionsImpl = DropdownMoneyFormValues & DropdownMoneyWithLabelFormValues; 
export type DropDownSelections = {
  [Property in keyof DropDownSelectionsImpl]: SelectedFields;
};

type RequiredMoneyFormValues = {
  govDocStampTax: number | null;
  govWasteTireManagementFee: number | null;
  vehicleTireFee: number | null;
};
type MoneyFormValues = RequiredMoneyFormValues & DropdownMoneyFormValues & DropdownMoneyWithLabelFormValues;

export type FormValues = MoneyFormValues & DropdownTextWithLabelFormValues;

export function isTextType (keyInput: any): keyInput is DropdownTextWithLabelFormValues {
  return ['documentProcessingChargeTo', 'emissionsTestingChargeTo', 'evChargingStationChargeTo', 'evRegisterTransferChargeTo', 'electronicLienAndTitleFeeTo', 'ertFeeTo',
    'officialFeesPaidToGovAgenciesTo', 'officialFeesPaidToGovAgenciesFor', 'forPriorCreditOrLeaseBalanceTo'].includes(keyInput);
}
