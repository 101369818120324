import {useQuery} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { contractDownloadKey } from "../../utils/react-query-key-factory";

type ContractUrls = {
  download: string,
  view: string,
  ttlInMs: number
};

async function getDownloadUrlFromBackend(get: <Result>(url:string) => Promise<Result|null>,
  dealershipNumber: string, deliveryGroupId: string): Promise<ContractUrls|null>{
    
  let contractEndpoint = `dealership/${dealershipNumber}/delivery-groups/${encodeURIComponent(deliveryGroupId)}/download-contract`;
  const res = await get<any>(contractEndpoint) as ContractUrls;
  return res;
}
export const timeToPollForUrlWhenNoExists = 5 * 1000
/*
usecases: 
  no url - means keep polling
  url - don't need to poll unless expired
*/
export function useGetDownloadUrl(dealershipNumber: string, deliveryGroupId: string | undefined, isContractRead: boolean ){

  const {get} = useHttp();
  
  return useQuery(
    contractDownloadKey(dealershipNumber ,deliveryGroupId),
   () => getDownloadUrlFromBackend(get, dealershipNumber, deliveryGroupId),
   { 
    enabled: deliveryGroupId !== undefined && isContractRead
  });
}
