import React, { useContext, useEffect } from "react";
import {
  SideContainer,
  PrintAndSignButton,
  StyledIcon,
} from "./SigningOptionsStyled";
import { Link } from "@ally/metronome-ui";
import { contractSummaryRoute } from "../../../routes/RouteFactory";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { TabContext, ContextType } from "../TabContext";
import { useParams } from "react-router";
import { ContractActions } from "../../Contracts/ContractActions";
import { useFinalizeContract } from "../../../hooks/contractHooks/useFinalizeContract";
import { ChangeSigningOptionsModal } from "../../Modal/ChangeSigningOptionsModal";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { muiDocumentPage } from "@ally/metronome-icons";

export type PrintAndSignProps = {
  contractStatus: "SUCCESS" | "PENDING" | "ERROR";
};

const PrintAndSign: React.FC<PrintAndSignProps> = ({
  contractStatus,
}) => {
  const [dealershipInfo] = useDealershipInfo();
  const { dealershipNumber } = dealershipInfo || {};
  const { id: customerId } = useParams<{ id: string }>();
  const { activeDeliveryGroupId } = useContext(TabContext) as ContextType;
  const finalizeContractAction = useFinalizeContract(dealershipNumber);
  const analytics = useAnalytics();

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_GATHER_SIGNATURES);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gatherSignatures = () => {
    const onSuccess = () => { }
    const onError = (errs: string[]) => {
      console.warn(errs)
    };
    finalizeContractAction.mutate({
      deliveryGroupId: activeDeliveryGroupId!,
      customerId: customerId,
      onSuccess,
      onError,
    });
  };

  return (
    <div>
      <SideContainer>
        <div>
          <h3>Print and sign</h3>
          <p>
            Download and print the documents below and gather signatures from
            all customers.
          </p>
          <ul>
            <li>
              {" "}
              <ContractActions
                activeDeliveryGroupId={activeDeliveryGroupId}
                contractStatus={contractStatus}
                printAndSign={true}
              /> (PDF)
            </li>
            <li>Ally approved credit application</li>
            <li>Title application for all vehicles</li>
          </ul>
        </div>
        <div>
          <div>
            <h4>Review documents</h4>
            <StyledIcon icon={muiDocumentPage} size="lg" ariaHidden />
            <Link
              // mt="1rem"
              aria-label="Contract summary"
              text="Contract summary"
              href={contractSummaryRoute(
                dealershipNumber,
                customerId,
                activeDeliveryGroupId
              )}
              variant="link"
              target="_blank"
              allytmln="open-contract-summary-page"
            />
          </div>
        </div>
      </SideContainer>
      <div>
        <PrintAndSignButton
          aria-label="Next"
          text="Next"
          variant="primary"
          onClick={gatherSignatures}
        />
        <ChangeSigningOptionsModal buttonType="link" />
      </div>
    </div>
  );
};

export default PrintAndSign;
