import { ReactNode } from 'react'
import { IdType, Row } from 'react-table'
import { OnTableDrawerClose } from '../types'
import { getPublicRowStateProps } from './getPublicRowStateProps'

type Expanded<D extends Record<string, any>> = Record<IdType<D>, boolean>

export interface HandleDrawerClosesParams<D extends Record<string, any>> {
  drawer?: ReactNode
  onDrawerClose?: OnTableDrawerClose<D>
  prevExpanded?: Expanded<D>
  expanded: Expanded<D>
  rows: Row<D>[]
}

export enum Action {
  NoOp,
  HandledCloses,
}

/**
 * Using the previous expanded state and current expanded state, check if any
 * drawers have closed. If so, then invoke onDrawerClose with the associated row
 * of data.
 *
 * Please note that this function assumes an expanded depth of 1 (i.e. no nested
 * drawers).
 */
export const handleDrawerCloses = <D extends Record<string, any>>({
  drawer,
  onDrawerClose,
  expanded,
  prevExpanded,
  rows,
}: HandleDrawerClosesParams<D>): Action => {
  if (!drawer || !onDrawerClose || !prevExpanded) return Action.NoOp

  /**
   * Get ids of drawers currently open
   *
   * { '3': true, '5': true, '10': false } => ['3', '5']
   */
  const currentlyOpen: Set<string> = Object.entries(expanded).reduce(
    (set, [rowId, isOpen]) => {
      if (isOpen) set.add(rowId)

      return set
    },
    new Set<string>(),
  )

  Object.entries(prevExpanded).forEach(([rowId, wasOpen]) => {
    if (wasOpen && !currentlyOpen.has(rowId)) {
      const row = rows[+rowId]
      onDrawerClose({ data: row.original, ...getPublicRowStateProps<D>(row) })
    }
  })

  return Action.HandledCloses
}
