import { FieldConfigurationSection, ValidationType } from "../field-configuration";

export const buyerInformationConfig: FieldConfigurationSection = {
  name: "buyerInformation",
  fieldConfigurations: [
    {
      fieldName: "name",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "address",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "city",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "county",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "state",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "zip",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "monthOfBirth",
      validationType: ValidationType.REQUIRED,
      states: ["FL"]
    },
    {
      fieldName: "taxId",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "buyerSignerName",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "buyerSignerTitle",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "buyerSignerEmail",
      validationType: ValidationType.REQUIRED,
      states: []
    }
  ]
}
