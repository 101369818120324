import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCalculateAssetContract } from "../../../hooks/assetContractHooks/useCalculateAssetContract";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import {
  ReadAssetContract,
  WriteAssetContractSections,
  WriteAssetContractSectionsNames,
} from "../../../model/asset-contract.model";
import {
  EditDeliveryGroupContractData,
  ReadDeliveryGroup,
} from "../../../model/delivery-group.model";
import { toCurrency } from "../../../utils/currency";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { cashPriceConfig } from "../../../validation-config/asset-contracts/cash-price";
import { getAllFieldsForStateInSection } from "../../../validation-config/field-config-utils";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import {
  BoxStyle,
  DeliveryFormFieldStyle,
} from "./CashPriceStyles";
import {
  FeeDescriptionStyle,
  OptionalFormFieldContainer,
  OptionalFormFieldStyle,
  OtherFeeFormFieldStyle,
  OtherFeeWrapper,
  TrashIconStyled,
} from "./FormSectionStyles";
import OptionalFieldsDropdown from "./OptionalFieldsDropdown";
import { muiTrash } from "@ally/metronome-icons";

type Props = {
  customerId: any;
  dealershipNumber: any;
  assetContract: ReadAssetContract;
  deliveryGroup: ReadDeliveryGroup;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames, calc?: any) => void;
};

type FormValues = {
  vehicleCashPrice: number | null;
  cashPriceOtherOneLabel: string | null;
  cashPriceOtherOneValue: number | null;
  cashPriceOtherTwoLabel: string | null;
  cashPriceOtherTwoValue: number | null;
  dealerProcessingCharge: number | null;
  freightCharge: number | null;
  deliveryAndHandlingFee: number | null;
  salesTax: number | null;
  exciseTax: number | null;
  preDeliveryServiceFees: number | null;
  vehicleDelivery: number | null;
  administrativeFee: number | null;
  dealerDocumentaryServiceFee: number | null;
  serviceAndHandlingFee: number | null;
};

const CashPrice: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const saveVehicle = useSaveVehicleForm(
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  const markAsCompleteRef = useRef(false);
  const currencyRegex = /[$,]/g;
  const [dealershipInfo] = useDealershipInfo();
  const fieldConfigurations = getAllFieldsForStateInSection(
    cashPriceConfig,
    dealershipInfo.dealershipState
  );
  const optionalFields = fieldConfigurations.optionalFields;

  const calculateAssetContract = useCalculateAssetContract(dealershipNumber);
  const onCalculated = (calculatedAssetContract: ReadAssetContract | null) => {
    const totalCashPrice = calculatedAssetContract?.totalCashPrice
      ? formatNumberToCurrency(calculatedAssetContract.totalCashPrice)
      : "";

    setTotalCashPrice(totalCashPrice);
  };

  const cashPrice = assetContract.cashPrice;

  const form = useForm<FormValues>({
    defaultValues: {
      vehicleCashPrice: cashPrice?.vehicleCashPrice,
      cashPriceOtherOneLabel: cashPrice?.cashPriceOtherOneLabel,
      cashPriceOtherOneValue: cashPrice?.cashPriceOtherOneValue,
      cashPriceOtherTwoLabel: cashPrice?.cashPriceOtherTwoLabel,
      cashPriceOtherTwoValue: cashPrice?.cashPriceOtherTwoValue,
      dealerProcessingCharge: cashPrice?.dealerProcessingCharge,
      freightCharge: cashPrice?.freightCharge,
      deliveryAndHandlingFee: cashPrice?.deliveryAndHandlingFee,
      salesTax: cashPrice?.salesTax,
      exciseTax: cashPrice?.exciseTax,
      preDeliveryServiceFees: cashPrice?.preDeliveryServiceFees,
      vehicleDelivery: cashPrice?.vehicleDelivery,
      administrativeFee: cashPrice?.administrativeFee,
      dealerDocumentaryServiceFee: cashPrice?.dealerDocumentaryServiceFee,
      serviceAndHandlingFee: cashPrice?.serviceAndHandlingFee,
    },
  });

  const { control, handleSubmit, formState, getValues, setValue } = form;
  const { errors } = formState;
  useEditVehicleFormErrors("Section 4", errors);

  const [selectedField, setSelectedField] = useState({
    salesTax: {
      selected: false,
      label: "Sales tax on taxable items (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.salesTax)
        ? ""
        : formatNumberToCurrency(cashPrice?.salesTax),
      isValidForDealerState: optionalFields.includes("salesTax"),
    },
    cashPriceOtherOneLabel: {
      selected: false,
      label: "Other fee 1 (optional)",
      displayLabel: cashPrice?.cashPriceOtherOneLabel,
      displayValue: isNullUndefinedOrEmpty(cashPrice?.cashPriceOtherOneValue)
        ? ""
        : formatNumberToCurrency(cashPrice.cashPriceOtherOneValue),
      isValidForDealerState: optionalFields.includes("cashPriceOtherOneValue"),
    },
    cashPriceOtherTwoLabel: {
      selected: false,
      label: "Other fee 2 (optional)",
      displayLabel: cashPrice?.cashPriceOtherTwoLabel,
      displayValue: isNullUndefinedOrEmpty(cashPrice?.cashPriceOtherTwoValue)
        ? ""
        : formatNumberToCurrency(cashPrice.cashPriceOtherTwoValue),
      isValidForDealerState: optionalFields.includes("cashPriceOtherTwoValue"),
    },
    dealerProcessingCharge: {
      selected: false,
      label: "Dealer processing charge (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.dealerProcessingCharge)
        ? ""
        : formatNumberToCurrency(cashPrice?.dealerProcessingCharge),
      isValidForDealerState: optionalFields.includes("dealerProcessingCharge"),
    },
    freightCharge: {
      selected: false,
      label: "Freight charge (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.freightCharge)
        ? ""
        : formatNumberToCurrency(cashPrice?.freightCharge),
      isValidForDealerState: optionalFields.includes("freightCharge"),
    },
    deliveryAndHandlingFee: {
      selected: false,
      label: "Delivery and handling fee (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.deliveryAndHandlingFee)
        ? ""
        : formatNumberToCurrency(cashPrice.deliveryAndHandlingFee),
      isValidForDealerState: optionalFields.includes("deliveryAndHandlingFee"),
    },
    exciseTax: {
      selected: false,
      label: "Excise tax (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.exciseTax)
        ? ""
        : formatNumberToCurrency(cashPrice.exciseTax),
      isValidForDealerState: optionalFields.includes("exciseTax"),
    },
    preDeliveryServiceFees: {
      selected: false,
      label: "Pre-Delivery service fees (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.preDeliveryServiceFees)
        ? ""
        : formatNumberToCurrency(cashPrice?.preDeliveryServiceFees),
      isValidForDealerState: optionalFields.includes("preDeliveryServiceFees"),
    },
    vehicleDelivery: {
      selected: false,
      label: "Vehicle delivery (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.vehicleDelivery)
        ? ""
        : formatNumberToCurrency(cashPrice?.vehicleDelivery),
      isValidForDealerState: optionalFields.includes("vehicleDelivery"),
    },
    administrativeFee: {
      selected: false,
      label: "Administrative fee (optional)",
      displayValue: isNullUndefinedOrEmpty(cashPrice?.administrativeFee)
        ? ""
        : formatNumberToCurrency(cashPrice?.administrativeFee),
      isValidForDealerState: optionalFields.includes("administrativeFee"),
    },
    dealerDocumentaryServiceFee: {
      selected: false,
      label: "Dealer documentary service fee (optional)",
      displayValue: isNullUndefinedOrEmpty(
        cashPrice?.dealerDocumentaryServiceFee
      )
        ? ""
        : formatNumberToCurrency(cashPrice?.dealerDocumentaryServiceFee),
      isValidForDealerState: optionalFields.includes(
        "dealerDocumentaryServiceFee"
      ),
    },
    serviceAndHandlingFee: {
      selected: false,
      label: "Service and handling fee (optional)",
      displayValue: isNullUndefinedOrEmpty(
        cashPrice?.serviceAndHandlingFee
      )
        ? ""
        : formatNumberToCurrency(cashPrice?.serviceAndHandlingFee),
      isValidForDealerState: optionalFields.includes(
        "serviceAndHandlingFee"
      ),
    },
  });

  const [totalCashPrice, setTotalCashPrice] = useState(
    !!assetContract?.totalCashPrice
      ? formatNumberToCurrency(assetContract.totalCashPrice)
      : ""
  );

  const [dropdownText, setDropdownText] = useState(
    "Select additional fees (optional)"
  );

  useEffect(() => {
    for (const name in selectedField) {
      if (
        !isNullUndefinedOrEmpty(selectedField[name].displayValue) ||
        !isNullUndefinedOrEmpty(selectedField[name].displayLabel)
      ) {
        setSelectedField((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              selected: true,
            },
          };
        });
      }
    }

    if (!!cashPrice?.cashPriceOtherOneValue) {
      setSelectedField((prev) => {
        return {
          ...prev,
          cashPriceOtherOneLabel: {
            ...prev["cashPriceOtherOneLabel"],
            selected: true,
          },
        };
      });
    }

    if (!!cashPrice?.cashPriceOtherTwoValue) {
      setSelectedField((prev) => {
        return {
          ...prev,
          cashPriceOtherTwoLabel: {
            ...prev["cashPriceOtherTwoLabel"],
            selected: true,
          },
        };
      });
    }
    // TODO !cashPrice?.cashPriceOtherOneValue should be included in dependancy array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatNumberToCurrency(value: number | undefined): string {
    if (value === undefined || value === null) {
      return "";
    }

    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  const onBlurChange = (event: any, name: string) => {
    if (!isNullUndefinedOrEmpty(event.target.value)) {
      setValue(name as any, +event.target.value.replace(currencyRegex, ""));
    }
    const values = getValues();
    submitData(values, onCalculatedField);
  };

  const { setPrevStepActive } = useSteppedProcess();

  const handleClick = (
    name: string
  ): void => {
    setDropdownText(selectedField[name].label);
    setSelectedField((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          selected: true,
        },
      };
    });
  };

  const onCalculatedField = (data: FormValues) => {
    const payload = {
      ...assetContract,
      cashPrice: {
        ...cashPrice,
        ...data,
      },
    };
    calculateAssetContract.mutate(
      {
        assetContract: payload,
      },
      { onSuccess: (data) => onCalculated(data) }
    );
  };

  const onBlurCalculateValues = () => {
    const values = getValues();
    onCalculatedField(values);
  };

  const removeOptionalField = (
    event: any,
    value: keyof FormValues,
    name: keyof typeof selectedField,
    label?: keyof FormValues
  ) => {
    setDropdownText("Select additional fees (optional)");
    let displayOverwrites = {
      displayValue: "",
      selected: false,
    };

    setValue(value, null);

    if (label) {
      setValue(label, null);
      displayOverwrites["displayLabel"] = "";
    }

    setSelectedField((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          ...displayOverwrites,
        },
      };
    });
    onBlurCalculateValues();
  };

  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    submitData(data, updateToBackEnd, markedAsComplete);
  };

  const submitData = (
    data: FormValues,
    submitAction: (data: FormValues, markedAsComplete?: boolean) => void,
    markedAsComplete?: boolean
  ) => {
    Object.keys(data).forEach((key) => {
      const v = data[key];
      if (typeof v === "string" && isNullUndefinedOrEmpty(data[key])) {
        data[key] = null;
      } else if (typeof v === "number") {
        const numVal = Number(v);
        data[key] = isNaN(numVal) ? null : numVal;
      }
    });
    submitAction(data, markedAsComplete);
  };

  const updateToBackEnd = (data: FormValues, markedAsComplete?: boolean) => {
    const onSucc = (patch: WriteAssetContractSections, calc?: any) => {
      updateAssetContractState(patch, 'cashPrice', calc);
    };

    const patches = [
      {
        cashPrice: {
          ...data,
          isComplete: markedAsComplete,
        },
      },
      { dealershipInformation: assetContract.dealershipInformation },
    ];
    let updateDeliveryGroupContractData = undefined;
    if (deliveryGroup) {
      updateDeliveryGroupContractData = {
        dgType: "edit-contract-data",
        cashPriceContractData: {
          cashPriceOtherOneLabel: data?.cashPriceOtherOneLabel,
          cashPriceOtherTwoLabel: data?.cashPriceOtherTwoLabel,
        },
      } as EditDeliveryGroupContractData;
    }
    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";

    saveVehicle.mutate({
      datas: patches,
      saveType,
      onSucc,
      updateDeliveryGroupContractData,
      assetContract,
      deliveryGroup
    });
  };

  return (
    <>
      <form allytm-form-tagged="true"
        onSubmit={(e) => {
          markAsCompleteRef.current = false;
          return handleSubmit((data) => onSubmitData(data))(e);
        }}
      >
        {" "}
        <BoxStyle p={["sm", "md"]} height="100%" id="available">
          <Controller
            control={control}
            name="vehicleCashPrice"
            rules={{
              validate: (value) => {
                if (
                  (!value || value + "" === "0.00") &&
                  markAsCompleteRef.current
                ) {
                  return "Please add vehicle cash price to continue.";
                }
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <DeliveryFormFieldStyle
                  variant="input"
                  inputType="text"
                  inputWidth="320px"
                  labelContent="Cash price (includes accessories, exclude sales tax)"
                  value={
                    isNullUndefinedOrEmpty(value)
                      ? ""
                      : "$" +
                      value.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(toCurrency(e.target.value, "."))
                  }
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onBlurChange(e, "vehicleCashPrice")
                  }
                  maxLength={11}
                  errorNotification={{
                    message: errors.vehicleCashPrice?.message,
                  }}
                />
              );
            }}
          />
          <OptionalFieldsDropdown
            selectedField={selectedField}
            titleText={dropdownText}
            handleClick={handleClick}
            fieldType="fees"
            className="cash-price"
          />
          {(selectedField.cashPriceOtherOneLabel.selected ||
            !!getValues("cashPriceOtherOneLabel") ||
            !!getValues("cashPriceOtherOneValue")) && (
              <OtherFeeWrapper>
                <div className="trash_container">
                  <h4>Other fee 1 (optional)</h4>
                <TrashIconStyled
                  tabIndex={0}
                  icon={muiTrash}
                  size="lg"
                  className="trash_icon"
                  id="trash-icon"
                  ariaLabelledBy="Delete"
                  onClick={(e) =>
                    removeOptionalField(
                      e,
                      "cashPriceOtherOneValue",
                      "cashPriceOtherOneLabel",
                      "cashPriceOtherOneLabel"
                    )
        }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      removeOptionalField(
                        e,
                        "cashPriceOtherOneValue",
                        "cashPriceOtherOneLabel",
                        "cashPriceOtherOneLabel"
                      )
                    }
                  }
                  }
                />
                </div>
                <div className="other_fee">
                  <FeeDescriptionStyle>
                    <Controller
                      control={control}
                      name="cashPriceOtherOneLabel"
                      rules={{
                        validate: (value) => {
                          if (
                            !value &&
                            markAsCompleteRef.current &&
                            getValues("cashPriceOtherOneValue")
                          ) {
                            return "Please add a fee description to continue.";
                          }
                        },
                        minLength: {
                          value: 2,
                          message: "Please provide at least two characters.",
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <OtherFeeFormFieldStyle
                            variant="input"
                            inputType="text"
                            inputWidth="300px"
                            labelContent="Fee Description"
                            maxLength={50}
                            value={value ? value : ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // cant have more than 2 spaces in a row, must start with a Word and everything else must be word or space
                              return onChange(
                                e.target.value.replace(
                                  / {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                            errorNotification={{
                              message: errors.cashPriceOtherOneLabel?.message,
                            }}
                          />
                        );
                      }}
                    />
                  </FeeDescriptionStyle>
                  <Controller
                    control={control}
                    name="cashPriceOtherOneValue"
                    rules={{
                      minLength: {
                        value: 2,
                        message: "Please provide at least two characters.",
                      },
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <OtherFeeFormFieldStyle
                          variant="input"
                          inputType="text"
                          inputWidth="300px"
                          labelContent="Amount"
                          maxLength={11}
                          value={
                            isNullUndefinedOrEmpty(value)
                              ? ""
                              : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "cashPriceOtherOneValue")
                        }
                      />
                    );
                  }}
                />
              </div>
            </OtherFeeWrapper>
          )}

          {(selectedField.cashPriceOtherTwoLabel.selected ||
            !!getValues("cashPriceOtherTwoLabel") ||
            !!getValues("cashPriceOtherTwoValue")) && (
              <OtherFeeWrapper>
                <div className="trash_container">
                  <h4>Other fee 2 (optional)</h4>
                <TrashIconStyled
                  tabIndex={0}
                  icon={muiTrash}
                  size="lg"
                  className="trash_icon"
                  id="trash-icon"
                  ariaLabelledBy="Delete"
                  onClick={(e) =>
                  removeOptionalField(
                    e,
                    "cashPriceOtherTwoValue",
                        "cashPriceOtherTwoLabel",
                        "cashPriceOtherTwoLabel"
                  )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      removeOptionalField(
                        e,
                        "cashPriceOtherTwoValue",
                        "cashPriceOtherTwoLabel",
                        "cashPriceOtherTwoLabel"
                      )
                    }
                  }
                  }
                />
                </div>
                <div className="other_fee">
                  <FeeDescriptionStyle>
                    <Controller
                      control={control}
                      name="cashPriceOtherTwoLabel"
                      rules={{
                        validate: (value) => {
                          if (
                            !value &&
                            markAsCompleteRef.current &&
                            getValues("cashPriceOtherTwoValue")
                          ) {
                            return "Please add a fee description to continue.";
                          }
                        },
                        minLength: {
                          value: 2,
                          message: "Please provide at least two characters.",
                        },
                      }}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <OtherFeeFormFieldStyle
                            variant="input"
                            inputType="text"
                            inputWidth="300px"
                            labelContent="Fee Description"
                            maxLength={50}
                            value={value ? value : ""}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              // cant have more than 2 spaces in a row, must start with a Word and everything else must be word or space
                              return onChange(
                                e.target.value.replace(
                                  / {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g,
                                  ""
                                )
                              );
                            }}
                            errorNotification={{
                              message: errors.cashPriceOtherTwoLabel?.message,
                            }}
                          />
                        );
                      }}
                    />
                  </FeeDescriptionStyle>
                  <Controller
                    control={control}
                    name="cashPriceOtherTwoValue"
                    rules={{
                      minLength: {
                        value: 2,
                        message: "Please provide at least two characters.",
                      },
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <OtherFeeFormFieldStyle
                          variant="input"
                          inputType="text"
                          inputWidth="300px"
                          labelContent="Amount"
                          maxLength={11}
                          value={
                            isNullUndefinedOrEmpty(value)
                              ? ""
                              : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "cashPriceOtherTwoValue")
                        }
                      />
                    );
                  }}
                />
              </div>
            </OtherFeeWrapper>
          )}

          {(selectedField.salesTax.selected || !!getValues("salesTax")) &&
            optionalFields.includes("salesTax") && (
              <Controller
                control={control}
                name="salesTax"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.salesTax.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash} size="lg"
                              id="trash-icon"
                              className="trash_icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                              removeOptionalField(
                                e, "salesTax", "salesTax")
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e, "salesTax", "salesTax")
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "salesTax")
                        }
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.dealerProcessingCharge.selected ||
            !!getValues("dealerProcessingCharge")) &&
            optionalFields.includes("dealerProcessingCharge") && (
              <Controller
                control={control}
                name="dealerProcessingCharge"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.dealerProcessingCharge.label}
                            <TrashIconStyled icon={muiTrash}
                              tabIndex={0}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "dealerProcessingCharge",
                                "dealerProcessingCharge"
                              )
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "dealerProcessingCharge",
                                    "dealerProcessingCharge"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "dealerProcessingCharge")
                        }
                        maxLength={11}
                        adornment={{
                          variant: "custom",
                          position: "right",
                          item: {
                            variant: "static",
                            "data-testid": "dealerProcessingCharge",
                            dejarg: {
                              topic: "Not required by law",
                              body: "Not required by law",
                            },
                          },
                        }}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.freightCharge.selected ||
            !!getValues("freightCharge")) &&
            optionalFields.includes("freightCharge") && (
              <Controller
                control={control}
                name="freightCharge"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.freightCharge.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "freightCharge",
                                "freightCharge"
                              )
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "freightCharge",
                                    "freightCharge"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "freightCharge")
                        }
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.deliveryAndHandlingFee.selected ||
            !!getValues("deliveryAndHandlingFee")) &&
            optionalFields.includes("deliveryAndHandlingFee") && (
              <Controller
                control={control}
                name="deliveryAndHandlingFee"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.deliveryAndHandlingFee.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "deliveryAndHandlingFee",
                                "deliveryAndHandlingFee",
                              )
                             } 
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "deliveryAndHandlingFee",
                                    "deliveryAndHandlingFee"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "deliveryAndHandlingFee")
                        }
                        maxLength={11}
                        adornment={{
                          variant: "custom",
                          item: {
                            variant: "static",
                            dejarg: {
                              heading: "Colorado only",
                              body:
                                "The delivery and handling charge represents costs and profit to the Seller or Creditor.",
                              topic: "Colorado only",
                            },
                          },
                        }}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.exciseTax.selected || !!getValues("exciseTax")) &&
            optionalFields.includes("exciseTax") && (
              <Controller
                control={control}
                name="exciseTax"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.exciseTax.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "exciseTax",
                                "exciseTax",
                              )
                            } 
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "exciseTax",
                                    "exciseTax"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "exciseTax")
                        }
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.preDeliveryServiceFees.selected ||
            !!getValues("preDeliveryServiceFees")) &&
            optionalFields.includes("preDeliveryServiceFees") && (
              <Controller
                control={control}
                name="preDeliveryServiceFees"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                          {selectedField.preDeliveryServiceFees.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                              removeOptionalField(
                                e,
                                "preDeliveryServiceFees",
                                "preDeliveryServiceFees",
                              )
                            } 
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "preDeliveryServiceFees",
                                    "preDeliveryServiceFees"
                                  )
                                }
                              }
                              }
                          />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "preDeliveryServiceFees")
                        }
                        maxLength={11}
                        adornment={{
                          variant: "custom",
                          item: {
                            variant: "static",
                            dejarg: {
                              heading: "Florida only",
                              body:
                                "These charges represent costs and profit to the dealer for items such as inspecting, cleaning, and adjusting vehicles, and preparing documents related to the sale.",
                              topic: "Florida only",
                            },
                          },
                        }}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.vehicleDelivery.selected ||
            !!getValues("vehicleDelivery")) &&
            optionalFields.includes("vehicleDelivery") && (
              <Controller
                control={control}
                name="vehicleDelivery"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.vehicleDelivery.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              onClick={(e) =>
                                removeOptionalField(
                                  e,
                                  "vehicleDelivery",
                                  "vehicleDelivery"
                                )
                              } 
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "vehicleDelivery",
                                    "vehicleDelivery"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "vehicleDelivery")
                        }
                        maxLength={11}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}

          {(selectedField.administrativeFee.selected ||
            !!getValues("administrativeFee")) &&
            optionalFields.includes("administrativeFee") && (
              <Controller
                control={control}
                name="administrativeFee"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.administrativeFee.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              className="trash_icon"
                              id="trash-icon"
                              onClick={(e) =>
                                removeOptionalField(
                                  e,
                                  "administrativeFee",
                                  "administrativeFee",
                                )
                              } 
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "administrativeFee",
                                    "administrativeFee"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "administrativeFee")
                        }
                        maxLength={11}
                        adornment={{
                          variant: "custom",
                          item: {
                            variant: "static",
                            dejarg: {
                              heading: "Missouri only",
                              body:
                                "AN ADMINISTRATIVE FEE IS NOT AN OFFICIAL FEE AND IS NOT REQUIRED BY LAW BUT MAY BE CHARGED BY A DEALER. THIS ADMINISTRATIVE FEE MAY RESULT IN A PROFIT TO DEALER. NO PORTION OF THIS ADMINISTRATIVE FEE IS FOR THE DRAFTING, PREPARATION, OR COMPLETION OF DOCUMENTS OR THE PROVIDING OF LEGAL ADVICE. THIS NOTICE IS REQUIRED BY LAW.",
                              topic: "Missouri only",
                            },
                          },
                        }}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}
          {(selectedField.dealerDocumentaryServiceFee.selected ||
            !!getValues("dealerDocumentaryServiceFee")) &&
            optionalFields.includes("dealerDocumentaryServiceFee") && (
              <Controller
                control={control}
                name="dealerDocumentaryServiceFee"
                render={({ field: { value, onChange } }) => {
                  return (
                    <OptionalFormFieldContainer>
                      <OptionalFormFieldStyle
                        variant="input"
                        inputType="text"
                        inputWidth="320px"
                        labelContent={
                          <>
                            {selectedField.dealerDocumentaryServiceFee.label}
                            <TrashIconStyled
                              tabIndex={0}
                              icon={muiTrash}
                              size="lg"
                              id="trash-icon"
                              className="trash_icon"
                              ariaLabelledBy="Delete"
                              onClick={(e) =>
                                removeOptionalField(
                                  e,
                                  "dealerDocumentaryServiceFee",
                                  "dealerDocumentaryServiceFee"
                                )
                              } 
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  removeOptionalField(
                                    e,
                                    "dealerDocumentaryServiceFee",
                                    "dealerDocumentaryServiceFee"
                                  )
                                }
                              }
                              }
                            />
                          </>
                        }
                        value={
                          isNullUndefinedOrEmpty(value)
                            ? ""
                            : "$" +
                            value.toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onChange(toCurrency(e.target.value, "."))
                        }
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onBlurChange(e, "dealerDocumentaryServiceFee")
                        }
                        maxLength={11}
                        adornment={{
                          variant: "custom",
                          item: {
                            variant: "static",
                            dejarg: {
                              heading: "Washington only",
                              body:
                                "The documentation service fee is a negotiable fee and isn't required by the state of Washington.",
                              topic: "Washington only",
                            },
                          },
                        }}
                      />
                    </OptionalFormFieldContainer>
                  );
                }}
              />
            )}
          {(selectedField.serviceAndHandlingFee.selected ||
            !!getValues("serviceAndHandlingFee")) &&
            optionalFields.includes("serviceAndHandlingFee") && (
              <OtherFeeWrapper>
                <div className="trash_container">
                  <h4>Service and handling fee (optional)</h4>
                <TrashIconStyled
                  tabIndex={0}
                  icon={muiTrash}
                  size="lg"
                  className="trash_icon"
                  id="trash-icon"
                  ariaLabelledBy="Delete"
                  onClick={(e) =>
                  removeOptionalField(
                    e,
                    "serviceAndHandlingFee",
                    "serviceAndHandlingFee",
                  )
                }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      removeOptionalField(
                        e,
                        "serviceAndHandlingFee",
                        "serviceAndHandlingFee"
                      )
                    }
                  }
                  }
                />
                </div>
                <div className="other_fee">
                  <Controller
                    control={control}
                    name="serviceAndHandlingFee"
                    rules={{
                      minLength: {
                        value: 2,
                        message: "Please provide at least two characters.",
                      },
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <OtherFeeFormFieldStyle
                          variant="input"
                          inputType="text"
                          inputWidth="300px"
                          labelContent="Amount"
                          maxLength={11}
                          value={
                            isNullUndefinedOrEmpty(value)
                              ? ""
                              : "$" +
                              value.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          }
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChange(toCurrency(e.target.value, "."))
                          }
                          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onBlurChange(e, "serviceAndHandlingFee")
                          }
                        />
                      );
                    }}
                  />
                </div>
              </OtherFeeWrapper>
            )}
          <ReadOnlyField label="Total cash price" value={totalCashPrice} />

          <NonTerminalFormButtons
            setPrevStepActive={setPrevStepActive}
            buttonCallStatus={{
              isPending: saveVehicle.isPending,
              actionType: saveVehicle.saveType,
            }}
            sectionNameForAnalytics="cash-price"
            isComplete={cashPrice.isComplete}
            markAsComplete={(e) => {
              markAsCompleteRef.current = true;
              return handleSubmit((data) => onSubmitData(data, true))(e);
            }}
          />
        </BoxStyle>
      </form>
    </>
  );
};

export default CashPrice;
