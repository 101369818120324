import { Box, Button, Link, AutoComplete, useAutoComplete, } from "@ally/metronome-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGetCustomers } from '../../hooks/customerHooks/useGetCustomers';
import { usePreviewAndLoadAll } from '../../hooks/tableHooks/usePreviewAndLoadAll';
import { Customer } from "../../model/customer.model";
import {
  HeaderStyle,
  DescriptionStyle,
  StyledTableWrapper,
  TableCaption,
  NoDataTableDiv,
  EmailLink,
  StyledAlert,
  CustomerAutoCompleteContainer,
} from "./CustomerDashboardStyles";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { convertCustomersToTableData } from "../../utils/table-utils";
import { TableCustomer } from "../../model/display/table-customer";
import { useAnalytics } from "../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../analytics/pageViews";
import { singleCustomerPageRoute } from "../../routes/RouteFactory";
import { Table } from "../../library/Table";
import { useHistory } from "react-router-dom";
import { useFilterStateSupportedDealerships } from "../../hooks/dealershipHooks/useFilterStateSupportedDealerships";

const createColumns: (data: TableCustomer[]) => any = (data) => {
  const lookUpNameByAlphaIndex = data.reduce((accum: TableCustomer, curr: TableCustomer) => {
    accum[curr.alphabeticalIndex!] = curr.company;
    return accum;
  }, {} as any);

  const lookUpTinByAlphaIndex = data.reduce((accum: TableCustomer, curr: TableCustomer) => {
    accum[curr.alphabeticalIndex!] = curr.tax_id;
    return accum;
  }, {} as any);

  function buildCustomerLink(customerId: string, customerName: string): React.ReactNode{
    return (
      <Link 
        text={customerName} 
        href={singleCustomerPageRoute(customerId)}
        variant="link" 
      />
    );
  }

  function makeDataPrivate(text: string): React.ReactNode {
    return (<div data-private>{text}</div>);
  }

  function formatDate(dateString: string): React.ReactNode {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return (
      formattedDate
    );
  }


  return [
    {
      Header: "Company name",
      id: "company_name",
      className: "testing",
      accessor: "alphabeticalIndex",
      isSortable: true,
      Cell: ({ cell: { value } }: { cell: any }): React.ReactNode | string => buildCustomerLink(lookUpTinByAlphaIndex[value], lookUpNameByAlphaIndex[value]),
      alignment: "left",
    },
    {
      accessor: "totalApps",
      Header: "Applications",
      alignment: "left",
      isSortable: true,
    },
    {
      accessor: "last_activity",
      Header: "Last activity",
      alignment: "left",
      isSortable: true,
      Cell: ({ cell: { value } }: { cell: any }): React.ReactNode | string => formatDate(value),
      initialSort: "desc",
    },
    {
      accessor: "tax_id",
      Header: "Tax ID",
      alignment: "left",
      isSortable: true,
      Cell: ({ cell: { value } }: { cell: any }): React.ReactNode | string => makeDataPrivate(value),
    },
    {
      accessor: "city",
      Header: "City",
      alignment: "left",
      isSortable: true,
    },
    {
      accessor: "state",
      Header: "State",
      alignment: "left",
      isSortable: true,
    },
    {
      accessor: "zip",
      Header: "ZIP",
      alignment: "left",
      isSortable: true,
    },
  ];
}

type LoadMoreCustomerProps = {
  customers: Customer[],
  dataLoading: boolean,
  newRowIndex: number,
  canShowAll: boolean,
  handleShowAll: () => void
};

const LoadMoreTable: React.FC<LoadMoreCustomerProps> = ({
  customers,
  dataLoading,
  newRowIndex,
  canShowAll,
  handleShowAll
}) => {
  const tableData = convertCustomersToTableData(customers);

  return (
    <Box display="flex" flexDirection="column">
      <StyledTableWrapper>
        <Table
          data={tableData}
          columns={createColumns(tableData)}
          autoResetSortBy={false}
          rowToFocus={newRowIndex}
          captionText="load more table"
          isLoading={dataLoading}
          suppressMdDownLayout={true}
        />
        {canShowAll && tableData && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="xs"
            borderBottom="1px solid"
            borderColor="grey-80"
            paddingTop="10px"
            paddingBottom="10px"
            backgroundColor="#FCFCFC"
          >
            <Button
              variant="link"
              aria-label="View All"
              allytmln="view-all-vehicles"
              text="View All"
              onClick={handleShowAll}
              id="view-more-button"
            />
          </Box>
        )}
        {!tableData?.length && !dataLoading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="xs"
          >
            <NoDataTableDiv>
              You don’t have any customers in your list.
            </NoDataTableDiv>
          </Box>
        )}
      </StyledTableWrapper>
    </Box>
  );
};

const CustomDashboard: React.FC = () => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { isError, data, isSuccess, isLoading } = useGetCustomers(dealershipNumber);
  const allCustomers = useMemo(() => data ?? [], [data]);
  const title = "Commercial customer dashboard";
  const pContent = `Select a company name to review lists of vehicles that are pending purchase and manage your delivery groups.`;
  const searchLabel = `Search by company name, tax ID`;
  const [displayError, setDisplayError] = useState(false);
  const displayErrorRef = React.useRef(null);
  const autoCompleteState = useAutoComplete({});
  const { setFreshOptions, selections } = autoCompleteState;

  const filteredCustomers = allCustomers.filter(customer =>
    selections.map(selection => selection.toLowerCase())
      .includes(customer.company.toLowerCase()));

  const deriveOptions = useCallback(
    (searchValue: string): string[] => {
      if (searchValue) {
        return allCustomers
          .filter(customer => {
            const customerName = customer.company.toLowerCase();
            const customerTaxId = customer.tax_id.toLowerCase();
            const lowerCaseSearch = searchValue.toLowerCase();

            return customerName.includes(lowerCaseSearch) || customerTaxId.includes(lowerCaseSearch);
          }).map(customer => customer.company);
      }

      return allCustomers.map(customer => customer.company);
    }, [allCustomers]);

  const onAutoCompleteChange = useCallback(
    (value: string) => {
      setFreshOptions(deriveOptions(value));
    },
    [setFreshOptions, deriveOptions],
  );

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.DASHBOARD);
    //TODO address unCheckedAll/analytics missing in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    setDisplayError(isError);
  }, [isError]);

  const tableData = selections.length > 0
    ? filteredCustomers
    : allCustomers;

  const plural = allCustomers.length === 1
    ? ''
    : 's';

  const { records, canShowAll, newRowIndex, handleShowAll } = usePreviewAndLoadAll(10, isSuccess ? tableData : []);

  const tableCaption = isLoading
    ? ""
    : records.length < allCustomers.length
      ? `${records.length} of ${allCustomers.length} customer${plural} listed`
      : `${allCustomers.length} customer${plural} listed`;


  const history = useHistory();
  const {stateSupportedDealerships, dealershipsReady} = useFilterStateSupportedDealerships();
  if(dealershipsReady && stateSupportedDealerships.length < 1 ){
    history.push('/not-supported')
  }

  return (
    <main>
      <HeaderStyle>{title}</HeaderStyle>
      <DescriptionStyle>{pContent}</DescriptionStyle>
      {displayError && (
        <StyledAlert
          variant="error"
          cancellable
          ref={displayErrorRef}
          handleClose={() => setDisplayError(false)}
          heading="We can&#39;t complete your request right now."
          body={<>Try again later or email Ally CSG at <EmailLink id="email-allycsg@ally.com-error-fallback" href="mailto:allycsg@ally.com">AllyCSG@ally.com</EmailLink> for help.</>}
        />
      )}
      <CustomerAutoCompleteContainer>
        <AutoComplete
          label={searchLabel}
          state={autoCompleteState}
          onValueChange={onAutoCompleteChange}
        />
      </CustomerAutoCompleteContainer>
      <TableCaption>{tableCaption}</TableCaption>
      <LoadMoreTable
        customers={records}
        canShowAll={canShowAll}
        dataLoading={isLoading}
        newRowIndex={newRowIndex}
        handleShowAll={handleShowAll}
      />
    </main>
  );
};

export default CustomDashboard;
