import { Button, FormText } from "@ally/metronome-ui";
import { useState } from "react";
import { EditDeliveryGroup, ReadDeliveryGroup } from "../../model/delivery-group.model";
import { ButtonDiv } from "../Tab/DeliveryGroupTabViews/InsurancePolicyStyled";
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { emitErrorEvent } from "../../analytics/apiErrors";

type FormProps = {
    deliveryGroupInfo: ReadDeliveryGroup,
    editDeliveryGroup: any,
    setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>,
    modalType: string,
    closeModal: () => void,
}
const InsurancePolicyForm: React.FC<FormProps> = ({
    deliveryGroupInfo,
    editDeliveryGroup,
    setShowContractModal,
    modalType,
    closeModal,
}) => {
    const analytics = useAnalytics();

    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [policyErrorMessage, setPolicyErrorMessage] = useState("");
    const [initiallySubmitted, setInitiallySubmitted] = useState(false);
    const [insuranceFields, setInsuranceFields] = useState({
        name: deliveryGroupInfo.insurancePolicy.name ?? "",
        policyId: deliveryGroupInfo.insurancePolicy.policyId ?? "",
    });
    const insurancePolicyInfo = {
        insurancePolicy: insuranceFields,
        dgType: "edit"
    } as EditDeliveryGroup;
    const editInsurance = () => {
        editDeliveryGroup.mutate({ deliveryGroup: insurancePolicyInfo, onEdit: () => { } });
    };
    const updateFields = (name: string, value: string) => {
        setInsuranceFields((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleChangeInsuranceName = (event: any) => {
        if (initiallySubmitted) {
            handleInsuranceNameValidation(event);
        }

        updateFields(event.target.name, event.target.value);
    };

    const handleInsuranceNameValidation = (e: any) => {
        let hasInsuranceNameError = false;
        let insuranceName;
        if (!e?.target) {
            insuranceName = e ?? "";
        } else {
            insuranceName = e.target.value;
        }

        if (insuranceName.trim().length < 2) {
            const nameMessage = "Please add the insurance company name to continue.";
            setNameErrorMessage(nameMessage);
            hasInsuranceNameError = true;
            const analyticsErrorMessage = modalType === "forgot" ? `Last chance - ${nameMessage}` : nameMessage
            analytics(emitErrorEvent, analyticsErrorMessage);
        } else {
            setNameErrorMessage("");
            hasInsuranceNameError = false;
        }

        return hasInsuranceNameError;
    };

    const handleChangePolicyName = (event: any) => {
        if (initiallySubmitted) {
            handleInsurancePolicyValidation(event);
        }

        updateFields(event.target.name, event.target.value);
    };

    const handleInsurancePolicyValidation = (e: any) => {
        let hasInsurancePolicyError = false;
        let insurancePolicyId;
        if (!e?.target) {
            insurancePolicyId = e ?? "";
        } else {
            insurancePolicyId = e.target.value;
        }

        if (insurancePolicyId.trim().length < 2) {
            const policyMessage = "Please add policy number to continue.";
            setPolicyErrorMessage(policyMessage);
            hasInsurancePolicyError = true;
            const analyticsErrorMessage = modalType === "forgot" ? `Last chance - ${policyMessage}` : policyMessage
            analytics(emitErrorEvent, analyticsErrorMessage);
        } else {
            setPolicyErrorMessage("");
            hasInsurancePolicyError = false;
        }

        return hasInsurancePolicyError;
    };
    return (
        <>
            <FormText
                variant="input"
                inputType="text"
                labelContent="Insurance company name"
                id="insurance-name"
                value={insuranceFields.name ?? ""}
                name="name"
                minLength={2}
                maxLength={56}
                onChange={(e) => handleChangeInsuranceName(e)}
                errorNotification={{ message: nameErrorMessage }}
            />
            <FormText
                variant="input"
                inputType="text"
                labelContent="Policy number"
                name="policyId"
                value={insuranceFields.policyId ?? ""}
                minLength={2}
                maxLength={25}
                onChange={(e) => handleChangePolicyName(e)}
                errorNotification={{ message: policyErrorMessage }}
            />
            <ButtonDiv>
                {modalType === "forgot" ?
                    <Button
                    text="Add Now"
                    onClick={() => {
                        setInitiallySubmitted(true);
                        const nameError = handleInsuranceNameValidation(insuranceFields.name);
                        const policyIdError = handleInsurancePolicyValidation(insuranceFields.policyId);
                        if (!nameError && !policyIdError && insuranceFields.name && insuranceFields.policyId) {
                            editInsurance();
                            editDeliveryGroup.mutate(
                                {
                                    deliveryGroup: insurancePolicyInfo,
                                    onEdit: () => { }
                                }
                            );
                            setShowContractModal(true)
                        } 
                        }}
                        allytmln="last-chance-add-insurance-policy"
                    />
                    :
                    <Button
                        aria-label="Add"
                        text="Add"
                        variant="primary"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            setInitiallySubmitted(true);
                            const nameError = handleInsuranceNameValidation(insuranceFields.name);
                            const policyIdError = handleInsurancePolicyValidation(insuranceFields.policyId);

                            if (!nameError && !policyIdError && insuranceFields.name && insuranceFields.policyId) {
                                editInsurance();
                                setInitiallySubmitted(false);
                                closeModal();
                            }
                        }}
                        allytmln="add-insurance-policy"
                    />
                }
                {modalType === "forgot" ?
                    <Button
                    text="Send Later"
                    onClick={() => setShowContractModal(true)}
                        variant="secondary"
                        allytmln="cancel-last-chance-add-insurance-policy"
                    />
                    :
                    <Button
                        aria-label="Close"
                        text="Close"
                        variant="secondary"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            setInsuranceFields({
                                name: "",
                                policyId: ""
                            });
                            setInitiallySubmitted(false);
                            closeModal();
                        }}
                        allytmln="cancel-add-insurance-policy"
                    />}
            </ButtonDiv>
        </>
    )
};

export default InsurancePolicyForm;

