import styled from "styled-components";
import {
  Box,
  Button,
  FormText,
  Icon,
  Select,
  SingleDatePickerDEPRECATED,
  TextBody,
  Modal
} from "@ally/metronome-ui";

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 250px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: 8px;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-left: 8px;
`;

export const DeleteButton = styled(Button)`
  width: 50px;
  background: #e7f6fd;
  color: #0071c4;
  font-size: 16px;
  margin-right: 16px;
  letter-spacing: 0;
  border-radius: 4px;
  line-height: 24px;
`;
export const ButtonStyle = styled(Button)`
  width: 30px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const ContinueButtonStyle = styled(Button)`
  width: 115px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const LinkButtonStyle = styled(Button)`
  letter-spacing: 0;
  line-height: 16px;
  color: #0071c4;
  margin-left: 16px;
  font-size: 16px;
  font-family: Helvetica;
  align-self: center;
`;

export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 21px;
  font-size: 14px;
  color: #2a2a2a;
  margin-bottom: 26px;
  max-width: 65%;
`;

export const BoxStyle = styled(Box)`
  padding: 0;
  margin-top: 24px;

  .contract_date {
    margin-top: 20px;
  }
`;

export const DeliveryFormFieldStyle = styled(FormText)`
  max-width: 349.75px;
  color: #ffffff;
  margin-bottom: 21px;
  margin-top: 20px;

  label {
    height: 20px;
    width: 91px;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 1px;
  }

  div {
    outline: none;
  }

  div > span > input {
    background-color: #f9f9f9;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 4px 4px 0 0;
  }
`;

export const SelectedDropdownTrigger = styled(Select)`
  width: 260px;
  margin-bottom: 21px;
  box-sizing: border-box;
  color: #959595;
  box-shadow: none;

  select {
    border: 1px solid #959595;
    background-color: #ffffff;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 25.2px;
    letter-spacing: 0;
    color: #2a2a2a;
  }
`;

export const LabelStyle = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 12px;
  color: #2a2a2a;
`;

export const SingleDatePickerStyle = styled(SingleDatePickerDEPRECATED)``;

export const SingleDatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;  


  & .SingleDatePickerInput__withBorder {
    border: none;
    border-bottom: 1px solid #959595;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 1px 0 0 #8391AF;
  }
 
  & .DateInput_1 > input {
    font-size: 16px;
    ::placeholder {
      font-style: normal;
      line-height: 24px;
      font-weight: normal;
      color: #2a2a2a
    }
  }

  div > span {
    margin-top: 10px;
  }
  }

`;
export const DateLabelStyle = styled.div`
  font-size: 11px;
  margin-bottom: 16px;
  color: #666666;
  margin-top: 12px;
  margin-left: 2px;
`;

export const GoBackStyle = styled.a`
  box-sizing: border-box
  height: 55px;
  padding-top: 14px;
  padding-left: 22px;
  padding-bottom: 14px;
  margin-top: 21px;
  color: #0071C4;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledIcon = styled(Icon)`
margin-top: 1px;
`;

export const ModalStyled = styled(Modal)`
  height: 182px;
  width: 495px;
`;

export const FirstPaymentModalStyled = styled(Modal)`
  height: 182px;
  width: 495px;
`;

export const ModalButtonStyle = styled(Button)`
  width: 75px;
  margin-left: 30px;
  color: #0071c4;
  background-color: #fcfcfc;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;

  &:hover {
    background-color: #fcfcfc;
  }
`;
export const FirstPaymentTextBody = styled(TextBody)`
  font-size: 16px;
  line-height: 24px;
`;

export const CancelModalStyled = styled(Modal)`
  width: 490px;
  height: 300px;

  div > h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 8px 22px 0;
  }
`;
export const DeleteModalStyled = styled(Modal)`
  width: 490px;
  height: 250px;

  div > h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 8px 22px 0;
  }
`;
export const CancelButtonStyle = styled(Button)`
  min-width: 108px;
  color: #0071c4;
  background-color: #e7f6fd;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
  margin-left: 8px;
`;
