import { useGetAssetContract } from "../../../../hooks/assetContractHooks/useGetAssetContract";
import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import { useTableDrawer } from "../../../../library/Table";
import { TableVehicle } from "../../../../model/display/table-vehicle";
import {
  Container,
  DetailRowContainer,
  DetailRowContent,
  DetailRowContentName,
  DetailRowHeader,
  StyledSpinner,
  VehicleCashContainer,
  VehicleDrawerContainer,
} from "./VehicleDrawerStyles";

const VehicleDrawer = () => {
  const {
    row: {
      values: {
        appId,
      },
    },
  } = useTableDrawer<TableVehicle>();

  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { data, isLoading: assetLoading } = useGetAssetContract(
    dealershipNumber,
    appId
  );

  const tradeIn = data?.tradeInCashDown?.hasTradeIn
    ? "Yes"
    : "No";
  
  if(assetLoading){
    return <StyledSpinner />
  }

  return (
    <Container>
      <VehicleDrawerContainer>
        <DetailRowContainer>
          <VehicleCashContainer>
            <DetailRowHeader>Vehicle Cash Price</DetailRowHeader>
            <DetailRowContentName>
              ${data?.totalCashPrice ? data?.totalCashPrice.toLocaleString(undefined, {minimumFractionDigits: 2}) : "0.00"}
            </DetailRowContentName>
          </VehicleCashContainer>
          <div>
            <DetailRowHeader>Term</DetailRowHeader>
            <DetailRowContentName>
              {data?.truthInLendingAct?.numberOfPayments ?? "0"}
            </DetailRowContentName>
          </div>
        </DetailRowContainer>
        <DetailRowContainer>
          <div>
            <DetailRowHeader>Total Down Payment</DetailRowHeader>
            <DetailRowContent>
              $
              {data?.totalDownPayment
                ? data?.totalDownPayment.toLocaleString(undefined, {minimumFractionDigits: 2})
                : "0.00"}
            </DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>Buy Rate</DetailRowHeader>
            <DetailRowContent>{data?.buyRate ?? "0.00"}%</DetailRowContent>
          </div>
        </DetailRowContainer>
        <DetailRowContainer>
          <div>
            <DetailRowHeader>Total Other Charges</DetailRowHeader>
            <DetailRowContent>
              $
              {data?.totalOtherCharges
                ? data?.totalOtherCharges.toLocaleString(undefined, {minimumFractionDigits: 2})
                : "0.00"}
            </DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>APR</DetailRowHeader>
            <DetailRowContent>
              {data?.truthInLendingAct?.customerBuyRate
                ? data?.truthInLendingAct.customerBuyRate.toFixed(2)
                : "0.00"}
              %
            </DetailRowContent>
          </div>
        </DetailRowContainer>
        <DetailRowContainer>
          <div>
            <DetailRowHeader>Amount Financed</DetailRowHeader>
            <DetailRowContent>
              ${data?.amountFinanced ? data?.amountFinanced.toLocaleString(undefined, {minimumFractionDigits: 2}) : "0.00"}
            </DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>Trade-In</DetailRowHeader>
            <DetailRowContent>{tradeIn}</DetailRowContent>
          </div>
        </DetailRowContainer>
        <DetailRowContainer>
          <div>
            <DetailRowHeader>Monthly Payment</DetailRowHeader>
            <DetailRowContent>
              $
              {data?.monthlyPayment
                ? data?.monthlyPayment.toLocaleString(undefined, {minimumFractionDigits: 2})
                : "0.00"}
            </DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>Co-Applicant</DetailRowHeader>
            <DetailRowContent>
              {data?.coBuyerInformation?.name ? "Yes" : "No"}
            </DetailRowContent>
          </div>
        </DetailRowContainer>
      </VehicleDrawerContainer>
    </Container>
  );
};

export default VehicleDrawer;
