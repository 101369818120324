import { useQuery } from "@tanstack/react-query";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ReadVehicle, vehicleDtoToModel as vehicleDtoToReadModel } from "../../model/vehicle.model";
import { assignedVehiclesKey, unassignedVehiclesKey } from "../../utils/react-query-key-factory";
import { useHttp } from "../adaptorHooks/useHttp";
import { useAnalytics } from "../analytics/useAnalytics";

async function getVehiclesFromBackend(get: <Result>(url: string) => Promise<Result|null>,
  dealershipNumber: string, customerId?: string, deliveryGroupId?: string): Promise<ReadVehicle[]>{
  
  let assetEndpoint = `dealership/${dealershipNumber}/assets`;
  if (customerId && deliveryGroupId){
    assetEndpoint += `?customerId=${encodeURIComponent(customerId)}&deliveryGroupId=${encodeURIComponent(deliveryGroupId)}`;
  }
  else if (customerId){
    assetEndpoint += `?customerId=${encodeURIComponent(customerId)}&noDeliveryGroup=true`;
  }
  else if( deliveryGroupId){
    assetEndpoint += `?deliveryGroupId=${encodeURIComponent(deliveryGroupId)}`;
  }

  const res = await get<any>(assetEndpoint);
  return res ? res.map((v: any) => vehicleDtoToReadModel(v)): [];
}

export function useAssetContractVehicles(dealershipNumber: string, customerId?: string, deliveryGroupId?: string){
  const {get} = useHttp();
  const analytics = useAnalytics();

  const key = deliveryGroupId === undefined 
    ? unassignedVehiclesKey(dealershipNumber, customerId)
    : assignedVehiclesKey(dealershipNumber, customerId, deliveryGroupId);
  return useQuery(key, () => getVehiclesFromBackend(get, dealershipNumber, customerId, deliveryGroupId),
   {  
    onError(error){
      console.error("Oops something went wrong. Please try again later ", error);
      analytics(emitErrorEvent, (error as any).message ?? "get asset contract vehicles error", (error as any).status ?? 500);
    },
    enabled: customerId !== undefined
  });
}
