import { SingleCustomerDashboard } from "../../Dashboards/SingleCustomerDashboard/SingleCustomerDashboard";
import { TextHeadingStyled } from "../DeliveryGroupTabControlerStyled";
import { ActiveTabs } from "../../../constants/enums";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { useEffect } from "react";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";

type Props = {
  isActiveTab: boolean;
};

export const UnassignedVehicleDashboard: React.FC<Props> = ({ isActiveTab }) => {
  const analytics = useAnalytics();
  useEffect(() => {
    if(isActiveTab){
      analytics(emitPageViewEvent, PageViews.VEHICLE_DASHBOARD);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);

  return (
    <>
      <TextHeadingStyled
        tag="h2"
        size="md"
        tabIndex={-1}
        id="vehicle-tab"
      >
        Vehicles
      </TextHeadingStyled>
      <SingleCustomerDashboard tabType={ActiveTabs.VEHICLES}/>
    </>
  );
};
