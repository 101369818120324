import { FormText, Icon } from "@ally/metronome-ui";
import styled from "styled-components";

export const OptionalFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-top: 17px;

  .trash_icon {
    margin-left: 20px;
    overflow: visible;
  }

  label {
    display: flex;
    align-items: center;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }

  div[data-testid="mui-dejargonator-trigger"] {
    pointer-events: none;
    cursor: default;
  }

`;
export const TrashIconStyled = styled(Icon)`
    margin-left: 20px;
    overflow: visible;
    cursor: pointer;
`
export const AfterMarketTrashStyled = styled(Icon)`
  cursor: pointer;
`
export const OtherFeeWrapper = styled.div`
  margin-top: 27px;
  color: #2a2a2a;
  h4 {
    font-size: 16px;
    line-height: 16px;

  }
  .other_fee {
    border-left: 1px solid #8391af;
    padding-left: 20px;
  }
  .for_label {
    padding-left: 20px;
  }
  .trash_container {
    display: flex;
    align-items: center;
  }
  .trash_icon {
    margin-left: 20px;
    overflow: visible;
  }
`;

export const OtherFeeFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-top: 17px;

  label {
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }
`;

export const FeeDescriptionStyle = styled.div`
  margin-top: 17px;
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
    margin-top: 6px;
  }
  h5 {
    margin-left: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  div {
    button {
      color: #0071c4;
      background-color: #fcfcfc;
      font-size: 14px;
      line-height: 20px;
      justify-content: flex-end;
      padding-right: 0;
      margin-top: 6px;
    }
  }
`;

export const OptionalFormFieldContainer = styled.div`
  .trash_icon {
    margin-left: 20px;
    overflow: visible;
  }
    label[data-testid="mui-label"] {
    display: flex;
    align-items: center;
  }
`



