import React, { useEffect } from "react";
import DeliveryGroupDetailsWithAssets from "./DeliveryGroupDetails/DeliveryGroupDetailsWithAssets";
import { useState, useContext } from "react";
import { TabContext, ContextType } from "../TabContext";
import { ContractActionButtonStyled } from "./DeliveryGroupDetails/DeliveryGroupDetailsStyled";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { ContractModalStyled } from "../../Modal/ContractModalsStyled";


type Props = {
  isActiveTab: boolean;
  setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>;
};
const FinalizeDeliveryGroup: React.FC<Props> = ({ isActiveTab, setShowContractModal }) => {
  const analytics = useAnalytics();
  const {
    saveActiveDeliveryGroupName,
  } = useContext(TabContext) as ContextType;
  const [error, setError] = useState("");
  const [sortTable, setSortTable] = useState(false);

  useEffect(() => {
    if (isActiveTab) {
      analytics(emitPageViewEvent, PageViews.VIEW_DELIVERY_GROUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);

  const progressTracker = <ContractProgressTracker stepNumber={1} />;
  const modal = <ContractModalStyled 
    trigger={ <ContractActionButtonStyled
      variant="primary"
      text="Create Contract"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        setError('All vehicles must be ready for contract to continue.')
        setSortTable(true)
      }
      }
      aria-label="Contract Ready Check"
    />}
    content={<></>}
  />

  return (
    <>
      <DeliveryGroupHeader />
      <DeliveryGroupDetailsWithAssets
        actionModal={modal}
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}//TODO: i dont think this is needed?
        deliveryGroupStatus={"OPEN"}
        error={error}
        sortTable={sortTable}
        setShowContractModal={setShowContractModal}
      />
    </>
  );
};

export default FinalizeDeliveryGroup;
