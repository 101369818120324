import { FieldConfigurationSection, ValidationType } from "../field-configuration";

export const vehicleDetailsConfig: FieldConfigurationSection = {
  name: "vehicleDetails",
  fieldConfigurations: [
    {
      fieldName: "condition",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "year",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "make",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "model",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "vin",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "odometer",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "grossWeight",
      validationType: ValidationType.OPTIONAL,
      states: ["PA"]
    },
  ]
}
