import styled from "styled-components";
import { Button, Modal } from "@ally/metronome-ui";

export const ModalStyled = styled(Modal)`
    width: 564px;
    h3 {
        line-height: 28px;
        font-size: 20px;
    }
    p {
        margin: 16px 16px 24px 0px;
        line-height: 24px;
        font-size: 16px;
    }
    .TextLine2 {
        width: 525px;
    }
`
export const CloseModalButton = styled(Button)`
   margin-left: 16px;
   font-size: 16px;
   line-height: 24px;
`
export const LinkStyled = styled(Button)`
    font-size: 16px;
    line-height: 21px;
    margin-left: 8px;
`
export const OpenDocuSignButton = styled(Button)`
    margin-right: 8px;
`
export const RecontractButton = styled(Button)`
    width: 126px;
    background: none;
    border: 1px solid;
    margin-top: 32px;
`
export const RecontractModalButton = styled(Button)`
    width: 126px;
    height: 44px;
`
