import React from 'react'
import styled from 'styled-components'
import { StepContent, StepTitle, StepSummary, VerticalRule } from '../shared'
import { VerticalSteppedProcessProps } from '../types'

const SectionLandmark = styled.section`
  display: flex;
  flex-direction: column;
`

const StepWrapper = styled.div`
  ${({ theme: { space } }): string => `
    display: flex;
    flex-direction: column;
    margin-bottom: ${space.xs};
  `}
`

const StepContentWrapper = styled.div`
  display: flex;
`

const VerticalSteppedProcess: React.FC<VerticalSteppedProcessProps> = ({
  steps,
  ariaLabel,
}) => {
  return (
    <SectionLandmark aria-label={ariaLabel}>
      {steps.map((step, index) => (
        <StepWrapper key={step.id}>
          <StepTitle stepIndex={index} step={step} />
          <StepContentWrapper>
            <VerticalRule stepIndex={index} />
            <StepSummary stepIndex={index} step={step}/>
            <StepContent stepIndex={index} step={step} />
          </StepContentWrapper>
        </StepWrapper>
      ))}
    </SectionLandmark>
  )
}

export { VerticalSteppedProcess }
