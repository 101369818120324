import { Button, ButtonGroup, DocUpload } from "@ally/metronome-ui";
import styled from "styled-components";

export const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    h2 {
        line-height: 28px;
        font-size: 20px;
    }
    h4 {
        line-height: 24px;
        margin-left: 4px;
    }
    p {
        line-height: 20px
    }
`
export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-top: 4px;
`
export const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
export const PreviewButton = styled(Button)`
    width: 108px;
    height: 44px;
`
export const DocUploadStyled = styled(DocUpload)`
   label {
    height: 34px;
    width: 122px;
   }
`
export const ButtonGroupStyled = styled(ButtonGroup)`
    align-items: baseline;
    margin-top: 64px;
`
export const ErrorMessageWrapper = styled.p`
    font-weight: bold;
    font-size: 14px;
    color: #D63440;
    line-height: 20px;
    margin-top: 24px;

`
