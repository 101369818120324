import styled from "styled-components";

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  height: 100px;
  color: #006899;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  margin-top: auto;
  top: 100%;
  left: 0;
  bottom: 0;
`;

export const Copyright = styled.div`
  margin-left: 33px;
  margin-right: 33px;
  font-size: 14px;
  line-height: 19px;
  height: 38px;
  color: #666666;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const FooterNav = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterNavLink = styled.a`
  border: none;
  height: 3.75rem;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 12;
  line-height: 17px;
  justify-content: center;
  font-weight: bold;
  color: #0071c4;
`;

export const FooterNavItem = styled.div`
  margin-right: 30px;
  margin-top: 24.5px;
  margin-bottom: auto;
  padding: 2px;
  font-size: 14px;
`;
