import { useContext } from "react";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { SigningOptionsModalContent } from "./SigningOptionsModals";
import { LinkStyled, ModalStyled } from "./SigningOptionsModalsStyled"
import { TabContext, ContextType } from "../Tab/TabContext";
import { useCancelContract } from "../../hooks/contractHooks/useCancelContract";
import { useParams } from "react-router";
import { useModalContext } from "@ally/metronome-ui";

export const EditContractModalTrigger: React.FC = () => {
    const { setOpen } = useModalContext();
    return (
        <LinkStyled
            aria-label="Edit contract"
            text="Edit contract"
            variant="link"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
        />
    )
}

export const EditContractModal: React.FC = () => {
    const [dealershipInfo] = useDealershipInfo();
    const { dealershipNumber } = dealershipInfo || {};
    const { id: customerId } = useParams() as any;
    const {
        activeDeliveryGroupId,
    } = useContext(TabContext) as ContextType;
    const cancelContract = useCancelContract(dealershipNumber, customerId);

    const cancelContractAction = () => {
        const onSuccess = () => {
        }
        const onError = (errs: string[]) => {
            errs.map((err) => console.warn(err))
        }
        cancelContract.mutate({
            deliveryGroupId: activeDeliveryGroupId!,
            onSuccess,
            onError
        });
    }

    const header = "Edit contract";
    const body = <p>You should only go back if you need to edit your contract.
        Selecting <b>Return</b> will revoke your current contract. If that’s not what
        you want to do, select <b>Close</b>.</p>;

    return (
        <ModalStyled
            trigger={<EditContractModalTrigger />}
            role="dialog"
            content={
                <SigningOptionsModalContent
                    onClickHandler={cancelContractAction}
                    loading={cancelContract?.isLoading}
                    header={header}
                    body={body}
                    allytmln="Return - Edit Contract"
                />
            }
            disableFullScreenSmDown={true}
        />
    )
}
