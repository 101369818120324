import { useEffect } from "react";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import ActionDeliveryGroups from "../../Form/Action-DeliveryGroup/ActionDeliveryGroups";
import { TextHeadingStyled } from "../DeliveryGroupTabControlerStyled";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";

type Props = {
  isActiveTab: boolean;
};

export const CreateDeliveryGroup: React.FC<Props> = ({isActiveTab}) => {
  const analytics = useAnalytics();

  useEffect(() => {
    if(isActiveTab){
      analytics(emitPageViewEvent, PageViews.CREATE_DELIVERY_GROUP);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);
  
  return (
    <>                  
      <TextHeadingStyled
        tag="h2"
        size="md"
        tabIndex={-1}
      >
        Create delivery group
      </TextHeadingStyled>
      <ActionDeliveryGroups editOrCreate="create" />
    </>
  );
};
