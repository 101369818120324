import { Spinner } from "@ally/metronome-ui";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;
export const VehicleDrawerContainer = styled.div`
  display: flex;
  margin-right: 20px;
  margin-left: 10px;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const DetailRowHeader = styled.h3`
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #737373;
  margin-top: 12px;
  white-space: nowrap;
`;

export const VehicleCashContainer = styled.div`
`;

export const TaxContainer = styled.div`
  margin-right: 68px;
`;

export const DetailRowContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #2a2a2a;
  margin-bottom: 12px;
`;

export const DetailRowContentName = styled(DetailRowContent)`
  word-wrap: break-word;
`;

export const SecondContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;


export const DetailRowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalPaymentContainer = styled.div`
  margin-right: 8px;
`;

export const TotalOtherChargeContainer = styled.div`
  margin-right: 10px;
`;

export const LastDetailRowContainer = styled(DetailRowContainer)`
`;

export const StyledSpinner = styled(Spinner)`
  align-items: center;
  justify-content: center;
  display: flex;
`;
