import { User } from "./User";

export enum ModalViews {
    ADD_INSURANCE = 'addInsurancePolicyModal',
    LAST_CHANCE_ADD_INSURANCE = `lastChanceAddInsurancePolicyModal`
}

export function emitModalViewEvent(user: User, modal: ModalViews) {
    window.allytm.event('modalView', `${modal}`);
}
