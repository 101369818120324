import { Box, useModalContext } from "@ally/metronome-ui";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAddBatchAssetContractToDeliveryGroup } from "../../../../hooks/assetContractHooks/useAddAssetToDeliveryGroup";
import { useGetDeliveryGroups } from "../../../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import { Identifiable } from "../../../../model/identifiable";
import { TabContext, ContextType } from "../../../Tab/TabContext";

import {
  ButtonContainer,
  ButtonStyle,
  CancelButtonStyle,
  AlertStyled,
  BoxStyled,
} from "./SelectDeliveryStyles";
import { ActiveTabs } from "../../../../constants/enums";
import { ValidationError } from '../../../../hooks/adaptorHooks/validationError';
import DeliveryGroupDropDown from "../../../Tab/DeliveryGroupTabViews/DeliveryGroupHeaders/DeliveryGroupDropDown";

//TODO: consider modifying and then making use of DeliveryGroupDropDown.tsx as a sub component.
// this usecase is for vehicle page and is slightly different but should be able to reuse drop down.
type Props = {
  selectedVehicles?: Identifiable[];
};
const SelectDeliveryGroup: React.FC<Props> = ({ selectedVehicles }) => {
  const [dealershipNumber] = useDealershipInfo();
  const { id } = useParams() as any;
  const [isLoadingAddGroup, setIsLoadingAddGroup] = useState(false);
  const [showGroupWarning, setShowGroupWarning] = useState(false);

  const { isLoading, isError, error } = useGetDeliveryGroups(
    dealershipNumber.dealershipNumber,
    id
  );
  const history = useHistory();

  const [activeDeliveryGroup, setActiveDeliveryGroup] = useState<{
    id: string | undefined;
    name: string;
  }>({ id: undefined, name: "" });

  const { mutate: addAcsToDg } = useAddBatchAssetContractToDeliveryGroup(
    dealershipNumber.dealershipNumber
  );
   
  const {
    saveSelection,
    saveDisplaySuccessfulAction,
    saveActiveDeliveryGroupId,
  } = React.useContext(TabContext) as ContextType;

  const { setOpen } = useModalContext();
   
  if (isLoading) {
    return <></>;
  }
  if (isError) {
    // handle error display
    return <>{error}</>;
  }

  const handleContinue = () => {
    const onSuccess = (results) => {
        const numberSuccessfullyAdded = results.filter(
          (result) => result.status === 204
        ).length;
        const plural = numberSuccessfullyAdded === 1 ? "" : "s";

      setOpen(false);
       
        saveSelection({
          destinationTab: ActiveTabs.DELIVERY_GROUPS,
        });
        saveActiveDeliveryGroupId(activeDeliveryGroup.id);
        setIsLoadingAddGroup(false);
        saveDisplaySuccessfulAction(
          `added ${numberSuccessfullyAdded} vehicle${plural} to ${activeDeliveryGroup.name} delivery group`
        );
        history.replace({ search: null });
    
    }
    const onError = (errs: ValidationError[]) => {
      if(errs instanceof ValidationError){
         if(errs.validationErrors.map(err => err.parameter === 'Limit Exceeded')) {
          setShowGroupWarning(true);
         }
      }
      else{
        saveActiveDeliveryGroupId(activeDeliveryGroup.id);
      }
    }

    if (selectedVehicles && activeDeliveryGroup.id) {
      const vehiclesIds = selectedVehicles.map((i) => i.id);
      setIsLoadingAddGroup(true); 
      addAcsToDg({
        applicationIds: vehiclesIds,
        customerId: id,
        deliveryGroupId: activeDeliveryGroup.id,
        onSuccess,
        onError
      })
    }
  };
  const closeModal = () => setOpen(false);
  return (
    <>
      <BoxStyled height="40px" mt="20px" ml="10px">
        <DeliveryGroupDropDown
          type="OPEN_ONLY"
          close={closeModal}
          selectedVehicles={selectedVehicles}
          setActiveDeliveryGroup={setActiveDeliveryGroup}
          setIsLoadingAddGroup={setIsLoadingAddGroup}
          setShowGroupWarning={setShowGroupWarning}
        />
        { showGroupWarning ? (       
          <AlertStyled
            variant="warning"
            body="You can't add any more vehicles to this delivery group. Create a new group or choose a new group to continue."
            cancellable={false}
            disableContainer={true}
          /> 
          ): false
        }
      </BoxStyled>
      <Box>
        <ButtonContainer>
          {!showGroupWarning ? (
          <ButtonStyle
            aria-label="Continue"
            text="Continue"
            loading={isLoadingAddGroup}
            variant={activeDeliveryGroup.id ? "primary" : "disabled"}
            allytmln="confirm-add-vehicle-to-group"
            onClick={handleContinue}
          />) : false
          }
        
          {showGroupWarning ? (
            <ButtonStyle
            aria-label="Go Back"
            allytmln="cancel-add-vehicle-to-group"
            text="Go Back"
            variant={showGroupWarning ? "primary" : "disabled"}
            onClick={closeModal}
           /> ) : (
          <CancelButtonStyle
            aria-label="Go Back"
            allytmln="cancel-add-vehicle-to-group"
            text="Go Back"
            variant="secondary"
            onClick={closeModal}
          />
          )}
        </ButtonContainer>
      </Box>
    </>
  );
};

export default SelectDeliveryGroup;
