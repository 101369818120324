import { useContext, useEffect } from "react";
import {
  Container,
  DGHeadingStyled,
  Divider,
  OuterContainer,
  StyledIcon,
} from "./SubmittedForFundingStyled";
import { RecontractModal } from "../../Modal/RecontractModal";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import { ContextType, TabContext } from "../TabContext";
import { muiDocumentPage, muiNewWindow } from "@ally/metronome-icons";
import { Link } from "@ally/metronome-ui";
import { contractSummaryRoute } from "../../../routes/RouteFactory";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useParams } from "react-router";
import { ContractActions } from "../../Contracts/ContractActions";
import DeliveryGroupDropDown from "./DeliveryGroupHeaders/DeliveryGroupDropDown";
import {
  SubmittedFile,
  useGetFundedDocuments,
} from "../../../hooks/contractHooks/useGetFundedDocuments";

type SubmittedForFundingProps = {
  isActiveTab: boolean;
  contractStatus: "SUCCESS" | "PENDING" | "ERROR";
  hideRecontractForDG: string;
};
const SubmittedForFunding: React.FC<SubmittedForFundingProps> = ({
  contractStatus,
  hideRecontractForDG,
}) => {
  const progressTracker = <ContractProgressTracker stepNumber={4} />;
  const { saveActiveDeliveryGroupName, activeDeliveryGroupId } = useContext(
    TabContext
  ) as ContextType;

  const [dealershipInfo] = useDealershipInfo();
  const { dealershipNumber } = dealershipInfo || {};

  const { data: funded_documents } = useGetFundedDocuments(
    dealershipNumber,
    activeDeliveryGroupId
  );

  const recontractHidden = hideRecontractForDG !== activeDeliveryGroupId;

  const { id: customerId } = useParams<{ id: string }>();
  let displayDocuments = [];
  if (funded_documents) {
    displayDocuments = funded_documents.submittedFiles;
  }  

  return (
    <>
      <DGHeadingStyled tag="h2" size="md" tabIndex={-1}>
        Delivery groups
      </DGHeadingStyled>
      <DeliveryGroupDropDown type="ALL" />
      <ContractedDeliveryGroupDetailsNew
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}
        deliveryGroupStatus={"CONTRACT_FINALIZED"}
        doNotShowProgressTracker={true}
      />
      <Divider />
      <OuterContainer>
        <h2>You've submitted your funding documents.</h2>
        <p>
          For funding status and communications for missing documents, see
          Dealer Track or RouteOne.
        </p>
      </OuterContainer>
      <OuterContainer>
        <h3>Additional uploaded documents</h3>
        {displayDocuments && (
          <ul>
            {displayDocuments.map((item: SubmittedFile, index: number) => (
              <li key={index}>{item.filename}</li>
            ))}
          </ul>
        )}
      </OuterContainer>
      <Container>
        <h3>Review documents</h3>
        <div className="summary">
          <StyledIcon icon={muiNewWindow} size="lg" ariaHidden />
          <Link
            aria-label="Contract summary"
            text="Contract summary"
            href={contractSummaryRoute(
              dealershipNumber,
              customerId,
              activeDeliveryGroupId
            )}
            variant="link"
            target="_blank"
            allytmln="open-contract-summary-page"
          />
        </div>
        <div className="contract">
          <StyledIcon icon={muiDocumentPage} size="lg" ariaHidden />
          <ContractActions
            activeDeliveryGroupId={activeDeliveryGroupId}
            text={"Multi-vehicle contract package"}
            eSignOrSend={true}
            contractStatus={contractStatus}
          />
        </div>
      </Container>
      <OuterContainer>
        <h3>Need help?</h3>
        <p>
          If you have any questions, reach out to your local account executive.
        </p>
      </OuterContainer>
      <OuterContainer>
        {recontractHidden && (
          <>
            <h3>Need to make changes?</h3>
            <p>
              You can recontract if you need to make changes to your contract.
              This will revoke your current contract and restart the signing
              process.
            </p>
            <RecontractModal />
          </>
        )}
      </OuterContainer>
    </>
  );
};

export default SubmittedForFunding;
