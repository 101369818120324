import { Icon, Link } from "@ally/metronome-ui";
import styled from "styled-components";

export const ContractActionLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  line-height: 24px;
  color: #0071c4;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 767px) {
    font-size: 16px;
  }

`;
export const ContractActionsContainter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const DownloadIconStyled = styled(Icon)`
  margin-right: 4px;
`

export const ContractActionLinkAlt = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  font-weight: bold;
  line-height: 24px;
  color: #0071c4;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const ContractViewLink = styled(Link)`
  display: block;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  line-height: 24px;

  color: #0071c4;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const SpanStyled = styled.span`
  margin-left: 1rem;
  margin-right: 0.9rem;
`;
