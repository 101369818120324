import React, { useEffect, useRef, useState } from "react";
import {
  ContactHoursContent,
  ContactHoursText,
  EmailText,
  EmailLink,
  ContactUsContainer,
  ContactUsMenuItem,
} from "./ContactUsStyled";
import { muiChevronDown } from "@ally/metronome-icons/dist/cjs";
import { MenuLink, SubMenuLink } from "./HeaderStyled";
import { Icon } from "@ally/metronome-ui";

export const ContactUs: React.FC = () => {
  const [openContactDropdown, setOpenContactDropdown] = useState(false)
  const dropdownRef = useRef(null);
  const contactUsRef = useRef(null);

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !contactUsRef.current.contains(event.target)) {
      setOpenContactDropdown(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown);
    return () => {
      document.removeEventListener('mousedown', closeDropdown);
    }
  }, [dropdownRef, contactUsRef])

  const dropDownContent = <ContactUsContainer role="menu" ref={dropdownRef} tabIndex={-1}>
    <ContactHoursText role="menuitem">Hours of Operation</ContactHoursText>
    <ContactHoursContent role="menuitem" aria-label="Mon through Fri">Mon-Fri</ContactHoursContent>
    <ContactHoursContent role="menuitem" aria-label="8:00 am - 5:00 pm Eastern Time">8:00 am - 5:00 pm ET</ContactHoursContent>
    <EmailText>Email</EmailText>
    <EmailLink
      text="AllyCSG@ally.com"
      href="mailto:AllyCSG@ally.com"
      variant="link"
      allytmln="AllyCSG@ally.com"
    />
  </ContactUsContainer>
  return (
    <ContactUsMenuItem className="contact-us-item">
      <MenuLink role="group" tabIndex={-1} aria-label="Contact Us Menu" ref={contactUsRef} onClick={() => setOpenContactDropdown(!openContactDropdown)}>
        <SubMenuLink className="menu-link">
          <p>
          Contact Us
          </p>
          <Icon
            icon={muiChevronDown}
            size="lg"
            fill="white"
            ariaHidden
          />
        </SubMenuLink>
      </MenuLink>
      {
        openContactDropdown ? dropDownContent : null
      }
    </ContactUsMenuItem>
  );
};
