import { Link } from "@ally/metronome-ui";
import { contractSummaryRoute } from "../../../routes/RouteFactory";
import { ContractActions } from "../../Contracts/ContractActions";
import { StyledIcon, ReviewDocumentsOuter, LinkWrapper } from "./EmbeddedStyled";
import { muiDocumentPage } from "@ally/metronome-icons";

type ReviewDocumentsProps = {
    dealershipNumber: string,
    customerId: string,
    activeDeliveryGroupId: string,
    contractStatus: "SUCCESS" | "PENDING" | "ERROR",
}
const ReviewDocuments: React.FC<ReviewDocumentsProps> = ({
    dealershipNumber,
    customerId,
    activeDeliveryGroupId,
    contractStatus
}) => {

    return (
        <ReviewDocumentsOuter>
            <h4>Review documents</h4>
            <p>The documents below are available for your review. Any documents added via DocuSign are unavailable within this portal.</p>
            <LinkWrapper>
                <div>
                    <StyledIcon icon={muiDocumentPage} size="lg" ariaHidden />
                    <Link
                        aria-label="Contract summary"
                        text="Contract summary"
                        href={contractSummaryRoute(
                            dealershipNumber,
                            customerId,
                            activeDeliveryGroupId
                        )}
                        variant="link"
                        target="_blank"
                        allytmln="open-contract-summary-page"
                    />
                </div>
                <div>
                    <StyledIcon icon={muiDocumentPage} size="lg" ariaHidden />
                    <ContractActions
                        activeDeliveryGroupId={activeDeliveryGroupId}
                        contractStatus={contractStatus}
                        eSignOrSend={true}
                    />
                </div>
            </LinkWrapper>
        </ReviewDocumentsOuter>
    )
}

export default ReviewDocuments;

