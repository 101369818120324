import { Link } from "@ally/metronome-ui";
import styled from "styled-components";

export const ContactUsContainer = styled.div`
  outline: none;
  padding-bottom: 5px;
  background-color: #FFFFFF;
  margin-top: 18px;
  border: 1px solid rgb(149, 149, 149);
  box-shadow: rgba(0, 0, 0, 0.25) 0.2em 0.2em 0.2em;
  width: 210px
`;

export const ContactHoursText = styled.p`
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #2a2a2a;
`;
export const ContactHoursContent = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  padding-left: 10px;
  color: #2a2a2a;
`;
export const EmailText = styled.p`
  font-size: 16px;
  line-height: 17px;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 16px;
  margin-bottom: 8px;
  color: #2a2a2a;
`;
export const EmailLink = styled(Link)`
  font-size: 14px;
  line-height: 24px;
  padding-left: 10px;
`;
export const ContactUsMenuItem = styled.div`
  outline: none;
  margin-right: 25px;
  font-size: 1em;
  height: 68px;
  display: flex;
  flex-direction: column;
  align-items: right;
`;

