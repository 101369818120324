import React, { useState, useCallback, useContext } from 'react'
import { useAriaLive } from '@ally/use-aria-live'
import { SteppedProcessContextValues, StepMetadata, StepState } from '../types'
import whisper from '../../whisper'

export type StepTransitionType =  "MarkAsComplete" | "SavedAndContinue";
const SteppedProcessContext = React.createContext<SteppedProcessContextValues>(
  {} as SteppedProcessContextValues,
)

const SteppedProcessConsumer = SteppedProcessContext.Consumer

const displayError = (functionName: string, errorMessage: string): void => {
  whisper.error({
    message: `SteppedProcessProvider:${functionName}:\n${errorMessage}`,
  })
}

const SteppedProcessProvider: React.FC = ({ children }) => {
  const [isStepPending, setIsStepPending] = useState(false);
  const [isStateInitialized, setStateInitialized] = useState(false)
  const [stepsMetadata, setStepsMetadata] = useState<StepMetadata[]>([])
  const [activeStep, setActiveStep] = useState<StepMetadata | undefined>()
  const [focusStep, setFocusStep] = useState(-1)

  const { announceAssertive } = useAriaLive()

  const isOutsideRange = useCallback(
    (stepIndex: number) => {
      return stepIndex >= stepsMetadata.length || stepIndex < 0
    },
    [stepsMetadata],
  )

  const setActiveStepImpl = useCallback(
    (stepIndex: number) => {
      if(isStepPending){
        return;
      }
      if (isOutsideRange(stepIndex)) {
        displayError(
          'setActiveStep',
          'index is out of range - returning without executing request',
        )
        return;
      }

      setActiveStep(stepsMetadata[stepIndex])
      // allow step to mount before setting focus
      setTimeout(() => setFocusStep(stepIndex), 0)
    },
    [stepsMetadata, isStepPending, setActiveStep, isOutsideRange],
  )

  const deactivateActiveStep = useCallback(() => {
    setActiveStep(undefined)
  }, [setActiveStep])

  const setStepState = useCallback(
    (stepIndex: number, stepState: StepState) => {
      setStepsMetadata(
        stepsMetadata.map((step, index) => {
          if (index === stepIndex) {
            return { ...step, state: stepState }
          }
          return step
        }),
      )
    },
    [stepsMetadata, setStepsMetadata],
  )

  const setNextStepActive = useCallback((type?: StepTransitionType) => {
    if (!activeStep) return

    const { index } = activeStep
    const stepCount = stepsMetadata.length

    const nextIndex = index < stepCount - 1 ? index + 1 : stepCount - 1

    if(type === "SavedAndContinue"){
      setStepState(activeStep.index, 'incomplete')

    } else {
      setStepState(activeStep.index, 'complete')
    }
    setActiveStepImpl(nextIndex)

    const currentStep = index + 1
    const currentTitle = activeStep.title
    const nextStep = nextIndex + 1
    const nextTitle = stepsMetadata[nextIndex].title

    announceAssertive(
      `Step ${currentStep}: ${currentTitle} - Complete. Step ${nextStep}: ${nextTitle} - Current Step.`,
    )
  }, [
    activeStep,
    stepsMetadata,
    setStepState,
    setActiveStepImpl,
    announceAssertive,
  ])

  const setPrevStepActive = useCallback(() => {
    if (!activeStep) return

    const { index } = activeStep

    let prevIndex = typeof index === 'number' ? index - 1 : 0
    if (prevIndex < 0) prevIndex = 0

    setActiveStepImpl(prevIndex)
  }, [activeStep, setActiveStepImpl])

  const isStepActive = useCallback(
    (stepIndex: number): boolean | undefined => {
      if (isOutsideRange(stepIndex)) {
        displayError(
          'isStepActive',
          'index is not in range - returning undefined',
        )
        return undefined
      }
      return activeStep?.index === stepIndex
    },
    [activeStep, isOutsideRange],
  )

  const isStepComplete = useCallback(
    (stepIndex: number): boolean | undefined => {
      if (isOutsideRange(stepIndex)) {
        displayError(
          'isStepComplete',
          'index is not in range - returning undefined',
        )
        return undefined
      }
      return stepsMetadata[stepIndex].state === 'complete'
    },
    [stepsMetadata, isOutsideRange],
  )

  const stepState = useCallback(
    (stepIndex: number): StepState | undefined => {
      if (isOutsideRange(stepIndex)) {
        displayError('stepState', 'index is not in range - returning undefined')
        return undefined
      }
      return stepsMetadata[stepIndex].state
    },
    [stepsMetadata, isOutsideRange],
  )

  const initializeState = useCallback(
    (initialStepsMetadata: StepMetadata[], initialActiveStepIndex: number) => {
      if (isStateInitialized) return
      setStepsMetadata(initialStepsMetadata)
      // intentionally not using setActiveStepImpl - prevents focus stealing on mount
      setActiveStep(initialStepsMetadata[initialActiveStepIndex])
      setStateInitialized(true)
    },
    [isStateInitialized, setStepsMetadata, setStateInitialized],
  )

  return (
    <SteppedProcessContext.Provider
      value={{
        stepsMetadata,
        activeStep,
        focusStep,
        isStateInitialized,
        initializeState,
        setActiveStep: setActiveStepImpl,
        setNextStepActive,
        setPrevStepActive,
        setStepState,
        deactivateActiveStep,
        isStepActive,
        isStepComplete,
        stepState,
        setIsStepPending,
      }}
    >
      {children}
    </SteppedProcessContext.Provider>
  )
}

const useSteppedProcess = (): SteppedProcessContextValues => {
  return useContext(SteppedProcessContext)
}

export { SteppedProcessProvider, SteppedProcessConsumer, useSteppedProcess }
