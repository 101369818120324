import { FieldConfiguration, FieldConfigurationSection, SectionFieldSummary, ValidationType } from "./field-configuration";

export function getFieldsForStateOfTypeInSection(section: FieldConfiguration[],
  state: String,
  validationType: ValidationType
): string[] {
  const fields: string [] = [];

  const fieldsMatchingType = section.filter(field => field.validationType === validationType);

  for (const field of fieldsMatchingType) {
    if (field.states.length === 0) {
      fields.push(field.fieldName);
    }

    else if (field.states.some(includedState => includedState === state)) {
      fields.push(field.fieldName);
    }
  }

  return fields;
}

export function getAllFieldsForStateInSection(section: FieldConfigurationSection, state: string): SectionFieldSummary {
  const requiredFields = getFieldsForStateOfTypeInSection(section.fieldConfigurations, state, ValidationType.REQUIRED);
  const optionalFields = getFieldsForStateOfTypeInSection(section.fieldConfigurations, state, ValidationType.OPTIONAL);

  return {
    name: section.name,
    requiredFields: requiredFields,
    optionalFields: optionalFields
  };
}

export function getAllFieldsForStateInCollection(collection: FieldConfigurationSection[],
  state: string,
): SectionFieldSummary[] {
  const results: SectionFieldSummary[] = [];

  for (const section of collection) {
    results.push(getAllFieldsForStateInSection(section, state));
  }

  return results;
}
