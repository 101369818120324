import { muiChevronLeft } from "@ally/metronome-icons";
import { GoBackStyle, Main, StyledIcon } from "./DeliveryGroupTabControlerStyled";
import DeliveryGroupTabController from "./DeliveryGroupTabController"
import { customersDashBoardRoute } from "../../routes/RouteFactory";
import { useHistory, useParams } from "react-router";
import { useGetDeliveryGroups } from "../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { Spinner } from "@ally/metronome-ui";
import { useGetCustomer } from "../../hooks/customerHooks/useGetCustomer";
import { useGetDeliveryGroup } from "../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { useContext, useEffect, useState } from "react";
import { TabContext } from "./TabContext";
import { ActiveTabs } from "../../constants/enums";
import { ReadDeliveryGroup } from "../../model/delivery-group.model";
const SingleCustomerPage: React.FC = () => {
  const history = useHistory();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const goBackToCustomer = () => {
    history.push(customersDashBoardRoute());
  };
  const { id } = useParams() as any;
  const { isSuccess, data: allDeliveryGroups } = useGetDeliveryGroups(dealershipNumber, id); 
  const { data: customer } = useGetCustomer(dealershipNumber, id);
  const { activeDeliveryGroupId } = useContext(TabContext);
  const { data: deliveryGroup } = useGetDeliveryGroup(dealershipNumber, activeDeliveryGroupId);
  const [loadingDestination, setLoadingDestination] = useState(true);
  const [destination, setDestination] = useState(null);
  const [activeDeliveryGroups, setActiveDeliveryGroups] = useState([]);

  const filterOpenDeliveryGroups = (data: ReadDeliveryGroup[]) => {
    return data.filter(
      (v) => v.delivery_status !== "FUNDING_CONFIRMED"
    );
  };

  const setTabLoading = (destination: string, activeDeliveryGroupId: string) => {
    if (destination === "VEHICLES" && !activeDeliveryGroupId) {
      setLoadingDestination(false);
    } else if ((destination === "DELIVERY_GROUPS" && activeDeliveryGroupId) || (destination === "DELIVERY_GROUPS")) {
      setLoadingDestination(false);
    }
  }

  useEffect(() => {
    if (isSuccess) {
      const filteredData = filterOpenDeliveryGroups(allDeliveryGroups);
      setActiveDeliveryGroups(filteredData);
      const tabDestination = (filteredData.length > 0 || deliveryGroup) ? ActiveTabs.DELIVERY_GROUPS : ActiveTabs.VEHICLES;
      setDestination(tabDestination)
      setTabLoading(tabDestination, activeDeliveryGroupId)
    }
  }, [isSuccess, deliveryGroup, destination, allDeliveryGroups, activeDeliveryGroupId]);

  return (  
    <Main role="main">
      <GoBackStyle onClick={goBackToCustomer} tabIndex={0}>
        <StyledIcon icon={muiChevronLeft} size="xl" ariaHidden />
        Back to Commercial Customers
      </GoBackStyle>
      {!customer || loadingDestination ?
        < Spinner /> :
        <DeliveryGroupTabController
          destination={destination}
          activeDeliveryGroups={activeDeliveryGroups}
          setLoadingDestination={setLoadingDestination} />
      }
    </Main>
  );
}

export default SingleCustomerPage;
