import { ButtonGroup, FormText } from "@ally/metronome-ui";
import styled from "styled-components";

const buttonGroupMapping = {
  "buyer-information": "21px",
  "cobuyer-information": "21px",
  "vehicle-information": "21px",
  "dealership-information": "21px",
}

export const DeliveryFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-bottom: 21px;
  margin-top: 20px;

  label {
    height: 20px;
    width: 91px;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 1px;
  }

  div {
    outline: none;
  }

  div > span > input {
    background-color: #f9f9f9;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 4px 4px 0 0;
  }
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${(props) => buttonGroupMapping[props.sectionName]};
  margin-bottom: 35px;
`;