import React, { ReactNode, useEffect } from 'react'
import { useTable, useSortBy, useExpanded, useRowState } from 'react-table'
import LgUpTable from './LgUpTable'
import TableCardList from './TableCardList'
import { getRowToExpand, getSortBy, handleDrawerCloses } from './utils'
import { OnTableDrawerClose, SortableColumn } from './types'
import { useMediaSize } from '@ally/metronome-ui'
import usePrevious from '../../utils/usePrevious'

export type { SortableColumn as TableColumn }

/*
 * Throughout the react-table library (the 3rd party library that this component
 * uses), the generic type variable D is used to represent each original row of data
 * passed to the table. The metronome table uses the same convention, representing the
 * original data row with generic type variable D
 */

export interface RowClickReturn<D> {
  index: number
  data: D
}

export interface SharedProps<D extends Record<string, any>> {
  captionText: string
  isCaptionVisible?: boolean
  className?: string
  container?: boolean
  drawer?: ReactNode
  onDrawerClose?: OnTableDrawerClose<D>
  rowToFocus?: number
  autoResetExpanded?: boolean
  isLoading?: boolean
}

export interface LgUpProps<D extends Record<string, any>> {
  withRowHeadings?: boolean
  rowClickHandler?: (row: RowClickReturn<D>) => void
  selectRow?: number | null
  fullWidth?: boolean
  autoResetSortBy?: boolean
  withFooter?: boolean
}

export interface MdDownProps {
  showFromMdDown?: string[]
  mdDownHeaderDetail?: string
  mdDownDrawerName?: string
  mdDownColumnFullWidth?: boolean
}

export interface TableProps<D extends Record<string, any>>
  extends LgUpProps<D>,
    MdDownProps,
    SharedProps<D> {
  data: D[]
  columns: SortableColumn<D>[]
  suppressMdDownLayout?: boolean
  multipleDrawersOpen?: boolean
}

const drawerSet = new WeakSet()

const getSubRows = <D extends Record<string, any>>(row: D): D[] => {
  if (drawerSet.has(row)) return []

  const drawer = { ...row }

  drawerSet.add(drawer)

  return [drawer]
}

const TableRefForwarding = <D extends Record<string, any>>(
  {
    data,
    columns,
    suppressMdDownLayout,
    withFooter,
    multipleDrawersOpen,
    drawer,
    onDrawerClose,
    withRowHeadings,
    rowClickHandler,
    selectRow,
    fullWidth,
    showFromMdDown = [],
    mdDownHeaderDetail,
    mdDownDrawerName = 'Details',
    mdDownColumnFullWidth,
    autoResetSortBy = true,
    autoResetExpanded = true,
    ...sharedProps
  }: TableProps<D>,
  ref: React.Ref<HTMLElement>,
): React.ReactElement => {
  const isLgUp = useMediaSize('LgUp')

  const tableInstance = useTable<D>(
    {
      columns: React.useMemo(() => columns, [columns]) as any,
      data: React.useMemo(() => data, [data]),
      initialState: React.useMemo(
        () => ({
          ...getSortBy<D>(columns),
        }),
        [columns],
      ),
      disableMultiSort: true,
      disableSortRemove: true,
      autoResetSortBy,
      autoResetExpanded,
      ...(drawer && { getSubRows }),
    },
    useSortBy,
    useExpanded,
    useRowState,
  )

  const {
    state: { expanded },
    toggleAllRowsExpanded,
    toggleRowExpanded,
    preExpandedRows,
  } = tableInstance

  const prevExpanded = usePrevious(expanded)

  useEffect(() => {
    handleDrawerCloses<D>({
      drawer,
      onDrawerClose,
      expanded,
      prevExpanded,
      rows: preExpandedRows,
    })
  }, [drawer, onDrawerClose, expanded, prevExpanded, preExpandedRows])

  if (drawer && !multipleDrawersOpen) {
    const rowToExpand = getRowToExpand(expanded, prevExpanded)

    if (rowToExpand) {
      toggleAllRowsExpanded(false)
      toggleRowExpanded([rowToExpand])
    }
  }

  if (isLgUp || suppressMdDownLayout) {
    return (
      <LgUpTable<D>
        {...{
          tableInstance,
          drawer,
          withRowHeadings,
          rowClickHandler,
          selectRow,
          fullWidth,
          withFooter,
          ref: ref as React.Ref<HTMLTableElement>,
          ...(sharedProps as SharedProps<D>),
        }}
      />
    )
  }

  return (
    <TableCardList<D>
      {...{
        tableInstance,
        drawer,
        showFromMdDown,
        mdDownHeaderDetail,
        mdDownDrawerName,
        mdDownColumnFullWidth,
        ref: ref as React.Ref<HTMLDivElement>,
        ...(sharedProps as SharedProps<D>),
      }}
    />
  )
}

// You cannot easily pass generics to forwardRefs, hence the casting here
// (https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref)

const Table = React.forwardRef(TableRefForwarding) as <
  D extends Record<string, any>,
>(
  props: TableProps<D> & { ref?: React.Ref<HTMLElement> },
) => React.ReactElement

export { Table }
