import styled from "styled-components";

export const ReadOnlyFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`

export const ReadOnlyLabel = styled.span`
  height: 20px;
  width: 91px;
  color: #2a2a2a;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 1px;
  margin-top: 10px;
  max-width: 320px;
  white-space: nowrap;
`

export const ReadOnlyText = styled.span`
  max-width: 320px;
  margin-bottom: 10px;
  margin-top: 5px;
`
