import { AutoComplete, Label, useAutoComplete } from "@ally/metronome-ui";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { useAriaLive } from "@ally/use-aria-live";
import {
  BoxStyle,
  Container,
  LabelStyle,
  LinkButtonStyle,
  OuterContainer,
  SelectedDropdownTriggerMake,
  SelectedDropdownTriggerModel,
  SelectedDropdownTriggerYear,
  VehicleFormFieldStyle,
} from "./SearchVehiclesStyles";
import { convertVehiclesToTableData } from "../../../../utils/table-utils";
import { useAssetContractVehicles } from "../../../../hooks/assetContractHooks/useGetAssetContractVehicles";
import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import { useParams } from "react-router";
import { VehicleSearch } from "../SingleCustomerDashboard";

type Props = {
  emitVehicleSearchCriteria: (args: VehicleSearch) => void;
  clear: () => void;
};

export const SearchVehicles: React.FC<Props> = ({
  emitVehicleSearchCriteria,
  clear
}) => {
  const initSearchModel = () => {
    return {
      ids: [] as string[],
      year: "",
      make: "",
      model: "",
    } as VehicleSearch;
  };
  const [dealershipInfo] = useDealershipInfo();
  const { id } = useParams() as any;
  const { data } = useAssetContractVehicles(
    dealershipInfo?.dealershipNumber,
    id
  );
  const clearAriaLiveAnnouncementPublisher = useAriaLive();
  const [searchCriteria, setSearchCriteria] = useState(initSearchModel);
  const yearRefValue = useRef(null);
  const modelRefValue = useRef(null);
  const makeRefValue = useRef(null);
  const vehicles = convertVehiclesToTableData(data);
  const makeOptions = vehicles
    .map((v) => v.vehicleMake)
    .filter((v, i, a) => a.indexOf(v) === i).sort();
  let makeOptionLabels = null;
  if (makeOptions) {
    makeOptionLabels = makeOptions!.map((y) => {
      return {
        value: y,
        label: `${y}`,
      };
    });
  }

  const yearOptions = vehicles
    .map((v) => v.year)
    .filter((v, i, a) => a.indexOf(v) === i).sort();
  let yearOptionLabels = null;
  if (yearOptions) {
    yearOptionLabels = yearOptions!.map((y) => {
      return {
        value: y,
        label: `${y}`,
      };
    });
  }

  const modelOptions = vehicles
    .map((v) => v.model)
    .filter((v, i, a) => a.indexOf(v) === i).sort();
  let modelOptionLabels = null;
  if (modelOptions) {
    modelOptionLabels = modelOptions!.map((m) => {
      return {
        value: m,
        label: `${m}`,
      };
    });
  }
  const autoCompleteState = useAutoComplete({});
  const { setFreshOptions, selections } = autoCompleteState;

  useEffect(() => {
    searchCriteria.ids = selections;
    emitVehicleSearchCriteria({ ...searchCriteria });
    //TODO address missing in dependency array for emitVehicleSearchCriteria
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selections)]);

  const deriveOptions = useCallback(
    (searchValue: string): string[] => {
      if (searchValue) {
        const vinOptions = vehicles
          .filter((vehicle) => {
            const vin = vehicle.vin.toLowerCase();
            const lowerCaseSearch = searchValue.toLowerCase();
            return vin.includes(lowerCaseSearch);
          })
          .map((vehicle) => vehicle.vin)
          .filter((v, i, a) => a.indexOf(v) === i);

        const appIdOptions = vehicles
          .filter((vehicle) => {
            const appId = vehicle.appId.toLowerCase();
            const lowerCaseSearch = searchValue.toLowerCase();
            return appId.includes(lowerCaseSearch);
          })
          .map((vehicle) => vehicle.appId)
          .filter((v, i, a) => a.indexOf(v) === i);

        return appIdOptions.concat(vinOptions);
      }
      return vehicles.map((vehicle) => vehicle.appId);
    },
    [vehicles]
  );

  const onAutoCompleteChange = useCallback(
    (value: string) => {
      setFreshOptions(deriveOptions(value));
    },
    [setFreshOptions, deriveOptions]
  );

  const handleClear = (event: any) => {
    const initVal = initSearchModel();
    autoCompleteState.deselectOptions(selections);
    setSearchCriteria(initVal);
    emitVehicleSearchCriteria(initVal);
    clearAriaLiveAnnouncementPublisher.announcePolite(
      " all search and filters cleared"
    );
    clear();
  };

  const selectionChange = (event: any): void => {
    const newSearch = {
      ...searchCriteria,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    setSearchCriteria(newSearch);
    emitVehicleSearchCriteria(newSearch);
  };

  const searchLabel = `Search by VIN or Ally app ID`;

  return (
    <>
      <OuterContainer>
        <Container>
          <VehicleFormFieldStyle>
            <AutoComplete
              label={searchLabel}
              state={autoCompleteState}
              onValueChange={onAutoCompleteChange}
              maxDisplayedOptions={15}
            />
          </VehicleFormFieldStyle>
          <BoxStyle mr="18px">
            <Label htmlFor="year">
              <LabelStyle>Year</LabelStyle>
            </Label>
            <SelectedDropdownTriggerYear
              aria-label="year options"
              id="year"
              name="year"
              options={yearOptionLabels}
              ref={yearRefValue}
              placeholder="Selection"
              value={searchCriteria.year}
              onChange={selectionChange}
            />
          </BoxStyle>

          <BoxStyle mr="20px">
            <Label htmlFor="make">
              <LabelStyle>Make</LabelStyle>
            </Label>
            <SelectedDropdownTriggerMake
              aria-label="make options"
              id="make"
              name="make"
              options={makeOptionLabels}
              ref={makeRefValue}
              placeholder="Selection"
              value={searchCriteria.make}
              onChange={selectionChange}
            />
          </BoxStyle>

          <BoxStyle mr="20px">
            <Label htmlFor="model">
              <LabelStyle>Model</LabelStyle>
            </Label>
            <SelectedDropdownTriggerModel
              aria-label="model options"
              id="model"
              name="model"
              options={modelOptionLabels}
              ref={modelRefValue}
              placeholder="Selection"
              value={searchCriteria.model}
              onChange={selectionChange}
            />
          </BoxStyle>
        </Container>
        <LinkButtonStyle variant="link" text="Clear" allytmln="clear-vehicle-search" onClick={handleClear} />
      </OuterContainer>
    </>
  );
};
