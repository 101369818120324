import { Link } from "@ally/metronome-ui";
import { useParams } from "react-router";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useContext, useEffect, useState } from "react";
import { TabContext, ContextType } from "../TabContext";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import { ActionBarContainerStyle } from "./DeliveryGroupDetails/DeliveryGroupDetailsStyled";
import ContractedDeliveryGroupDetails from "./DeliveryGroupDetails/ContractedDeliveryGroupDetails";
import { ContractActions } from "../../Contracts/ContractActions";
import { useGetDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { Container, Line, OpenDrawer} from "./ContractFinalizingStyled";
import { useCancelContract } from "../../../hooks/contractHooks/useCancelContract";
import { useFinalizeContract } from "../../../hooks/contractHooks/useFinalizeContract";
import { CancelContractModalTrigger, ContractActionModalContent, FinalizeContractModalTrigger } from "../../Modal/CompleteContractModal";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { ContractModalStyled } from "../../Modal/ContractModalsStyled";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { contractSummaryRoute } from "../../../routes/RouteFactory";
import { atom, useAtom } from "jotai";
import { backToFinalizeContract } from "./ContractReadyForFunding";

type Props = {
  contractStatus?: "SUCCESS" | "PENDING" | "ERROR";
  isActiveTab: boolean;
};

export const finalizeStepProcessNumber = atom(2);

const ContractFinalizing: React.FC<Props> = ({ contractStatus, isActiveTab }) => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id: customerId } = useParams() as any;
  const {
    saveActiveDeliveryGroupName,
    activeDeliveryGroupId,
   } = useContext(TabContext) as ContextType;
  const [hasFinalizeError, setHasFinalizeError] = useState<boolean>(false);
  const [isFinalizePending, setIsFinalizePending] = useState<boolean>(false);
  const [finalizeErrors, setFinalizeError] = useState<string[]>([]);
  const [isCancelPending, setIsCancelPending] = useState<boolean>(false);
  const finalizeContract = useFinalizeContract(dealershipNumber);
  const cancelContract = useCancelContract(dealershipNumber, customerId);
  const [useBackToFinalizeContractState, setUseBackToFinalizeContractState] = useAtom(backToFinalizeContract);

  const [useFinalizeStepProcessNumber, setFinalizeStepProcessNumber] = useAtom(finalizeStepProcessNumber);
  const { data: activeDeliveryGroup} = useGetDeliveryGroup(
    dealershipNumber,
    activeDeliveryGroupId
  );

  useEffect(() => {
    if(isActiveTab){
      analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_FINALIZE);
    }
    setFinalizeStepProcessNumber(2);
    if(useBackToFinalizeContractState) {
      const elem = document.getElementById("Finalize_contract_button");
      elem.click();
    }
    return () => {
      setUseBackToFinalizeContractState(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);
  
  const finalizeContractAction = (closeModal?: () => void) => {
    const onSuccess = () => {
      setIsFinalizePending(false);
      if(closeModal){
        closeModal();
      }
    }
    const onError = (errs: string[]) => {
      setIsFinalizePending(false);
      setHasFinalizeError(true);
      setFinalizeError(errs);
    }

    setIsFinalizePending(true);
    finalizeContract.mutate({
      deliveryGroupId: activeDeliveryGroupId!,
      customerId: customerId,
      onSuccess,
      onError,
    }); 
  }

  const cancelContractAction = (closeModal?: () => void) => {
    const onSuccess = () => {
      setIsCancelPending(false);
      if(closeModal){
        closeModal();
      }
    }
    const onError = (errs: string[]) => {
      setIsCancelPending(false);
    }

    setIsCancelPending(true);
    cancelContract.mutate({
      deliveryGroupId: activeDeliveryGroupId!,
      onSuccess,
      onError,
    }); 
  }
  const progressTracker = <ContractProgressTracker stepNumber={useFinalizeStepProcessNumber}/>;
  const [openFinalizeContract, setOpenFinalizeContract] = useState<boolean>(false);
  const actionBar = <ActionBarContainerStyle disableStackSmDown={true}>
    <ContractModalStyled
      trigger={<FinalizeContractModalTrigger initModal={() => {
        setHasFinalizeError(false);
        setFinalizeError([]);
      }}
        setOpenFinalizeContract={setOpenFinalizeContract}
      />}
      content={
        <ContractActionModalContent 
          isContractActionPending={isFinalizePending}
          actionType="Finalize"
          contractAction={finalizeContractAction}
          errors={finalizeErrors}
        />
      }
      hideCloseButton={hasFinalizeError}
      dataTrackRegion="finalize-contract-modal"
      open={openFinalizeContract}
      onOpenChange={(newOpen: boolean) => {
        setFinalizeStepProcessNumber(2);
        setOpenFinalizeContract(newOpen)
      }}
    />
    

    <ContractModalStyled
      trigger={<CancelContractModalTrigger/>}
      content={
        <ContractActionModalContent 
          isContractActionPending={isCancelPending}
          actionType="Cancel"
          contractAction={cancelContractAction}
          errors={[]}
        />
      }
      dataTrackRegion="cancel-contract-modal"
    />
  </ActionBarContainerStyle>;
  
  return (
    <>                  
      <DeliveryGroupHeader/>
      <ContractedDeliveryGroupDetails 
        actionBar={actionBar} 
        progressTracker={progressTracker} 
        updateDeliveryGroupName={saveActiveDeliveryGroupName} 
        deliveryGroupStatus={"CONTRACT_CREATED"} 
      />
    
      <Container>
        <p>Step 1: View Documents</p>
      </Container>
      <OpenDrawer>

        <div className="step_content_container">
          <p>Once you&#39;ve received your contract summary you can download, print, and sign your documents.</p>
        </div>

        <div >
          <div className="step1_column_container">
            <h4 className="firstColumn"> Contract summary</h4>
            <Link 
              text="View" 
              href={contractSummaryRoute(dealershipNumber, customerId, activeDeliveryGroupId)}
              variant="link" 
              target="_blank"
              allytmln="open-contract-summary-page"
            />
          </div>
         <Line></Line>
          <div className="step1_column_container">
            <h4 className="firstColumn"> Multi-vehicle contract</h4>
            <ContractActions activeDeliveryGroupId={activeDeliveryGroupId} contractStatus={contractStatus} />
          </div>
        
        </div>
      </OpenDrawer>

      <Container>
        <p>Step 2: Sign and Submit</p>
      </Container>
      <OpenDrawer>
        <div className="step_content_container">
          <p>Now that you’ve created your contract, you’ll need to submit these required documents:</p>
          <ul>
            <li><span>Signed multi-vehicle contract</span></li>
            <li><span>Signed Ally approved credit application</span></li>
            <li><span>Signed title application for all vehicles</span></li>
            <li><span>Proof of insurance for all vehicles</span></li>
            <li><span>Invoices or book out sheet for all vehicles</span></li>
          </ul>
        </div>
        <Line></Line>
        <div className="step_content_container">
          <p>You can sign and submit your required documents using one of these methods:</p>
          
          <h4>Option 1: Print and Sign </h4>
          
          <h4>Option 2: E-Sign with DocuSign</h4>
          <div className="option_header">
            a. 
            <span className="option_content"> 
              We only accept documents electronically signed through DocuSign
            </span>
          </div>
          
          <h4>Option 3: Request a DocuSign loan package from the Ally CSG team</h4>
          <div className="option_header">
            a. 
            <span className="option_content"> 
              Email all the documents you need signed, including your multi-vehicle contract to <Link 
              text=" AllyCSG@ally.com" 
              href='mailto:AllyCSG@ally.com'
              variant="link" 
              allytmln="email all document to allycsg@ally.com"
            />.
            </span>
          </div>
          <div className="option_header">
            b. 
            <div className="option_content"> Include an email for each authorized signer listed on the contract 
              <BuyerAndSellerDetails deliveryGroup={activeDeliveryGroup} />
            </div>
          </div>
          <div className="option_header">c. 
            <span className="option_content"> 
              We'll email a DocuSign contract package to the authorized signers to complete
            </span>
          </div>
        </div>
      </OpenDrawer>

      <Container>
        <p>Step 3: Mailing Options</p>
      </Container>
      <OpenDrawer>
        <div className="step_content_container"> 
          <p>You&#39;ll need to send your signed documents to one of the following addresses, depending on which carrier you choose:</p>
        </div>
        <div className="step3_column_container">
          <div className="column_one">
            <h4>FedEx (Including Ground)</h4>
            <p>Ally Financial</p>
            <p>Suite 2070</p>
            <p>4054 Willow Lake Blvd</p>
            <p>Memphis, TN 38153</p>
          </div>
          <div className="column_two">
            <h4>All Other Couriers:</h4>
            <p>Ally Financial</p>
            <p>Suite 2070</p>
            <p>4054 Willow Lake Blvd</p>
            <p>Memphis, TN 38118</p>
          </div>
        </div>
      </OpenDrawer>
    
    </>
  );
};

type BuyerAndSellerDetailsProps = {
  deliveryGroup?: ReadDeliveryGroup | null; 
};
const BuyerAndSellerDetails: React.FC<BuyerAndSellerDetailsProps> = ({ deliveryGroup }) => {
  if(!deliveryGroup){
    return <></>
  }

  let cobuyerOrGuarantorLabel = "CoBuyer";
  let cobuyerOrGuarantorValue = deliveryGroup.coBuyerName;
  if(deliveryGroup.dealerState === "IL" && deliveryGroup.guarantorName){
    cobuyerOrGuarantorLabel = "Guarantor";
    cobuyerOrGuarantorValue = deliveryGroup.guarantorName;
  }
  const hasCobuyerOrGuarantor = !!cobuyerOrGuarantorValue;

  return (
  <div className="buyer_info">
    <p> Buyer Name: {deliveryGroup.buyerName}</p> 
    { hasCobuyerOrGuarantor &&
      <p> {cobuyerOrGuarantorLabel} Name: {cobuyerOrGuarantorValue} </p>
    }
    <p> Seller Name: {deliveryGroup.sellerName} </p>
  </div>);
}
export default ContractFinalizing;
