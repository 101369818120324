import styled from "styled-components";
import { Button } from "@ally/metronome-ui";

export const SubHeader = styled.h2`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  padding-right: 18px;
  letter-spacing: 0;
`;

export const Description = styled.p`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
`;

export const LinkButtonStyle = styled(Button)`
  letter-spacing: 0;
  line-height: 16px;
  color: #1b88d3;
  margin-left: 16px;
  font-weight: bold;
  display: flex;
  align-self: center;
`;

export const SectionContainer = styled.div`
  padding-bottom: 15px;
`;
