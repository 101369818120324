import {
  Heading,
  StyledPanel,
} from "./DeliveryGroupTabControlerStyled";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router";
import { useGetCustomer } from "../../hooks/customerHooks/useGetCustomer";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { ContextType, TabContext } from "./TabContext";
import NoDeliveryGroupSelected from "./DeliveryGroupTabViews/NoDeliveryGroupSelected";
import FinalizeDeliveryGroup from "./DeliveryGroupTabViews/FinalizeDeliveryGroup";
import { CreateDeliveryGroup } from "./DeliveryGroupTabViews/CreateDeliveryGroup";
import { EditDeliveryGroup } from "./DeliveryGroupTabViews/EditDeliveryGroup";
import { useAssetContractVehicles } from "../../hooks/assetContractHooks/useGetAssetContractVehicles";
import { useGetDeliveryGroup } from "../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { useGetContractStatus } from "../../hooks/contractHooks/useGetContractStatus";
import { CONTRACT_STATE, ActiveTabs } from "../../constants/enums";
import { Contract } from "../../model/contract.model";
import { Spinner, Tabs } from "@ally/metronome-ui";
import CreateContract from "./DeliveryGroupTabViews/CreateContract";
import ContractReadyForFunding from "./DeliveryGroupTabViews/ContractReadyForFunding";
import ContractFullyCompleted from "./DeliveryGroupTabViews/ContractFullyCompleted";
import {
  Machine,
  PreviousState,
} from "../../utils/deriveLifecycleStepForDeliveryGroup";
import { UnassignedVehicleDashboard } from "./VehicleTabViews/UnassignedVehicleDashboard";
import SigningOptions from "./DeliveryGroupTabViews/SigningOptions";
import CardSelected from "./DeliveryGroupTabViews/CardSelected";
import ContractFinalizing from "./DeliveryGroupTabViews/ContractFinalizing";
import MailContract from "./DeliveryGroupTabViews/MailContract";
import { useFlags } from "launchdarkly-react-client-sdk";
import FinalPage from "./DeliveryGroupTabViews/FinalPage";
import SendContract from "./DeliveryGroupTabViews/SendContract";
import SubmittedForFunding from "./DeliveryGroupTabViews/SubmittedForFunding";

export interface Tab {
  label: string;
  ariaLabel?: string;
  panel: React.ReactNode;
  allytmln?: string;
}

const setActiveTabTo = (tabName?: string) => {
  if (!tabName) {
    return;
  }
  const tabIndex = tabName === ActiveTabs.VEHICLES ? 0 : 1;
  const t = document.querySelectorAll('[role="tablist"]')[0].childNodes[
    tabIndex
  ];
  if (t) {
    (t as HTMLElement).click();
  }
};

const DeliveryGroupTabController: React.FC<{ destination: any, activeDeliveryGroups: any[], setLoadingDestination: any }> = ({ destination, activeDeliveryGroups, setLoadingDestination }) => {
  const { ffDeliveryGroupsDocusignOptions } = useFlags();
  const { ffCsgDdsFileSubmission } = useFlags();
  const contractState = new Machine(ffDeliveryGroupsDocusignOptions, ffCsgDdsFileSubmission);

  const getContractState = (
    prevContractState: PreviousState,
    contract: Contract,
    deliveryGroupId?: string,
    deliveryGroupAction?: string,
    finalizeContract?: boolean
  ): CONTRACT_STATE => {
    return contractState.run(
      prevContractState,
      contract,
      deliveryGroupId,
      deliveryGroupAction
    );
  };

  const {
    activeDeliveryGroupId,
    selectionGroup,
    handleManualTabClick,
    saveSelection
  } = useContext(TabContext) as ContextType;


  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id: customerId } = useParams() as any;
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const [hideRecontractForDG, setHideRecontractForDG] = useState(null);
  const [showContractModal, setShowContractModal] = useState(false);
  const [showForgotInsuranceModal, setShowForgotInsuranceModal] = useState(false);

  const prevContractState = useRef<PreviousState>({
    contractState: null,
    deliveryGroupId: activeDeliveryGroupId,
  });
  const tab = selectionGroup.destinationTab;
  const deliveryGroupAction = queryParams.get("action");
  const {
    data: deliveryGroup,
    isLoading: deliveryGroupLoading,
  } = useGetDeliveryGroup(dealershipNumber, activeDeliveryGroupId);

  const {
    data: contractStatus,
    isLoading: contractStatusLoading,
  } = useGetContractStatus(dealershipNumber, activeDeliveryGroupId);
  const {
    data: assetContracts,
    isLoading: assetContractsLoading,
  } = useAssetContractVehicles(
    dealershipNumber,
    customerId,
    activeDeliveryGroupId
  );
  const { data: customer } = useGetCustomer(dealershipNumber, customerId);

  useLayoutEffect(() => {
    setActiveTabTo(tab);
  }, [tab]);

  const setNoClick = useRef<boolean>(false);
  useEffect(() => {
    if (!setNoClick.current) {
      const tabs = document.querySelectorAll('[role="tablist"]')[0].childNodes;
      tabs.forEach((t: HTMLElement) => {
        t.setAttribute("allytm-no-track", "true");
      });
      setNoClick.current = true;
    }
  }, [setNoClick]);

  const contract = {
    isHydrating:
      deliveryGroupLoading || contractStatusLoading || assetContractsLoading,
    deliveryGroup,
    assetContracts,
    physicalContractCreatedSuccessfully:
      (contractStatus?.status ?? "") === "SUCCESS",
    physicalContractPending: (contractStatus?.status ?? "") === "PENDING",
    physicalContractError: (contractStatus?.status ?? "") === "ERROR",
  } as Contract;

  const contractProgressState = getContractState(
    prevContractState.current,
    contract,
    activeDeliveryGroupId,
    deliveryGroupAction
  );

  prevContractState.current = {
    contractState: contractProgressState,
    deliveryGroupId: activeDeliveryGroupId,
  } as PreviousState;

  const handleTabChange = (i: number): void => {
    const tabName = i === 0 ? ActiveTabs.VEHICLES : ActiveTabs.DELIVERY_GROUPS;
    handleManualTabClick(tabName, deliveryGroupAction, tab);
  };

  const history = useHistory();

  const { id, dgId } = useParams() as any;
  const { isError: customerError } = useGetCustomer(dealershipNumber, id);
  const { isError: deliveryGroupError } = useGetDeliveryGroup(
    dealershipNumber,
    dgId
  );

  if (customerError || (dgId && deliveryGroupError)) {
    history.push("/not-found");
  }


  useEffect(() => {
    saveSelection({
      destinationTab: destination
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination, deliveryGroupLoading])

  const createDg = (
    <CreateDeliveryGroup isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS} />
  );
  const dgFullyComplete = useMemo(
    () => (
      <ContractFullyCompleted
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
      />
    ),
    [tab]
  );
  const dgFinalPage = useMemo(
    () => (
      <FinalPage
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
        hideRecontractForDG={hideRecontractForDG}
      />
    ), [tab, hideRecontractForDG]
  );
  const dgReadyForFunding = useMemo(
    () => (
      <ContractReadyForFunding
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
      />
    ),
    [tab]
  );
  const dgMailContract = useMemo(
    () => (
      <MailContract
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
        setHideRecontractForDG={setHideRecontractForDG}
      />
    ), [tab]
  );
  const dgCreatedButNotFinal = useMemo(
    () => (
      <FinalizeDeliveryGroup 
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS} 
        setShowContractModal={setShowContractModal}
        />
    ),
    [tab]
  );
  const noDgSelected = useMemo(
    () => (
      <NoDeliveryGroupSelected
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
        numberOfOpenGroups={activeDeliveryGroups.length}
        setLoadingDestination={setLoadingDestination}
      />
    ),
    [tab, activeDeliveryGroups.length, setLoadingDestination]
  );

  const createContract = useMemo(() => {
    return <CreateContract 
    isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
    showContractModal={showContractModal}
    setShowContractModal={setShowContractModal}
      showForgotInsuranceModal={showForgotInsuranceModal}
      setShowForgotInsuranceModal={setShowForgotInsuranceModal}
     />;
  }, [tab, showContractModal, showForgotInsuranceModal]);
  const dgContractFinalize = useMemo(() => {
    return (
      <ContractFinalizing
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
        contractStatus={contractStatus?.status as any}
      />
    );
  }, [tab, contractStatus?.status]);
  const dgSigningOptions = useMemo(() => {
    return (<SigningOptions
      isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
      contractStatus={contractStatus?.status}
      deliveryGroup={deliveryGroup}
    />
    );
  }, [tab, contractStatus?.status, deliveryGroup]);
  const dgCardSelected = useMemo(() => {
    return (<CardSelected
      isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
      contractStatus={contractStatus?.status} />
    );
  }, [tab, contractStatus?.status]);
  const editDg = (
    <EditDeliveryGroup isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS} />
  );
  const unassignedVehicleDashboard = useMemo(() => {
    return (
      <UnassignedVehicleDashboard isActiveTab={tab === ActiveTabs.VEHICLES} />
    );
  }, [tab]);

  const sendContract = useMemo(() => {
    return (
      <SendContract
        isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS}
        dealershipNumber={dealershipNumber}
        deliveryGroupId={dgId}
        setHideRecontractForDG={setHideRecontractForDG}

      />
    )
  }, [tab, dealershipNumber, dgId]);

  const submittedForFunding = useMemo(() => {
    return (
      <SubmittedForFunding 
      isActiveTab={tab === ActiveTabs.DELIVERY_GROUPS} 
      hideRecontractForDG={hideRecontractForDG}
      contractStatus={contractStatus?.status  as any}
      />
    )
  }, [tab, hideRecontractForDG, contractStatus?.status]);

  const vehicleTab: Tab = {
    label: "Vehicles",
    panel: (
      unassignedVehicleDashboard
    ),
    allytmln: "vehicles-tab",
  };
  const plural = activeDeliveryGroups.length === 1 ? "" : "s";
  const DeliveryGroupTab: Tab = {
    label: `Delivery Groups (${activeDeliveryGroups.length} Open Delivery Group${plural})`,
    panel: (
      <StyledPanel>
      {contractProgressState === CONTRACT_STATE.LOADING && (
        <Spinner></Spinner>
      )}
      {contractProgressState === CONTRACT_STATE.DG_READY_FOR_CREATION &&
        createDg}
      {contractProgressState ===
        CONTRACT_STATE.FUNDING_CONTRACT_COMPLETE && dgFullyComplete}
      {contractProgressState ===
        CONTRACT_STATE.CONTRACT_FUNDING_CONFIRMED && dgFinalPage}
      {contractProgressState ===
        CONTRACT_STATE.FINALIZE_CONTRACT_COMPLETE && dgReadyForFunding}
      {contractProgressState ===
        CONTRACT_STATE.CONTRACT_CREATED_MAIL_CONTRACT && dgMailContract}
      {contractProgressState ===
          CONTRACT_STATE.CONTRACT_CREATED_SEND_CONTRACT && sendContract}
      {contractProgressState ===
          CONTRACT_STATE.SUBMIT_FOR_FUNDING && submittedForFunding}
      {contractProgressState ===
        CONTRACT_STATE.CONTRACT_CREATION_REQUESTED &&
        dgContractFinalize}
      {contractProgressState ===
        CONTRACT_STATE.CONTRACT_CREATED_SIGNING_OPTIONS &&
        dgSigningOptions}
      {contractProgressState ===
        CONTRACT_STATE.CONTRACT_CREATED_CARD_SELECTED &&
        dgCardSelected}
      {contractProgressState === CONTRACT_STATE.DG_READY_TO_EDIT &&
        editDg}
      {contractProgressState ===
        CONTRACT_STATE.CONTRACT_READY_FOR_CREATION && createContract}
      {contractProgressState === CONTRACT_STATE.NOT_IN_CONTRACT &&
        noDgSelected}
      {contractProgressState ===
        CONTRACT_STATE.DG_CREATED_BUT_NOT_FINALIZED &&
        dgCreatedButNotFinal}
    </StyledPanel>
    ),
    allytmln: "delivery-groups-tab"
  };

  const tabs = [vehicleTab, DeliveryGroupTab]

  return (
    <>
      <Heading>{customer?.company ?? ""}</Heading>

      <Tabs tabs={tabs} onSelectedTabChange={handleTabChange} initialSelectedTab={0} accessibleDropdownTitle="Tabs" />
    </>

  );
};

export default DeliveryGroupTabController;
