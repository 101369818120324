import { Identifiable } from "./identifiable";
import { Condition, States } from "../constants/enums";
import { isNullUndefinedOrEmpty } from "../utils/form-utils";

interface isCompletable {
  isComplete?: boolean;
}
export class TradeInCashDown implements isCompletable {
  public hasTradeIn?: boolean;
  public tradeInYear?: number | null;
  public tradeInMake?: string | null;
  public tradeInModel?: string | null;
  public grossTradeInAllowance?: number | null;
  public lessPayoffMadeBySeller?: number | null;
  public lessPayoffMadeBySellerTo?: string | null;
  public equalsNetTradeIn?: number | null;
  public cash?: number | null;
  public rebate?: number | null;
  public tradeInCreditAgreementBenefit?: number | null;
  public tradeInCashDownOtherLabel?: string | null;
  public tradeInCashDownOtherValue?: number | null;
  public isComplete?: boolean;
}

export class CashPrice implements isCompletable {
  public vehicleCashPrice?: number | null;
  public cashPriceOtherOneLabel?: string | null;
  public cashPriceOtherOneValue?: number | null;
  public cashPriceOtherTwoLabel?: string | null;
  public cashPriceOtherTwoValue?: number | null;
  public dealerProcessingCharge?: number | null;
  public freightCharge?: number | null;
  public deliveryAndHandlingFee?: number | null;
  public salesTax?: number | null;
  public exciseTax?: number | null;
  public preDeliveryServiceFees?: number | null;
  public vehicleDelivery?: number | null;
  public administrativeFee?: number | null;
  public dealerDocumentaryServiceFee?: number | null;
  public serviceAndHandlingFee?: number | null;
  public isComplete?: boolean;
}

export class VehicleDetails implements isCompletable {
  public vin?: string | null; // read only
  public year?: number | null; // read only
  public make?: string | null; // read only
  public model?: string | null;
  public odometer?: number | null;
  public grossWeight?: number | null;
  public condition?: Condition; // read only
  public isComplete?: boolean;
}

export class BuyerInformation implements isCompletable {
  public name?: string | null;
  public address?: string | null;
  public city?: string | null;
  public county?: string | null;
  public state?: States | null;
  public zip?: string | null;
  public monthOfBirth?: number | null;
  public taxId?: string | null; //read only
  public buyerSignerName?: string | null;
  public buyerSignerTitle?: string | null;
  public buyerSignerEmail?: string | null;
  public isComplete?: boolean;
}

export class TruthInLendingAct implements isCompletable {
  public customerBuyRate?: number;
  public numberOfPayments?: number;
  public isComplete?: boolean;
}

export class CoBuyerInformation implements isCompletable {
  public name?: string | null;
  public address?: string | null;
  public streetAddress?: string | null;
  public city?: string | null;
  public state?: States | null;
  public zip?: string | null;
  public county?: string | null;
  public monthOfBirth?: number | null;
  public type?: string | null;
  public isComplete?: boolean;
  public email?: string | null;
}

export class AuxProduct {
  public typeOfBenefit?: string;
  public company?: string;
  public charge?: number;
  public coverageMonths?: number;
  public coverageMiles?: number;
}

export class AuxProducts implements isCompletable {
  auxProducts?: AuxProduct[];
  isComplete?: boolean;
}

export class SellerGovernmentFees implements isCompletable {
  public dealerDocumentaryFee?: number;
  public deputyServiceFeeToSeller?: number;
  public sellersInventoryTax?: number;
  public miscCharges?: number;
  public documentProcessingChargeTo?: string;
  public documentProcessingCharge?: number;
  public emissionsTestingChargeTo?: string;
  public emissionsTestingCharge?: number;
  public evChargingStationChargeTo?: string;
  public evChargingStationCharge?: number;
  public evRegisterTransferChargeTo?: string;
  public evRegisterTransferCharge?: number;
  public electronicLienAndTitleFeeTo?: string;
  public electronicLienAndTitleFee?: number;
  public ertFeeTo?: string;
  public ertFee?: number;
  public officialFeesPaidToGovAgencies?: number;
  public officialFeesPaidToGovAgenciesTo?: string;
  public officialFeesPaidToGovAgenciesFor?: string;
  public govTaxesNotIncludedInPrice?: number;
  public govLicenseFee?: number;
  public govRegistrationFee?: number;
  public govCertificateOfTitleFee?: number;
  public securityInterestRecordingFee?: number;
  public plateTransferFee?: number;
  public govDocStampTax?: number;
  public govWasteTireManagementFee?: number;
  public govVehicleInspectionFee?: number;
  public lienNotationFee?: number;
  public supplementalTitleFee?: number;
  public vehicleTireFee?: number;
  public stateEmissionsCertExemptFee?: number;
  public totalOfficialFeesPaidToGovAgencies?: number;
  public forPriorCreditOrLeaseBalance?: number;
  public forPriorCreditOrLeaseBalanceTo?: string;
  public toSellerForTradeInCreditAgreement?: number;
  public securityInterestRecordingFeeCash?: number;
  public isComplete?: boolean;
}

export class DealershipInformation implements isCompletable {
  public sellerName?: string | null;
  public sellerAddress?: string | null;
  public sellerCity?: string | null;
  public sellerState?: States | null; //read only
  public sellerZip?: string | null;
  public sellerSignedRepName?: string | null;
  public sellerSignedRepTitle?: string | null;
  public sellerEmail?: string | null;
  public isComplete?: boolean;
}

export type WriteAssetContract = {
  appId: string;
  customerId: string;
  vehicleDetails?: VehicleDetails;
  buyerInformation?: BuyerInformation;
  coBuyerInformation?: CoBuyerInformation;
  cashPrice?: CashPrice;
  tradeInCashDown?: TradeInCashDown;
  dealershipInformation?: DealershipInformation;
  truthInLendingAct?: TruthInLendingAct;
  sellerGovernmentFees?: SellerGovernmentFees;
  auxProducts?: AuxProducts;
};

export type ReadAssetContract = WriteAssetContract &
  Identifiable & {
    decisionDateTime: Date;
    deliveryGroupId?: string;
    appDecision?: string;
    dealStatus?: string;
    buyRate?: number;
    approvedFinanceAmount?: number;  
    totalCashPrice: number | null;
    totalDownPayment: number | null;
    taxesAndFees: number;
    unpaidBalanceOfCashPrice: number | null;
    totalOtherCharges: number | null;
    amountFinanced: number | null;
    totalOptionalBenefits: number | null;
    monthlyPayment: number | null;
    lastUpdatedDate: Date | null;
};
export type ContractedDeliveryGroupStatus = "CONTRACT_CREATED" | "CONTRACT_FINALIZED" | "FUNDING_CONFIRMED";
export type DeliveryGroupStatus = ContractedDeliveryGroupStatus | "OPEN";
type ContractSummaryInfo = {
  deliveryGroupName: string;
  dealershipNumber: string;
  totalAmountFinanced: number;
  customerId: string;
  contractDate: string;
  firstPaymentDueDate: string;
  deliveryGroupStatus: ContractedDeliveryGroupStatus;
}
export type ContractSnapshot = {
  assetContracts: ReadAssetContract[];
  contractInfo: ContractSummaryInfo;
}
type PatchTypes =  Omit<WriteAssetContract, "appId" | "customerId">;

export type WriteAssetContractSectionsNames = {
  [K in keyof PatchTypes]: keyof PatchTypes;
}[keyof PatchTypes];

export type WriteAssetContractSections = {
  [K in keyof PatchTypes]: {
    [K2 in K]: PatchTypes[K2]
  }
}[keyof PatchTypes];

export function patchAssetContractFactory(appId: string, customerId: string, patch: WriteAssetContractSections){
  const [patchType, patchValue]= Object.entries(patch)[0]; //ugly but idk if theres a way for TS to know type should only have 1 key
  switch(patchType){
    case "vehicleDetails": {
      return writeVehicleToWriteAssetContract(appId, customerId, patchValue);
    }
    case "buyerInformation": {
      return writeBuyerInformationToWriteAssetContract(appId, customerId, patchValue);
    }
    case "coBuyerInformation": {
      return writeCoBuyerInformationToWriteAssetContract(appId, customerId, patchValue);
    }
    case "cashPrice": {
      return writeCashPriceToWriteAssetContract(appId, customerId, patchValue);
    }
    case "tradeInCashDown": {
      return writeTradeInCashDownToWriteAssetContract(appId, customerId, patchValue);
    }
    case "dealershipInformation": {
      return writeDealershipInformationToWriteAssetContract(appId, customerId, patchValue);
    }
    case "truthInLendingAct": {
      return writeTruthInLendingActToWriteAssetContract(appId, customerId, patchValue);
    }
    case "sellerGovernmentFees": {
      return writeSellerGoveAndFeesToWriteAssetContract(appId, customerId, patchValue);
    }
    case "auxProducts": {
      return writeAuxProductAssetContract(appId, customerId, patchValue);
    }
  }
}

function writeVehicleToWriteAssetContract(
  appId: string,
  customerId: string,
  vehicle: VehicleDetails
): WriteAssetContract {
  return {
    appId: appId,
    customerId: customerId,
    vehicleDetails: vehicle,
  };
}

function writeBuyerInformationToWriteAssetContract(
  appId: string,
  customerId: string,
  buyerInformation: BuyerInformation
): WriteAssetContract {
  return {
    appId: appId,
    customerId: customerId,
    buyerInformation: buyerInformation,
  };
}

export function writeCoBuyerInformationToWriteAssetContract(
  appId: string,
  customerId: string,
  coBuyerInformation: CoBuyerInformation
): WriteAssetContract {
  return {
    appId: appId,
    customerId: customerId,
    coBuyerInformation: coBuyerInformation,
  };
}

function writeSellerGoveAndFeesToWriteAssetContract(
  appId: string,
  customerId: string,
  sellerGovernmentFees: SellerGovernmentFees
): WriteAssetContract {

  return {
    appId: appId,
    customerId: customerId,
    sellerGovernmentFees: sellerGovernmentFees,
  };
}

function writeCashPriceToWriteAssetContract(
  appId: string,
  customerId: string,
  cashPrice: CashPrice
): WriteAssetContract {
  return {
    appId: appId,
    customerId: customerId,
    cashPrice: cashPrice,
  };
}

function writeTradeInCashDownToWriteAssetContract(
  appId: string,
  customerId: string,
  tradeInCashDown: TradeInCashDown
): WriteAssetContract {
  return {
    appId: appId,
    customerId: customerId,
    tradeInCashDown: tradeInCashDown,
  };
}

function writeAuxProductAssetContract(
  appId: string,
  customerId: string,
  auxProducts: any
): WriteAssetContract {

  const writeAuxProducts = auxProducts.auxProducts.map(formAuxProduct => {
    const cleanUpString = (string: string) => {
      if (!string || string === "") {
        return null;
      }

      string = string.trim();

      return string;
    }
    
    return {
      typeOfBenefit: cleanUpString(formAuxProduct.typeOfBenefit),
      company: cleanUpString(formAuxProduct.company),
      charge: isNullUndefinedOrEmpty(formAuxProduct.charge)
        ? null
        : Number((formAuxProduct.charge.toString()).replace(/[^0-9.-]+/g,"")),
      coverageMiles: !formAuxProduct.coverageMiles || formAuxProduct.coverageMiles === ""
        ? null
        : Number(formAuxProduct.coverageMiles.replace(/[^0-9.-]+/g,"")),
      coverageMonths: !formAuxProduct.coverageMonths || formAuxProduct.coverageMonths === ""
        ? null
        : Number(formAuxProduct.coverageMonths.replace(/[^0-9.-]+/g,"")),
    }
  });

  return {
    appId: appId,
    customerId: customerId,
    auxProducts: {
      auxProducts: writeAuxProducts,
      isComplete: auxProducts.isComplete
    }
  };
}

function writeDealershipInformationToWriteAssetContract(
  appId: string,
  customerId: string,
  dealershipInformation: DealershipInformation
): WriteAssetContract {
  for (const [key, value] of Object.entries(dealershipInformation)) {
    if(value === ""){
      dealershipInformation[key] = null;
    }
  }
  return {
    appId: appId,
    customerId: customerId,
    dealershipInformation: dealershipInformation,
  };
}

function writeTruthInLendingActToWriteAssetContract(
  appId: string,
  customerId: string,
  truthInLendingAct: TruthInLendingAct
): WriteAssetContract {
  return {
    appId: appId,
    customerId: customerId,
    truthInLendingAct: truthInLendingAct,
  }
}


export function assetContractDtoToModel(json: any): ReadAssetContract {
  if (!json) {
    throw new Error("Oh no! asset contract dto mapping failed");
  }
  return {
    id: json.applicationId,
    deliveryGroupId: json.deliveryGroupId,
    appId: json.applicationId,
    decisionDateTime: json.decisionDateTime ? new Date(json.decisionDateTime) : null,
    appDecision: json.appDecision,
    dealStatus: json.dealStatus,
    lastUpdatedDate: json.lastUpdated ? new Date(json.lastUpdated) : null,
    customerId: json.customerId,
    buyRate: json.buyRate,
    taxesAndFees: json.taxesAndFees,
    approvedFinanceAmount: json.approvedFinanceAmount,
    buyerInformation: json.buyerInformation,
    coBuyerInformation: json.coBuyerInformation,
    vehicleDetails: json.vehicleDetails,
    cashPrice: json.cashPrice,
    totalCashPrice: json.totalCashPrice,
    tradeInCashDown: json.tradeInCashDown,
    auxProducts: json.auxProducts,
    totalDownPayment: json.totalDownPayment,
    unpaidBalanceOfCashPrice: json.unpaidBalanceOfCashPrice,
    totalOtherCharges: json.totalOtherCharges,
    amountFinanced: json.amountFinanced,
    totalOptionalBenefits: json.totalOptionalBenefits,
    truthInLendingAct: json.truthInLendingAct,
    sellerGovernmentFees: json.sellerGovernmentFees,
    monthlyPayment: json.monthlyPayment,
    dealershipInformation: json.dealershipInformation,
  };
}

export function assetContractWriteModelToDto(
  assetContract: WriteAssetContract
): any {
  if (!assetContract) {
    throw new Error("Oh no! asset contract dto mapping failed");
  }
  return {
    applicationId: assetContract.appId,
    customerId: assetContract.customerId,
    buyerInformation: assetContract.buyerInformation,
    coBuyerInformation: assetContract.coBuyerInformation,
    vehicleDetails: assetContract.vehicleDetails,
    cashPrice: assetContract.cashPrice,
    tradeInCashDown: assetContract.tradeInCashDown,
    auxProducts: assetContract.auxProducts,
    truthInLendingAct: assetContract.truthInLendingAct,
    sellerGovernmentFees: assetContract.sellerGovernmentFees,
    dealershipInformation: assetContract.dealershipInformation
  };
}
function convertDateToTwoDigitString(date?: string | null | Date): string {
  if (!date) {
    return "";
  }

  return date.toLocaleString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
}
export function contractSummaryDtoToModel(json: any): ContractSnapshot {
  if (!json) {
    throw new Error("Oh no! asset contract dto mapping failed");
  }

  const ac = json.assetContracts.map(assetContractDtoToModel) as ReadAssetContract[];
  const totalAmountFinanced = ac.reduce((acc, curr) => acc += curr.amountFinanced, 0);
  const cInfo =  {
    deliveryGroupName: json.contractInfo.deliveryGroupName,
    dealershipNumber: json.contractInfo.dealershipNumber,
    totalAmountFinanced,
    customerId: json.contractInfo.customerId,
    contractDate: convertDateToTwoDigitString(json.contractInfo.contractDate),
    firstPaymentDueDate: convertDateToTwoDigitString(json.contractInfo.firstPaymentDueDate),
    deliveryGroupStatus: json.contractInfo.deliveryGroupStatus
  };

  return {
    assetContracts: ac,
    contractInfo: cInfo
  }
}
