import {useQuery} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { useContext, useState, useEffect } from "react";
import { TabContext, ContextType } from "../../components/Tab/TabContext";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { contractStatusKey } from "../../utils/react-query-key-factory";

type ContractStatus = {
  status: "PENDING" | "SUCCESS" | "ERROR";
  created_at: string;
};

async function getContractStatusFromBackend(get: <Result>(url:string) => Promise<Result|null>,
  dealershipNumber: string, deliveryGroupId: string): Promise<ContractStatus|null>{
  let contractEndpoint = `dealership/${dealershipNumber}/delivery-groups/${encodeURIComponent(deliveryGroupId)}/contract-status`;
  const res = await get<any>(contractEndpoint) as ContractStatus;
  return res;
}
export const noPendingTime = 300 * 1000;
export const pendingTime = 5 * 1000;
export function useGetContractStatus(dealershipNumber: string, deliveryGroupId?: string){

  const [isPending, setIsPending] = useState<boolean>(false);
  useEffect(() => {
    setIsPending(false);
  }, [deliveryGroupId])

  const {
    saveHasContractInitiallyTransitionedToSuccess, 
   } = useContext(TabContext) as ContextType;
  const {get} = useHttp();
  const analytics = useAnalytics();

  return useQuery(
    contractStatusKey(dealershipNumber, deliveryGroupId),
    () => getContractStatusFromBackend(get, dealershipNumber, deliveryGroupId),
    { 
      onError(error){
        analytics(emitErrorEvent, (error as any).message ??  "get contract status error", (error as any).status ?? 500);
      },
      onSuccess: (data) => {
        if(!isPending && data?.status === "PENDING"){
          setIsPending(true);
        }
        if(isPending && data?.status === "SUCCESS"){
          setIsPending(false);
          saveHasContractInitiallyTransitionedToSuccess(true);//up to user to determine when this is no longer true
        }
      },
      enabled: deliveryGroupId !== undefined
    }
  );
}
