import { atom, useAtom } from "jotai";
import { useCallback, useMemo } from "react";
import { TableCustomer } from "../../model/display/table-customer";

/*
expects data to be fully set. If you want to seperate preview and full data into seperated calls 
make a new hook accepting a callback to trigger full retrival
*/

//Jotai to persist through navigation via internal provider. Consider adding "by dealershipNumber" logic
const showAllAtom = atom(false);
export function usePreviewAndLoadAll(previewSize: number, data: any[] ){

  const [showAll, setShowAll] = useAtom(showAllAtom);
  const handleShowAll = useCallback(() => {
    setShowAll(true);
  }, [setShowAll]);

  const canShowAll = data.length > previewSize && !showAll;
  const totalRecordCount = data.length;
  const records = useMemo(() => showAll
    ? data
    : data.sort(function(a: TableCustomer, b: TableCustomer) {
      return new Date(b.last_activity!).getTime() - new Date(a.last_activity!).getTime();
    }).slice(0, previewSize), [data, previewSize, showAll]);
  let newRowIndex = canShowAll ? previewSize+1: 0;

  return {records, canShowAll, totalRecordCount, newRowIndex, handleShowAll};
}
