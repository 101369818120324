import { useContext, useEffect } from "react";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import { ContextType, TabContext } from "../TabContext";
import DeliveryGroupDropDown from "./DeliveryGroupHeaders/DeliveryGroupDropDown";
import { DGHeadingStyled, InitialContent, Outer, SecondaryContent } from "./FinalPageStyled";
import { Link } from "@ally/metronome-ui";
import { DownloadContractButton } from "./DownloadContractButton";
import { RecontractModal } from "../../Modal/RecontractModal";
import { emitPageViewEvent, PageViews } from "../../../analytics/pageViews";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";

type Props = {
    isActiveTab: boolean;
    hideRecontractForDG: string
};

const FinalPage: React.FC<Props> = ({ hideRecontractForDG }) => {
    const {
        saveActiveDeliveryGroupName,
        activeDeliveryGroupId
    } = useContext(TabContext) as ContextType;
    const recontractHidden = hideRecontractForDG !== activeDeliveryGroupId;
    const analytics = useAnalytics();

    useEffect(() => {
        analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_COMPLETE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Outer>
            <DGHeadingStyled
                tag="h2"
                size="md"
                tabIndex={-1}
            >
                Delivery groups
            </DGHeadingStyled>
            <DeliveryGroupDropDown type="ALL" />
            <ContractedDeliveryGroupDetailsNew
                updateDeliveryGroupName={saveActiveDeliveryGroupName}
                deliveryGroupStatus={"CONTRACT_CREATED"}
            />
            <DownloadContractButton />
            <InitialContent>
                <p>Thank you for using Ally Business Vehicle Financing Portal.</p>
                <p>If you have any questions, send an email to <Link
                    text="AllyCSG@ally.com"
                    href='mailto:AllyCSG@ally.com'
                    variant="link"
                    allytmln="email all document to allycsg@ally.com"
                /> or call the Ally Dealer </p>
                <p>Service Center at <Link
                    text="1-888-919-2559"
                    href="tel:1-888-919-2559"
                    variant="link"
                    className="number"
                />, Monday – Friday, 9:30 am – 6:30 pm ET and</p>
                <p>Saturday 9 am – 6 pm ET.</p>
            </InitialContent>
            <SecondaryContent>
                {recontractHidden &&
                    <>
                        <p>If you have any issues with your contract, you can recontract. You should only recontract</p>
                        <p>if you need to make changes, as it will revoke your current contract and restart the</p>
                        <p>signing process.</p>
                        <RecontractModal />
                    </>
                }
            </SecondaryContent>
        </Outer>
    )
};

export default FinalPage;
