import { Box, Button } from '@ally/metronome-ui'
import {
  CheckBox,
  Container,
  StyledTableWrapper,
} from "./VehicleDashboardStyles";
import { useCheckboxState } from '../../../../hooks/tableHooks/useCheckboxState';
import { usePreviewAndLoadAll } from '../../../../hooks/tableHooks/usePreviewAndLoadAll';
import { AdornmentProvider } from '@ally/metronome-ui/dist/cjs/FormText/TextInput/Adornment';
import { TableVehicle } from '../../../../model/display/table-vehicle';
import VehicleDrawer from "./VehicleDrawer";
import OptionsStickyNav, { VehicleMetadata } from './OptionsStickyNavs/OptionsStickyNav';
import React, { useContext, useEffect, useState } from 'react';
import { TabContext, ContextType } from '../../../Tab/TabContext';
import { ActiveTabs } from '../../../../constants/enums';
import { useAnalytics } from '../../../../hooks/analytics/useAnalytics';
import { emitClickEvent } from '../../../../analytics/clickEvents';
import { Table, TableColumn } from '../../../../library/Table';


type Props = {
  vehicles: TableVehicle[];
  canShowAll: boolean;
  handleShowAll: () => void;
  dataLoading: boolean;
  mockRemount: boolean;
  showCheckBox?: boolean;
  notifyCheckboxChange?: (newData: VehicleMetadata[]) => void;
  showStickyNav?: boolean;
  sortTable?: boolean;
};

const LoadMoreTable: React.FC<Props> = ({ sortTable, vehicles, canShowAll, dataLoading, handleShowAll, mockRemount, showCheckBox, showStickyNav, notifyCheckboxChange }) => {

  const analytics = useAnalytics();
  const noop = (_: any) => { return };
  const onCheckBoxChange = notifyCheckboxChange ?? noop;
  const { toggleCheckbox, isChecked, selected, unCheckedAll, isReady } = useCheckboxState(vehicles);

  useEffect(() => {
    if (mockRemount && isReady) {
      unCheckedAll();
    }
    //TODO address unCheckedAll/analytics missing in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mockRemount, isReady])

  function buildCheckbox(
    id: string,
    vehicle?: TableVehicle,
    position?: number,
    sortedRows?: any[]
  ): React.ReactNode | any {
    const getIndexPosition = (sortedArray: any[], index: number): number => {
      for (let i = 0; i < sortedArray.length; i++) {
        if (sortedArray[i].index === index) {
          return i;
        }
      }
      return -1;
    };

    let ariaLabel = "checkbox to select all vehicles";
    if (vehicle && position !== undefined && sortedRows !== undefined) {
      const tablePosition = getIndexPosition(sortedRows, position) + 1;
      ariaLabel = `Year, Make and Model:${vehicle.yearMakeModel}. ${tablePosition} of ${sortedRows.length} checkbox`;
    }

    const allytmln = id === "-1" ? "toggle-all-group-vehicles" : "toggle-single-group-vehicle";
    return (
      <CheckBox
        key={id}
        name={`select-vehicle${id}`}
        inputId={id}
        aria-label={ariaLabel}
        label={''}
        isChecked={selected[id]}
        onChange={(e: any) => {
          toggleCheckbox(e.target.id);
          analytics(emitClickEvent, {
            tagName: 'CheckBox',
            allytmln: allytmln,
            href: '#'
          });
        }}
      />
    );
  }

  function buildVehicleStatus(status: string): React.ReactNode | string {
    if (status.toLowerCase().trim().includes("ready for contract")) {
      return <p style={{ color: "#0C8100", fontWeight: "bold" }}>{status}</p>;
    }

    return <p style={{ color: "#2A2A2A", fontWeight: "400" }}>{status}</p>;
  }

  const vehicleByIdLookup = vehicles.reduce((accum, curr: TableVehicle) => {
    accum[curr.id] = curr;
    return accum;
  }, {} as any);

  function formatDate(dateString: string): React.ReactNode {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return (
      formattedDate
    );
  }

  const columns: TableColumn<TableVehicle>[] = [
    {
      Header: "Ally app ID",
      accessor: "appId",
      alignment: "left",
      isSortable: true,
      initialSort: !sortTable ? "asc" : undefined,
      Cell: ({
        cell,
        isDrawerOpen,
        toggleDrawer,
        drawerTriggerRef,
      }: any): React.ReactElement | null => {
        return (
          <>
            <Button
              variant="link"
              allytmln="vehicle-info-inside-group"
              text={`${cell.value}`}
              onClick={(): void => {
                toggleDrawer();
              }}
              ref={drawerTriggerRef}
              aria-expanded={isDrawerOpen ? "true" : "false"}
            />
          </>
        );
      },
    },
    {
      accessor: "dealStatus",
      Header: "Deal status",
      alignment: "left",
      isSortable: true,
      initialSort: sortTable ? "asc" : undefined,
      Cell: ({ cell: { value } }: { cell: any }): any =>
        buildVehicleStatus(value),
    },
    {
      accessor: "yearMakeModel",
      Header: "Year, make and model",
      alignment: "left",
      isSortable: true,
      initialSort: "asc",
    },
    {
      accessor: "vin",
      Header: "VIN",
      alignment: "left",
      isSortable: true,
      initialSort: "asc",
      Cell: ({ cell: { value } }: { cell: any }): any =>
        `${value ?? "-"}`,
    },
    {
      accessor: "appDecision",
      Header: "App decision",
      alignment: "left",
      isSortable: true,
      initialSort: "asc",
    },
    {
      accessor: "lastUpdatedDate",
      Header: "Last updated",
      alignment: "left",
      isSortable: true,
      Cell: ({ cell: { value } }: { cell: any }): any => formatDate(value),
      initialSort: "asc",
    },
  ];

  if (showCheckBox || showCheckBox === undefined) {
    columns.unshift(
      {
        accessor: "id",
        Header: buildCheckbox("-1"),
        alignment: "left",
        Cell: (s: any): any =>
          buildCheckbox(
            s.cell.value,
            vehicleByIdLookup[s.cell.value],
            parseInt(s.row.id),
            s.sortedRows
          ),
      },
    )
  }

  const checkedVehicles: VehicleMetadata[] = vehicles.filter((v) => isChecked(v.id))
    .map(vehicle => {
      return {
        id: vehicle.id,
        customerId: vehicle.customerId,
        isReadyForContract: vehicle.isReadyForContract,
        yearMakeModel: vehicle.yearMakeModel
      }
    });
  onCheckBoxChange(checkedVehicles);

  let hasValidCloneTargets = false;
  const readyForContract = checkedVehicles.filter(v => v.isReadyForContract);
  const readyForContractCnt = readyForContract.length;

  if (readyForContractCnt === 1 && checkedVehicles.length === 1) {
    const canidateSrc = readyForContract[0];
    const canidateTgts = vehicles.filter(v => {
      return (v.id !== canidateSrc.id &&
        v.yearMakeModel === canidateSrc.yearMakeModel)
    });
    hasValidCloneTargets = canidateTgts.length > 0;
  }

  const handleCancelClick = (arg: string) => {
    unCheckedAll();
  };

  return (
    <Container>
      <StyledTableWrapper>
        <Table
          data={vehicles}
          columns={columns}
          drawer={<VehicleDrawer />}
          container
          captionText={"Vehicles for given customer"}
          isLoading={dataLoading}
          isCaptionVisible={false}
          suppressMdDownLayout={true}
        />
        {canShowAll && (vehicles.length > 0) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="xs"
            borderBottom="1px solid"
            borderColor="grey-80"
            paddingBottom="20px"
            marginTop="14px"
            backgroundColor="#FCFCFC"
          >
            <Button
              variant="link"
              aria-label="view all"
              allytmln="view-all-vehicles"
              text="View All"
              onClick={handleShowAll}
              id="view-more-button"
            />
          </Box>
        )}
        {!vehicles?.length && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="xs"
          >
          </Box>
        )}
      </StyledTableWrapper>
      {checkedVehicles.length >= 1 && showStickyNav && (
        <OptionsStickyNav
          comingFrom="VehicleDashboardDeliveryGroup"
          hasValidCloneTargets={hasValidCloneTargets}
          selectedVehicles={checkedVehicles}
          handleCancel={handleCancelClick}
        />
      )}
    </Container>
  );
};

type VehicleDashboardDeliveryGroupProps = {
  isVehiclesError: boolean;
  isVehiclesSuccess: boolean;
  isVehiclesLoading: boolean;
  vehicles: TableVehicle[];
  vehiclesError: any;
  showCheckBox?: boolean;
  notifyCheckboxChange?: (newData: VehicleMetadata[]) => void;
  showStickyNav?: boolean;
  sortTable?: boolean;
}

export const VehicleDashboardDeliveryGroup: React.FC<VehicleDashboardDeliveryGroupProps> = (
  { sortTable, isVehiclesError: isVehicleError, isVehiclesSuccess: isVehicleSuccess, isVehiclesLoading, vehicles, vehiclesError: vehicleError, showCheckBox, showStickyNav, notifyCheckboxChange }) => {

  const tab = ActiveTabs.DELIVERY_GROUPS;
  const { records, canShowAll, handleShowAll } = usePreviewAndLoadAll(10, isVehicleSuccess ? vehicles : []);

  const [shouldClearTab, setShouldClearTab] = useState(true);
  const {
    selectionGroup,
  } = useContext(TabContext) as ContextType;

  const currtab = selectionGroup.destinationTab;
  useEffect(() => {
    if (tab !== currtab && shouldClearTab) {
      setShouldClearTab(false);
    }
    else if (tab === currtab && !shouldClearTab) {
      setShouldClearTab(true);
    }
  }, [currtab, shouldClearTab, tab]);

  const handleErrorVehicleFetch = (error: any) => {
    return <p>{error}</p>;
  };

  const handleLoadingVehicleFetch = () => {
    return <p>....</p>;
  };

  if (isVehicleSuccess || isVehiclesLoading) {
    return (
      <AdornmentProvider>
        <LoadMoreTable
          vehicles={records}
          canShowAll={canShowAll}
          handleShowAll={handleShowAll}
          dataLoading={isVehiclesLoading}
          mockRemount={shouldClearTab}
          showCheckBox={showCheckBox}
          notifyCheckboxChange={notifyCheckboxChange}
          showStickyNav={showStickyNav}
          sortTable={sortTable}
        />
      </AdornmentProvider>
    );
  } else if (isVehicleError) {
    return <div>{handleErrorVehicleFetch(vehicleError)}</div>;
  } else {
    return <div> {handleLoadingVehicleFetch()} </div>;
  }

};
