import { useEffect } from "react";
import { useAnalytics } from "./useAnalytics";
import { useErrorDeduper } from "./useErrorDeduper";
import { emitAssetContractError } from "../../analytics/assetContractErrors";

export function useDeliveryGroupFormErrors(errors: Record<string, any>) {
  const analytics = useAnalytics();
  const {getUnseenErrors, resetErrorRecords} = useErrorDeduper();
  const joinedErrorValues = Object.values(errors).join(",");
  useEffect(() => {
    const errorValues = Object.values(errors);
    const newErrors = getUnseenErrors(errorValues);
    const aggregatedErrors = newErrors;

    analytics(emitAssetContractError, aggregatedErrors);
    
    return () => {
      resetErrorRecords();
      getUnseenErrors(errorValues);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedErrorValues]); 
}
