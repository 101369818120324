import {useMutation} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { BUYER_INFO_MUTATION_KEY_ROOT } from "../../utils/react-query-key-factory";

async function putAddBuyerInfo(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string, applicationId: string): Promise<void|null>{
  const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/use-buyer-info/${encodeURIComponent(applicationId)}`;
  
  return put<any, void>(endpoint, {});
}

export type UseInfo = {
  deliveryGroupId: string;
  applicationId: string;
  customerId: string;
}

export function useAddBuyerInfo(dealershipNumber: string) {
  const { put } = useHttp();
  
  return useMutation(
    [BUYER_INFO_MUTATION_KEY_ROOT],
    async (useInfo: UseInfo) => await putAddBuyerInfo(put, dealershipNumber, useInfo.deliveryGroupId, useInfo.applicationId));
}
