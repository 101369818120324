import React, { useMemo } from "react";
import styled from "styled-components";
import { useSteppedProcess } from "../context";
import { StepSummaryProps } from "../types";
import { Button, Icon, TextBody } from "@ally/metronome-ui";

const StyledSummarySection = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const SummaryListItem = styled.li`
  ${({ theme: { space } }): string => `
    display: flex;
    align-items: center;
    text-transform: capitalize;
    margin-bottom: ${space.sm};
  `}
`;

const SummaryListItemText = styled(TextBody)`
  ${({ theme: { colors } }): string => `
    color: ${colors["gray-700"]};
  `}
`;

const StepSummary: React.FC<StepSummaryProps> = ({ stepIndex, step }) => {
  const { isStepActive, stepState, setActiveStep } = useSteppedProcess();
  const { summary, unsupportedSummary: UnsupportedSummary } = step;
  const isActive = !!isStepActive(stepIndex);
  const isComplete = stepState(stepIndex) === "complete";
  const summaryItemsLength = summary?.items.length || 0;
  const isEditButtonHidden = summary?.editStepButton?.isHidden;

  const isCompleteAndNotActive = isComplete && !isActive;

  const showSummaryList = useMemo(() => {
    return !!summaryItemsLength && isCompleteAndNotActive;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryItemsLength, isActive, isComplete, isCompleteAndNotActive]);

  const showEditButton = useMemo(() => {
    return !isEditButtonHidden && isCompleteAndNotActive;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditButtonHidden, isActive, isComplete, isCompleteAndNotActive]);

  if (UnsupportedSummary && isCompleteAndNotActive) {
    return <UnsupportedSummary stepIndex={stepIndex} step={step} />;
  }

  if (showSummaryList || showEditButton) {
    return (
      <StyledSummarySection>
        {showSummaryList && (
          <SummaryList>
            {summary?.items.map((summaryItem) => (
              <SummaryListItem
                key={summaryItem.text}
                aria-label={summaryItem.ariaLabel}
              >
                {summaryItem.icon && (
                  <Icon
                    icon={summaryItem.icon}
                    size="lg"
                    fill="periwinkle-500"
                    mr="xxs"
                    ariaHidden
                  />
                )}
                <SummaryListItemText
                  tag="span"
                  size="sm"
                  tabIndex={0}
                >
                  {summaryItem.text}
                </SummaryListItemText>
              </SummaryListItem>
            ))}
          </SummaryList>
        )}
        {showEditButton && (
          <Button
            variant="link"
            allytmln={`edit-${step.id}`}
            ariaLabel={`Edit ${step.title.text}`}
            text={summary?.editStepButton?.customText || "Edit"}
            onClick={(): void => setActiveStep(stepIndex)}
            mb="xxs"
          />
        )}
      </StyledSummarySection>
    );
  }

  return null;
};

export { StepSummary };
