import { Label, Select } from "@ally/metronome-ui";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { States } from "../../../constants/enums";
import { listOfStates } from "../../../constants/state";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import { useMutateAssetContract } from "../../../hooks/assetContractHooks/useMutateAssetContract";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import {
  ReadAssetContract,
  WriteAssetContract,
  WriteAssetContractSections,
  WriteAssetContractSectionsNames,
  writeCoBuyerInformationToWriteAssetContract,
} from "../../../model/asset-contract.model";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { cobuyerInformationConfig } from "../../../validation-config/asset-contracts/cobuyer-information";
import { getAllFieldsForStateInSection } from "../../../validation-config/field-config-utils";
import CobuyerAlerts, { CobuyerAlertType } from "../Form-Components/CobuyerAlerts";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import { BasicTextField, EmailField, ZipcodeField } from "../Form-Components/TextFields";
import {
  BoxStyle,
  LabelStyle,
  StyledSelect,
  SubHeader,
} from "./CobuyerInformationStyles";

type ValidationMsg = { parameter: string; message: string };

type Props = {
  customerId: any;
  dealershipNumber: any;
  assetContract: ReadAssetContract | WriteAssetContract;
  deliveryGroup?: ReadDeliveryGroup | null;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames) => void;
};

type FormValues = {
  type: string | null;
  name: string | null;
  address: string | null;
  streetAddress: string | null;
  city: string | null;
  county: string | null;
  state: States | null;
  zip: string | null;
  email: string | null;
  monthOfBirth: number | null;
  isComplete: boolean | null;
};

const CobuyerInformation: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const editAssetContract = useMutateAssetContract(dealershipNumber);
  const [dealershipInfo] = useDealershipInfo();
  const [cobuyerAlert, setCobuyerAlert] = useState<CobuyerAlertType>("warning");
  //initialClick and isPending states used for buttonCallStatus
  //eslint-disable-next-line
  const [initialClick, setInitialClick] = useState<string | null>(null);
  //eslint-disable-next-line
  const [isPending, setIsPending] = useState(false);
  const [saveType, setSaveType] = useState(null);

  const fieldConfigurations = getAllFieldsForStateInSection(
    cobuyerInformationConfig,
    dealershipInfo.dealershipState
  );


  const requiredFields = fieldConfigurations.requiredFields;

  const {
    setNextStepActive,
    setIsStepPending,
    setPrevStepActive
  } = useSteppedProcess();

  const saveVehicle = useSaveVehicleForm(
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  const cobuyerInformation = assetContract.coBuyerInformation;

  const form = useForm<FormValues>({
    defaultValues: {
      type: cobuyerInformation?.type,
      name: cobuyerInformation?.name,
      address: cobuyerInformation?.streetAddress,
      streetAddress: cobuyerInformation?.streetAddress,
      city: cobuyerInformation?.city,
      county: cobuyerInformation?.county,
      state: cobuyerInformation?.state,
      zip: cobuyerInformation?.zip,
      email: cobuyerInformation?.email,
      monthOfBirth: cobuyerInformation?.monthOfBirth,
      isComplete: cobuyerInformation?.isComplete ?? false,
    }
  });

  const { control, handleSubmit, formState } = form;
  const { errors } = formState;
  const markAsCompleteRef = useRef(false);


  useEditVehicleFormErrors("Section 2", errors);

  const stateOptions = listOfStates.map((state) => {
    return { label: state.label, value: state.label };
  });

  const onBlurHandle = (e) => { };

  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    submitData(data, updateDataToBackend, markedAsComplete)
  };

  const submitData = (data: FormValues, submitAction: (data: FormValues, markedAsComplete?: boolean) => void, markedAsComplete?: boolean) => {

    let actionType = saveType === "SaveAndContinue" ? "SavedAndContinue" : "MarkAsComplete";
    setInitialClick(actionType);

    Object.keys(data).forEach((key) => {
      const v = data[key];
      if (key === 'monthOfBirth') {
        data[key] = Number(v);
      }

      if (typeof v === "string" && isNullUndefinedOrEmpty(data[key])) {
        data[key] = null;
      } else if (typeof v === "number") {
        const numVal = Number(v);
        data[key] = (isNaN(numVal)) ? null : numVal;
      }
    });

    // check for no co-buyer clean data from form
    let cleandata = data;
    if (!cobuyerInformation?.name) {
      cleandata.state = null;
      cleandata.zip = null;
      cleandata.address = null;
      cleandata.streetAddress = null;
      cleandata.county = null;
      cleandata.city = null;
      cleandata.monthOfBirth = null;
    }

    if (cleandata.monthOfBirth === 0) {
      cleandata.monthOfBirth = null;
    }

    submitAction(cleandata, markedAsComplete);
  };

  const onError = (errs: ValidationMsg[]) => {
    setIsPending(false);
    setIsStepPending(false);
    setSaveType(null);

    if (saveType === "SaveAndContinue") {
      setCobuyerAlert("warning");
      setNextStepActive("SavedAndContinue");
      return;
    }

    errs.forEach((e) => {
      if (e.parameter === "cobuyerMismatch") {
        /* Check if it has a co-buyer */
        if (!!cobuyerInformation?.name) {
          setCobuyerAlert("with-cobuyer-error");
        } else {
          setCobuyerAlert("no-cobuyer-error");
        }
      }
    });
  };

  const updateDataToBackend = (data: FormValues, markedAsComplete?: boolean) => {
    setIsPending(true)

    const patch = {
      coBuyerInformation: {
        ...data,
        monthOfBirth: Number(data.monthOfBirth) === 0 ? null : Number(data.monthOfBirth),
        isComplete: markedAsComplete
      }
    };

    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";

    const onSuccess = () => {
      updateAssetContractState(patch, 'coBuyerInformation');
      setIsPending(false);
      setIsStepPending(false);
      setSaveType(null);
      setNextStepActive(saveType);
      setCobuyerAlert("warning");
    };

    const ac = writeCoBuyerInformationToWriteAssetContract(
      assetContract.appId,
      customerId,
      {
        ...data,
        isComplete: markedAsComplete,
      }
    );

    ac.dealershipInformation = assetContract.dealershipInformation;

    editAssetContract.mutate({
      assetContract: ac,
      onSuccess,
      onError,
      deliveryGroupId: deliveryGroup?.id,
    });
  };

  const buildErrorMsg = (fieldName: string): string => {
    return `Add ${fieldName} to continue.`
  }

  const options = [
    { value: "CO_BUYER", label: "Co-Buyer" },
    { value: "GUARANTOR", label: "Guarantor" },
  ];

  const coBuyerContent = (
    <>
      Make sure this information is correct. Once you select a co-buyer or
      guarantor, you can select <b>Mark as Complete.</b> We&#39;ll include the
      co-buyer or guarantor&#39;s information in your contract.
    </>
  );
  const noCoBuyerContent = (
    <>
      You don&#39;t have a co-buyer or guarantor listed on your application. If
      that&#39;s correct, you can select <b>Mark as Complete.</b> If you want to
      add a co-buyer or guarantor, you&#39;ll need to update and re-submit your
      application.
    </>
  );

  return (
    <>
      <CobuyerAlerts alertType={cobuyerAlert} />
      {
        !cobuyerInformation?.name
          ? (<SubHeader tabIndex={0}>{noCoBuyerContent}</SubHeader>)
          : (<SubHeader tabIndex={0}>{coBuyerContent}</SubHeader>)
      }
      <>
        <form allytm-form-tagged="true"
          onSubmit={(e) => {
            markAsCompleteRef.current = false;
            return handleSubmit((data) => onSubmitData(data))(e);
          }}
        >
          <BoxStyle p={["sm", "md"]} height="100%">

            {!!cobuyerInformation?.name
              ? (
                <>
                  <Controller
                    control={control}
                    name="type"
                    rules={{
                      validate: (value) => {
                        if (markAsCompleteRef.current && isNullUndefinedOrEmpty(value)) {
                          return "Add type to continue";
                        }
                      }
                    }}
                    render={({
                      field: { value, onChange }
                    }) => {
                      return (
                        <StyledSelect>
                          <Label htmlFor="type">
                            <LabelStyle>Co-buyer or Guarantor</LabelStyle>
                            <Select
                              id="type"
                              name="type"
                              options={options}
                              placeholder="Select"
                              value={value ?? ""}
                              onChange={onChange}
                              error={errors.type?.message}
                            />
                          </Label>
                        </StyledSelect>
                      );
                    }}
                  />
                  <div data-private>
                    <BasicTextField
                      control={control} errors={errors} requiredRef={markAsCompleteRef} name="name"
                      labelContent="Name" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("name")}
                    />
                  </div>
                  <div data-private>
                    <BasicTextField
                      control={control} errors={errors} requiredRef={markAsCompleteRef} name="streetAddress"
                      labelContent="Address" onBlurHandle={onBlurHandle} minCharCount={5} customErrorMsg={buildErrorMsg("address")}
                    />
                  </div>
                  <div data-private>
                    <BasicTextField
                      control={control} errors={errors} requiredRef={markAsCompleteRef} name="city"
                      labelContent="City" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("city")}
                    />
                  </div>
                  <div data-private>
                    <BasicTextField
                      control={control} errors={errors} requiredRef={markAsCompleteRef} name="county"
                      labelContent="County" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("county")}
                    />
                  </div>
                  <Controller
                    control={control}
                    name="state"
                    rules={{
                      validate: (value) => {
                        if (markAsCompleteRef.current && isNullUndefinedOrEmpty(value)) {
                          return "Add cobuyer state to continue.";
                        }
                      }
                    }}
                    render={({
                      field: { value, onChange }
                    }) => {
                      return (
                        <StyledSelect>
                          <Label htmlFor="state">
                            <LabelStyle>State</LabelStyle>
                            <Select
                              id="state"
                              name="state"
                              options={stateOptions}
                              placeholder="Select"
                              value={value ?? ""}
                              onChange={onChange}
                              error={errors.state?.message}
                            />
                          </Label>
                        </StyledSelect>
                      );
                    }}
                  />
                  <div data-private>
                    <ZipcodeField
                      control={control} errors={errors} requiredRef={markAsCompleteRef} name="zip"
                      labelContent="ZIP" onBlurHandle={onBlurHandle} minCharCount={5}
                    />
                  </div>
                  <div data-private>
                    <EmailField
                      control={control} requiredRef={markAsCompleteRef} errors={errors} name="email"
                      labelContent="Co-buyer email address" onBlurHandle={onBlurHandle} minCharCount={5}
                    />
                  </div>

                  {!!requiredFields.includes("monthOfBirth") && (
                    <Controller
                      control={control}
                      name="monthOfBirth"
                      rules={{
                        validate: (value) => {
                          if (markAsCompleteRef.current && requiredFields.includes("monthOfBirth") && isNullUndefinedOrEmpty(value)) {
                            return "Add buyer month of birth to continue.";
                          }
                        }
                      }}
                      render={({
                        field: { value, onChange }
                      }) => {
                        return (
                          <StyledSelect className="mob_select">
                            <Label htmlFor="monthOfBirth">
                              <LabelStyle>Month of birth</LabelStyle>
                              <div data-private>
                                <Select
                                  id="monthOfBirth"
                                  name="monthOfBirth"
                                  options={[
                                    { label: 1, value: 1 },
                                    { label: 2, value: 2 },
                                    { label: 3, value: 3 },
                                    { label: 4, value: 4 },
                                    { label: 5, value: 5 },
                                    { label: 6, value: 6 },
                                    { label: 7, value: 7 },
                                    { label: 8, value: 8 },
                                    { label: 9, value: 9 },
                                    { label: 10, value: 10 },
                                    { label: 11, value: 11 },
                                    { label: 12, value: 12 },
                                  ] as any}
                                  placeholder="Select"
                                  value={value ?? null}
                                  onChange={onChange}
                                  error={errors.monthOfBirth?.message}
                                />
                              </div>
                            </Label>
                          </StyledSelect>
                        );
                      }}
                    />
                  )}
                  <NonTerminalFormButtons
                    setPrevStepActive={setPrevStepActive}

                    buttonCallStatus={
                      {
                        isPending: saveVehicle.isPending,
                        actionType: saveVehicle.saveType
                      }
                    }

                    sectionNameForAnalytics="cobuyer-information"
                    isComplete={cobuyerInformation.isComplete}
                    markAsComplete={(e) => {
                      markAsCompleteRef.current = true;
                      return handleSubmit((data) =>
                        onSubmitData(data, true)
                      )(e);
                    }}
                  />

                </>
              )
              : (
                <NonTerminalFormButtons
                  setPrevStepActive={setPrevStepActive}
                  buttonCallStatus={
                    {
                      isPending: saveVehicle.isPending,
                      actionType: saveVehicle.saveType
                    }
                  }

                  sectionNameForAnalytics="cobuyer-information"
                  isComplete={true}
                  markAsComplete={(e) => {
                    markAsCompleteRef.current = true;
                    return handleSubmit((data) =>
                      onSubmitData(data, true)
                    )(e);
                  }}
                />
              )
            }
          </BoxStyle>
        </form>
      </>
    </>
  );
};

export default CobuyerInformation;
