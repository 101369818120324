import {useQuery} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { ReadDeliveryGroup, deliveryGroupDtoToModel } from "../../model/delivery-group.model";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";
import { useHistory } from "react-router";

export function useGetDeliveryGroup(dealershipNumber: string, deliveryGroupId?: string){
  const {get} = useHttp();
  const analytics = useAnalytics();
  const history = useHistory();

  async function getDeliveryGroupFromBackend(get:<Result>(url:string) => Promise<Result|null>,
  dealershipNumber: string, deliveryGroupId?: string): Promise<ReadDeliveryGroup|null>{
  if(! deliveryGroupId){
    return null;
  }

  try {
    let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}`;
    const res = await get<any>(dgEndpoint);
    return deliveryGroupDtoToModel(res);
  } catch(err) {
    const errorString = err +'';
    if(errorString.includes('404') || errorString.includes('400')) {
      history.push('/not-found');
    } else {
      console.error("Oops something went wrong. Please try again later ", err);
    }
  }

}

  return useQuery(
    singleDeliveryGroupsKey(dealershipNumber, deliveryGroupId),
    () => getDeliveryGroupFromBackend(get, dealershipNumber, deliveryGroupId),
    {  
      onError(error){
        analytics(emitErrorEvent, (error as any).message ?? "get delivery group error", (error as any).status ?? 500);
      },
      enabled: deliveryGroupId !== undefined
    }
  );
}
