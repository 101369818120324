import { Button, Modal } from "@ally/metronome-ui";
import styled from "styled-components";

export const CloneDetailsModalStyle= styled(Modal)`
  height: 244px;
  width: 495px;
`;

export const CloneModalStyle = styled(Modal)`
  width: 495px;
`;
export const ConfirmCloneModalStyle = styled(Modal)`
  width: 525px;
`

export const ListedItemStyle = styled.body`
  padding-top: 16px;
  padding-left: 50px;
`;

export const CancelButtonStyle = styled(Button)`
  min-width: 97px;
  margin-left: 16px;
`;

export const GotItButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`; 

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

export const ButtonStyle = styled(Button)`
  height: 44px;
  width: 115px;
`;

export const ButtonLinkStyle = styled(Button)`
  height: 44px;
  width: 115px;
  background-color: #0071C4;
  border-radius: 4px;

  span {
    color: white;
    margin: auto;
    font-size: 16px;

    svg {
      fill: white;
    }
  }
`;
