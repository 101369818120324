import { useContext, useState } from "react";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { ModalStyled, OpenDocuSignButton } from "./SigningOptionsModalsStyled"
import { TabContext, ContextType } from "../Tab/TabContext";
import { useParams } from "react-router";
import { Button, ButtonGroup, TextBody, TextHeading, useModalContext } from "@ally/metronome-ui";
import { useGetEnvelopeStatus } from "../../hooks/docusignHooks/useGetEnvelopeStatus";

const LeavingAllyModalTrigger: React.FC = () => {
    const { setOpen } = useModalContext();
    return (
        <OpenDocuSignButton
            aria-label="Open DocuSign"
            text="Open DocuSign"
            variant="primary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
        />

    )
}

const ModalContent: React.FC = () => {
    const [dealershipInfo] = useDealershipInfo();
    const { dealershipNumber } = dealershipInfo || {};
    const { id: customerId } = useParams() as any;
    const {
        activeDeliveryGroupId,
    } = useContext(TabContext) as ContextType;
    const { setOpen } = useModalContext();

    const {
        data: envelopeStatuses
    } = useGetEnvelopeStatus(dealershipNumber, customerId, activeDeliveryGroupId);
    const [isDocusignLoading, setIsDocuSignLoading] = useState(false);

    const openDocuSign = () => {
        const docusignUrl = envelopeStatuses?.docusignUrl;
        window.location.href = (docusignUrl);
        setIsDocuSignLoading(true);
    }
    return (<>
        <TextHeading size="sm" tag="h3" tabIndex={-1}>Important Notice</TextHeading>
        <TextBody size="sm" tag="p">
            <b>You’re leaving Ally Business Vehicle Financing Portal and heading to a third-party site we don’t own or control. </b>
        </TextBody>
        <TextBody size="sm" tag="p" className="TextLine2">
            FYI: That site has its own privacy policy and level of security. We suggest reviewing those policies and any terms and conditions on that site.
        </TextBody>
        <ButtonGroup>
            <Button
                aria-label="Next"
                text="Next"
                variant="primary"
                onClick={() => openDocuSign()}
                loading={isDocusignLoading}
            />
            <Button
                aria-label="Close"
                text="Close"
                variant="link"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(false)}
            />
        </ButtonGroup>
    </>)
}

export const LeavingAllyModal = () => {
    return (
        <ModalStyled
            trigger={<LeavingAllyModalTrigger />}
            content={
                <ModalContent />
            }
            disableFullScreenSmDown={true}
        />
    )
}
