import { Row } from 'react-table'
import { PublicRowStateProps } from '../types'

/**
 * Each react-table `row` has a `state` property and a `setState` method for
 * setting it. This function returns corresponding members that get and set the
 * `publicRowState` key from `row.state`.
 */
export const getPublicRowStateProps = <
  D extends Record<string, any> = Record<string, any>,
>(
  row: Row<D>,
): PublicRowStateProps => ({
  rowState: row.state.publicRowState,
  setRowState: (updater): void => {
    row.setState(({ publicRowState: current, ...rest }) => ({
      ...rest,
      publicRowState:
        typeof updater === 'function' ? updater(current) : updater,
    }))
  },
})
