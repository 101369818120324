import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { TableCardListProps } from '.'
import { TextBodyDEPRECATED, get } from '@ally/metronome-ui'
// import { TextBodyDEPRECATED } from '../../TypographyDEPRECATED'
// import { get } from '../../tools/utils'

const StyledTextBody = styled(TextBodyDEPRECATED)<{
  isCaptionVisible: boolean
}>`
  text-align: center;

  ${(props): string =>
    !props.isCaptionVisible ? get.screenReader('srOnly')(props) : ''}
`

interface CaptionProps<D extends Record<string, any>>
  extends Required<
    Pick<TableCardListProps<D>, 'captionText' | 'isCaptionVisible'>
  > {
  tableId: string
}

const Caption = <D extends Record<string, any>>({
  captionText,
  isCaptionVisible,
  tableId,
}: CaptionProps<D>): ReactElement => (
  <StyledTextBody
    id={tableId}
    tag="p"
    size="sm"
    isCaptionVisible={isCaptionVisible}
  >
    {captionText}
  </StyledTextBody>
)

export default Caption
