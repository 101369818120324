import React from "react";
import {
  Container,
  FooterNav,
  FooterNavItem,
  Copyright,
  FooterNavLink,
} from "./FooterStyled";
import { useLocation } from "react-router";

type childProps = {
  modal?: string;
}

const Footer: React.FC<childProps> = (props: childProps) => {

  const { pathname } = useLocation();
  if(pathname.match("^\\/dealership\\/?[0-9]+\\/customer\\/?[0-9]+\\/delivery-group\\/?[0-9]+\\/summary"))
  {
    return (
      <>
      </>
    )
  }

  return (
    <>
      <Container role="contentinfo">
        <FooterNav>
          <FooterNavItem>
            <FooterNavLink href="" id="feedbackLink" target="_self" rel="noreferrer noopener">
              Feedback
            </FooterNavLink>
          </FooterNavItem>
          <FooterNavItem>
            <FooterNavLink href="http://www.ally.com/do-it-right/car/" id="allyCommunityLink"  target="_self" rel="noreferrer noopener">
              Ally Community
            </FooterNavLink>
          </FooterNavItem>
          <FooterNavItem>
            <FooterNavLink href="https://www.ally.com/privacy/" id="privacyLink"  target="_self" rel="noreferrer noopener">
              Privacy
            </FooterNavLink>
          </FooterNavItem>
          <FooterNavItem>
            <FooterNavLink href="https://www.ally.com/security/" id="securityLink"  target="_self" rel="noreferrer noopener">
              Security
            </FooterNavLink>
          </FooterNavItem>
          <FooterNavItem>
            <FooterNavLink href="https://www.ally.com/legal/" id="legalLink"  target="_self" rel="noreferrer noopener">
              Legal
            </FooterNavLink>
          </FooterNavItem>
        </FooterNav>
        <Copyright>
          ©2009-{new Date().getFullYear()} Ally Financial Inc.
        </Copyright>
      </Container>
    </>
  );
};

export default Footer;
