import React, { useEffect } from "react";
import { MainHeader } from "./NoMatchPageStyles";
import { atom, useAtom } from "jotai";

export const noMatchRoute = atom(false);

const NoMatchPage: React.FC = () => {
  const [, setState ] = useAtom(noMatchRoute);


  useEffect(() => {
        setState(true);
    return () => {
      setState(false);
    }
  });

  return (
    <MainHeader>
      <h1>We can't find this page.</h1>
      <p>No worries — we can still get you where you need to go.</p>
      <p>
        Our <a href="/">homepage</a> is a good place to start.
      </p>
    </MainHeader>
  );
};

export default NoMatchPage;
