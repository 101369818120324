import { Box, Button, ButtonGroup, FormText, Icon } from "@ally/metronome-ui";
import styled from "styled-components";

interface ButtonProps {
  itemProp: string;
}
export const StyledButtonGroup = styled(ButtonGroup)`

  margin-bottom: 35px;
  margin-top: 24px;
`;

export const RemovedIcon = styled(Icon)`
  padding-top: 0px;
  margin-left: 24px;
  margin-top: 1px;


  path {
    fill: #0071C4;
  }

`;

export const ProductWrapper = styled.div`
  margin-bottom: 20px;
  padding-bottom: 5px;
`;

export const IconContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: -2px;
`;

export const WarningParagraph = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
`;


export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: #000000;
  max-width: 900px;
  max-height: 40px;
  margin-bottom: 8px
`;

export const BoxStyle = styled(Box)`
  padding: 0;
  label {
    margin-top: 21px;
  }
`;

export const AddProductButton = styled(Button)<ButtonProps>`
  visibility: ${(props) => props.itemProp};
  margin-top: 10px;

 span {
  font-size: 16px;
  line-height: 24px;

 }
`
export const OtherFeeWrapper = styled.div`
  margin-top: 27px;
  margin-bottom: 20px;
  color: #2a2a2a;

  h4 {
    font-size: 16px;
    line-height: 16px;
    margin-right: 20px;
  }
  .other_fee {
    border-left: 1px solid #8391af;
    padding-left: 20px;
  }
`;

export const OtherFeeFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-top: 17px;
  padding-left: 20px;

  label {
    width: 91px;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
  }
`;

export const FeeDescriptionStyle = styled.div`
  margin-top: 17px;
  h4 {
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
    margin-top: 6px;
  }
  h5 {
    margin-left: 20px;
    font-size: 12px;
    line-height: 16px;
  }
  div {
    display: flex;
    max-width: 321px;
    justify-content: space-between;

    Button {
      color: #0071c4;
      background-color: #fcfcfc;
      font-size: 14px;
      line-height: 20px;
      justify-content: flex-end;
      padding-right: 0;
      margin-top: 6px;
    }
  }
`;

export const CoverageWrapper = styled.div`
  margin-top: 27px;
  color: #2a2a2a;

  h4 {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  }
  .coverage_fee {
    border-left: 1px solid #8391af;
    padding-left: 20px;
    margin-top 20px; 
  }
`;