import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Row, TableInstance } from 'react-table'
import { CustomColumnOptions } from '../types'
import { LgUpTableProps } from '.'
import Drawer from './Drawer'
import { trStyles, tdStyles } from './shared'
import {
  CellDrawerProps,
  getPublicRowStateProps,
  TableCellHookValues,
  TableCellProvider,
  useDrawer,
} from '../utils'
import { useFocus } from '@ally/metronome-ui'

interface TableRowProps<D extends Record<string, any>>
  extends Pick<
    LgUpTableProps<D>,
    'rowClickHandler' | 'selectRow' | 'withRowHeadings' | 'drawer'
  > {
  row: Row<D>
  index: number
  tableInstance: TableInstance<D>
  rowToFocus?: number
}

type StyledCellProps = Pick<CustomColumnOptions, 'alignment'>

const StyledTr = styled.tr`
  ${trStyles}

  td:nth-child(n + 2) {
    text-align: right;
  }
`

// increased specificity is used to override the nth-child selector above
const StyledTd = styled.td<StyledCellProps>`
  ${tdStyles}

  ${({ alignment }): string =>
    alignment ? `&&& { text-align: ${alignment} }` : ''}
`

const StyledTh = styled.th<StyledCellProps>`
  ${tdStyles}
  font-weight: normal;
  text-align: ${({ alignment }): string => alignment || 'left'};
`

const TableRow = <D extends Record<string, any>>({
  row,
  index,
  rowClickHandler,
  selectRow = null,
  withRowHeadings,
  drawer,
  tableInstance,
  rowToFocus,
}: TableRowProps<D>): ReactElement => {
  const {
    toggleDrawer,
    closeDrawerAndFocus,
    isDrawerOpen,
    drawerTriggerRef,
    drawerTargetRef,
  } = useDrawer(row)

  const cellDrawerProps: CellDrawerProps = {
    isDrawerOpen,
    toggleDrawer,
    drawerTriggerRef,
  }

  const tableCellContext: TableCellHookValues<D> = {
    ...cellDrawerProps,
    ...getPublicRowStateProps<D>(row),
    data: row.original,
  }

  const [focusRef, setFocus] = useFocus<HTMLTableRowElement>()
  const [rowIsFocused, setRowIsFocused] = useState(false)
  const rowToFocusRef = useRef<undefined | number>(rowToFocus)

  useEffect(() => {
    if (rowToFocus !== rowToFocusRef.current) {
      setRowIsFocused(false)
    }

    if (index === rowToFocus && !rowIsFocused) {
      setFocus()
      setRowIsFocused(true)
      rowToFocusRef.current = rowToFocus
    }
  }, [index, rowToFocus, rowIsFocused, setFocus])

  // cells are wrapped in divs to prevent an issue with NVDA sometimes "merging" cells when they are read
  return (
    <>
      <StyledTr
        {...row.getRowProps()}
        {...(rowClickHandler && {
          onClick: (): void => {
            rowClickHandler({ index, data: row.original })
          },
        })}
        className={[selectRow === index && 'selected', index % 2 && 'even']
          .filter(Boolean)
          .join(' ')}
        ref={focusRef}
        tabIndex={-1}
      >
        {row.cells.map((cell, i) => {
          if (withRowHeadings && i === 0) {
            return (
              <StyledTh
                scope="row"
                alignment={cell.column.alignment}
                {...cell.getCellProps()}
              >
                <div>
                  <TableCellProvider value={tableCellContext}>
                    {cell.render('Cell', cellDrawerProps)}
                  </TableCellProvider>
                </div>
              </StyledTh>
            )
          }

          return (
            <StyledTd
              alignment={cell.column.alignment}
              {...cell.getCellProps()}
            >
              <div>
                <TableCellProvider value={tableCellContext}>
                  {cell.render('Cell', cellDrawerProps)}
                </TableCellProvider>
              </div>
            </StyledTd>
          )
        })}
      </StyledTr>
      {drawer && (
        <Drawer
          {...{
            tableInstance,
            row,
            drawer,
            closeDrawerAndFocus,
            isDrawerOpen,
            drawerTargetRef,
          }}
        />
      )}
    </>
  )
}

export default TableRow
