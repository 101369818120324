import React from 'react'

export interface LoadMoreHookValues<D> {
  canShowMore: boolean
  canShowLess: boolean
  handleShowMore(): void
  handleShowLess(): void
  data: D
  loadingState: LoadingState
  pageNumber: number
  pageSize: number
  rowTotal: number
  newRowIndex: number | undefined
}
const LoadMoreContext = React.createContext<
  LoadMoreHookValues<any> | undefined
>(undefined)

enum LoadingState {
  unloaded = 'UNLOADED',
  loading = 'LOADING',
  loaded = 'LOADED',
  failed = 'FAILED',
}

interface ReducerState<D extends Array<any>> {
  pageNumber: number
  data: D[]
  rowTotal: number
  newRowIndex: number | undefined
  loadingState: LoadingState
}

enum ActionTypes {
  pageReset = 'PAGE_INDEX/RESET',
  pageInc = 'PAGE_INDEX/INC',
  pageDec = 'PAGE_INDEX/DEC',
  loadingStateSet = 'LOADING_STATE/SET',
}

interface PageActionWithDataPayload<D> {
  type: ActionTypes.pageReset | ActionTypes.pageInc
  payload: [D, RowTotal]
}

interface PageDecAction {
  type: ActionTypes.pageDec
}

interface LoadingStateSetAction {
  type: ActionTypes.loadingStateSet
  payload: LoadingState
}

type ReducerAction<D> =
  | PageActionWithDataPayload<D>
  | PageDecAction
  | LoadingStateSetAction

const reducer = <D extends Array<any>>(
  state: ReducerState<D>,
  action: ReducerAction<D>,
): ReducerState<D> => {
  switch (action.type) {
    case ActionTypes.pageReset: {
      const [nextPage, rowTotal] = action.payload
      return {
        ...state,
        pageNumber: 1,
        data: [nextPage],
        rowTotal,
        newRowIndex: undefined,
      }
    }
    case ActionTypes.pageInc: {
      const [nextPage, rowTotal] = action.payload
      const newRowIndex = state.data.flat().length
      return {
        ...state,
        pageNumber: state.pageNumber + 1,
        data: [...state.data, nextPage],
        rowTotal,
        newRowIndex,
      }
    }
    case ActionTypes.pageDec:
      return {
        ...state,
        pageNumber: state.pageNumber - 1,
        data: state.data.slice(0, -1),
        newRowIndex: undefined,
      }
    case ActionTypes.loadingStateSet:
      return {
        ...state,
        loadingState: action.payload,
      }
    default:
      throw new Error('Invalid action dispatched for LoadMore reducer')
  }
}

type RowTotal = number

interface BaseProps<D> {
  /**
   * `pageSize` is the total number of data rows
   * that should be displayed on each page.
   */
  pageSize: number
  /**
   * The API call should take place within `handleGetData`,
   * which should return a 2-tuple array with the formatted data
   * as the first value and the row total as the second
   */
  handleGetData(pageSize: number, pageNumber: number): Promise<[D, RowTotal]>
  /** The `Table` instance should be passed as a child. */
  children: React.ReactElement
}

/**
 * This pattern--having the same set of props either as all required or
 * all optional and set to never--ensures that _if_ one of the props in
 * InitialDataProps is used, then _all_ must be used; otherwise,
 * none is required
 */

interface InitialDataProps<D> {
  initialData: D
  initialRows: number
}

type InitialDataPropsNever<D> = Partial<
  Record<keyof InitialDataProps<D>, never>
>

export type LoadMoreProps<D> =
  | (BaseProps<D> & InitialDataProps<D>)
  | (BaseProps<D> & InitialDataPropsNever<D>)

interface LoadNewPageOpts<D> {
  isInitial?: boolean
  dispatch: React.Dispatch<ReducerAction<D>>
  pageNumber: number
  pageSize: number
  handleGetData: LoadMoreProps<D>['handleGetData']
}

const loadNewPage = async <D,>({
  isInitial,
  dispatch,
  pageNumber,
  pageSize,
  handleGetData,
}: LoadNewPageOpts<D>): Promise<void> => {
  try {
    dispatch({
      type: ActionTypes.loadingStateSet,
      payload: LoadingState.loading,
    })

    const response = await handleGetData(pageSize, pageNumber)

    const pageActionType = isInitial
      ? ActionTypes.pageReset
      : ActionTypes.pageInc

    dispatch({ type: pageActionType, payload: response })
    dispatch({
      type: ActionTypes.loadingStateSet,
      payload: LoadingState.loaded,
    })
  } catch {
    dispatch({
      type: ActionTypes.loadingStateSet,
      payload: LoadingState.failed,
    })
  }
}

const generatePagedData = <D extends any[]>(
  data: D,
  pageSize: number,
  numberOfPages: number,
): D[] => {
  const blankPages = new Array(...Array(numberOfPages))
  return blankPages.reduce((acc, page, i) => {
    const chunkStart = i * pageSize
    const chunkEnd = (i + 1) * pageSize
    const chunk = data.slice(chunkStart, chunkEnd)
    return [...acc, chunk]
  }, [])
}

const LoadMore = <D extends any[]>({
  pageSize,
  handleGetData,
  initialData,
  initialRows = 0,
  children,
}: LoadMoreProps<D>): React.ReactElement => {
  const initialDataRowTotal = initialData?.length || 0
  const initialPageNumber = Math.ceil(initialDataRowTotal / pageSize) || 1
  const pagedData = initialDataRowTotal
    ? generatePagedData<D>(initialData as D, pageSize, initialPageNumber)
    : []

  const initialState: ReducerState<D> = {
    pageNumber: initialPageNumber,
    data: pagedData,
    rowTotal: initialRows,
    loadingState: LoadingState.unloaded,
    newRowIndex: undefined,
  }

  const [
    { pageNumber, data, rowTotal: dataRowTotal, newRowIndex, loadingState },
    dispatch,
  ] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    if (!initialDataRowTotal) {
      loadNewPage({
        isInitial: true,
        pageNumber: initialState.pageNumber,
        pageSize,
        dispatch,
        handleGetData,
      })
    }
  }, [])

  const flattenedData = React.useMemo(() => data.flat(), [data])
  const pageTotal = Math.ceil(dataRowTotal / pageSize)
  const canShowMore = pageTotal - pageNumber > 0
  const canShowLess = pageNumber > 1

  const handleShowMore = (): void => {
    if (canShowMore) {
      loadNewPage({
        pageNumber: pageNumber + 1,
        pageSize,
        dispatch,
        handleGetData,
      })
    }
  }
  const handleShowLess = (): void => {
    if (canShowLess) {
      dispatch({ type: ActionTypes.pageDec })
    }
  }

  return (
    <LoadMoreContext.Provider
      value={{
        canShowMore,
        canShowLess,
        handleShowMore,
        handleShowLess,
        data: flattenedData,
        loadingState,
        pageNumber,
        pageSize,
        rowTotal: dataRowTotal,
        newRowIndex,
      }}
    >
      {children}
    </LoadMoreContext.Provider>
  )
}

export const useLoadMore = <D extends Array<any>>(): LoadMoreHookValues<D> => {
  const instanceValues = React.useContext(LoadMoreContext)
  if (!instanceValues) {
    throw new Error('useLoadMore must be used inside of the LoadMore provider')
  }

  return instanceValues
}

export default LoadMore
