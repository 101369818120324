import { useQuery } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { docuSignContractDownloadKey } from "../../utils/react-query-key-factory";

type DocuSignUrls = {
    download: string,
    view: string,
    ttlInMs: number
};

async function getDocuSignContractFromBackend(get: <Result>(url: string) => Promise<Result | null>, dealershipNumber: string, deliveryGroupId: string): Promise<DocuSignUrls | null> {
    const key = 'completed_docusign_contract.pdf';
    const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/download-staged-file/${key}`;
    const response = await get(endpoint) as DocuSignUrls;
    return response;
};

export function useGetDocuSignDownloadUrl(dealershipNumber: string, deliveryGroupId: string) {
    const { get } = useHttp();

    return useQuery(
        docuSignContractDownloadKey(dealershipNumber, deliveryGroupId),
        () => getDocuSignContractFromBackend(get, dealershipNumber, deliveryGroupId)
    )
};


