import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { ValidationError } from "../adaptorHooks/validationError";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { CREATE_CONTRACT_MUTATION_KEY_ROOT, contractDownloadKey, contractStatusKey, multipleDeliveryGroupsKey, singleDeliveryGroupsKey, contractSnapshotsOfAssetContractsKey, getDocumentsStatusKey } from "../../utils/react-query-key-factory";

async function putCreateContract(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string): Promise<void|null>{
  const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/create-contract`;
  return put<any, void>(endpoint, {});
}

type CreateContract = {
  onError: (error: string[]) => void;
  onSuccess: () => void;
  deliveryGroupId: string;
  customerId: string;
}

export function useCreateContract(dealershipNumber: string) {
  const { put } = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation(
    [CREATE_CONTRACT_MUTATION_KEY_ROOT],
    async (req: CreateContract) => await putCreateContract(put, dealershipNumber, req.deliveryGroupId),
    {
      onError(error, req: CreateContract, context){
        if(error instanceof ValidationError){
          req.onError(error.validationErrors.map(e => e.message));
          if (error.validationErrors.length > 0) {
            error.validationErrors.forEach((errorString) =>
              analytics(emitErrorEvent, errorString.message, error.status)
            );
          }
        }
        else {
          req.onError(["Oops something went wrong. Please try again later"]);
          analytics(emitErrorEvent, "create contract error", (error as any).status ?? 500);
        }
      },
      onSuccess: (data, req, context) => {
        req.onSuccess();
        queryClient.refetchQueries(contractStatusKey(dealershipNumber, req.deliveryGroupId ));
        queryClient.refetchQueries(getDocumentsStatusKey(dealershipNumber, req.deliveryGroupId ));
        queryClient.invalidateQueries(contractDownloadKey(dealershipNumber, req.deliveryGroupId ));
        queryClient.invalidateQueries(singleDeliveryGroupsKey(dealershipNumber ,req.deliveryGroupId));
        queryClient.invalidateQueries(multipleDeliveryGroupsKey(dealershipNumber, req.customerId));
        queryClient.invalidateQueries(contractSnapshotsOfAssetContractsKey(dealershipNumber, req.customerId, req.deliveryGroupId));
      },
    }
  );
}
