import { muiInfoFill, muiTrash } from "@ally/metronome-icons";
import React, { useRef } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import {
  WriteAssetContractSectionsNames,
  WriteAssetContractSections,
  ReadAssetContract,
} from "../../../model/asset-contract.model";
import { toCurrency } from "../../../utils/currency";
import {
  AddProductButton,
  BoxStyle,
  CoverageWrapper,
  IconContainer,
  OtherFeeFormFieldStyle,
  OtherFeeWrapper,
  SubHeader,
  WarningParagraph,
  ProductWrapper
} from "./AftermarketProductsStyles";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import { Icon } from "@ally/metronome-ui";
import { AfterMarketTrashStyled } from "./FormSectionStyles";

type FormValues = {
  auxProducts: {
    typeOfBenefit?: string;
    company?: string;
    charge?: number;
    coverageMonths?: string;
    coverageMiles?: string;  
  }[]
}

type Props = {
  customerId: any;
  dealershipNumber: any;
  assetContract: ReadAssetContract;
  deliveryGroup: ReadDeliveryGroup | null;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames, calc?: any) => void;
};

const AftermarketProducts: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const { setPrevStepActive } = useSteppedProcess();
  const markAsCompleteRef = useRef(false);

  const saveVehicle = useSaveVehicleForm(    
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  const form = useForm<FormValues>({
    defaultValues: {
      auxProducts: assetContract.auxProducts?.auxProducts.map(auxProduct => {
        return {
          typeOfBenefit: auxProduct.typeOfBenefit,
          company: auxProduct.company,
          charge: auxProduct.charge,
          coverageMonths: auxProduct.coverageMonths?.toString(),
          coverageMiles: auxProduct.coverageMiles?.toString()
        };
      })
    },
  });
  
  const { control, handleSubmit, formState } = form;
  const { errors } = formState;

  useEditVehicleFormErrors("Section 7", errors);

  const { fields, append, remove } = useFieldArray({
    name: 'auxProducts',
    control: control
  });
  
  const addProduct = () => {
    append({
      typeOfBenefit: "",
      company: "",
      charge: undefined,
      coverageMonths: "",
      coverageMiles: ""
    });
  };

  const onSubmitData = (data: any, markedAsComplete?: boolean) => {
    const patches = [{
        auxProducts: {
          ...data,
          isComplete: markedAsComplete
        },
      },
      {dealershipInformation: assetContract.dealershipInformation}
    ];
    const onSucc = (patch: WriteAssetContractSections, calc?: any) => {
      updateAssetContractState(patch, 'auxProducts', calc);
    };

    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";
    
    saveVehicle.mutate({
      datas: patches,
      saveType,
      onSucc,
      updateDeliveryGroupContractData: undefined,
      assetContract,
      deliveryGroup
    });
  }

  const stringIsBlank = (string: string) => {
    return !string || string.trim() === "";
  }

  return (
    <>
      <form allytm-form-tagged="true"  onSubmit={(e) => {
          markAsCompleteRef.current = false;
          return handleSubmit((data) => onSubmitData(data))(e);
        }}
      >
        <IconContainer>
          <Icon
            icon={muiInfoFill}
            size="xl"
            fill="plum"
            ariaHidden
          />
          <WarningParagraph>
            Credit Life, Disability, and GAP products are not permitted on this
            contract.{" "}
          </WarningParagraph>
        </IconContainer>
        <SubHeader>You can add up to four ancillary products per VIN.</SubHeader>
        <BoxStyle p={["sm", "md"]}>
          <AddProductButton
            itemProp={fields.length > 3 ? "hidden" : "visible"}
            variant="link"
            allytmln="add-product"
            aria-label="Add Product (optional)"
            text="+ Add Product (optional)"
            onClick={addProduct}
          />
          <ProductWrapper>
            {fields.length > 0 && (
              <>
                {fields.map((auxProduct, index) => {
                  return (
                    <OtherFeeWrapper key={auxProduct.id}>
                      <IconContainer>
                        <h4>Product {index + 1} (optional)</h4>
                        <AfterMarketTrashStyled
                          tabIndex={0}
                          icon={muiTrash}
                          size="lg"
                          ariaLabelledBy="Delete"
                          id="trash-icon"
                          onClick={(e: any) => remove(index)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              remove(index)
                            }
                          }}
                        />
                      </IconContainer>
                      <div className="other_fee">
                      <Controller
                        control={control}
                        name={`auxProducts.${index}.typeOfBenefit`}
                        rules={{
                          validate: (value) => {
                            if (markAsCompleteRef.current && stringIsBlank(value)) {
                              return "Please add type of benefit to continue."
                            }
                          },
                          minLength: {
                            value: 2,
                            message: "Please provide at least two characters.",
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <OtherFeeFormFieldStyle
                              key={auxProduct.id}
                              variant="input"
                              inputType="text"
                              inputWidth="300px"
                              labelContent="Type of benefit"
                              value={value ? value : ""}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChange(e.target.value.replace(/ {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g, ""))
                              }
                              maxLength={50}
                              errorNotification={{
                                message: errors?.auxProducts?.[index]?.typeOfBenefit?.message
                              }}
                            />
                          );
                        }}
                      />
                      <Controller
                        control={control}
                        name={`auxProducts.${index}.company`}
                        rules={{
                          minLength: {
                            value: 2,
                            message: "Please provide at least two characters.",
                          },
                          validate: (value) => {
                            if (markAsCompleteRef.current && stringIsBlank(value)) {
                              return "Please add company to continue."
                            }
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <OtherFeeFormFieldStyle
                              key={auxProduct.id}
                              variant="input"
                              inputType="text"
                              inputWidth="300px"
                              labelContent="Company"
                              value={value ? value : ""}
                              maxLength={50}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChange(e.target.value.replace(/ {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g, ""))
                              }
                              errorNotification={{
                                message: errors?.auxProducts?.[index]?.company?.message
                              }}
                            />
                          )
                        }}
                      />
                      <Controller
                        control={control}
                        name={`auxProducts.${index}.charge`}
                        rules={{
                          validate: (value) => {
                            if (markAsCompleteRef.current && value === undefined) {
                              return "Please add charge to continue."
                            }
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <OtherFeeFormFieldStyle
                              key={auxProduct.id}
                              variant="input"
                              inputType="text"
                              inputWidth="300px"
                              labelContent="Charge"
                              maxLength={11}
                              value={
                                isNullUndefinedOrEmpty(value)
                                  ? "" 
                                  : "$" +
                                  value.toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                              }
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                onChange(toCurrency(e.target.value, "."))                              }
                              errorNotification={{
                                message: errors?.auxProducts?.[index]?.charge?.message
                              }}
                            />
                          )
                        }}
                      />
                        <CoverageWrapper>
                          <h4>Coverage period</h4>
                          <div className="coverage_fee">
                            <Controller
                              control={control}
                              name={`auxProducts.${index}.coverageMonths`}
                              rules={{
                                validate: (value) => {
                                  if (markAsCompleteRef.current && stringIsBlank(value)) {
                                    return "Please add months to continue."
                                  }
                                },
                              }}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <OtherFeeFormFieldStyle
                                    key={auxProduct.id}
                                    variant="input"
                                    inputType="text"
                                    inputWidth="300px"
                                    labelContent="Months"
                                    value={value ? `${value}` : ""}
                                    maxLength={3}
                                    onBlur={(e) => {}}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      return onChange(e.target.value.replace(/[^\d]+/g, ''));
                                    }}
                                    errorNotification={{
                                      message: errors?.auxProducts?.[index]?.coverageMonths?.message
                                    }}
                                  />
                                )
                              }}
                            />
                            <Controller
                              control={control}
                              name={`auxProducts.${index}.coverageMiles`}
                              rules={{
                                validate: (value) => {
                                  if (markAsCompleteRef.current && stringIsBlank(value)) {
                                    return "Please add miles to continue."
                                  }
                                },
                              }}
                              render={({ field: { value, onChange } }) => {
                                return (
                                  <OtherFeeFormFieldStyle
                                    key={auxProduct.id}
                                    variant="input"
                                    inputType="text"
                                    inputWidth="300px"
                                    labelContent="Miles"
                                    value={value ? `${value}` : ""}
                                    maxLength={6}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      return onChange(e.target.value.replace(/[^\d]+/g, ''));
                                    }}
                                    errorNotification={{
                                      message: errors?.auxProducts?.[index]?.coverageMiles?.message
                                    }}
                                  />
                                )
                              }}
                            />
                          </div>
                        </CoverageWrapper>
                      </div>
                    </OtherFeeWrapper>
                  );
                })}
              </>
            )}
          </ProductWrapper>

          <NonTerminalFormButtons 
            setPrevStepActive={setPrevStepActive}
            buttonCallStatus={
              {
                isPending: saveVehicle.isPending,
                actionType: saveVehicle.saveType
              }
            }
            sectionNameForAnalytics="aftermarket-products"
            isComplete={assetContract.auxProducts?.isComplete}
            markAsComplete={(e) => {
              markAsCompleteRef.current = true;
              return handleSubmit((data) =>
                onSubmitData(data, true)
              )(e);
            }}
            />
        </BoxStyle>
      </form>
    </>
  );
};

export default AftermarketProducts;
