import { Button, TextBody, Icon, Modal } from "@ally/metronome-ui";
import styled from "styled-components";

export const OptionStickyNavContainer = styled.div`
  background-color: #ffffff;
  min-height: 112px;
  box-shadow: 0 -6px 0 0 rgba(122, 122, 122, 0.2);
  margin-top: 20px;
  border: 1px solid #ffffff;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`;

export const OptionStickyNavSubContainer = styled.div`
  width: 1024px;
  margin-bottom: 16px;
`

export const OptionHeading = styled.p`
  margin-top: 20px;
  color: #2a2a2a;
  font-size: 14px;
  line-height: 16px;
  color: #2a2a2a;
  font-weight: normal;
  margin-left: 20px;
  max-width: 67%;
`;

export const LinkStyle = styled(Button)`
  min-width: 146px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  color: #0071c4;
  background-color: #ffffff;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border: 1px solid #0071c4;
  margin-top: 14px;
  margin-left: 20px;
  justify-content: center;

  &:hover {
    background-color: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08),
      0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  }
`;

export const ButtonStyle = styled(Button)`
  min-width: 146px;
  min-height: 44px;
  border-radius: 4px;
  font-size: 16px;
  color: #0071c4;
  background-color: #ffffff;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border: 1px solid #0071c4;
  margin-top: 14px;
  margin-left: 20px;
  &:hover {
    background-color: #ffffff;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08),
      0 3px 1px -2px rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  }
`;

export const LinkButtonStyle = styled(Button)`
  letter-spacing: 0;
  line-height: 16px;
  font-size: 16px;
  color: #0071c4;
  margin-left: 16px;
  font-family: Helvetica;
  align-self: center;
`;

export const ModalStyled = styled(Modal)`
  height: 400px;
  width: 490px;

  div > h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-left: 9px;
  }

  div > aside > p {
    font-size: 16px;
    line-height: 24px;
    margin-left: 9px;
  }
`;

export const TextBodyStyled = styled(TextBody)`
  p:nth-child(2) {
    margin-top: 20px;
    margin-bottom: 5px;
  }
`;

export const RemovedModalStyled = styled(Modal)`
  width: 490px;
  min-height: 263px;

  div > h1 {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  
  p {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 8px 22px 0;
  }
`;

export const RemoveButtonStyle = styled(Button)`
  max-width: 92px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const CancelButtonStyle = styled(Button)`
  min-width: 83px;
  color: #0071c4;
  background-color: #e7f6fd;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`;

export const StyledIcon = styled(Icon)`
  margin: 3px 10px 0 0;
`;

export const MaxVehicleMessageContainer = styled.div`
  font-weight: normal;
  display: flex;
  padding: 10px 0 0 20px;
  span {
    max-width: 450px;
  }
`;
