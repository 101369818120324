import { CancelButtonStyled, EditButtonStyled, EditedEmailStyled, EmailFieldStyled, EmailStyled, FormTextStyled, SaveButtonStyled } from "./VerifyEmailsStyled"
import { ChangeEvent, useState } from 'react';
import { useUpdateDeliveryGroupEmail } from "../../../hooks/deliveryGroupHooks/useUpdateDeliveryGroupEmail";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useParams } from "react-router";
import { ContextType, TabContext } from "../../Tab/TabContext";
import React from "react";
import { EmailType } from "./VerifyEmails";

type VerifyEmailFieldType = {
    emailType: EmailType,
    name: string,
    email: string,
    setSaveNotification: React.Dispatch<React.SetStateAction<{ name: string, success: boolean, error: string | null }>>,
    validateEmail: (email: string) => boolean,
    convertToTitleCase: (name: string) => string,
    saveNotification: Object
}

const VerifyEmailField: React.FC<VerifyEmailFieldType> = ({ saveNotification, emailType, name, email, validateEmail, convertToTitleCase, setSaveNotification }) => {
    const [inputEmail, setInputEmail] = useState(email);
    const [updatedEmail, setUpdatedEmail] = useState(email);
    const [emailPatternError, setEmailPatternError] = useState(null)
    const [isEdited, setIsEdited] = useState(false);
    const [dealershipInfo] = useDealershipInfo();
    const dealershipNumber = dealershipInfo?.dealershipNumber;
    const { id } = useParams() as any;
    
    const allytmln = (operation: "Edit" | "Save" | "Cancel", emailType: EmailType) => {
        const result = emailType === EmailType.buyerSigner
            ? `${operation} - Customer Email`
            : emailType === EmailType.cobuyer
                ? `${operation} - CoBuyer Email`
                : `${operation} - Dealer Email`;
        
        return result;
    }

    const {        
        activeDeliveryGroupId,
      } = React.useContext(TabContext) as ContextType;

    const updateDgEmail = useUpdateDeliveryGroupEmail(
        dealershipNumber,
        id,
        activeDeliveryGroupId
    );

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputEmail(value);
        setEmailPatternError(validateEmail(value) ? null : 'Add dealer email address to continue')
    }
      
    const onEditClick = () => {
        setIsEdited(true);
        setEmailPatternError(null);
    };

    const onSaveClick = () => {
        updateDgEmail.mutate({
            onUpdate: onUpdate,
            onError: () => setSaveNotification({ name: name, success: false, error: null }),
            emailType: emailType,
            email: inputEmail,
        });
    };

    const onUpdate = () => {
        setEmailPatternError(validateEmail(inputEmail) ? null : 'Add dealer email address to continue');
        if (!emailPatternError) {
            setIsEdited(false);
            setUpdatedEmail(inputEmail)
            setSaveNotification({ name: name, success: true, error: null })
        }
    };

    const onCancelClick = () => {
        setIsEdited(false);
        setSaveNotification(null);
        setInputEmail(updatedEmail);
    }

    return (
        <EmailFieldStyled >

            <h3 data-private>{convertToTitleCase(name)}</h3>
            {isEdited ?
                <div>
                    <EditedEmailStyled>
                        <div data-private>
                            <FormTextStyled
                                inputWidth="240px"
                                variant="input"
                                labelContent={null}
                                inputType="email"
                                placeholder={inputEmail}
                                value={inputEmail}
                                onChange={(e) => handleEmailChange(e)}
                                name="email"
                                maxLength={100}
                                errorNotification={{ message: emailPatternError ? emailPatternError : null }}
                            />
                        </div>
                        <SaveButtonStyled
                            text="Save"
                            aria-label="save"
                            variant="secondary"
                            onClick={() => onSaveClick()}
                            allytmln={allytmln("Save", emailType)}
                            loading={updateDgEmail.isLoading}
                        />
                        <CancelButtonStyled
                            text="Cancel"
                            aria-label="cancel"
                            variant="outline"
                            onClick={() => onCancelClick()}
                            allytmln={allytmln("Cancel", emailType)}
                        />
                    </EditedEmailStyled>
                </div>
                :
                <EmailStyled>
                    <p data-private>{inputEmail}</p>
                    <EditButtonStyled
                        text="Edit"
                        ariaLabel="edit-email"
                        variant="link"
                        onClick={() => onEditClick()}
                        allytmln={allytmln("Edit", emailType)}
                    />
                </EmailStyled>
            }
        </EmailFieldStyled>
    )

}

export default VerifyEmailField;
