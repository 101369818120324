import { User } from "./User";
export type ClickEvent = { 
  tagName: string;
  allytmln: string;
  href: string;
}

export function emitClickEvent(user: User, clickEvent: ClickEvent){
  window.allytm.event('clickEvent', clickEvent, { user });
}
