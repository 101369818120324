import React, { ReactElement, useEffect } from 'react'
import { Row } from 'react-table'
import { pick } from 'ramda'
import { TableSpinner } from '../TableSpinner'
import CardHeader from './CardHeader'
import CardData from './CardData'
import { StyledTableCard } from './shared'
import { TableCardListProps } from './index'
import {
  CellDrawerProps,
  getPublicRowStateProps,
  TableCellHookValues,
  TableCellProvider,
  useDrawer,
  UseDrawerProps,
} from '../utils'
import { useFocus } from '@ally/metronome-ui'

interface CardProps<D extends Record<string, any>>
  extends Pick<TableCardListProps<D>, 'mdDownHeaderDetail'> {
  row: Row<D>
  cardIsFocused: boolean
  isLoading?: boolean
  index?: number
}

interface CardBodyProps<D extends Record<string, any>> {
  drawerProps: UseDrawerProps
  row: Row<D>
  cellDrawerProps: CellDrawerProps
  isLoading?: boolean
}

const CardBody = <D extends Record<string, any>>({
  isLoading,
  row,
  drawerProps,
  cellDrawerProps,
  ...cardDataProps
}: CardBodyProps<D>): ReactElement | null => {
  if (isLoading) {
    return <TableSpinner />
  }

  return (
    <CardData
      row={row}
      drawerProps={drawerProps}
      cellDrawerProps={cellDrawerProps}
      {...cardDataProps}
    />
  )
}

const Card = <D extends Record<string, any>>({
  row,
  mdDownHeaderDetail,
  cardIsFocused,
  isLoading,
  index,
  ...cardDataProps
}: CardProps<D>): ReactElement | null => {
  const [focusRef, setFocus] = useFocus<HTMLTableRowElement>()

  useEffect(() => {
    if (cardIsFocused) {
      setFocus()
    }
  }, [cardIsFocused, setFocus])

  const drawerProps = useDrawer(row)
  const cellDrawerProps: CellDrawerProps = pick(
    ['isDrawerOpen', 'toggleDrawer', 'drawerTriggerRef'],
    drawerProps,
  )

  const tableCellContext: TableCellHookValues<D> = {
    ...cellDrawerProps,
    ...getPublicRowStateProps<D>(row),
    data: row.original,
  }

  const hideCard = isLoading && index !== 0
  if (hideCard) return null
  return (
    <StyledTableCard ref={focusRef} tabIndex={-1}>
      <TableCellProvider value={tableCellContext}>
        <CardHeader
          row={row}
          mdDownHeaderDetail={mdDownHeaderDetail}
          cellDrawerProps={cellDrawerProps}
          isLoading={isLoading}
        />
        <CardBody
          isLoading={isLoading}
          row={row}
          drawerProps={drawerProps}
          cellDrawerProps={cellDrawerProps}
          {...cardDataProps}
        />
      </TableCellProvider>
    </StyledTableCard>
  )
}

export default Card
