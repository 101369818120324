import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { ReadDeliveryGroup } from "../../model/delivery-group.model";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { DELETE_DELIVERY_GROUP_MUTATION_KEY_ROOT, assignedVehiclesKey, multipleCustomersKey, multipleDeliveryGroupsKey, singleCustomerKey, singleDeliveryGroupsKey, unassignedVehiclesKey } from "../../utils/react-query-key-factory";

async function deleteDg(deleteMethod: (url: string) => Promise<void>,
  dealershipNumber: string, deliveryGroupId: string): Promise<void>{
  if(deliveryGroupId){
    let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}`;
    return deleteMethod(dgEndpoint);
  }
}

export function useDeleteDeliveryGroup(dealershipNumber: string, customerId: string){
  const {deleteMethod} = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const dgCollectionQueryKey = multipleDeliveryGroupsKey(dealershipNumber, customerId);

  return useMutation(
   [DELETE_DELIVERY_GROUP_MUTATION_KEY_ROOT],
   async (deliveryGroupId:string) => await deleteDg(deleteMethod, dealershipNumber, deliveryGroupId),
   { 
    onMutate: async (deliveryGroupId) =>{
      //clear related queries in progress so we can get a current snapshot 
      await queryClient.cancelQueries(dgCollectionQueryKey);

      //current snapshot for rollback
      const dgToDelete = 
        (queryClient.getQueryData(dgCollectionQueryKey) as ReadDeliveryGroup[]) ?? []
        .find((d: ReadDeliveryGroup) => d.id === deliveryGroupId);

      //keep all but removed for optimistic update 
      queryClient.setQueryData<ReadDeliveryGroup[]>(dgCollectionQueryKey, old => {
        return (old ?? []).filter(d =>  d.id !== deliveryGroupId);
      });

      return {dgToDelete};
    }, 
    onError(error, variables, context){ //rollback
      if(context?.dgToDelete){
        queryClient.setQueryData<ReadDeliveryGroup[]>(dgCollectionQueryKey, old =>{
          return (old ?? []).concat(context?.dgToDelete)
        });
      }
      analytics(emitErrorEvent, (error as any).message ?? "delete delivery group error", (error as any).status ?? 500);
    },
    onSettled: (data, error, deliveryGroupId) => {
      queryClient.invalidateQueries(dgCollectionQueryKey);
      queryClient.removeQueries(singleDeliveryGroupsKey(dealershipNumber, deliveryGroupId));
      queryClient.invalidateQueries(singleCustomerKey(dealershipNumber, customerId));
      queryClient.invalidateQueries(multipleCustomersKey(dealershipNumber));
      queryClient.invalidateQueries(assignedVehiclesKey(dealershipNumber, customerId, deliveryGroupId));
      queryClient.invalidateQueries(unassignedVehiclesKey(dealershipNumber, customerId));
    },
    retry: 2 // put is indempotent as an upsert so retry should be okay
  });
}
