import moment from "moment";

export type Customer = {
  company: string;
  tax_id: string;
  address: string;
  city: string;
  county?: string;
  state: string;
  zip: string;
  totalApps: number;
  last_activity?: Date;
}

export type CustomerError = {
  errorMsg: string;
}

export function customerDtoToModel(json: any): Customer {
  const lastActivityDate = json.lastActivity ? new Date(json.lastActivity) : null;

  return {
    tax_id: json.customerId,
    company: json.companyName,
    address: json.address,
    city: json.city,
    state: json.state,
    zip: json.zip,
    county: json.county,
    totalApps: json.totalApps,
    last_activity: lastActivityDate ? moment.tz(lastActivityDate, "America/New_York").toDate() : null,
  } as Customer;
}
