import React from 'react'
// import { muiCaretDown, muiCaretUp } from '@ally/metronome-icons'
import { Box } from '../../Box'
import { Icon } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'

interface SortIndicatorProps {
  isSorted?: boolean
  isSortedDesc?: boolean
}

const SortIndicator: React.FC<SortIndicatorProps> = ({
  isSorted,
  isSortedDesc,
}) => {

  const { muiCaretUp,  muiCaretDown} = deprecated;

  const isAsc = isSorted && !isSortedDesc
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      {isSorted && (
        <Icon
          icon={isAsc ? muiCaretUp : muiCaretDown}
          size="xs"
          fill="slate-5"
          ml="xxs"
          unsupportedCustomWidth={7}
          unsupportedCustomHeight={7}
          data-testid="table-sort-indicator"
          ariaHidden
        />
      )}
    </Box>
  )
}
export default SortIndicator
