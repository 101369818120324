import { 
  Box,
  ButtonGroup, 
  FormText, 
} from "@ally/metronome-ui";
import styled from "styled-components";

export const BoxStyle = styled(Box)`
  padding: 0;
  label {
    margin-top: 21px;
  }
  .monthly_payment {
    div {
      margin-top: 20px;
    }
  }
`;

export const DeliveryFormFieldStyle = styled(FormText)`
  max-width: 320px;
  color: #ffffff;
  margin-bottom: 21px;
  margin-top: 20px;

  label {
    height: 20px;
    width: 91px;
    color: #2a2a2a;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    margin-bottom: 1px;
  }

  div {
    outline: none;
  }

  div > span > input {
    background-color: #f9f9f9;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 4px 4px 0 0;
  }
`;

export const SubHeader = styled.p`
  font-weight: normal;
  line-height: 16px;
  font-size: 14px;
  color: #2a2a2a;
  max-width: 65%;
  max-height: 51px;
  margin-bottom: 21px;
  margin-top: 20px;
`;

export const WarningParagraph = styled.p`
  font-weight: bold;
  font-size: 14px;
  color: #ff0000;
  margin-bottom: 21px;
  margin-top: 20px;
  max-width: 65%;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-bottom: 35px;
`;
