import { get } from '@ally/metronome-ui'
import { css } from 'styled-components'

export const tdStyles = css`
  height: 34px;
  color: ${get.colors('slate-5')};
  font-size: ${get.fontSizes('sm')};
  line-height: 25px;
  padding: ${get.space('xxs')} ${get.space('xs')};
`

export const trStyles = css`
  border-bottom: 1px solid ${get.colors('grey-80')};
  background-color: ${get.colors('white')};

  &.even {
    background-color: ${get.colors('light-gray')};
  }
`
