import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { Row, TableInstance } from 'react-table'
import { LgUpTableProps } from '.'
import { trStyles, tdStyles } from './shared'
import {
  UseDrawerProps,
  DrawerProvider,
  getPublicRowStateProps,
} from '../utils'
import { getBoxShadow } from '../shared'
import { CloseButton, CloseButtonSpacer, get } from '@ally/metronome-ui'

interface DrawerProps<D extends Record<string, any>>
  extends Pick<LgUpTableProps<D>, 'drawer'>,
    Pick<
      UseDrawerProps,
      'closeDrawerAndFocus' | 'isDrawerOpen' | 'drawerTargetRef'
    > {
  tableInstance: TableInstance<D>
  row: Row<D>
}

const StyledTr = styled.tr`
  ${trStyles}
`

const StyledDrawerTd = styled.td`
  ${tdStyles}
  ${getBoxShadow(true)}
  padding: ${get.space('md')} 10px;
  position: relative;
`

const StyledContentWrapper = styled.div`
  padding: ${get.space('md')} ${get.space('xs')};
`

const Drawer = <D extends Record<string, any>>({
  tableInstance: { prepareRow },
  row,
  drawer,
  closeDrawerAndFocus,
  isDrawerOpen,
  drawerTargetRef,
}: DrawerProps<D>): ReactElement | null => {
  if (!isDrawerOpen) return null

  const [drawerRow] = row.subRows

  if (!drawerRow) return null

  prepareRow(drawerRow)

  return (
    <StyledTr {...drawerRow.getRowProps()}>
      <StyledDrawerTd colSpan={row.cells.length}>
        <CloseButtonSpacer />
        {/* drawer is wrapped in div to prevent an issue with NVDA sometimes "merging" cells when they are read */}
        <StyledContentWrapper>
          <DrawerProvider
            value={{
              row,
              ...getPublicRowStateProps<D>(row),
              closeDrawer: closeDrawerAndFocus,
              targetRef: drawerTargetRef,
            }}
          >
            {drawer}
          </DrawerProvider>
        </StyledContentWrapper>
        <CloseButton
          data-track-elem="icon"
          data-track-name="Close"
          data-track-trigger="close"
          onClick={(): void => {
            closeDrawerAndFocus()
          }}
        />
      </StyledDrawerTd>
    </StyledTr>
  )
}

export default Drawer
