import React from "react";
import {
  ReadAssetContract,
  WriteAssetContract,
  WriteAssetContractSections,
  WriteAssetContractSectionsNames
} from "../../../model/asset-contract.model";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import {
  BoxStyle,
  DeliveryFormFieldStyle,
} from "./VehicleInformationStyles";
import { Condition } from "../../../constants/enums";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { getAllFieldsForStateInSection } from "../../../validation-config/field-config-utils";
import { vehicleDetailsConfig } from "../../../validation-config/asset-contracts/vehicle-details";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";

type Props = {
  appId: string;
  customerId: string;
  dealershipNumber: any;
  assetContract: ReadAssetContract | WriteAssetContract;
  deliveryGroup: ReadDeliveryGroup | null;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames) => void;
};

type FormValues = {
  condition: Condition;
  odometer: number | null;
  vin: string | null;
  year: number | null;
  make: string | null;
  model: string | null;
  grossWeight: number | null;
};

const VehicleInformation = ({
  appId,
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const { setPrevStepActive } = useSteppedProcess();
  const markAsCompleteRef = useRef(false);
  const [dealershipInfo] = useDealershipInfo();
  const fieldConfigurations = getAllFieldsForStateInSection(
    vehicleDetailsConfig,
    dealershipInfo.dealershipState
  );
  const optionalField = fieldConfigurations.optionalFields;

  const vehicleDetails = assetContract.vehicleDetails;
  const form = useForm<FormValues>({
    defaultValues: {
      condition: vehicleDetails?.condition,
      odometer: vehicleDetails?.odometer,
      vin: vehicleDetails?.vin,
      year: vehicleDetails?.year,
      make: vehicleDetails?.make,
      model: vehicleDetails?.model,
      grossWeight: vehicleDetails?.grossWeight,
    },
  });

  const { control, handleSubmit, formState, setError } = form;
  const { errors } = formState;

  const saveVehicle = useSaveVehicleForm(    
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );
  useEditVehicleFormErrors("Section 3", errors);
  
  const handleOnBlur = (
    e: React.ChangeEvent<HTMLInputElement>,
    errorName: any
  ) => {
    if (markAsCompleteRef.current && !e.target.value) {
      setError(errorName, {
        type: "custom",
        message: `Please add ${errorName} to continue.`,
      });
    }
  };

  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    data.year = data.year ? +data.year : null;
    data.make = data.make ? data.make : null;
    data.model = data.model ? data.model : null;
    data.vin = data.vin ? data.vin.toUpperCase() : null;
    data.grossWeight =
      data.grossWeight || (!data.grossWeight && data.grossWeight === 0)
        ? +data.grossWeight
        : null;
    data.odometer =
      data.odometer || (!data.odometer && data.odometer === 0)
        ? +data.odometer
        : null;

    updateToBackEnd(data, markedAsComplete);
  };

  const updateToBackEnd = (data: FormValues, markedAsComplete?: boolean) => {
    const onSucc = (patch: WriteAssetContractSections) => {
      updateAssetContractState(patch, 'vehicleDetails');
    };

    const patches = [{
      vehicleDetails: {
        ...data,
        isComplete: markedAsComplete,
      },
    },
    {dealershipInformation: assetContract.dealershipInformation}
  ];
    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";
    
    saveVehicle.mutate({datas: patches, saveType, onSucc});
  };

  return (
    <form allytm-form-tagged="true" 
      onSubmit={(e) => {
        markAsCompleteRef.current = false;
        return handleSubmit((data) => onSubmitData(data))(e);
      }}
    >
      <BoxStyle p={["sm", "md"]} height="100%">
        <ReadOnlyField label="Ally app ID" value={appId} />
        <ReadOnlyField
          label="Condition"
          value={vehicleDetails?.condition ?? ""}
        />
        <Controller
          control={control}
          name="year"
          rules={{
            validate: (value) => {
              if (!value && markAsCompleteRef.current) {
                return "Please add year to continue.";
              }
            },
            minLength: {
              value: 4,
              message: "Please add four digits.",
            },
          }}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { error },
          }) => {
            return (
              <DeliveryFormFieldStyle
                variant="input"
                inputType="text"
                inputWidth="320px"
                labelContent="Year"
                value={value + "" ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  return onChange(e.target.value.replace(/\D/g, ""));
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnBlur(e, "year");
                }}
                maxLength={4}
                errorNotification={{
                  message: errors.year?.message,
                }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="make"
          rules={{
            validate: (value) => {
              if (markAsCompleteRef.current && isNullUndefinedOrEmpty(value)) {
                return "Please add make to continue.";
              }
            },
            minLength: {
              value: 2,
              message: "Please provide at least two characters.",
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <DeliveryFormFieldStyle
                variant="input"
                inputType="text"
                inputWidth="320px"
                labelContent="Make"
                value={value ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  return onChange(e.target.value);
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnBlur(e, "make");
                }}
                maxLength={100}
                errorNotification={{
                  message: errors.make?.message,
                }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="model"
          rules={{
            validate: (value) => {
              if (markAsCompleteRef.current && isNullUndefinedOrEmpty(value)) {
                return "Please add model to continue.";
              }
            },
            minLength: {
              value: 2,
              message: "Please provide at least two characters.",
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <DeliveryFormFieldStyle
                variant="input"
                inputType="text"
                inputWidth="320px"
                labelContent="Model"
                value={value ?? ""}
                onChange={onChange}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnBlur(e, "model");
                }}
                maxLength={100}
                errorNotification={{ message: errors.model?.message }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="vin"
          rules={{
            validate: (value) => {
              if (!value && markAsCompleteRef.current) {
                return "Please add vin to continue.";
              }
            },
            minLength: {
              value: 17,
              message: "Please provide 17 letters and numbers.",
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <DeliveryFormFieldStyle
                id="vin"
                variant="input"
                inputType="text"
                inputWidth="320px"
                labelContent="VIN"
                value={value ?? ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  return onChange(e.target.value.replace(/\W/g, ""));
                }}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleOnBlur(e, "vin");
                }}
                maxLength={17}
                errorNotification={{ message: errors.vin?.message }}
              />
            );
          }}
        />

        <Controller
          control={control}
          name="odometer"
          rules={{
            validate: (value) => {
              if (markAsCompleteRef.current && isNullUndefinedOrEmpty(value)) {
                return "Please add odometer to continue.";
              }
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <DeliveryFormFieldStyle
                id="odometer"
                variant="input"
                inputType="text"
                inputWidth="320px"
                labelContent="Odometer"
                value={isNullUndefinedOrEmpty(value)
                  ? ""
                  : value.toString()
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  return onChange(e.target.value.replace(/\D/g, ""));
                }}
                errorNotification={{ message: errors.odometer?.message }}
              />
            );
          }}
        />

        {optionalField.includes("grossWeight") && (
          <Controller
            control={control}
            name="grossWeight"
            render={({ field: { value, onChange } }) => {
              return (
                <DeliveryFormFieldStyle
                  id="grossWeight"
                  variant="input"
                  inputType="text"
                  inputWidth="320px"
                  labelContent="Manufacture gross vehicle weight"
                  value={isNullUndefinedOrEmpty(value)
                    ? ""
                    : value.toString()
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    return onChange(e.target.value.replace(/\D/g, ""));
                  }}
                />
              );
            }}
          />
        )}
        

        <NonTerminalFormButtons 
          setPrevStepActive={setPrevStepActive}
          buttonCallStatus={
            {
              isPending: saveVehicle.isPending,
              actionType: saveVehicle.saveType
            }
          }
          sectionNameForAnalytics="vehicle-information"
          isComplete={vehicleDetails.isComplete}
          markAsComplete={(e) => {
            markAsCompleteRef.current = true;
            return handleSubmit((data) =>
              onSubmitData(data, true)
            )(e);
          }}
        />

      </BoxStyle>
    </form>
  );
};

export default VehicleInformation;
