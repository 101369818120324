import { Dejargonator, DropdownMenu, Icon } from "@ally/metronome-ui"
import { DejargonatorBodyStyle, DropdownContainer, OptionalFieldsDropdownContainer } from "./OptionalFieldsDropdownStyles"
import { muiQuestionCircleOutline } from "@ally/metronome-icons"

type Props = {
    selectedField: Object,
    titleText: string,
    handleClick: (item: string) => void,
    fieldType: string,
    className?: string
}
const OptionalFieldsDropdown: React.FC<Props> = ({ className, selectedField, titleText, handleClick, fieldType }) => {

    const newList = Object.keys(selectedField).reduce((list, item) => {
        if (selectedField[item].selected !== undefined &&
            selectedField[item].isValidForDealerState && !selectedField[item].featureFlag) {
            list.push({
                key: item,
                text: selectedField[item].label,
                onClick: () => {
                    handleClick(item)
                }
            })
        }
        return list;
    }, []);
    const dejargonatorBody = `Any additional ${fieldType} you add will apply to all vehicles in this delivery group once you select`
    return (
        <OptionalFieldsDropdownContainer className={className} >
            <DropdownContainer>
                <DropdownMenu
                    dropdownItems={newList}
                    title={titleText}
                    selectionAsTitle={false}
                    ariaLabelTrigger={(isOpen): string =>
                        isOpen
                            ? `Close select additional ${fieldType} menu`
                            : `Open select additional ${fieldType} menu`
                    }
                />

            <Dejargonator
                trigger={{
                    variant: "icon",
                        content: <Icon icon={muiQuestionCircleOutline} size="lg" />,
                    ariaLabel: `Additional ${fieldType}`,
                }}
                heading={`Additional ${fieldType}`}
                placement="right"
                body={
                    <>
                        <DejargonatorBodyStyle>
                            {dejargonatorBody}
                        </DejargonatorBodyStyle>
                        <p>
                            <b>Mark as Complete.</b>
                        </p>
                    </>
                }
            />
            </DropdownContainer>
        </OptionalFieldsDropdownContainer>
    )
}

export default OptionalFieldsDropdown;

