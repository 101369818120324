import { Button } from "@ally/metronome-ui";
import { StyledButtonGroup } from "../FormStyles";
import { StepTransitionType } from "../../../library/SteppedProcess/context";
import { WarningParagraph } from "../Edit-VehicleDetail/ContractSummaryStyles";

type NonTerminalFormButtonsProps = {
  isComplete: boolean;
  sectionNameForAnalytics: string
  buttonCallStatus: {
    isPending: boolean;
    actionType: StepTransitionType
  }
  setPrevStepActive: () => void;
  markAsComplete: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
};

export const NonTerminalFormButtons: React.FC<NonTerminalFormButtonsProps> = ({
  isComplete, buttonCallStatus, setPrevStepActive, markAsComplete, sectionNameForAnalytics
}: NonTerminalFormButtonsProps) => { 

return (
  <StyledButtonGroup
    sectionName={sectionNameForAnalytics}>
    <Button
      loading={buttonCallStatus.isPending && buttonCallStatus.actionType === "MarkAsComplete"}
      variant="primary"
      aria-label="Mark as Complete"
      text="Mark as Complete"
      allytmln={`mark-as-complete-${sectionNameForAnalytics}-edit`}
      onClick={markAsComplete}
      disabled={buttonCallStatus.isPending}
    />
    {!isComplete && (
      <Button
        loading={buttonCallStatus.isPending && buttonCallStatus.actionType === "SavedAndContinue"}
        variant="secondary"
        aria-label="Save and Continue"
        text="Save and Continue"
        allytmln={`save-and-continue-${sectionNameForAnalytics}`}
        type="submit"
        disabled={buttonCallStatus.isPending}
      />
    )}
    <Button
      variant="link"
      allytmln={`cancel-${sectionNameForAnalytics}-edit`}
      aria-label="Cancel"
      text="Cancel"
      onClick={(): void => {
        setPrevStepActive();
      }}
    />
  </StyledButtonGroup>
)};

type TerminalFormButtonsProps = {
  isComplete: boolean;
  allSectionsCompleted: boolean;
  sectionNameForAnalytics: string
  buttonCallStatus: {
    isPending: boolean;
    actionType: StepTransitionType
  }
  readyForContractClicked: boolean;
  setPrevStepActive: () => void;
  markAsComplete: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
};

export const TerminalFormButtons: React.FC<TerminalFormButtonsProps> = ({
  isComplete, buttonCallStatus, setPrevStepActive, markAsComplete, allSectionsCompleted, readyForContractClicked, sectionNameForAnalytics
}: TerminalFormButtonsProps) => { 

return (
  <>
    {(!allSectionsCompleted && readyForContractClicked &&
    <WarningParagraph>
      You must fill out all required fields in each section before you can select Ready for Contract.
    </WarningParagraph>
    )}
    <StyledButtonGroup>
      <Button
        loading={buttonCallStatus.isPending && buttonCallStatus.actionType === "MarkAsComplete" && allSectionsCompleted}
        variant="primary"
        aria-label="Ready for Contract"
        text="Ready for Contract"
        allytmln="mark-ready-for-contract"
        onClick={markAsComplete} 
        disabled={buttonCallStatus.isPending}
      />
      {!isComplete && (<Button
        loading={buttonCallStatus.isPending && buttonCallStatus.actionType === "SavedAndContinue"}
        variant="secondary"
        aria-label="Save Progress"
        text="Save Progress"
        allytmln={`save-and-continue-${sectionNameForAnalytics}`}
        type="submit"
        disabled={buttonCallStatus.isPending}
      />)}

      <Button
        variant="link"
        aria-label="Cancel"
        text="Cancel"
        allytmln={`cancel-${sectionNameForAnalytics}-edit`}
        onClick={(): void => {
          setPrevStepActive();
        }} />
    </StyledButtonGroup>
  </>
)};
