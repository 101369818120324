import React, { ReactElement } from 'react'
import { Row } from 'react-table'
import { TableCardListProps } from '.'
import { Header, HeaderDetail, HeaderLabel, CardValue } from './shared'
import { CellDrawerProps } from '../utils'

interface CardHeaderProps<D extends Record<string, any>>
  extends Pick<TableCardListProps<D>, 'mdDownHeaderDetail'> {
  row: Row<D>
  cellDrawerProps: CellDrawerProps
  isLoading?: boolean
}

const CardHeader = <D extends Record<string, any>>({
  row,
  mdDownHeaderDetail,
  cellDrawerProps,
  isLoading,
}: CardHeaderProps<D>): ReactElement => {
  const { cells } = row

  const renderDetail = (): React.ReactNode => {
    const detailCell = cells.find(
      ({ column }) => column.Header === mdDownHeaderDetail,
    )

    return detailCell?.render('Cell', cellDrawerProps)
  }

  const [cell] = cells
  const { column } = cell
  const header = column.render('Header')
  const displayedHeader = typeof header === 'string' ? `${header}:` : header
  const displayHeaderDetail = !isLoading && mdDownHeaderDetail

  return (
    <Header>
      <HeaderLabel>{displayedHeader}</HeaderLabel>
      <CardValue>
        {!isLoading && cell.render('Cell', cellDrawerProps)}
      </CardValue>

      {displayHeaderDetail && <HeaderDetail>{renderDetail()}</HeaderDetail>}
    </Header>
  )
}

export default CardHeader
