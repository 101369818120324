import React, { useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSteppedProcess } from "../context";
import { StepNumber } from "./StepNumber";
import { StepTitleButtonProps } from "../types";
import { MuiTheme, TextHeading } from "@ally/metronome-ui";
import { emitClickEvent } from "../../../analytics/clickEvents";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";

const StepTitleWrapper = styled.div`
  ${({ theme: { space } }): string => `
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0;
    margin: 0 0 ${space.xs} 0;
  `}
`;

const stepHeadingColor = (
  isStepActive: boolean,
  isStepComplete: boolean
): keyof MuiTheme["colors"] => {
  return isStepActive || isStepComplete ? "slate-5" : "bluesuedeshoes";
};

const stepCursor = (isStepActive: boolean, isStepComplete: boolean): any => {
  return isStepActive || isStepComplete ? "unset" : "pointer";
};
const StepHeading = styled(TextHeading)<{
  isStepActive: boolean;
  isStepComplete: boolean;
}>`
  ${({ isStepActive, isStepComplete, theme: { colors } }): string => `
    color: ${colors[stepHeadingColor(isStepActive, isStepComplete)]};
    cursor: ${stepCursor(isStepActive, isStepComplete)};
    font-weight: 'bold'
  `}
`;

const StepTitle: React.FC<StepTitleButtonProps> = ({ stepIndex, step }) => {
  const {
    focusStep,
    isStepActive,
    isStepComplete,
    setActiveStep,
  } = useSteppedProcess();

  const titleRef = useRef<HTMLHeadingElement>(null);
  const analytics = useAnalytics();
  useEffect(() => {
    if (stepIndex === focusStep) titleRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusStep]);

  const stepStatus = useMemo(() => {
    if (isStepActive(stepIndex)) return "Current Step";
    if (isStepComplete(stepIndex)) return "Complete";
    return undefined; // future steps return no status
  }, [stepIndex, isStepActive, isStepComplete]);

  const ariaLabel = useMemo(() => {
    const stepNumber = stepIndex + 1;
    const stepTitle = step.title.text;
    const formattedStatus = stepStatus ? ` - ${stepStatus}` : "";
    return `Step ${stepNumber}: ${stepTitle}${formattedStatus}`;
  }, [stepIndex, step, stepStatus]);

  return (
    <StepTitleWrapper
      tabIndex={0}
      role="button"
      onClick={(e) => {
        analytics(emitClickEvent, {
          tagName: "div",
          allytmln: step.id,
          href: "#",
        });
        setActiveStep(stepIndex);
      }}
      
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          analytics(emitClickEvent, {
            tagName: "div",
            allytmln: step.id,
            href: "#",
          });
          setActiveStep(stepIndex);
        }
      }}
    >
      <StepNumber stepIndex={stepIndex} />
      <StepHeading
        ref={titleRef}
        id={step.title.id}
        aria-label={ariaLabel}
        tag={step.title.headingLevel || "h2"}
        size="sm"
        tabIndex={-1}
        isStepActive={!!isStepActive(stepIndex)}
        isStepComplete={!!isStepComplete(stepIndex)}
      >
        {step.title.text}
      </StepHeading>
    </StepTitleWrapper>
  );
};

export { StepTitle };
