import { createContext, useContext } from 'react'
import { PublicRowStateProps } from '../types'
import { CellDrawerProps } from './useDrawer'

export interface TableCellHookValues<D extends Record<string, any>, R = unknown>
  extends PublicRowStateProps<R>,
    CellDrawerProps {
  data: D
}

const TableCellContext = createContext<TableCellHookValues<any, any>>(
  null as unknown as TableCellHookValues<any, any>,
)

export const TableCellProvider = TableCellContext.Provider

export const useTableCell = <
  D extends Record<string, any>,
  R = undefined,
>(): TableCellHookValues<D, R> => useContext(TableCellContext)
