import { Control, Controller, FieldErrors, Path } from "react-hook-form";
import { DeliveryFormFieldStyle } from "../FormStyles";
import { toCurrency } from "../../../utils/currency";
import { FeeDescriptionStyle, OptionalFormFieldContainer, OptionalFormFieldStyle, OtherFeeFormFieldStyle, OtherFeeWrapper, TrashIconStyled } from "../Edit-VehicleDetail/FormSectionStyles";
import { MutableRefObject } from "react";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { muiTrash } from "@ally/metronome-icons";

type BasicMoneyFieldProps<FormValues> = {
  control: Control<FormValues, any>;
  name: Path<FormValues>;
  labelContent: string;
  onBlurHandle: (e: React.ChangeEvent<HTMLInputElement>, reCalculateFields: boolean) => void;
  recalc: boolean;
  requiredRef?: MutableRefObject<boolean>;
  errors?: FieldErrors<any>
};

type OptionalBasicMoneyFieldProps<FormValues> = BasicMoneyFieldProps<FormValues> & {
  removeOptionalField: (e: any) => void;
};

type OptionalBasicMoneyFieldWithToolTipProps<FormValues> = OptionalBasicMoneyFieldProps<FormValues> & {
  dejarg: {body: string, topic: string, triggerAriaLabel: string};
};

export type MultipleElementField<FormValues> = Omit<BasicMoneyFieldProps<FormValues>, 'control'> & {
  textValidation: (text: string) => string | undefined;
  errorMsg?: string;
}

//wanted to put control here at this level and omit on MultipleElementField but needed for typing 
export type ComplexMoneyWithLabelFieldProps<FormValues> = {
  control: Control<FormValues, any>;
  moneyField: MultipleElementField<FormValues>;
  labelField: MultipleElementField<FormValues>;
  optLabelField?: MultipleElementField<FormValues>;
  removeOptionalFields: (e: any) => void;
  complexFieldHeader: string;
}

export const BasicMoneyField = <T extends object>({ control, name, labelContent, onBlurHandle, recalc, requiredRef, errors }: BasicMoneyFieldProps<T>) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if (requiredRef && requiredRef.current && isNullUndefinedOrEmpty(value)) {
            return `Please add ${labelContent.toLowerCase()} to continue`;
          }
        }
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <DeliveryFormFieldStyle
            variant="input"
            inputType="text"
            inputWidth="320px"
            labelContent={labelContent}
            maxLength={11}
            value={
              isNullUndefinedOrEmpty(value)
                ? ""
                : "$" +
                  (value as number).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChange(toCurrency(e.target.value, "."))
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              onBlurHandle(e, recalc);
            }}
            errorNotification={{
              message: errors[name]?.message as string
            }}
          />
        );
      }}
    />
  );
};


export const OptionalBasicMoneyField = <T extends object>({ control, name, labelContent, onBlurHandle, recalc, removeOptionalField }: OptionalBasicMoneyFieldProps<T>) => {

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        return (
          <OptionalFormFieldContainer>
            <OptionalFormFieldStyle
              variant="input"
              inputType="text"
              inputWidth="320px"
              labelContent={
                <>
                  {labelContent}
                  <TrashIconStyled
                    icon={muiTrash}
                    size="lg"
                    className="trash_icon" 
                    id="trash-icon"
                    ariaLabelledBy="Delete"
                    onClick={(e) => removeOptionalField(e)} 
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        removeOptionalField(e)
                      }
                    }}
                  />
                </>
              }
              value={
                isNullUndefinedOrEmpty(value)
                  ? ""
                  : "$" +
                    (value as number).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(toCurrency(e.target.value, "."))
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                onBlurHandle(e, recalc);
              }}
              maxLength={11}
            />
          </OptionalFormFieldContainer>
        );
      }}
    />
  );
};

export const OptionalBasicMoneyFieldWithToolTip = <T extends object>({ control, name, labelContent, onBlurHandle, recalc, removeOptionalField, dejarg }: OptionalBasicMoneyFieldWithToolTipProps<T>) => {

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => {
        return (
          <OptionalFormFieldContainer>
            <OptionalFormFieldStyle
              variant="input"
              inputType="text"
              inputWidth="320px"
              labelContent={
                <>
                  {labelContent}
                  <TrashIconStyled
                    icon={muiTrash}
                    size="lg"
                    className="trash_icon" 
                    id="trash-icon"
                    ariaLabelledBy="Delete"
                    onClick={(e) => removeOptionalField(e)}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        removeOptionalField(e)
                      }
                    }} 
                  />
                </>}
              value={
                isNullUndefinedOrEmpty(value)
                ? ""
                : "$" +
                  (value as number).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange(toCurrency(e.target.value, "."))
              }
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                onBlurHandle(e, recalc);
              }}
              maxLength={11}
              adornment={{
                variant: "custom",
                item: {
                  variant: "static",
                  dejarg: dejarg
                },
              }}
            />
          </OptionalFormFieldContainer>
        );
      }}
    />
  );
};


export const ComplexMoneyWithLabelField = <T extends object>({ moneyField, labelField, optLabelField, control, removeOptionalFields, complexFieldHeader}: ComplexMoneyWithLabelFieldProps<T>) => {

  return (
    <OtherFeeWrapper>
      <div className="trash_container">
        <h4>{complexFieldHeader}</h4> 
        <TrashIconStyled
          icon={muiTrash}
          size="lg"
          className="trash_icon" 
          id="trash-icon"
          ariaLabelledBy="Delete"
          onClick={(e) => removeOptionalFields(e)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              removeOptionalFields(e)
            }
          }}
        />
      </div>
      <div className="other_fee">
        <FeeDescriptionStyle>
          <Controller
            control={control}
            name={labelField.name}
            rules={{
              validate: (value) => {
                return labelField.textValidation(value as string);
              },
              minLength: {
                value: 2,
                message: "Please provide at least two characters.",
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <OtherFeeFormFieldStyle
                  variant="input"
                  inputType="text"
                  inputWidth="300px"
                  labelContent={labelField.labelContent}
                  maxLength={50}
                  value={value ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // cant have more than 2 spaces in a row, must start with a Word and everything else must be word or space
                    return onChange(e.target.value.replace(/ {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g, ""));
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    labelField.onBlurHandle(e, false);
                  }}
                  errorNotification={{
                    message: labelField.errorMsg
                  }}
                />
              );
            }}
          />
        </FeeDescriptionStyle>
        <Controller
          control={control}
          name={moneyField.name}
          render={({ field: { value, onChange } }) => {
            return (
              <OtherFeeFormFieldStyle
                variant="input"
                inputType="text"
                inputWidth="300px"
                labelContent={moneyField.labelContent}
                maxLength={11}
                value={isNullUndefinedOrEmpty(value)
                  ? ""
                  : "$" +
                    (value as number).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChange(toCurrency(e.target.value, "."))
                }
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  moneyField.onBlurHandle(e, moneyField.recalc);
                }}
              />
            );
          }}
        />
      </div>
      {!!optLabelField && (
        <div className="for_label">
        <FeeDescriptionStyle>
          <Controller
            control={control}
            name={optLabelField.name}
            rules={{
              validate: (value) => {
                return optLabelField.textValidation(value as string);
              },
              minLength: {
                value: 2,
                message: "Please provide at least two characters.",
              },
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <OtherFeeFormFieldStyle
                  variant="input"
                  inputType="text"
                  inputWidth="300px"
                  labelContent={optLabelField.labelContent}
                  maxLength={50}
                  value={value ?? ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    // cant have more than 2 spaces in a row, must start with a Word and everything else must be word or space
                    return onChange(e.target.value.replace(/ {2,}|^[^A-Za-z0-9]|[^A-Za-z0-9 ]/g, ""));
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    optLabelField.onBlurHandle(e, false);
                  }}
                  errorNotification={{
                    message: optLabelField.errorMsg
                  }}
                />
              );
            }}
          />
        </FeeDescriptionStyle>
        </div>
      )}
    </OtherFeeWrapper>
  );
};
