import React from "react";
import {
  TextBody,
  TextHeading,
} from "@ally/metronome-ui";
import "moment-timezone";
import { CancelContractCreationButtonStyle, ContinueContractActionButtonStyle, ContractModalActionButtonContainer, TextBodyStyle } from "./ContractModalsStyled";
import { ContractActionButtonStyled } from "../Tab/DeliveryGroupTabViews/DeliveryGroupDetails/DeliveryGroupDetailsStyled";


export const CreateContractModalTrigger: React.FC<{
  openModal?: Function;
}> = ({ openModal }) => {

  return (
    <ContractActionButtonStyled
      variant="primary"
      text="Create Contract"
      allytmln="contract-ready"
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => openModal()}
      aria-label="Contract Ready"
    />
  );
};

export type CancelModalContentProps = {
  closeModal?: () => void;
  setActions: () => void;
  headingText?: string;
};

export type CreateContractModalContentProps = {
  createContract: (closeModal?: () => void) => void;
  isContractCreationRequestPending: boolean;
  errors: string[];
  setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  closeModal?: () => void;
};

const initCreateModalContent = ({ createContract, isContractCreationRequestPending, setShowContractModal }: CreateContractModalContentProps) => {
  return (
    <>
      <TextHeading size="sm" tag="h1" focusOnMount tabIndex={-1}>
        Create Contract
      </TextHeading>
      <TextBody size="md" tag="span">
        <p>
          Before you create your contract, make sure all your information is correct.
          If there are any errors, funding can take longer.
          You can cancel your contract to update any incorrect information.
        </p>
        <p>
          You can <b>Go Back</b> to double check everything
          and select <b>Continue</b> to create your contract.
        </p>
      </TextBody>

      <ContractModalActionButtonContainer>
        <ContinueContractActionButtonStyle
          aria-label="continue"
          text="Continue"
          variant="primary"
          allytmln="confirm-create-contract"
          loading={isContractCreationRequestPending}
          onClick={() => { createContract() }}
        />
        <CancelContractCreationButtonStyle
          text="Go Back"
          aria-label="Go Back"
          allytmln="cancel-create-contract"
          variant="secondary"
          onClick={() => {
            setShowContractModal(false);
          }}
        />
      </ContractModalActionButtonContainer>

    </>
  );
}
const errorModal = ({ errors }: CreateContractModalContentProps) => {
  return (
    <>
      <TextHeading size="sm" tag="h1" focusOnMount tabIndex={-1}>
        Can&#39;t Create Contract
      </TextHeading>
      <TextBodyStyle size="md" tag="span">
        <>
          <p>Some of the information in this delivery group isn’t correct or is missing. Update the items listed below to create your contract.</p>
          {errors.map((e, i) => <p key={i}>{e}</p>)}
        </>
      </TextBodyStyle>
    </>
  );
}
export const CreateContractModalContent: React.FC<CreateContractModalContentProps> = ({
  createContract,
  isContractCreationRequestPending,
  errors,
  setShowContractModal
}) => {
  const closeModal = () => setShowContractModal(false);
  return (errors && errors.length > 0)
    ? errorModal({ closeModal, createContract, isContractCreationRequestPending, errors, setShowContractModal })
    : initCreateModalContent({ createContract, closeModal, isContractCreationRequestPending, errors, setShowContractModal });
};
