import {useQuery} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { contractSnapshotsOfAssetContractsKey } from "../../utils/react-query-key-factory";
import { ContractSnapshot, contractSummaryDtoToModel } from "../../model/asset-contract.model";
import { useHistory } from "react-router";



export function useContractSnapshotAssetContracts(dealershipNumber: string, customerId: string, deliveryGroupId: string){
  const {get} = useHttp();
  const analytics = useAnalytics();
  const history = useHistory();
  
  async function getAssetContractsFromBackend(get: <Result>(url: string) => Promise<Result|null>,
  dealershipNumber: string, deliveryGroupId: string): Promise<ContractSnapshot>{
  try {
    const endpoint = `dealership/${dealershipNumber}/delivery-groups/${encodeURIComponent(deliveryGroupId)}/contract-summary`;
    const res = await get<any>(endpoint);
    return contractSummaryDtoToModel(res);
  }
  catch(err)
  {
    const errorString = err +'';
    if(errorString.includes('404') || errorString.includes('400')) {
      history.push('/not-found');
    } else {
      console.error("Oops something went wrong. Please try again later ", err);
    }
  }
}
  const key = contractSnapshotsOfAssetContractsKey(dealershipNumber, customerId, deliveryGroupId);
  return useQuery(key, () => getAssetContractsFromBackend(get, dealershipNumber, deliveryGroupId),
   {  
    onError(error){
      analytics(emitErrorEvent, (error as any).message ?? "get contract summary data error", (error as any).status ?? 500);
    },  
    enabled: customerId !== undefined
  });
}
