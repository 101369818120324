const setupLogRocket = require('logrocket/setup');

const logrocketEnv = process.env.REACT_APP_LOGROCKET;

const LogRocket = setupLogRocket({
  sdkServer: 'https://cdn.lr-in-prod.com',
  ingestServer: 'https://r.lr-in-prod.com',
});

LogRocket.init(logrocketEnv, {
  network: {
    requestSanitizer: request => {
      if (request.url.toLowerCase().indexOf('csg-portal') !== -1) {
        return null;
      }
      
      return request;
    }
  }
});

export { LogRocket };
