import { Button, Icon, Select } from "@ally/metronome-ui";
import styled from "styled-components";

export const LabelStyle = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
`;

export const SelectedDropdownTrigger = styled(Select)`
  width: 535px;
  margin-bottom: 44px;
  box-sizing: border-box;
  color: #959595;
  box-shadow: none;

  select {
    background-color: #ffffff;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    color: #2a2a2a;
    font-style: normal;
  }
`;

export const TitleStyle = styled.h1`
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 18px;
`;

export const ParagraphContent = styled.p`
  height: 40px;
  width: 460px;
  color: #2A2A2A;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 42px;
`;

export const ButtonStyle = styled(Button)`
  min-width: 106px;
  min-height: 44px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const CancelButtonStyle = styled(Button)`
  min-width: 106px;
  min-height: 44px;
  font-size: 14px;
  color: #0071c4;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
  border-radius: 4px;
`;

export const StyledIcon = styled(Icon)`
  padding-top: 0px;
  margin-right: 10px;
  margin-top: 5px;
`;

export const IconContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;
