import React, { useEffect, useState } from 'react'
import { ColumnInstance } from 'react-table'
import styled from 'styled-components'
import { useAriaLive } from '@ally/use-aria-live'
import SortIndicator from './SortIndicator'
import { CustomColumnOptions } from '../types'
import { Box } from '../../Box'
import { getFlexAlignment } from '../utils'
import { get } from '@ally/metronome-ui'

type StyledThProps = Pick<CustomColumnOptions, 'alignment'>

type AriaSortValues = 'none' | 'ascending' | 'descending'

const StyledTh = styled.th<StyledThProps>`
  background-color: 'inherit';
  height: 34px;
  text-align: ${({ alignment }): string => alignment || 'center'};
  color: ${get.colors('slate-5')};
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: normal;
  padding: 0 10px;

  &:last-child {
    border-right: 0;
  }
  &:hover {
    background-color: ${get.colors('patina-2')};
  }
`

const Header = <D extends Record<string, any>>({
  column: {
    id,
    ariaLabel,
    isSorted = false,
    isSortedDesc = false,
    isSortable,
    getHeaderProps,
    getSortByToggleProps,
    render,
    alignment,
  },
}: {
  column: ColumnInstance<D>
}): React.ReactElement => {
  const { announcePolite } = useAriaLive()
  const ref = React.useRef<HTMLTableHeaderCellElement>(null)

  const getAriaSort = (): AriaSortValues => {
    if (!isSorted) return 'none'
    if (isSortedDesc) return 'descending'
    return 'ascending'
  }

  const getFriendlyColumnName = (): string => {
    if (ariaLabel) return ariaLabel
    const textContent = ref.current?.textContent
    if (textContent) return textContent
    return id
  }

  const getMessage = (): string => {
    if (isSorted) {
      return `Sorted by ${getFriendlyColumnName()} ${
        isSortedDesc ? 'descending' : 'ascending'
      }`
    }
    return 'Not sorted'
  }

  const [sortChanged, setSortChanged] = useState(false)
  useEffect(() => {
    if (sortChanged) {
      announcePolite(getMessage())
      setSortChanged(false)
    }
  }, [isSorted, isSortedDesc])

  const [columnName, setColumnName] = useState('')

  // set column name after mount since it relies on the header being rendered
  useEffect(() => {
    setColumnName(getFriendlyColumnName())
  }, [])

  if (isSortable) {
    return (
      <StyledTh
        scope="col"
        {...getHeaderProps()}
        aria-sort={getAriaSort()}
        {...(ariaLabel && { 'aria-label': ariaLabel })}
        ref={ref}
        alignment={alignment}
      >
        <Box onClick={(): void => setSortChanged(true)}>
          <Box
            display="flex"
            alignItems="center"
            role="button"
            {...getSortByToggleProps({ title: '' })}
            justifyContent={getFlexAlignment(alignment)}
            data-track-elem="button"
            data-track-name={`${columnName} (sort)`}
            data-track-state={getAriaSort()}
          >
            {render('Header')}
            {isSorted && <SortIndicator {...{ isSorted, isSortedDesc }} />}
          </Box>
        </Box>
      </StyledTh>
    )
  }

  return (
    <StyledTh
      scope="col"
      {...(ariaLabel && { 'aria-label': ariaLabel })}
      alignment={alignment}
      {...getHeaderProps()}
    >
      {render('Header')}
    </StyledTh>
  )
}

export default Header
