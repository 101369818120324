
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import DeliveryGroupDetailsWithAssets from "./DeliveryGroupDetails/DeliveryGroupDetailsWithAssets";
import { useContext, useEffect, useState } from "react";
import { TabContext, ContextType } from "../TabContext";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import { CreateContractModalContent, CreateContractModalTrigger } from "../../Modal/CreateContractModal";
import { useCreateContract } from "../../../hooks/contractHooks/useCreateContract";
import { ContractModalStyled } from "../../Modal/ContractModalsStyled";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { useParams } from "react-router";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { useGetDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { InsuranceModalStyled } from "./InsurancePolicyStyled";
import { ForgotInsuranceModalContent } from "../../Modal/ForgotInsurancePolicyModal";
import { useEditDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useEditDeliveryGroup";

type Props = {
  isActiveTab: boolean;
  showContractModal: boolean;
  setShowContractModal: React.Dispatch<React.SetStateAction<boolean>>;
  showForgotInsuranceModal: boolean;
  setShowForgotInsuranceModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreateContract: React.FC<Props> = ({
  isActiveTab,
  showContractModal,
  setShowContractModal,
  showForgotInsuranceModal,
  setShowForgotInsuranceModal
}) => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const {
    saveActiveDeliveryGroupName,
    activeDeliveryGroupId,
    isContractCreationRequestPending,
    saveIsContractCreationRequestPending,
  } = useContext(TabContext) as ContextType;

  const { id: customerId } = useParams() as any;

  const submitContractCreationRequest = useCreateContract(dealershipNumber);
  const [err, setErr] = useState<string[]>([]);
  const { data: deliveryGroup } = useGetDeliveryGroup(dealershipNumber, activeDeliveryGroupId);
  const editDeliveryGroup = useEditDeliveryGroup(dealershipNumber, customerId, activeDeliveryGroupId);

  useEffect(() => {
    if (isActiveTab) {
      analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_CREATION_READY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);

  const createContractAction = (closeModal?: () => void) => {
    const onSuccess = () => {
      saveIsContractCreationRequestPending(false);
      if (closeModal) {
        closeModal();
      }
    }
    const onError = (errs: string[]) => {
      setErr(errs);
      saveIsContractCreationRequestPending(false);
    }

    saveIsContractCreationRequestPending(true);
    submitContractCreationRequest.mutate({
      deliveryGroupId: activeDeliveryGroupId!,
      customerId: customerId,
      onSuccess,
      onError,
    });
  }

  const insurancePolicyName = deliveryGroup.insurancePolicy?.name;

  const progressTracker = <ContractProgressTracker stepNumber={1} />;

  let modal;
  if (showContractModal || insurancePolicyName) {
    modal = <ContractModalStyled
      trigger={<CreateContractModalTrigger openModal={() => setShowContractModal(true)} />}
      content={
        <CreateContractModalContent
          isContractCreationRequestPending={isContractCreationRequestPending}
          createContract={createContractAction}
          errors={err}
          setShowContractModal={setShowContractModal}
        />
      }
      open={showContractModal}
      onOpenChange={newOpen => setShowContractModal(newOpen)}
      disableFullScreenSmDown={true}
    />
  } else {
    modal = <InsuranceModalStyled
      trigger={<CreateContractModalTrigger openModal={() => setShowForgotInsuranceModal(true)} />}
      content={<ForgotInsuranceModalContent
        editDeliveryGroup={editDeliveryGroup}
        setShowContractModal={setShowContractModal}
        deliveryGroupInfo={deliveryGroup}
      />}
      open={showForgotInsuranceModal}
      onOpenChange={newOpen => setShowForgotInsuranceModal(newOpen)}
      disableFullScreenSmDown={true}
    />
  }

  return (
    <>
      <DeliveryGroupHeader />
      <DeliveryGroupDetailsWithAssets
        actionModal={modal}
        progressTracker={progressTracker}
        updateDeliveryGroupName={saveActiveDeliveryGroupName}
        deliveryGroupStatus={"READY_FOR_CONTRACT"}
        sortTable={false}
        setShowContractModal={setShowContractModal}
      />
    </>
  );
};

export default CreateContract;
