import { CONTRACT_STATE } from "../constants/enums";
import { Contract } from "../model/contract.model";

export type PreviousState = {
  contractState: CONTRACT_STATE | null;
  deliveryGroupId?: string;
}
type Input = {
  prevContractState: PreviousState
  contract: Contract;
  deliveryGroupId?: string;
  deliveryGroupAction?: string;
}

export class Machine {
  private sourceNode: Node;

  constructor(enableDocuSignFlow: boolean, enableDdsFileSubmissionFlow: boolean) {
    this.initializeMachine(enableDocuSignFlow, enableDdsFileSubmissionFlow);
  }
  public run(prevContractState: PreviousState,
    contract: Contract,
    deliveryGroupId?: string,
    deliveryGroupAction?: string): CONTRACT_STATE {

    const input = {
      prevContractState,
      contract,
      deliveryGroupId,
      deliveryGroupAction,
    };

    const state = this.sourceNode.evaluate(input);
    const defaultState = CONTRACT_STATE.CONTRACT_READY_FOR_CREATION;

    const res = state || defaultState;
    return res;
  }

  private initializeMachine(enableDocuSignFlow: boolean, enableDdsFileSubmissionFlow: boolean) {
    const states = this.buildStates(enableDocuSignFlow, enableDdsFileSubmissionFlow);
    this.sourceNode = this.linkStates(states, enableDocuSignFlow);
  }

  private buildStates(enableDocuSignFlow: boolean, enableDdsFileSubmissionFlow: boolean) {
    if (enableDocuSignFlow) {
      return {
        createDg: new Node(
          (input: Input) => input.deliveryGroupAction === "create-group" ? CONTRACT_STATE.DG_READY_FOR_CREATION : null
        ),
        noGroup: new Node(
          (input: Input) => (!input.deliveryGroupId) ? CONTRACT_STATE.NOT_IN_CONTRACT : null
        ),
        inputStillPending: new Node((input: Input) => {
          const loading = input.contract.isHydrating;
          const shouldLoading = input.prevContractState.contractState === null ||
            (input.deliveryGroupId && input.prevContractState.deliveryGroupId !== input.deliveryGroupId);
          const res = shouldLoading ? CONTRACT_STATE.LOADING : input.prevContractState.contractState;
          return loading ? res : null;
        }),
        contractFundingComplete: new Node(({ contract }) => {
          const inkSignFlow = contract.deliveryGroup?.contract_status === "FUNDING_CONFIRMED" && contract.deliveryGroup?.signingOption === "INK_SIGN";
          const eSignFlow = contract.deliveryGroup?.contract_status === "FUNDING_CONFIRMED" && (contract.deliveryGroup?.signingOption === "EMBEDDED_SIGN" || contract.deliveryGroup?.signingOption === "EMBEDDED_SEND");
          if (enableDdsFileSubmissionFlow) {
            return (inkSignFlow
              ? CONTRACT_STATE.CONTRACT_FUNDING_CONFIRMED
              : eSignFlow
                ? CONTRACT_STATE.SUBMIT_FOR_FUNDING
                : null
            );          
          }
          return contract.deliveryGroup?.contract_status === "FUNDING_CONFIRMED"
            ? CONTRACT_STATE.CONTRACT_FUNDING_CONFIRMED
            : null;
        }),
        contractFinalizeComplete: new Node(({ contract }) => {
          const inkSignFlow = contract.deliveryGroup?.contract_status === "CONTRACT_FINALIZED" && contract.deliveryGroup?.signingOption === "INK_SIGN";
          const eSignFlow = contract.deliveryGroup?.contract_status === "CONTRACT_FINALIZED" && (contract.deliveryGroup?.signingOption === "EMBEDDED_SIGN" || contract.deliveryGroup?.signingOption === "EMBEDDED_SEND");
          if (enableDdsFileSubmissionFlow) {
            return (inkSignFlow
            ? CONTRACT_STATE.CONTRACT_CREATED_MAIL_CONTRACT
              : eSignFlow
                ? CONTRACT_STATE.CONTRACT_CREATED_SEND_CONTRACT
                : null
            );
          } else {
            return (
              contract.deliveryGroup?.contract_status === "CONTRACT_FINALIZED"
                ? CONTRACT_STATE.CONTRACT_CREATED_MAIL_CONTRACT
                : null
            );
          }
        }),
        contractCreationRequested: new Node(({ contract }) => {
          return (
            ((contract.physicalContractCreatedSuccessfully || contract.physicalContractPending)
              && contract.deliveryGroup?.signingOption === "NONE" && enableDocuSignFlow)
              ? CONTRACT_STATE.CONTRACT_CREATED_SIGNING_OPTIONS
              : null);
        }),
        contractCreatedCardSelected: new Node(({ contract }) => {
          return (
            ((contract.physicalContractCreatedSuccessfully || contract.physicalContractPending)
              && contract.deliveryGroup?.signingOption !== "NONE" && enableDocuSignFlow)
              ? CONTRACT_STATE.CONTRACT_CREATED_CARD_SELECTED
              : null);
        }),
        editDg: new Node(
          ({ deliveryGroupAction }) => deliveryGroupAction === "edit-group" ? CONTRACT_STATE.DG_READY_TO_EDIT : null
        ),
        deliveryGroupToBeFinalized: new Node(({ contract }) => {
          const assets = contract.assetContracts ?? []
          const notAllMarkedAsComplete = (assets.length === 0) || assets.some(a => !a.isMarkedAsComplete);

          return notAllMarkedAsComplete
            ? CONTRACT_STATE.DG_CREATED_BUT_NOT_FINALIZED
            : null;
        }),
        contractReadyForCreation: new Node(
          (input: Input) => CONTRACT_STATE.CONTRACT_READY_FOR_CREATION
        ),
      };
    } else {
      return {
        createDg: new Node(
          (input: Input) => input.deliveryGroupAction === "create-group" ? CONTRACT_STATE.DG_READY_FOR_CREATION : null
        ),
        noGroup: new Node(
          (input: Input) => (!input.deliveryGroupId) ? CONTRACT_STATE.NOT_IN_CONTRACT : null
        ),
        inputStillPending: new Node((input: Input) => {
          const loading = input.contract.isHydrating;
          const shouldLoading = input.prevContractState.contractState === null ||
            (input.deliveryGroupId && input.prevContractState.deliveryGroupId !== input.deliveryGroupId);
          const res = shouldLoading ? CONTRACT_STATE.LOADING : input.prevContractState.contractState;
          return loading ? res : null;
        }),
        contractFundingComplete: new Node(({ contract }) => {
          return contract.deliveryGroup?.contract_status === "FUNDING_CONFIRMED"
            ? CONTRACT_STATE.FUNDING_CONTRACT_COMPLETE
            : null;
        }),
        contractFinalizeComplete: new Node(({ contract }) => {
          return contract.deliveryGroup?.contract_status === "CONTRACT_FINALIZED"
            ? CONTRACT_STATE.FINALIZE_CONTRACT_COMPLETE
            : null;
        }),
        contractCreationRequested: new Node(({ contract }) => {
          return contract.physicalContractCreatedSuccessfully || contract.physicalContractPending
            ? CONTRACT_STATE.CONTRACT_CREATION_REQUESTED
            : null;
        }),
        editDg: new Node(
          ({ deliveryGroupAction }) => deliveryGroupAction === "edit-group" ? CONTRACT_STATE.DG_READY_TO_EDIT : null
        ),
        deliveryGroupToBeFinalized: new Node(({ contract }) => {
          const assets = contract.assetContracts ?? []
          const notAllMarkedAsComplete = (assets.length === 0) || assets.some(a => !a.isMarkedAsComplete);

          return notAllMarkedAsComplete
            ? CONTRACT_STATE.DG_CREATED_BUT_NOT_FINALIZED
            : null;
        }),
        contractReadyForCreation: new Node(
          (input: Input) => CONTRACT_STATE.CONTRACT_READY_FOR_CREATION
        )
      };
    }
  }
  private linkStates(states: any, enableDocuSignFlow: boolean) {
    const source = new Node(_ => null);
    if (enableDocuSignFlow) {
      source
        .next(states.createDg)
        .next(states.noGroup)
        .next(states.inputStillPending)
        .next(states.contractFundingComplete)
        .next(states.contractFinalizeComplete)
        .next(states.contractCreationRequested)
        .next(states.contractCreatedCardSelected)
        .next(states.editDg)
        .next(states.deliveryGroupToBeFinalized)
        .next(states.contractReadyForCreation)
    } else {
      source
        .next(states.createDg)
        .next(states.noGroup)
        .next(states.inputStillPending)
        .next(states.contractFundingComplete)
        .next(states.contractFinalizeComplete)
        .next(states.contractCreationRequested)
        .next(states.editDg)
        .next(states.deliveryGroupToBeFinalized)
        .next(states.contractReadyForCreation)
    }
    return source;
  }
}

class Node {
  constructor(predicate: (input: Input) => CONTRACT_STATE | null) {
    this.predicate = predicate;
  }
  public evaluate(input: Input): CONTRACT_STATE | null {
    const thisRule = this.predicate(input);
    return thisRule || (this?.nextPredicate?.evaluate(input) ?? null)
  }
  public next(n: Node) {
    this.nextPredicate = n;
    return n;
  }
  private predicate: (input: Input) => CONTRACT_STATE | null;
  private nextPredicate?: Node
};
