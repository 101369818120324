import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReadAssetContract } from "../../model/asset-contract.model";
import { useHttp } from "../adaptorHooks/useHttp";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ValidationError } from "../adaptorHooks/validationError";
import { unassignedVehiclesKey, assignedVehiclesKey, JOIN_AC_MUTATION_KEY_ROOT, ASSET_CONTRACT_QUERY_KEY_ROOT } from "../../utils/react-query-key-factory";

export type BatchJoinResult = {
  applicationId: string,
  status: number,
  errors: {
    parameter: string,
    message: string
  }[]
};

type BatchJoinBody = {
  applicationIds: string[]
}

async function joinAcWithDgBatch(put: <In, Out>(url: string, body: In) => Promise<Out | null>,
  dealershipNumber: string, applicationIds: string[], deliveryGroupId: string): Promise<BatchJoinResult[]> {
  const joinEndpoint = `dealership/${dealershipNumber}/delivery-groups/${encodeURIComponent(deliveryGroupId)}/add`;
  const body = {
    applicationIds: applicationIds
  };

  return put<BatchJoinBody, BatchJoinResult[]>(joinEndpoint, body);
}


export type BatchJoinIds = {
  applicationIds: string[];
  deliveryGroupId: string;
  customerId: string;
  onError?: (error: ValidationError[]) => void;
  onSuccess?: (res: BatchJoinResult[]) => void;
}

export function useAddBatchAssetContractToDeliveryGroup(dealershipNumber: string) {
  const { put } = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation(
    [JOIN_AC_MUTATION_KEY_ROOT],
    async (ids: BatchJoinIds) => await joinAcWithDgBatch(put, dealershipNumber, ids.applicationIds, ids.deliveryGroupId),
    {
      onMutate: async (ids: BatchJoinIds) => {
        const vehiclesKey = assignedVehiclesKey(dealershipNumber, ids.customerId, ids.deliveryGroupId);
        ids.applicationIds.forEach(applicationId => {
          queryClient.setQueryData<ReadAssetContract | null>([ASSET_CONTRACT_QUERY_KEY_ROOT, { dealershipNumber }, { applicationId }], _ => {
            return null
          });
        });

        await queryClient.cancelQueries(vehiclesKey)
      },
      onSettled: (data, error, ids: BatchJoinIds) => {
        const vehiclesForDgKey = assignedVehiclesKey(dealershipNumber, ids.customerId, ids.deliveryGroupId);
        const vehiclesForNoDgKey = unassignedVehiclesKey(dealershipNumber, ids.customerId);
        queryClient.invalidateQueries(vehiclesForDgKey);
        queryClient.invalidateQueries(vehiclesForNoDgKey);

        ids.applicationIds.forEach(applicationId => {
          queryClient.invalidateQueries([ASSET_CONTRACT_QUERY_KEY_ROOT, { dealershipNumber }, { applicationId }]);
        })
      },
      onError: (error: ValidationError[], req: BatchJoinIds, context) => {
        if (error instanceof ValidationError) {
          req.onError(error);
        } else {
          console.error("Oops something went wrong. Please try again later ", error);
        }
        analytics(emitErrorEvent, (error as any).message ?? "add asset to delivery group error", (error as any).status ?? 500);
      },

      onSuccess: (data, req, context) => {
        req.onSuccess?.(data);
      },
    }
  );
}
