import { Alert, Button } from "@ally/metronome-ui";
import styled from "styled-components";

export const TableCaption = styled.h2`
  text-align: left;
  color: #2a2a2a;
  font-weight: bold;
  font-size: 18px;
  line-height: 54px;
  height: 54px;
`;

export const ButtonStyle = styled(Button)`
  min-width: 106px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 24px;
  border-radius: 4px;
`;

export const DoneButtonStyle = styled(Button)`
  height: 44px;
  width: 82px;
`;

export const InfoHeader = styled.p`
  line-height: 20px;
  color: #000000;
  max-width: 300px;
  max-height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #0071c4;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
`;

export const StyledAlert = styled(Alert)`
  max-width: 1003px;
`;
