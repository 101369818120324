import { Identifiable } from "./identifiable";

export type WriteVehicle  = 
{
  appId: string;
  customerId: string;
  appDecision: string;
  vin?: string;
  dealStatus: string;
  condition: string;
  year: number,
  make: string,
  model: string,
  odometer?: number,
  grossWeight?: number,
  isAssignedDeliveryGroup: boolean,
  lastUpdatedDate: Date | null;
};
export type ReadVehicle = WriteVehicle & Identifiable & {
  isMarkedAsComplete: boolean,
  cashPrice: number, 
  totalDownPayment: number,
  totalOtherCharges: number,
  taxesAndFees: number,
  numberOfPayments: number,
  annualPercentageRate: number,
  amountFinanced: number,
  monthlyPayment: number,
  hasTradeIn: boolean,
};

export function vehicleDtoToModel(json: any) : ReadVehicle{
  if(!json){
    throw new Error("Oh no! vehicle dto mapping failed");
  }

  return {
    id: json.applicationId,
    appId: json.applicationId,
    appDecision: json.appDecision,
    vin: json.vin, 
    dealStatus: json.dealStatus,
    condition: json.condition,
    year: json.year,
    make: json.make?.trim() ?? "",
    model: json.model?.trim() ?? "",
    odometer: json.odometer,
    grossWeight: json.grossWeight,
    amountFinanced: json.amountFinanced,
    taxesAndFees: json.taxesAndFees,
    cashPrice: json.cashPrice,
    totalOtherCharges: json.totalOtherCharges,
    totalDownPayment: json.totalDownPayment,
    numberOfPayments: json.numberOfPayments,
    annualPercentageRate: json.annualPercentageRate,
    monthlyPayment: json.monthlyPayment,
    hasTradeIn: json.hasTradeIn,
    isMarkedAsComplete: json.dealStatus === "READY_FOR_CONTRACT",
    isAssignedDeliveryGroup: json.deliveryGroupId ?? false,
    lastUpdatedDate: json.lastUpdated ? new Date(json.lastUpdated) : null,
    customerId: json.customerId,
  };
}
