import { Icon } from "@ally/metronome-ui";
import { useState } from "react";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import VerifyEmailField from "./VerifyEmailField";
import { EmailFieldsOuter, NotificationStyled, VerifyEmailsStyled } from "./VerifyEmailsStyled";
import { muiCheckCircleFill, muiClearXFill } from "@ally/metronome-icons";

type VerifyEmailsType = {
    deliveryGroup: ReadDeliveryGroup,
}

export enum EmailType {
    buyerSigner = "buyerSignerEmail",
    cobuyer = "cobuyerEmail",
    seller = "sellerEmail",
  }
  
const VerifyEmails: React.FC<VerifyEmailsType> = ({ deliveryGroup }) => {
    const [saveNotification, setSaveNotification] = useState(null);

    const validateEmail = (email: string) => {
        const emailPattern = new RegExp(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        );

        return emailPattern.test(email);
    };

    const convertToTitleCase = (string: string) => {
        if (!string) {
            return string;
        }

        const lowerCaseStr = string.toLowerCase();
        const splitStr = lowerCaseStr.split(' ');

        return splitStr.map((word: string) => {
            return word.charAt(0).toUpperCase().concat(word.substring(1));
        }).join(' ');
    }

    let cobuyerOrGuarantor = undefined;
    if (deliveryGroup.coBuyerOrGuarantorType === "CO_BUYER" || (deliveryGroup.coBuyerOrGuarantorType === "GUARANTOR" && deliveryGroup.dealerState === "IL")) {
        cobuyerOrGuarantor = (
            <VerifyEmailField
                emailType={EmailType.cobuyer}
                name={deliveryGroup.coBuyerName ? deliveryGroup.coBuyerName : deliveryGroup.guarantorName}
                email={deliveryGroup.coBuyerOrGuarantorEmail}
                setSaveNotification={setSaveNotification}
                validateEmail={validateEmail}
                convertToTitleCase={convertToTitleCase}
                saveNotification={saveNotification}
            />
        )
    }

    return (
        <VerifyEmailsStyled>
            <h3>Verify emails</h3>
            <h4>The following email addresses will receive the contract for signatures.</h4>
            {saveNotification &&
                <NotificationStyled>
                    <Icon
                        icon={saveNotification.success ? muiCheckCircleFill : muiClearXFill}
                        fill={saveNotification.success ? "success" : "error"}
                        size="lg"
                    />
                    <p>
                        {saveNotification.success ?
                            `Successfully updated email for ${convertToTitleCase(saveNotification.name)}.`
                            : `We could not update the email address for ${convertToTitleCase(saveNotification.name)} at this time, please try again later.`}
                    </p>
                </NotificationStyled>
            }
            <EmailFieldsOuter>
                <VerifyEmailField
                    emailType={EmailType.seller}
                    name={deliveryGroup.sellerName}
                    email={deliveryGroup.sellerEmail}
                    setSaveNotification={setSaveNotification}
                    validateEmail={validateEmail}
                    convertToTitleCase={convertToTitleCase}
                    saveNotification={saveNotification}
                />
                <VerifyEmailField
                    emailType={EmailType.buyerSigner}
                    name={deliveryGroup.customer_name}
                    email={deliveryGroup.customer_email}
                    setSaveNotification={setSaveNotification}
                    validateEmail={validateEmail}
                    convertToTitleCase={convertToTitleCase}
                    saveNotification={saveNotification}
                />
                {cobuyerOrGuarantor}
            </EmailFieldsOuter>
        </VerifyEmailsStyled >
    )
}

export default VerifyEmails;
