import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useGetCustomer } from "../../../hooks/customerHooks/useGetCustomer";
import {
  GoBackStyle,
  Heading,
  SteppedProcessWrapper,
  StyledBox,
  StyledIcon,
  StyledLink,
  StyledSection,
  SubHeader,
  Text,
} from "./EditVehicleDetailStyles";
import { useGetAssetContract } from "../../../hooks/assetContractHooks/useGetAssetContract";
import { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router";
import { useGetDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { SteppedProcessProvider } from "../../../library/SteppedProcess";
import EditVehicleFormSection from "./EditVechileFormSection";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { WriteAssetContractSectionsNames } from "../../../model/asset-contract.model";
import { singleCustomerPageRoute } from "../../../routes/RouteFactory";
import { Box } from "@ally/metronome-ui";
import { Link } from 'react-router-dom'
import { muiChevronLeft } from "@ally/metronome-icons";

function mapAssetContractSectionToStepProcessIndex(section: WriteAssetContractSectionsNames | string): number {
  switch(section){
    case 'buyerInformation':
      return 0;
    case 'coBuyerInformation':
      return 1;
    case 'vehicleDetails':
      return 2;
    case 'cashPrice':
      return 3;
    case 'tradeInCashDown':
      return 4;
    case 'sellerGovernmentFees':
      return 5;
    case 'auxProducts':
      return 6;
    case 'dealershipInformation':
      return 7;
    case 'truthInLendingAct':
      return 8;
    default:
      return -1;
  }
}

const EditVehicleDetail = () => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const location = useLocation() as any;
  const vehicle = location.state?.selectedVehicles[0];
  const { id: appId } = useParams() as any;
  const customerId = vehicle?.customerId;
  const { data: customer } = useGetCustomer(dealershipNumber, customerId);
  const { data: assetContract } = useGetAssetContract(dealershipNumber, appId);

  const { data: deliveryGroup } = useGetDeliveryGroup(
    dealershipNumber,
    assetContract?.deliveryGroupId
  );

  const [sectionsMarkedAsComplete, setSectionsMarkedAsComplete] = useState<number[] | null>(
    null
  );

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.EDIT_GROUP_VEHICLE);
    //TODO address unCheckedAll/analytics missing in dependency array
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mac: number[] | null = null;
    if (assetContract) {
      mac = []
      Object.entries(assetContract).forEach((data) => {
        if (data[1]) {
          let isMarkComplete: boolean = data[1]["isComplete"];
          const index = mapAssetContractSectionToStepProcessIndex(data[0]);
          if (isMarkComplete && index >= 0) {
            mac.push(index);
          }
        }
        mac.sort();
      });
      setSectionsMarkedAsComplete(mac);
    }
  }, [assetContract, assetContract?.appId]);

  // Navigate to not-found page when error is thrown
  const history = useHistory();
  if (!vehicle) {
    history.push('/not-found')
  }
  // TODO: loader?
  if (!assetContract || !deliveryGroup ) {
    return <Box mt="20px" ml="20px">Loading...</Box>;
  }

  const customerName = customer?.company;
  const yearMakeModel = vehicle?.yearMakeModel;
  const lastUpdatedDate = assetContract?.decisionDateTime;
  const appDecision = assetContract?.appDecision;
  const approvedFinanceAmount = assetContract?.approvedFinanceAmount;
  const buyRate = assetContract?.buyRate;

  const formatAppDecision = (value: string): string => {
    switch (value) {
      case "APPROVED":
        return "Approved";
      case "CONDITIONAL":
        return "Conditional";
      default:
        return "";
    }
  };

  const formatDate = (date: Date | null): string => {
    if (date == null) {
      return "";
    }

    return Intl.DateTimeFormat("en-US", {
      timeZone: "America/New_York",
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);
  };

  const formatTime = (date: Date | null): string => {
    if (date == null) {
      return "";
    }

    let time = Intl.DateTimeFormat("en-US", {
      timeZone: "America/New_York",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    }).format(date);
    return `${time} ET`;
  };

  const formatCurrency = (value: number | undefined): string => {
    if (!value) {
      return "";
    }

    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const pContent =
    "Fill out all required fields, double check your information, and mark each section as complete to get your vehicle ready for contract. You can save your progress at any time and come back later.";

  const macNewSection = (section: WriteAssetContractSectionsNames) => {
    const mac = (sectionsMarkedAsComplete ?? []);
    mac.push(mapAssetContractSectionToStepProcessIndex(section));
    const r = [...new Set(mac)].sort();
    setSectionsMarkedAsComplete(r);
  }


  return (
    <StyledBox>
      <GoBackStyle>
        <StyledIcon icon={muiChevronLeft} size="xl" ariaHidden />
        <StyledLink
          allytmln="back-to-single-customer-dashboard"
          text={`Back to ${customerName}`}
        >
          <Link to={singleCustomerPageRoute(customerId, deliveryGroup.id)} />
        </StyledLink>
      </GoBackStyle>

      <Heading>{yearMakeModel}</Heading>
      <StyledSection>
        <Text>
          <b>Ally app ID: {appId}</b>
        </Text>
        <br />
        <Text>
          <b>Ally App Decision:</b> {formatAppDecision(appDecision ?? "")}
        </Text>
        <Text>
          <b>Approval Date:</b>{" "}
          {`${formatDate(lastUpdatedDate)} - ${formatTime(lastUpdatedDate)}`}
        </Text>
        <Text>
          <b>Approved Finance Amount:</b>{" "}
          {formatCurrency(approvedFinanceAmount)}
        </Text>
        <Text>
          <b>Dealer Buy Rate:</b> {buyRate}%
        </Text>
      </StyledSection>
      <br />
      <SubHeader>{pContent}</SubHeader>
      <br />
      <SteppedProcessWrapper id="steppedprocess">
        <SteppedProcessProvider>
          {assetContract && sectionsMarkedAsComplete != null && (
            <EditVehicleFormSection
              assetContract={assetContract}
              deliveryGroup={deliveryGroup}
              sectionsMarkedAsComplete={sectionsMarkedAsComplete}
              macNewSection={macNewSection}
            />
          )}
        </SteppedProcessProvider>
      </SteppedProcessWrapper>
    </StyledBox>
  );
};

export default EditVehicleDetail;
