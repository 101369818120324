import { TextBody, useModalContext } from "@ally/metronome-ui"
import { InsuranceModalContent } from "../Tab/DeliveryGroupTabViews/InsurancePolicyStyled";
import InsurancePolicyForm from "./InsurancePolicyForm";
import { ReadDeliveryGroup } from "../../model/delivery-group.model";
import { useAnalytics } from "../../hooks/analytics/useAnalytics";
import { useEffect } from "react";
import { ModalViews, emitModalViewEvent } from "../../analytics/modalViews";

type ContentProps = {
    editDeliveryGroup: any,
    setShowContractModal?: React.Dispatch<React.SetStateAction<boolean>>,
    deliveryGroupInfo: ReadDeliveryGroup
}

export const ForgotInsuranceModalContent: React.FC<ContentProps> = ({ editDeliveryGroup, setShowContractModal, deliveryGroupInfo }) => {
    const { setOpen } = useModalContext(); 

    const analytics = useAnalytics();
    useEffect(() => {
        analytics(emitModalViewEvent, ModalViews.LAST_CHANCE_ADD_INSURANCE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <InsuranceModalContent tabIndex={-1}>
            <h2>
                Do you want to add insurance coverage information?
            </h2>
            <TextBody size="md" tag="span">
                <p>Enter the details below, or choose to send them later.</p>
            </TextBody>
            <InsurancePolicyForm
                closeModal={() => setOpen(false)}
                deliveryGroupInfo={deliveryGroupInfo}
                modalType="forgot"
                editDeliveryGroup={editDeliveryGroup}
                setShowContractModal={setShowContractModal}
            />

        </InsuranceModalContent>
    )
}
