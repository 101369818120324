import { HTMLAttributes } from 'react'
import styled, { StyledComponent, DefaultTheme } from 'styled-components'
import {
  border,
  BorderProps,
  grid,
  GridProps,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  flexbox,
  FlexboxProps,
  color,
  ColorProps,
  compose,
  typography,
  TypographyProps,
} from 'styled-system'

export type BoxProps = SpaceProps &
  LayoutProps &
  PositionProps &
  FlexboxProps &
  ColorProps &
  TypographyProps &
  GridProps &
  BorderProps &
  HTMLAttributes<HTMLDivElement>

const Box: StyledComponent<
  'div',
  DefaultTheme,
  BoxProps
> = styled.div<BoxProps>`
  box-sizing: border-box;
  ${compose(space, layout, position, flexbox, color, typography, grid, border)};
`

Box.displayName = 'Box'

export { Box }
