import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { v4 as uuidv4 } from "uuid";
import { deliveryGroupModelToDto, CreateDeliveryGroup } from "../../model/delivery-group.model";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ValidationError } from "../adaptorHooks/validationError";
import { ADD_DELIVERY_GROUP_MUTATION_KEY_ROOT, multipleCustomersKey, multipleDeliveryGroupsKey, singleCustomerKey } from "../../utils/react-query-key-factory";

async function postDg(post: <In,Out>(url: string, body:In, idempotentKey: string) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroup: CreateDg, idempotentKey: string): Promise<number|null>{
  const dg = deliveryGroupModelToDto(deliveryGroup.deliveryGroup);
  let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups`;

  return post<any, number>(dgEndpoint, dg, idempotentKey);
}

type CreateDg = {
  onCreate: (id: string) => void;
  deliveryGroup: CreateDeliveryGroup
}

export function useCreateDeliveryGroup(dealershipNumber: string, customerId: string, ){
  const {post} = useHttp();
  const queryClient = useQueryClient();
  const idempotentKey = uuidv4();
  const dgCollectionQueryKey = multipleDeliveryGroupsKey(dealershipNumber, customerId);
  const analytics = useAnalytics();

  return useMutation([ADD_DELIVERY_GROUP_MUTATION_KEY_ROOT],
   async (dg: CreateDg) => { 
    return await postDg(post, dealershipNumber, dg, idempotentKey)
   },
   { 
    onSuccess(data, createDg, context) {
      createDg.onCreate(`${data!}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries(dgCollectionQueryKey);
      queryClient.invalidateQueries(singleCustomerKey(dealershipNumber, customerId));
      queryClient.invalidateQueries(multipleCustomersKey(dealershipNumber));
    },
    onError(error){
      if(error instanceof ValidationError){
        if (error.validationErrors.length > 0) {
          error.validationErrors.forEach((errorString) =>
            analytics(emitErrorEvent, errorString.message, error.status)
          );
        }
      }
      else {
        console.error("Oops something went wrong. Please try again later ", error);
        analytics(emitErrorEvent, (error as any).message ?? "create delivery group error", (error as any).stats ?? 500);
      }        
    }
   // can only do retry if we use idempotent key in backend (currently not)
  });
}
