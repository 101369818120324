import { User } from "./User";

export type CustomEvent = {
  name: string 
  type: string;
  attr: any;
}

export function emitCustomEvent(user: User, customEvent: CustomEvent) {
  window.allytm.event('customEvent', customEvent, { user });
}
