import React from 'react'
import { MainHeader } from './MaintenancePageStyled'

const ServerErrorPage = () => {
  return (
    <MainHeader>
    <h1>This application is currently unavailable</h1>
    <p>Something is off with this application, and it isn't working right. We're on it and expect everything to be back up and running soon.</p>
    </MainHeader>
  )
}

export default ServerErrorPage
