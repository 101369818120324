import React, { useContext, useEffect, useState } from "react";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { TabContext, ContextType } from "../TabContext";
import { useParams } from "react-router";
import { useFinalizeContract } from "../../../hooks/contractHooks/useFinalizeContract";
import {
    ESignInner,
    ESignOuter,
    FinalizeSignaturesButton,
    StatusStyled,
    InstructionsStyled,
    FinalizeSignaturesError,
} from "./EmbeddedStyled";
import EnvelopeStatus from "./EnvelopeStatus";
import { useGetEnvelopeStatus } from "../../../hooks/docusignHooks/useGetEnvelopeStatus";
import { ChangeSigningOptionsModal } from "../../Modal/ChangeSigningOptionsModal";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { emitErrorEvent } from "../../../analytics/apiErrors";
import ReviewDocuments from "./ReviewDocuments";

export type EmbeddedSigningProps = {
    contractStatus: "SUCCESS" | "PENDING" | "ERROR";
};

const EmbeddedSigning: React.FC<EmbeddedSigningProps> = ({
    contractStatus,
}) => {
    const [dealershipInfo] = useDealershipInfo();
    const { dealershipNumber } = dealershipInfo || {};
    const { id: customerId } = useParams<{ id: string }>();
    const { activeDeliveryGroupId } = useContext(TabContext) as ContextType;
    const finalizeContractAction = useFinalizeContract(dealershipNumber);
    const { isLoading, data: envelopeStatuses } = useGetEnvelopeStatus(dealershipNumber, customerId, activeDeliveryGroupId);
    const analytics = useAnalytics();
    const [finalizeError, setFinalizeError] = useState(null);

    const memoizedEnvelopeStatus = React.useMemo(() => {
        if (envelopeStatuses === undefined) {
            return null;
        }
        return envelopeStatuses.envelopeStatus;
    }, [envelopeStatuses])

    useEffect(() => {
        analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_GATHER_SIGNATURES);
        if (finalizeError !== null) {
            setFinalizeError(null)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedEnvelopeStatus]);

    const gatherSignatures = () => {
        const onSuccess = () => { }
        const onError = (errs: string[]) => {
            console.warn(errs);
            analytics(emitErrorEvent, "All recipients must sign the envelope", 500);
        };
        if (envelopeStatuses.envelopeStatus === "SUCCESS") {
            setFinalizeError(null);
            finalizeContractAction.mutate({
                deliveryGroupId: activeDeliveryGroupId!,
                customerId: customerId,
                onSuccess,
                onError,
            });
        } else {
            analytics(emitErrorEvent, "All recipients must sign the envelope", 500);
            setFinalizeError("Each recipient must sign all documents before you can finalize your signatures.");
        };
    }
    return (
        <div>
            <ESignOuter>
                <ESignInner>
                    <InstructionsStyled>
                        <h3>DocuSign instructions</h3>
                        <p>
                            DocuSign sent your contract to the customer’s email address. You’ll receive an email to countersign when the documents are signed by all customers.
                        </p>
                    </InstructionsStyled>
                    <StatusStyled>
                        <h3>DocuSign status</h3>
                        <p>
                            When a recipient takes action, the status will automatically update. Select <b>Finalize Signatures</b> below once all statuses are complete.
                        </p>
                    </StatusStyled>
                </ESignInner>
                <ReviewDocuments
                    dealershipNumber={dealershipNumber}
                    customerId={customerId}
                    activeDeliveryGroupId={activeDeliveryGroupId}
                    contractStatus={contractStatus}
                />
            </ESignOuter>
            <EnvelopeStatus
                isLoading={isLoading}
                envelopeStatuses={envelopeStatuses}
            />
            {finalizeError ? <FinalizeSignaturesError>{finalizeError}</FinalizeSignaturesError> : null}
            <div>
                <FinalizeSignaturesButton
                    aria-label="Finalize Signatures"
                    text="Finalize Signatures"
                    variant="primary"
                    allytmln="Finalize Signatures"
                    onClick={gatherSignatures}
                    loading={finalizeContractAction.isLoading}
                />
                <ChangeSigningOptionsModal buttonType="link" />
            </div>
        </div>
    );
};

export default EmbeddedSigning;
