import styled from "styled-components";

export const DivStyled = styled.div`
  margin-left: 22px;
`;

export const Container = styled.div`
  box-sizing: border-box;
  height: 50px;

  border: 1px solid #dddddd;
  background-color: #ffffff;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.16),
    0 5px 8px -4px rgba(0, 0, 0, 0.16);
  p {
    margin: 14px 13px;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0;
    color: #2a2a2a;
    font-weight: bold;
  }
`;

export const OpenDrawer = styled.div`
  box-sizing: border-box;
  height: 246px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
  display: flex;

  .detail_container {
    font-family: Lato;
    display: flex;
    color: #2a2a2a;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 13px;

    .firstColumn {
        width: 90px;
        margin-right: 14px;
    }

    .secondColumn {
      margin-right: 30px;
    }

    h4 {
      font-weight: bold;
      width: 140px;
    }
    p {
      margin-left: 40px;
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  .container {
    margin-right: 150px;
    div:first-child {
        margin-top: 11px;
    }
  }



`;
