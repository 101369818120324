import { useEffect } from 'react'
import { MainHeader } from './MaintenancePageStyled'
import { useAnalytics } from '../../hooks/analytics/useAnalytics';
import { emitPageViewEvent, PageViews } from '../../analytics/pageViews';
import { emitErrorEvent } from '../../analytics/apiErrors';

const MaintenancePage = () => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.MAINTENANCE_PAGE);
    analytics(emitErrorEvent, 'This application is currently unavailable.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainHeader>
    <h1>This application is currently unavailable.</h1>
    <p>We’re performing some updates on the server to improve your experience and we should be up and running again shortly. Check back again soon.</p>
    </MainHeader>
  )
}

export default MaintenancePage
