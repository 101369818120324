import { FieldConfigurationSection, ValidationType } from "../field-configuration";

export const cobuyerInformationConfig: FieldConfigurationSection = {
  name: "cobuyerInformation",
  fieldConfigurations: [
    {
      fieldName: "name",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "address",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "streetAddress",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "city",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "zip",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "state",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "county",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "email",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "monthOfBirth",
      validationType: ValidationType.REQUIRED,
      states: ["FL"]
    },
    {
      fieldName: "taxId",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "type",
      validationType: ValidationType.REQUIRED,
      states: []
    }
  ]
}
