import { ReadAssetContract } from "../asset-contract.model";

export type ContractSummaryCardModel = {
  count: number;
  appId: string;
  year: string;
  make: string;
  model: string;
  vin: string;
  appDecision: string;
  lastUpdatedDate: string;
  cashPrice: string;
  taxesAndFees: string;
  totalDownPayment: string;
  totalOtherCharges: string;
  amountFinanced: string;
  annualPercentageRate: string;
  tradeInStatus : string;
  numberOfPayments: string;
  monthlyPayment: string;
};

export function assetContractToContractSummaryCardModel(assetContract: ReadAssetContract, count: number): ContractSummaryCardModel{  
  return {
    count,
    appId: assetContract.appId,
    vin: assetContract.vehicleDetails.vin ?? "",
    year: `${assetContract.vehicleDetails.year ?? ""}`,
    make: assetContract.vehicleDetails.make ?? "",
    model: assetContract.vehicleDetails.model ?? "",
    appDecision: assetContract.appDecision?.toLocaleLowerCase() ?? "",
    lastUpdatedDate: assetContract.lastUpdatedDate?.toLocaleDateString() ?? "",
    cashPrice: (assetContract.cashPrice?.vehicleCashPrice ?? 0).toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2}),
    taxesAndFees: (assetContract.taxesAndFees ?? 0).toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2}),
    totalDownPayment: (assetContract.totalDownPayment ?? 0).toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2}),
    totalOtherCharges: (assetContract.totalOtherCharges ?? 0).toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2}),
    amountFinanced: (assetContract.amountFinanced ?? 0).toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2}),
    annualPercentageRate: (assetContract.truthInLendingAct.customerBuyRate ?? 0).toFixed(2),
    tradeInStatus: assetContract.tradeInCashDown?.hasTradeIn ? 'Yes' : 'No',
    numberOfPayments: `${assetContract.truthInLendingAct?.numberOfPayments ?? "0"}`,
    monthlyPayment: (assetContract.monthlyPayment ?? 0).toLocaleString('en',{minimumFractionDigits:2, maximumFractionDigits:2})
  };
}
