import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { TabContext, ContextType } from "../TabContext";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import ContractedDeliveryGroupDetails from "./DeliveryGroupDetails/ContractedDeliveryGroupDetails";
import { useFundContract } from "../../../hooks/contractHooks/useFundContract";
import { useFinalizeContract } from "../../../hooks/contractHooks/useFinalizeContract";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { ContractActionModalContent, FundContractModalTrigger } from "../../Modal/CompleteContractModal";
import { ContractModalStyled } from "../../Modal/ContractModalsStyled";
import { ViewPdfAction } from "../../Contracts/ContractActions";
import { Link, CancelFinalizeContractButton, FundingSpecificWrapper, FundingInfoWrapper, ContractLinksWrapper } from "./ContractReadyForFundingStyled";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { contractSummaryRoute } from "../../../routes/RouteFactory";
import { atom, useAtom } from "jotai";

export const backToFinalizeContract = atom(false);

type Props = {
  isActiveTab: boolean;
};
const FundContractForDeliveryGroup: React.FC<Props> = ({ isActiveTab }) => {
  const analytics = useAnalytics();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id: customerId } = useParams() as any;
  const [, setBackToFinalizeContractState] = useAtom(backToFinalizeContract);
  const {
    saveActiveDeliveryGroupName,
    activeDeliveryGroupId,
   } = useContext(TabContext) as ContextType;

  const [isPending, setIsPending] = useState<boolean>(false);
  const fundContract = useFundContract(dealershipNumber);
  const finalizeContract = useFinalizeContract(dealershipNumber);
  const progressTracker = <ContractProgressTracker stepNumber={4}/>;

  useEffect(() => {
    if(isActiveTab){
      analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_FUNDING_COMPLETE);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActiveTab]);
  
  const fundContractAction = (closeModal?: () => void) => {
    const onSuccess = () => {
      setIsPending(false);
      if(closeModal){
        closeModal();
      }
    }
    const onError = (errs: string[]) => {
      setIsPending(false);
    }

    fundContract.mutate({
      deliveryGroupId: activeDeliveryGroupId!,
      customerId: customerId,
      onSuccess,
      onError,
    }); 
  };


  return (
    <>                  
      <DeliveryGroupHeader/>
      <ContractedDeliveryGroupDetails 
        progressTracker={progressTracker} 
        updateDeliveryGroupName={saveActiveDeliveryGroupName} 
        deliveryGroupStatus={"CONTRACT_FINALIZED"} 
      />
      <FundingSpecificWrapper>
        <ContractLinksWrapper>
        <p>View your documents here: </p>
        <Link 
          aria-label="Contract Summary"
          text="Contract Summary" 
            href={contractSummaryRoute(dealershipNumber, customerId, activeDeliveryGroupId)}
          variant="link" 
          target="_blank"
          allytmln="open-contract-summary-page"
        />
        <ViewPdfAction activeDeliveryGroupId={activeDeliveryGroupId} contractStatus={"SUCCESS"} linkName="Multi-Vehicle Contract"/>
        </ContractLinksWrapper>
        <FundingInfoWrapper>
          <p>We’ll start the funding process once we’ve received and reviewed your documents. You can select <b>Confirm</b> to update your dashboard once your contract is fully funded. </p>
          <p>If you haven’t sent us your documents, you can go <b>Back to Finalize Contract</b> to finish finalizing your contract.</p>
        </FundingInfoWrapper>

        <ContractModalStyled
          trigger={<FundContractModalTrigger/>}
          content={
            <ContractActionModalContent 
              actionType="Fund"
              isContractActionPending={isPending}
              contractAction={fundContractAction}
              errors={[]}
            />
          }
          dataTrackRegion="finalize-contract-modal"
        />
        <CancelFinalizeContractButton 
            variant="link" 
            text="Back to Finalize Contract" 
            aria-label="Back to Finalize Contract"
            allytmln="back-to-finalize-contract"
            onClick={() => {
              if(activeDeliveryGroupId)
              finalizeContract.mutate({
                deliveryGroupId: activeDeliveryGroupId!,
                customerId: customerId,
                onSuccess: () => {
                  setBackToFinalizeContractState(true)
                },
                onError: () => {},
                unfinalize: true
              })
              
          }}/> 
      </FundingSpecificWrapper>
    </>
  );
};

export default FundContractForDeliveryGroup;
