import { useState } from "react";
import { useEditDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useEditDeliveryGroup";
import { useGetDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { EditDeliveryGroup } from "../../../model/delivery-group.model";
import {
    InsuranceHeader,
    InsuranceInfo,
    InsurancePolicyDivInner,
    InsurancePolicyDivOuter,
    InsuranceModalStyled,
    StyledSpinner,
    TrashDiv
} from "./InsurancePolicyStyled";
import { AddInsuranceModalContent, AddInsuranceModalTrigger } from "../../Modal/AddInsurancePolicyModal";
import { muiTrash } from "@ally/metronome-icons";
import { Icon } from "@ally/metronome-ui";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { emitClickEvent } from "../../../analytics/clickEvents";

type Props = {
    dealershipNumber: string,
    customerId: string,
    deliveryGroupId: string,
    setShowContractModal?: React.Dispatch<React.SetStateAction<boolean>>
};

const InsurancePolicy: React.FC<Props> = ({
    dealershipNumber,
    customerId,
    deliveryGroupId,
    setShowContractModal }) => {
    const { data: deliveryGroupInfo, isFetching } = useGetDeliveryGroup(dealershipNumber, deliveryGroupId);
    const editDeliveryGroup = useEditDeliveryGroup(dealershipNumber, customerId, deliveryGroupId);
    const [insuranceFields, setInsuranceFields] = useState({
        name: deliveryGroupInfo.insurancePolicy.name ?? "",
        policyId: deliveryGroupInfo.insurancePolicy.policyId ?? "",
    });
    const analytics = useAnalytics();
    const deleteInsurance = () => {
        analytics(emitClickEvent, {
            tagName: "TrashIcon",
            allytmln: "delete-insurance-policy",
            href: "#"
        })
        const noInsurance = {
            name: null,
            policyId: null
        };
        setInsuranceFields(noInsurance);
        setShowContractModal(false);
        editDeliveryGroup.mutate(
            {
                deliveryGroup: { insurancePolicy: noInsurance, dgType: "edit" } as EditDeliveryGroup,
                onEdit: () => { }
            }
        );
    };

    const insuranceName = deliveryGroupInfo.insurancePolicy?.name
    const insuranceId = deliveryGroupInfo.insurancePolicy?.policyId

    if (editDeliveryGroup.isLoading || isFetching) {
        return (
            <StyledSpinner />
        )
    }
    return (insuranceName ?
        <InsurancePolicyDivOuter>
            <InsuranceHeader>Insurance policy</InsuranceHeader>
            <InsurancePolicyDivInner>
                <InsuranceInfo>
                    {`${insuranceName} (${insuranceId})`}
                </InsuranceInfo>
                <TrashDiv
                    onClick={deleteInsurance}
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            deleteInsurance();
                        }
                    }}
                >
                    <Icon
                        icon={muiTrash}
                        id="trash-icon"
                        size="xl"
                        ariaLabelledBy="Delete"
                    />
                </TrashDiv>
            </InsurancePolicyDivInner>
        </InsurancePolicyDivOuter>
        :
        <InsuranceModalStyled
            trigger={<AddInsuranceModalTrigger />}
            content={<AddInsuranceModalContent
                editDeliveryGroup={editDeliveryGroup}
                setInsuranceFields={setInsuranceFields}
                deliveryGroup={deliveryGroupInfo}
                setShowContractModal={setShowContractModal}
            />}
            disableFullScreenSmDown={true}
        />)
};

export default InsurancePolicy;

