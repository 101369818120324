import React from 'react'
import { SteppedProcessProvider, useSteppedProcess } from './context'
import { SteppedProcessBase } from './SteppedProcessBase'
import { SteppedProcessProps, StepState } from './types'

const SteppedProcessInner: React.FC<SteppedProcessProps> = ({
  steps,
  variant,
  ariaLabel,
  initialState,
}) => {
  const { isStateInitialized, initializeState } = useSteppedProcess()

  React.useEffect(() => {
    if (!isStateInitialized && steps.length) {
      const initialStepState = (stepIndex: number): StepState => {
        const foundIndex = initialState?.completedStepIndices?.find(
          completedIndex => stepIndex === completedIndex,
        )
        
        return typeof foundIndex === 'number' ? 'complete' : 'incomplete'
      }

      const initializedStepsMetadata = steps.map((step, index) => ({
        id: step.id,
        index,
        title: step.title.text,
        state: initialStepState(index),
      }))

      initializeState(
        initializedStepsMetadata,
        initialState?.activeStepIndex || 0,
      )
    }
  }, [steps, isStateInitialized, initializeState, initialState])
  
  return isStateInitialized && steps.length ? (
    <SteppedProcessBase steps={steps} variant={variant} ariaLabel={ariaLabel} />
  ) : (
    <></>
  )
}


const SteppedProcess: React.FC<SteppedProcessProps> = ({
  variant,
  initialState,
  steps,
  ariaLabel,
}) => {
  return (
    <SteppedProcessProvider>
      <SteppedProcessInner
        variant={variant}
        steps={steps}
        ariaLabel={ariaLabel}
        initialState={initialState}
      />
    </SteppedProcessProvider>
  )
}

export { SteppedProcess }
