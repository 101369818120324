import { Button, ButtonGroup, DocUpload, Icon, TextHeading } from "@ally/metronome-ui";
import styled from "styled-components";

export const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
    h2 {
        line-height: 28px;
        font-size: 20px;
    }
    h3 {
        line-height: 24px;
        font-size: 16px;
        margin-left: 4px;
    }
    p {
        font-size: 14px;
        line-height: 20px;
        max-width: 720px;
    }
    li {
        margin-left: 40px;
    }
    ul {
        margin-bottom: 20px;
    }
`
export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
    margin-top: 4px;
`
export const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
export const PreviewButton = styled(Button)`
    width: 108px;
    height: 44px;
`
export const DocUploadStyled = styled(DocUpload)`
   label {
    height: 34px;
    width: 122px;
   }
`
export const ButtonGroupStyled = styled(ButtonGroup)`
    align-items: baseline;
    margin-top: 64px;
`
export const ErrorMessageWrapper = styled.p`
    font-weight: bold;
    font-size: 14px;
    color: #D63440;
    line-height: 20px;
    margin-top: 24px;

`

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  h3 {
    margin-top: 10px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
  }

  h4 {
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }

  ul {
    margin-top: .5rem;
  }

  li {
    line-height: 1.5rem;
    margin-left: 1rem;
  }

  a {
    font-size: 16px;
  }

  .summary {
    margin-top: 10px;;
  }

  .contract {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledIcon = styled(Icon)`
  margin: 0 4px 0 0;
`;

export const DGHeadingStyled = styled(TextHeading)`
  font-weight: bold;
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 32px;
  color: #2a2a2a
`;
