import styled from "styled-components";
import { Checkbox } from "@ally/metronome-ui";
export const Heading = styled.h2`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 20px;
  line-height: 54px;
  margin-top: 20px;
`;

export const CheckBox = styled(Checkbox)``;
export const Container = styled.div`
  margin-bottom: 20px;
`;

export const NoVehicleStyle = styled.p`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 44px;
    font-size: 14px;
    line-height: 17px;
    color: #2A2A2A;
    font-weight: normal;
`

export const StyledTableWrapper = styled.div`
  table {
    width: 100%;
    max-width: 1330px;
    height: max-content;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.16),
      0 5px 8px -4px rgba(0, 0, 0, 0.16);
    border: none;
    border-spacing: 0;
    border-collapse: collapse;
  }

  caption {
    text-align: left;
    color: #2a2a2a;
    font-weight: bold;
    font-size: 18px;
    line-height: 54px;
  }
  thead {
    min-width: 96px;
    font-weight: normal;
    line-height: 13px;
    background-color: #e7eaf7;
    border-bottom: 0;
  }

  th {
    text-transform: none;
    font-size: 14px;
    line-height: 20px;
    height: 48px;
    font-weight: normal;
    color: #2a2a2a;
    padding-right: 32px;

    div > div {
      &[data-track-state="ascending"] {
        font-weight: bold;
      }
      &[data-track-state="descending"] {
        font-weight: bold;
      }
    }
  }

  tbody > tr > td[colspan] {
    box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  }

  tbody > tr:nth-child(even) {
    background-color: #fcfcfc;
    border-bottom: 1px solid #dddddd;
  }

  tbody > tr:nth-child(odd) {
    background-color: #fcfcfc;
    border-bottom: 1px solid #dddddd;
  }

  tr > td {
    font-weight: normal;
    min-width: 70px;
    padding-left: 10px;

    button > svg {
      display: none;
    }
  }

  tr > td:nth-child(2) {
    color: #0071c4;
    min-width: 123px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 45px;
  }

  tr > td:first-child, tr > th:first-child {
    padding: 0 0 0 20px;
    min-width; 0;
  }

  tr > td:last-child, tr > th:last-child {
    padding-right: 32px;
  }
`;

export const NoDataTableDiv = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #2a2a2a;
  margin-top: 43px;
`;

export const TableCaption = styled.h2`
  text-align: left;
  color: #2a2a2a;
  font-weight: bold;
  font-size: 18px;
  line-height: 54px;
  margin-left: 5px;
  height: 54px;
`;
