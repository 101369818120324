import React from 'react'
import { Box } from '../../Box'
import { useSteppedProcess } from '../context'
import { StepContentProps } from '../types'

const StepContent: React.FC<StepContentProps> = ({ stepIndex, step }) => {
  const { activeStep } = useSteppedProcess()

  return activeStep?.index === stepIndex ? (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      data-testid={`mui-step-${stepIndex}-content`}
    >
      {step.content}
    </Box>
  ) : (
    <></>
  )
}

export { StepContent }
