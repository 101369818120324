import { DropdownMenuDEPRECATED, Icon } from "@ally/metronome-ui";
import styled from "styled-components";

export const SubHeaderStyle = styled.p`
  font-weight: normal;
  max-width: 65%;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const StyledIcon = styled(Icon)`
  padding-left: 5px;
  padding-top: 0px;
  height: 10px;
`;

export const SubMenuLink = styled.div`
  text-decoration: none;
  cursor: pointer;
  color: #0071c4;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  padding-right: 10px;
  outline: none;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &:focus {
    outline: none;
  }
  link {
    outline: none;
  }
  &:hover {
  }
`;

export const SubMenuText = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

export const DropdownMenuTriggerStyled = styled(DropdownMenuDEPRECATED.Trigger)`
  color: #0071c4;
`;

export const DropdownMenuStyled = styled(DropdownMenuDEPRECATED)`
  font-weight: normal;
  margin-bottom: 20px;
  div > div {
    box-shadow: none;
    border: none;
    top: 30px;

    &:before {
      content: none;
    }

    &:after {
      content: none;
    }
  }
`;

export const StyledDropdownContainer = styled(DropdownMenuDEPRECATED.MenuContainer)`
  max-height: 202.36px;
  overflow-y: auto;
`;
