export const getDigitsFromValue = (value = "") =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, "") || "";

const padDigits = (digits: string) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = "0".repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, "$1");

const addDecimalToNumber = (number: string, separator: string) => {
  const centsStartingPosition = number.length - 2;
  const dollars = removeLeadingZeros(
    number.substring(0, centsStartingPosition)
  );
  
  const cents = number.substring(centsStartingPosition);

  const a = dollars.split("").reverse().join("");

  let mask = [];
  for (let i = 0; i < a.length; i++) {
    if (i % 3 === 0 && i > 1) {
      mask.push(",");
    }
    mask.push(a[i]);
  }

  return mask.reverse().join("") + separator + cents;
};

function reverseFormatNumber(val: string, locale: string){
  if(!val){
    return 0;
  }
  const parts = new Intl.NumberFormat(locale).formatToParts(1111.1);
  const group = parts.find(part => part.type === 'group')?.value;
  const decimal = parts.find(part => part.type === 'decimal')?.value;
  
  let reversedVal = val;
  if(group){
    reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
  }
  if(decimal){
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
  }
  return Number.isNaN(reversedVal) ? 0 : Number(reversedVal);
}

export const toCurrency = (value: string, separator = ".") => {
  const digits = getDigitsFromValue(value);

  const digitsWithPadding = padDigits(digits);
  return addDecimalToNumber(digitsWithPadding, separator);
};

export function formateCurrencyToString(value: string){
  return reverseFormatNumber((value ?? "").replace("$", ""), 'en-US');
}

export function formatNumberToCurrency(value: number | string | undefined | null): string {
  const num = (typeof(value) == 'string' ? reverseFormatNumber(value, 'en-US') : value) ?? 0;
  const v = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(num);

  return v;
}
