import { useQuery } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { useState, useEffect } from "react";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { getDocumentsStatusKey } from "../../utils/react-query-key-factory";

type DdsSubmission = {
    status: DdsSubmissionStatus;
    submittedFiles?: SubmittedFile[];
}
export enum DdsSubmissionStatus {
    PENDING = "PENDING",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    NONE = "NOT_SUBMITTED"
}

export type SubmittedFile = {
    filename: string
}

export type DdsSubmissionDto = DdsSubmission & {
    submittedAt?: string;
}

async function getDocumentsFromBackend(get: <Result>(url: string) => Promise<Result | null>,
    dealershipNumber: string, deliveryGroupId: string): Promise<DdsSubmissionDto | null> {
    let contractEndpoint = `dealership/${dealershipNumber}/delivery-groups/${encodeURIComponent(deliveryGroupId)}/dds-submission-status`;
    const res = await get<DdsSubmissionDto>(contractEndpoint) as DdsSubmissionDto;
    return res;
}
export const noPendingTime = 300 * 1000;
export const pendingTime = 5 * 1000;
export function useGetFundedDocuments(dealershipNumber: string, deliveryGroupId?: string) {

    const [isPending, setIsPending] = useState<boolean>(false);
    useEffect(() => {
        setIsPending(false);
    }, [deliveryGroupId])

    const { get } = useHttp();
    const analytics = useAnalytics();

    return useQuery(
        getDocumentsStatusKey(dealershipNumber, deliveryGroupId),
        () => getDocumentsFromBackend(get, dealershipNumber, deliveryGroupId),
        {
            onError(error) {
                analytics(emitErrorEvent, (error as any).message ?? "get funded documents error", (error as any).status ?? 500);
            },
            onSuccess: (data) => {
                if (!isPending && data?.status === "PENDING") {
                    setIsPending(true);
                }
                if (isPending && data?.status === "SUCCESS") {
                    setIsPending(false);
                }
            },
            enabled: deliveryGroupId !== undefined
        }
    );
}
