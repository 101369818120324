import { Box, Step, Stepper } from "@material-ui/core";
import styled from "styled-components";

export const StepperBox = styled(Box)`
    .MuiStepper-root {
        padding: 0;
        background: #FCFCFC;
    }
`

export const StepperContainer = styled(Stepper)`
    margin-top: 24px;

    div {
        padding: 0;
    }

    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
        margin-left: 6px;
        margin-right: 6px;
        border-color: #DDDDDD;
    }
`
export const StepStyle = styled(Step)`
    .MuiStepLabel-root {
        span > svg > circle{
            cx: 12;
            cy: 12;
            r: 11;
        }
        
        .MuiStepIcon-completed {
            color: #650360;
        }

        .MuiStepIcon-active {
            color: #650360;
            font-weight: bold;
        }

        .MuiStepLabel-active {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            color: #2A2A2A;
        }

        .MuiStepLabel-label {
            font-size: 18px;
            font-family: Lato;
            font-weight: regular;
            line-height: 27px;
        }

        .MuiStepIcon-text {
            font-family: 'Lato';
            font-size: 12px;
            font-weight: regular;    
            color: #737373;   
        }
    }

    .MuiStepLabel-iconContainer.Mui-disabled  {
        padding-right: 5px;
    }

    .Mui-disabled {
        fill: none;
        stroke: #8996A9;
        stroke-width: 1;
        stroke-linecap: round;
        stroke-dasharray: 0;

        .MuiSvgIcon-root {
            fill: none;
        }
            
        text {
            stroke: #737373;
            stroke-width: 1;
            font-size: 12px;
            line-height: 16px;
            font-weight: normal;
        }
    }
`;
