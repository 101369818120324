import { useQuery } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { Dealership } from "../../model/dealership.model";
import { useOktaAuth } from '@okta/okta-react';
import { DEALERSHIP_INFO_QUERY_KEY_ROOT } from "../../utils/react-query-key-factory";

async function postDealershipRequest(post: <In, Out>(url: string, body: In) => Promise<Out | null>,
  dealershipNumbers: string[] | number[]): Promise<Dealership[] | null> {

  const dealershipRequestDto = {
    dealershipNumbers: dealershipNumbers,
  };

  const result = await post<any, Dealership[]>('dealership-info', dealershipRequestDto);
  return result;
}

export function useGetDealerships() {
  const { post } = useHttp();
  const { authState } = useOktaAuth();
  const secureRoutesDisabled = process.env.REACT_APP_SECURE_ROUTES !== 'true'

  const dealershipNumbers = secureRoutesDisabled
    ? ["35440", "43401", "10592", "10445", "56685", "55880", "62367", "67157", "52455", "28755",
      "61129", "13134", "70476", "30923", "56685", "10607", "12064", "68800", "39779", "10617"]
    : (authState?.accessToken?.claims?.secondaryDealership?.toString())?.split(",") ?? [];
  const enableQuery = secureRoutesDisabled || !!authState?.accessToken;

  return useQuery([DEALERSHIP_INFO_QUERY_KEY_ROOT].concat(dealershipNumbers),
    () => postDealershipRequest(post, dealershipNumbers),
    {
      onError(error){
        console.error("Oops something went wrong. Please try again later: ", error)
      },
      staleTime: 60 * 1000,// 1 minute
      cacheTime: 60 * 1000 * 10, // 10 minutes
      keepPreviousData: false,
      enabled: enableQuery
    });
}
