import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { useAnalytics } from "../analytics/useAnalytics";
import { singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";

async function putSigningOption(put: <In, Out>(url: string, body: In) => Promise<Out | null>,
    dealershipNumber: string, deliveryGroupId?: string): Promise<void | null> {

    let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/reset-signing-option`;
    const res = put<any, void>(dgEndpoint, {});

    return res;
}


export function useResetSigningOption(dealershipNumber: string, deliveryGroupId: string) {
    const { put } = useHttp();
    const queryClient = useQueryClient();
    const analytics = useAnalytics();
    const resetSigningOption = async () => await putSigningOption(put, dealershipNumber, deliveryGroupId);

    return useMutation({
        mutationFn: resetSigningOption,
        onSuccess: () => {
            queryClient.invalidateQueries(singleDeliveryGroupsKey(dealershipNumber, deliveryGroupId));
        },
        onError: (error) => {
            analytics(emitErrorEvent, "reset signing option error", (error as any).status ?? 500);
        },
    })
}
