import React from "react";
import { Identifiable } from "../../../../../model/identifiable";
import {
  OptionStickyNavContainer,
  OptionStickyNavSubContainer,
} from "./OptionsStickyNavStyles";
import { UnassignedVehicleStickyNav } from "./UnassignedVehicleStickyNav";
import { AssignedVehicleStickyNav } from "./AssignedVehicleStickyNav";

export type VehicleMetadata = Identifiable & {
  isReadyForContract?: boolean;
  customerId: string;
  yearMakeModel: string;
};

type Props = {
  selectedVehicles: VehicleMetadata[];
  hasValidCloneTargets: boolean; 
  handleCancel: (arg: string) => void;
  comingFrom: "VehicleDashboard" | "VehicleDashboardDeliveryGroup";
};

const OptionsStickyNav: React.FC<Props> = ({
  selectedVehicles,
  hasValidCloneTargets,
  handleCancel,
  comingFrom,
}) => {

  return (
    <OptionStickyNavContainer> 
      <OptionStickyNavSubContainer> 
        {comingFrom === "VehicleDashboard" && (
          <UnassignedVehicleStickyNav selectedVehicles={selectedVehicles} handleCancel={handleCancel}/>
        )}
        {comingFrom === "VehicleDashboardDeliveryGroup" && (
          <AssignedVehicleStickyNav selectedVehicles={selectedVehicles} handleCancel={handleCancel} hasValidCloneTargets={hasValidCloneTargets}/>
        )}
      </OptionStickyNavSubContainer>
    </OptionStickyNavContainer>
  )
};

export default OptionsStickyNav;
