import React from "react";
import {
  Container,
  Logo,
  Menu,
  MenuItem,
  LogoImg,
  LogoLink,
  MenuLink
} from "./HeaderStyled";
import logo from "../../assets/ally_white.png";
import { ContactUs } from "./ContactUs";
import { useLocation } from "react-router";

type headerChildProps =  {
  updateModal: (arg: string) => void;
};

const Header: React.FC<headerChildProps> = (props: headerChildProps) => {

  const logout = (_: any) => {
    /*
     no way of catching window close error so just try and redirect after. 
     if close works we're done, otherwise it'll redirect
    */
    window.close();
    window.location.assign("https://www.ally.com/dealer/");
  };

  const { pathname } = useLocation();
  if(pathname.match("^\\/dealership\\/?[0-9]+\\/customer\\/?[0-9]+\\/delivery-group\\/?[0-9]+\\/summary"))
  {
    return (
      <>
        <Container>
          <Logo>
            <LogoLink
              href="http://www.ally.com/dealer"
              id="ally-logo"
              rel="noreferrer noopener"
            >
              <LogoImg src={logo} alt="ally logo go to ally.com/dealer" />
            </LogoLink>
          </Logo>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <Logo>
          <LogoLink
            href="http://www.ally.com/dealer"
            id="ally-logo"
            rel="noreferrer noopener"
          >
            <LogoImg src={logo} alt="ally logo go to ally.com/dealer" />
          </LogoLink>
        </Logo>
        <Menu>
          <ContactUs />
          <MenuItem>
            <MenuLink onClick={logout} id="log out link" tabIndex={0}>
              Log Out
            </MenuLink>
          </MenuItem>
        </Menu>
      </Container>
    </>
  );
};

export default Header;
