import { get } from '@ally/metronome-ui'
import styled from 'styled-components'
import {
  space,
  SpaceProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
} from 'styled-system'
// import { get } from '../..'

export const CardLabel = styled.span<
  SpaceProps & FlexboxProps & TypographyProps
>`
  && {
    ${space}
    ${flexbox}
    ${typography}
    font-size: 11px;
    text-transform: uppercase;
    font-family: ${get.fonts('regular')};
    font-weight: bold;
  }
`

export const CardValue = styled.span<
  FlexboxProps & TypographyProps & LayoutProps
>`
  && {
    ${flexbox}
    ${typography}
    ${layout}
    font-size: 14px;
  }
`

export const StyledTableCard = styled.div`
  && {
    border-bottom: 1px solid ${get.colors('grey-80')};
    background-color: ${get.colors('white')};

    :last-child {
      border-bottom: none;
    }
  }
`

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  min-height: 34px;

  && {
    border-bottom: 1px solid ${get.colors('grey-80')};
    background-color: ${get.colors('yacht-rock')};
    color: ${get.colors('slate-5')};
    padding: ${get.space('xs')};
  }
`

export const HeaderDetail = styled(CardValue)`
  && {
    margin-left: auto;
  }
`

export const HeaderLabel = styled(CardLabel)`
  word-break: normal;
  margin-right: ${get.space('xxs')};
`
