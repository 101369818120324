import { FieldConfigurationSection, ValidationType } from "../field-configuration";

export const cashPriceConfig: FieldConfigurationSection = {
  name: "cashPrice",
  fieldConfigurations: [
    {
      fieldName: "vehicleCashPrice",
      validationType: ValidationType.REQUIRED,
      states: []
    },
    {
      fieldName: "cashPriceOtherOneValue",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "cashPriceOtherTwoValue",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "dealerProcessingCharge",
      validationType: ValidationType.OPTIONAL,
      states: ["MD"]
    },
    {
      fieldName: "freightCharge",
      validationType: ValidationType.OPTIONAL,
      states: ["MD"]
    },
    {
      fieldName: "deliveryAndHandlingFee",
      validationType: ValidationType.OPTIONAL,
      states: ["CO"]
    },
    {
      fieldName: "salesTax",
      validationType: ValidationType.OPTIONAL,
      states: []
    },
    {
      fieldName: "exciseTax",
      validationType: ValidationType.OPTIONAL,
      states: ["OK"]
    },
    {
      fieldName: "preDeliveryServiceFees",
      validationType: ValidationType.OPTIONAL,
      states: ["FL"]
    },
    {
      fieldName: "vehicleDelivery",
      validationType: ValidationType.OPTIONAL,
      states: ["PA"]
    },
    {
      fieldName: "administrativeFee",
      validationType: ValidationType.OPTIONAL,
      states: ["MO"]
    },
    {
      fieldName: "dealerDocumentaryServiceFee",
      validationType: ValidationType.OPTIONAL,
      states: ["WA"]
    },
    {
      fieldName: "serviceAndHandlingFee",
      validationType: ValidationType.OPTIONAL,
      states: ["AR"]
    },
  ]
}
