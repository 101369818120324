import React from 'react'
import { VerticalSteppedProcess } from './VerticalSteppedProcess'
import { SteppedProcessProps } from './types'

const SteppedProcessBase: React.FC<
  Omit<SteppedProcessProps, 'initialState'>
> = ({ steps, variant, ariaLabel }) => {
  return variant === 'vertical' ? (
    <VerticalSteppedProcess
      variant="vertical"
      steps={steps}
      ariaLabel={ariaLabel}
    />
  ) : null
}

export { SteppedProcessBase }
