import { muiCheckCircleOutline, muiClock, muiInfoOutline, muiMailClosed, muiWarningOutline, } from "@ally/metronome-icons";
import { DejargonatorBodyStyle, NameStyled, StatusInner, StatusOuter, StatusRowStyled, StatusStyled } from "./EnvelopeStatusStyled";
import { Dejargonator, Icon, Spinner } from "@ally/metronome-ui";

type EnvelopeStatusType = {
    isLoading: boolean;
    envelopeStatuses: {
        envelopeStatus: string;
        recipient: [Object];
        isEnvelopeSent: boolean;
    };
}

type RecipientType = {
    name: string,
    status: string,
}

const EnvelopeStatus: React.FC<EnvelopeStatusType> = ({ isLoading, envelopeStatuses }) => {
    const recipients = envelopeStatuses?.recipient;

    const statuses = {
        "WORKING": { icon: muiMailClosed, fill: "grape", text: "Working", toolTip: "Envelope has been sent and is awaiting signatures by all parties." },
        "PENDING": { icon: muiMailClosed, fill: "grape", text: "Pending", toolTip: "Recipient signing is in progress." },
        "ACCEPTED": { icon: muiCheckCircleOutline, fill: "success", text: "Accepted", toolTip: "The envelope has been accepted by the recipient." },
        "SUCCESS": { icon: muiCheckCircleOutline, fill: "success", text: "Success", toolTip: "The envelope has been completed by all recipients." },
        "WAITING": { icon: muiClock, fill: "alert-orange", text: "Waiting", toolTip: "Recipient signing is in progress." },
        "DECLINED": { icon: muiWarningOutline, fill: "error", text: "Declined", toolTip: "The envelope has been declined for signing by the recipient." },
        "CANCELLED": { icon: muiWarningOutline, fill: "error", text: "Cancelled", toolTip: "The envelope has been declined for signing or the 3-day time limit has expired. Please update contract date and resend." },
        "AUTO_RESPONDED": { icon: muiWarningOutline, fill: "alert-orange", text: "Auto Responded", toolTip: "The recipient’s email system auto responded to the email from DocuSign. Please confirm receipt of the email or try again." },
        "NOT_FOUND": { icon: muiWarningOutline, fill: "error", text: "Not Found", toolTip: "This envelope no longer exists. Please go back to signing options to create a new envelope." }
    }

    const envelopeStatus = statuses[envelopeStatuses?.envelopeStatus];

    return (
        <StatusOuter>
            {isLoading ?
                <Spinner />
                :
                <StatusInner>
                    <StatusRowStyled>
                        <NameStyled>Envelope status</NameStyled>
                        <StatusStyled>
                            <Icon icon={envelopeStatus.icon} fill={envelopeStatus.fill} size="lg" />
                            <p>{envelopeStatus.text}</p>
                            <Dejargonator
                                trigger={{
                                    variant: "icon",
                                    content: (
                                        <Icon
                                            icon={muiInfoOutline}
                                            size="lg"
                                        />
                                    ),
                                    ariaLabel: "Envelope status"
                                }}
                                heading={envelopeStatus.text}
                                placement="right"
                                body={
                                    <>
                                        <DejargonatorBodyStyle>
                                            {envelopeStatus.toolTip}
                                        </DejargonatorBodyStyle>
                                    </>
                                }
                            />
                        </StatusStyled>
                    </StatusRowStyled>
                    {recipients.map((recipient: RecipientType, i: number) => {
                        const recipientStatus = statuses[recipient.status]
                        return (
                            <StatusRowStyled key={i}>
                                <NameStyled data-private>{recipient.name} status</NameStyled>
                                <StatusStyled>
                                    <Icon icon={recipientStatus.icon} fill={recipientStatus.fill} size="lg" />
                                    <p>{recipientStatus.text}</p>
                                    <Dejargonator
                                        trigger={{
                                            variant: "icon",
                                            content: (
                                                <Icon
                                                    icon={muiInfoOutline}
                                                    size="lg"
                                                />
                                            ),
                                            ariaLabel: "Envelope status"
                                        }}
                                        heading={recipientStatus.text}
                                        placement="right"
                                        body={
                                            <>
                                                <DejargonatorBodyStyle>
                                                    {recipientStatus.toolTip}
                                                </DejargonatorBodyStyle>
                                            </>
                                        }
                                    />

                                </StatusStyled>
                            </StatusRowStyled>
                        )
                    })}
                </StatusInner>
            }
        </StatusOuter>
    );
}

export default EnvelopeStatus;
