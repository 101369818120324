import { TextHeading } from "@ally/metronome-ui";
import styled from "styled-components";

export const Outer = styled.div`
  h2 {
    margin-top: 0px;
  }
`

export const DGHeadingStyled = styled(TextHeading)`
  font-weight: bold;
  line-height: 32px;
  font-size: 24px;
  margin-bottom: 32px;
  color: #2a2a2a

`;

export const InitialContent = styled.div`
  width: 618px;
  height: 96px;
  margin-bottom: 8px;
  p {
    line-height: 24px;
    font-size: 16px;
    display: flex;
    a {
        font-size: 16px;
        margin-right: 4px;
        margin-left: 4px;
    }
    .number{
      margin-right: 0px
    }
  }
`
export const SecondaryContent = styled.div`
height: 148px;
p {
  line-height: 24px;
  font-size: 16px;
}
`

export const AllyNumber = styled.p`
  color: #0071C4;
  font-weight: bold;
  margin-left: 4px;
`
