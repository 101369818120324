import { useContext } from "react";
import { useGetDownloadUrl } from "../../../hooks/contractHooks/useGetDownloadUrl";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { ContractButton } from "./ContractFullyCompletedStyled"
import { TabContext, ContextType } from "../TabContext";
import { useGetContractStatus } from "../../../hooks/contractHooks/useGetContractStatus";

export const DownloadContractButton = () => {
    const [dealershipInfo] = useDealershipInfo();
    const dealershipNumber = dealershipInfo?.dealershipNumber;
    const { activeDeliveryGroupId } = useContext(TabContext) as ContextType;
    const {
        data: contractStatus,
    } = useGetContractStatus(dealershipNumber, activeDeliveryGroupId);
    const {
        data: contractUrls,
    } = useGetDownloadUrl(dealershipNumber, activeDeliveryGroupId, contractStatus.status === "SUCCESS");

    return (
        <>
            <ContractButton
                ariaLabel="Download Contract (PDF)"
                text="Download Contract (PDF)"
                variant="secondary"
                onClick={() => window.open(contractUrls.download, '_self')}
            />
        </>
    )
}
