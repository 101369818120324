import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCalculateAssetContract } from "../../../hooks/assetContractHooks/useCalculateAssetContract";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import { WriteAssetContractSectionsNames, WriteAssetContractSections, ReadAssetContract } from "../../../model/asset-contract.model";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import { formatNumberToCurrency, toCurrency } from "../../../utils/currency";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import {
  BoxStyle,
  DeliveryFormFieldStyle,
  SubHeader,
} from "./ContractSummaryStyles";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { TerminalFormButtons } from "../Form-Components/FormActionButtons";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";

type Props = {
  customerId: string;
  dealershipNumber: any;
  assetContract: ReadAssetContract; 
  deliveryGroup: ReadDeliveryGroup | null;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames) => void;
  handleRedirectToDeliveryGroup: () => void;
  allSectionsCompleted: boolean;
}

type FormValues = {
  customerBuyRate: string | null;
  numberOfPayments: number | null;
  formSectionsComplete : any;
};

const ContractSummary: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
  handleRedirectToDeliveryGroup,
  allSectionsCompleted
}: Props) => {

  const saveVehicle = useSaveVehicleForm(    
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );
  const percentageRegex = /[%]/g;
  useEffect(() => {
    if(allSectionsCompleted){
      clearErrors(['formSectionsComplete']);
    }
  //TODO address clearErrors missing in dependency array
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSectionsCompleted]);
  
  const calculateAssetContract = useCalculateAssetContract(dealershipNumber);
  const onCalculated = (calculatedAssetContract: ReadAssetContract | null) => {
    calculatedAssetContract?.monthlyPayment
      ? setMonthlyPayment(formatNumberToCurrency(calculatedAssetContract?.monthlyPayment))
      : setMonthlyPayment('');
  };

  const onBlurChange = (event: any, name: any) => {
    let values = getValues();
    values.customerBuyRate = values?.customerBuyRate?.replace(percentageRegex, "");
    onCalculatedField(values);
  }; 

  const readyForContractRef = useRef(false);
  const truthInLendingAct = assetContract.truthInLendingAct;

  const [totalCashPrice] = useState(
    !!assetContract?.totalCashPrice
      ? formatNumberToCurrency(assetContract.totalCashPrice)
      : "$0"
  );

  const [totalDownPayment] = useState(
    !!assetContract?.totalDownPayment
      ? formatNumberToCurrency(assetContract.totalDownPayment)
      : "$0"
  );

  const [totalOtherCharges] = useState(
    !!assetContract?.totalOtherCharges
      ? formatNumberToCurrency(assetContract.totalOtherCharges)
      : "$0"
  );

  const [amountFinanced] = useState(
    !!assetContract?.amountFinanced
      ? formatNumberToCurrency(assetContract.amountFinanced)
      : "$0"
  );

  const [monthlyPayment, setMonthlyPayment] = useState(
    !!assetContract?.monthlyPayment
      ? formatNumberToCurrency(assetContract.monthlyPayment)
      : ""
  );
  
  const onSubmitData = (data: FormValues, readyForContract?: boolean) => {
    data.numberOfPayments = data.numberOfPayments ? +data.numberOfPayments : null;
    updateToBackEnd(data, readyForContract);
  }

  const updateToBackEnd = (data: FormValues, readyForContract?: boolean) => {
    const customerBuyRate = data.customerBuyRate === null ? null : +(data.customerBuyRate.replace(percentageRegex, "")); 
    const patches = [{
        truthInLendingAct: {
          numberOfPayments: data.numberOfPayments,
          customerBuyRate: customerBuyRate,
          isComplete: readyForContract
        },
      },
      { dealershipInformation: assetContract.dealershipInformation}
    ];
    const onSucc = (patch: WriteAssetContractSections) => {
      updateAssetContractState(patch, 'truthInLendingAct' )
      handleRedirectToDeliveryGroup();
    }
    const saveType = readyForContract ? "MarkAsComplete" : "SavedAndContinue";

    saveVehicle.mutate({
      datas: patches,
      saveType,
      onSucc,
      updateDeliveryGroupContractData: undefined,
      assetContract: assetContract,
      deliveryGroup
    });
  }

  const { setPrevStepActive } = useSteppedProcess();
  const form = useForm<FormValues>({
    defaultValues: {
      customerBuyRate: isNullUndefinedOrEmpty(truthInLendingAct?.customerBuyRate)
        ? null
        : (truthInLendingAct.customerBuyRate.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })),
      numberOfPayments: truthInLendingAct?.numberOfPayments,
    },
  });
  const { control, handleSubmit, formState, getValues, setError, clearErrors } = form;
  const { errors } = formState;

  useEditVehicleFormErrors("Section 9", errors);
  
  const onCalculatedField = (data: FormValues) => {
    const payload = {
      ...assetContract,
      truthInLendingAct: {
        numberOfPayments: data?.numberOfPayments,
        customerBuyRate: data?.customerBuyRate
        ? +data.customerBuyRate
        : null
      },
    };
    calculateAssetContract.mutate(
      {
        assetContract: payload,
        contractDate: deliveryGroup?.contract_date,
        firstPaymentDueDate: deliveryGroup?.first_payment_due_date
      },
      { onSuccess: (data) => onCalculated(data) }
    );
  }
 
  const mac = (e) => {
    if(!allSectionsCompleted){
      setError('formSectionsComplete', { type: 'custom', message: 'all sections not complete ' });
    }
    readyForContractRef.current = true;
    return handleSubmit((data) =>
      onSubmitData(data, true)
    )(e);
  }

  return (
      <form allytm-form-tagged="true"  onSubmit={(e) => {
          readyForContractRef.current = false;
          clearErrors(['formSectionsComplete']);
          return handleSubmit((data) => onSubmitData(data))(e);
        }}
      >
        <BoxStyle p={["sm", "md"]} height="100%"> 
          <ReadOnlyField label="Total cash price" value={totalCashPrice} />
          <ReadOnlyField label="Total down payment" value={totalDownPayment} />
          <ReadOnlyField label="Total other charges" value={totalOtherCharges} />
          <ReadOnlyField label="Amount financed" value={amountFinanced} />
          <Controller
            control={control}
            name="customerBuyRate"
            rules={{
              validate: (value) => {
                if ((!value) && readyForContractRef.current) {
                  return "Please add customer APR to continue.";
                }
                if(value && +(value.replace(percentageRegex, "")) > 25){
                  return "Customer APR should be no more than 25%.";
                }
              }
            }}
            render={({ field: { value, onChange } }) => {
              return (
                <DeliveryFormFieldStyle
                  variant="input"
                  inputType="text"
                  inputWidth="320px"
                  labelContent="Customer APR"
                  adornment={{
                    variant: 'custom',
                    position: 'right',
                    item: {
                      variant: 'static',
                      text: '%',
                    }
                  }}
                  value={isNullUndefinedOrEmpty(value)
                    ? ""
                    : toCurrency(value, ".")
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChange(toCurrency(e.target.value, "."))
                  }
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onBlurChange(e, "customerBuyRate");
                  }}
                  maxLength={5}
                  errorNotification={{
                    message: errors.customerBuyRate?.message,
                  }}
                />
              );
            }}
          />
          <Controller
            control={control}
            name="numberOfPayments"
            rules={{
              max: {
                value: 84,
                message: "Number of payments should be no more than 84."
              },
              validate: (value) => {
                if ((!value || value + "" === "0" || value.toString() === "") && readyForContractRef.current) {
                  return "Please add number of payments to continue.";
                }
                if(value && value <= 1){
                  return "Number of payments should be more than 1.";
                }
              }
            }}
            render={({
              field: { value, onChange },
            }) => {
              return (
                <DeliveryFormFieldStyle
                  id="numberofPayments"
                  variant="input"
                  inputType="text"
                  inputWidth="320px"
                  labelContent="Number of payments"
                  value={isNullUndefinedOrEmpty(value)
                    ? ""
                    : value.toString()
                  }
                  maxLength={2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    return onChange(e.target.value.replace(/\D/g, ""));
                  }}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onBlurChange(e, "numberOfPayments");
                  }}
                  errorNotification={{
                    message: errors.numberOfPayments?.message,
                  }}
                />
              );
            }}
          />
          <div className="monthly_payment">
          <ReadOnlyField label="Estimated monthly payment" value={monthlyPayment} />
          </div>
  
 

      <SubHeader>
        Once you've filled out all the required fields, you can mark each step as 
        complete and change your deal status to <b>Ready for Contract</b>. If you don't have 
        all the information you need, you can select <b>Save Progress</b> and come back later 
        to finish. 
      </SubHeader>


      <TerminalFormButtons 
        setPrevStepActive={setPrevStepActive}
        buttonCallStatus={
          {
            isPending: saveVehicle.isPending,
            actionType: saveVehicle.saveType
          }
        }
        sectionNameForAnalytics="contract-summary"
        isComplete={truthInLendingAct.isComplete}
        allSectionsCompleted={allSectionsCompleted}
        readyForContractClicked={readyForContractRef.current}
        markAsComplete={mac}
      />
    </BoxStyle> 
</form>
  );
};

export default ContractSummary;
