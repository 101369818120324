import { Alert } from "@ally/metronome-ui";
import styled from "styled-components";

export const StyledTableWrapper = styled.div`
  margin: 0 21px 0 22px;

  table {
    width: 100%;
    height: max-content;
    box-shadow: none;
    border: none;
    border-spacing: 0;
    border-collapse: collapse;
  }

  caption {
    text-align: left;
    color: #2a2a2a;
    font-weight: bold;
    font-size: 18px;
    line-height: 54px;
  }
  thead {
    min-width: 96px;
    font-weight: bold;
    line-height: 13px;
    background-color: #E7EAF7;
    border-bottom: 0;
  }

  th {
    text-transform: none;
    font-size: 14px;
  }

  tbody > tr > td:nth-child(2) > div {
  margin-right: 30px;
    text-align: center;
  }
  tbody > tr:nth-child(even) {
    background-color: #FCFCFC;
    border-bottom: 1px solid #DDDDDD;

  }

  tbody > tr:nth-child(odd) {
    background-color: #FCFCFC;
    border-bottom: 1px solid #DDDDDD;

    
  }

  tr > td:first-child {
    color: #0071c4;
    min-width: 123px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 45px;
  }

  tr > td {
    background-color: #FCFCFC
    font-weight: normal;
    line-height: 45px;
    min-width: 70px;
  }
`;

export const TableCaption = styled.h2`
  text-align: left;
  color: #2a2a2a;
  font-weight: bold;
  font-size: 18px;
  line-height: 54px;
  margin-left: 22px;
  height: 54px;
`;

export const HeaderStyle = styled.h1`
  color: #650360;
  font-family: Lato;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  margin: 20px 0 16px 22px;
`;

export const DescriptionStyle = styled.p`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0 0 18px 22px;
  max-width: 65%;
`;

export const CustomerAutoCompleteContainer = styled.div`
  width: 314px;
  color: #f9f9f9;
  margin-bottom: 21px;
  margin-left: 22px;
  min-width: 314px;
`;

export const NoDataTableDiv = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  color: #2a2a2a;
  margin-top: 43px;
`;

export const StyledAlert = styled(Alert)`
  margin: 0 22px 18px 22px;
  max-width: 600px;
  width: 90%;
`;

export const EmailLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #0071c4;
`;
