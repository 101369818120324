import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { EditDeliveryGroup, deliveryGroupModelToDto, EditDeliveryGroupContractData } from "../../model/delivery-group.model";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ValidationError } from "../adaptorHooks/validationError";
import { EDIT_DELIVERY_GROUP_MUTATION_KEY_ROOT, allAssetContractsForDealershipKey, multipleCustomersKey, multipleDeliveryGroupsKey, singleCustomerKey, singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";

async function putDg(put: <In, Out>(url: string, body: In) => Promise<Out | null>,
  dealershipNumber: string, deliveryGroup: EditDg, deliveryGroupId?: string): Promise<void | null> {
  const dgDto = deliveryGroupModelToDto(deliveryGroup.deliveryGroup);
  let dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}`;

  return put<any, void>(dgEndpoint, dgDto);
}

type EditDg = {
  onEdit: (id: number) => void;
  deliveryGroup: EditDeliveryGroup | EditDeliveryGroupContractData
}

export function useEditDeliveryGroup(dealershipNumber: string, customerId: string, deliveryGroupId?: string) {
  const { put } = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const dgCollectionQueryKey = multipleDeliveryGroupsKey(dealershipNumber, customerId);


  return useMutation([EDIT_DELIVERY_GROUP_MUTATION_KEY_ROOT],
    async (dg: EditDg) => await putDg(put, dealershipNumber, dg, deliveryGroupId),
    {
      onSuccess: (data, editDg, context) => {
        editDg.onEdit(data! as any);
      },
      onSettled: (data, error, dg: EditDg) => {
        queryClient.invalidateQueries(dgCollectionQueryKey);
        queryClient.invalidateQueries(singleDeliveryGroupsKey(dealershipNumber, deliveryGroupId));
        queryClient.invalidateQueries(singleCustomerKey(dealershipNumber, customerId));
        queryClient.invalidateQueries(multipleCustomersKey(dealershipNumber));
        queryClient.invalidateQueries(allAssetContractsForDealershipKey(dealershipNumber));
      },
      onError: (error) => {
        if (error instanceof ValidationError) {
          if (error.validationErrors.length > 0) {
            error.validationErrors.forEach((errorString) =>
              analytics(emitErrorEvent, errorString.message, error.status)
            );
          }
        }
        else {
          analytics(emitErrorEvent, (error as any).message ?? "edit delivery group error", (error as any).status ?? 500);
        }
      },
      retry: 2 // put is indempotent as an upsert so retry should be okay
    });
}
