import { Button, Icon, CardSelectionCard, CardSelectionGroup} from "@ally/metronome-ui";
import styled from "styled-components";

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  h3 {
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
    font-size: 1.25rem;
    line-height: 1.75rem; /* 140% */
    letter-spacing: 0;
    color: #2a2a2a;
    font-style: normal;
    font-weight: 700;
  }
  h4 {
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  ul {
    margin-top: .5rem;
  }
  li {
    line-height: 1.5rem;
    margin-left: 1rem;
  }
  a {
    font-size: 16px;
  }
  div:nth-of-type(2) {
    margin-top: 10px;
  }
  .summary {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display flex;
    aligh-content: center;
  }
  .contract {
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const SideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 100%;
  h3 {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem; /* 140% */
    letter-spacing: 0;
    color: #2a2a2a;
    font-style: normal;
    font-weight: 700;
  }
  p {
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  h4 {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 20px;
  }
  ul {
    margin-top: .5rem;
  }
  li {
    line-height: 1.5rem;
    margin-left: 1rem;
  }
  div:nth-of-type(2) {
    min-width: 321px;
    min-height: 136px;
    div {
      margin-top: 1rem;
      padding-left: 14px;
      padding-bottom: 24px;
      border: 1px solid #dddddd;
    }
  }
`
export const StyledIcon = styled(Icon)`
  margin: 0 4px 0 0;
`;

export const CardSelectionGroupStyle = styled(CardSelectionGroup)`
  margin-top: 14px;
  margin-bottom: ${(props) => props.emails === "true" ? "32px" : "0px"};
  display: flex;
  gap: 16px;
`;

export const CardSelectionCardStyle = styled(CardSelectionCard)`
  max-width: 294px;
  height: 169px;
  span > p:first-child {
    width: 220px;
    margin: 0 auto;
  }
  span > span {
    width: 260px;
  }
`;

export const ContractError = styled.p`
  color: #D63440;
  font-size: 16px;
  font-weight: bold;
  max-width: 100%;
  line-height: 24px;
  padding: 16px 0px 16px 0px;
`;

export const SigningOptionsButton = styled(Button)`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 8px;
  border-radius: 4px;
  margin-bottom: 5rem;
  max-width: ${(props) => props.text === "Send Contract" ? "150p" : "84px"}
`;

export const PrintAndSignButton = styled(Button)`
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 8px;
  margin-top: 30px;
  border-radius: 4px;
  margin-bottom: 5rem;
  max-width: 84px;
`;

export const CancelButtonStyle = styled(Button)`
  min-width: 108px;
  max-width: 20%;
  color: #0071c4;
  background-color: #fcfcfc;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.3125rem;
  margin-right: 1rem;
  margin-left: 8px;
  &:hover {
    background: #fcfcfc;
  }
`;
