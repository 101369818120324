import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { LgUpTableProps } from '.'
import { get } from '@ally/metronome-ui'

const StyledCaption = styled.caption<{ isCaptionVisible: boolean }>`
  ${(props): string =>
    !props.isCaptionVisible ? get.screenReader('srOnly')(props) : ''}
`

type CaptionProps<D> = Required<
  Pick<LgUpTableProps<D>, 'captionText' | 'isCaptionVisible'>
>

const Caption = <D extends Record<string, any>>({
  captionText,
  isCaptionVisible,
}: CaptionProps<D>): ReactElement => (
  <StyledCaption isCaptionVisible={isCaptionVisible}>
    {captionText}
  </StyledCaption>
)

export default Caption
