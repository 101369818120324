import { useHistory, useParams } from "react-router";
import { useGetAssetContract } from "../../../hooks/assetContractHooks/useGetAssetContract";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { muiChevronLeft } from "@ally/metronome-icons";
import {
  GoBackStyle,
  Heading,
  StyledBox,
  StyledIcon,
  StyledLink,
  SubHeader,
} from "../Edit-VehicleDetail/EditVehicleDetailStyles";
import { DoneButtonStyle, StyledAlert, TableCaption } from "./CloneVehicleStyles";
import { useAssetContractVehicles } from "../../../hooks/assetContractHooks/useGetAssetContractVehicles";
import { convertVehiclesToCloneTableData, convertVehiclesToTableData } from "../../../utils/table-utils";
import { VehicleDashboardDeliveryGroup } from "../../Dashboards/SingleCustomerDashboard/VehicleDashboard/VehicleDashboardDeliveryGroup";
import { useCloneAssetContracts } from "../../../hooks/assetContractHooks/useCloneAssetContracts";
import { CloneModalStyle, CloneDetailsModalStyle } from "./CloneDetailsModalStyles";
import { CloneDetailsModalContent, CloneDetailsModalTrigger, CloneInfoModalContent, CloneInfoModalTrigger } from "./CloneModals";
import { VehicleMetadata } from "../../Dashboards/SingleCustomerDashboard/VehicleDashboard/OptionsStickyNavs/OptionsStickyNav";
import { useEffect, useRef, useState } from "react";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import { emitCloneAlertEvent } from "../../../analytics/cloneAlertEvents";
import { singleCustomerPageRoute } from "../../../routes/RouteFactory";
import { Link } from 'react-router-dom'

const CloneVehicle = () => {
  const { id: customerId, dgId: deliveryGroupId, vehicleId: srcId } = useParams() as any;
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;

  const history = useHistory();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.CLONE_VEHICLE);
    // TODO address unCheckedAll/analytics missing in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const { isSuccess, data: cloneSrc, isLoading: isAssetContractLoading } = useGetAssetContract(
    dealershipNumber,
    srcId
  );

  const { cloneStatus, runClone, cancelClone, isCloned} = useCloneAssetContracts(dealershipNumber, 5);
  
  const {
    isError: isVehicleError,
    isSuccess: isVehicleSuccess,
    isLoading: isVehicleLoading,
    data: vehicles,
    error: vehicleError,
  } = useAssetContractVehicles(dealershipNumber, customerId, deliveryGroupId);

  const selectedVehicles = useRef([]);
  const displayAlertRef = useRef(null);
  const targets = useRef([]);

  const [numSelectedVehicles, setNumSelectedVehicles] = useState(0);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [isCloneLoading, setIsCloneLoading] = useState(false);

  let vehicleDash = undefined;
  let singleVehicleDash = undefined;
  let clonedVehicleDash = undefined;
  let detailFromVehicle = vehicles;
  let detailToVehicles = vehicles;
  let clones = vehicles;

  const errorMessage = `We can’t complete your request right now. Try again later, or call us for help.`;
  const warningMessage = `The details of your ${cloneSrc?.vehicleDetails?.year} ${cloneSrc?.vehicleDetails?.make} ${cloneSrc?.vehicleDetails?.model} weren’t added to ${numSelectedVehicles-cloneStatus.totalSuccess} of the vehicles in your list. Go Back to Delivery Group, and try again.`;
  const successMessage = `The details of your ${cloneSrc?.vehicleDetails?.year} ${cloneSrc?.vehicleDetails?.make} ${cloneSrc?.vehicleDetails?.model} have been added to all the vehicles in your list.`;

  targets.current = selectedVehicles.current.map(target => { return target.id });
  const notifyRunClone = async () => {
    setIsCloneLoading(true);
    await runClone({source: cloneSrc, targets: targets.current, deliveryGroupId});
    setIsCloneLoading(false);
  };

  const notifyCancelClone = () => {
    setIsCloneLoading(false);
    cancelClone();
  };

  useEffect(() => {
    if(cloneStatus.poolDone && !isCloneLoading){
      setTimeout(() => setDisplayAlert(true), 1000);
    }
  }, [cloneStatus.poolDone, isCloneLoading]);

  useEffect(() => {
    if(displayAlert){
      if(numSelectedVehicles === cloneStatus.totalSuccess){
        analytics(emitCloneAlertEvent, successMessage)
      }else if(numSelectedVehicles === cloneStatus.totalFailure){
        analytics(emitCloneAlertEvent, errorMessage)
      }else{
        analytics(emitCloneAlertEvent, warningMessage)
      }
    }
    // TODO address unCheckedAll/analytics missing in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAlert])

  if (isVehicleSuccess || isVehicleLoading) {
    if (vehicles && vehicles.length > 1) {
      detailFromVehicle = vehicles.filter((vehicle) => vehicle.id === srcId);    
      detailToVehicles = vehicles.filter((vehicle) => (
        vehicle.id !== srcId 
        && vehicle.make === detailFromVehicle[0].make
        && vehicle.model === detailFromVehicle[0].model
        && vehicle.year === detailFromVehicle[0].year));
      clones = detailToVehicles?.filter((vehicle) => {
        return targets.current.includes(vehicle.appId.toString());
      });
    } else {
      history.push('/not-found');
    }

    const notifyCheckboxChange = (newData: VehicleMetadata[]) => {
      selectedVehicles.current = newData;
      setNumSelectedVehicles(selectedVehicles.current.length);
    };

    const detailToVehiclesTable = vehicles ? convertVehiclesToTableData(detailToVehicles) : [];
    vehicleDash = (
      <VehicleDashboardDeliveryGroup
        vehicles={detailToVehiclesTable}
        isVehiclesError={isVehicleError}
        isVehiclesSuccess={isVehicleSuccess}
        isVehiclesLoading={isVehicleLoading}
        vehiclesError={vehicleError}
        notifyCheckboxChange={notifyCheckboxChange}
        showStickyNav={false}
        showCheckBox={true}
      />
    );

    const clonedVehiclesTable = vehicles ? convertVehiclesToCloneTableData(clones, isCloned) : [];
    clonedVehicleDash = (
      <VehicleDashboardDeliveryGroup
        vehicles={clonedVehiclesTable}
        isVehiclesError={isVehicleError}
        isVehiclesSuccess={isVehicleSuccess}
        isVehiclesLoading={isVehicleLoading}
        vehiclesError={vehicleError}
        showStickyNav={false}
        showCheckBox={false}
      />
    );

    const detailFromTableVehicles = detailFromVehicle
      ? convertVehiclesToTableData(detailFromVehicle)
      : [];

    singleVehicleDash = (
      <VehicleDashboardDeliveryGroup
        vehicles={detailFromTableVehicles}
        isVehiclesError={isVehicleError}
        isVehiclesSuccess={isVehicleSuccess}
        isVehiclesLoading={isVehicleLoading}
        vehiclesError={vehicleError}
        showCheckBox={false}
        showStickyNav={false}
      />
    );
  }

  if (isSuccess || isVehicleLoading || isAssetContractLoading) {
    let alert= null;
    if(displayAlert && cloneStatus.poolDone && numSelectedVehicles === cloneStatus.totalSuccess){
      alert =
        <StyledAlert
          variant="success"
          cancellable
          ref={displayAlertRef}
          handleClose={() => setDisplayAlert(false)}
          body={<p 
            tabIndex={0}
            aria-label={successMessage}>
              {successMessage}
              </p>}
          focusOnMount={true}
        />
    }else if(displayAlert && cloneStatus.poolDone && numSelectedVehicles === cloneStatus.totalFailure){
      alert =
      <StyledAlert
        variant="error"
        cancellable
        ref={displayAlertRef}
        handleClose={() => setDisplayAlert(false)}
        body={<p 
          tabIndex={0}
          aria-label={errorMessage}>
            {errorMessage}
          </p>}
        focusOnMount={true}
      />
    }else if (displayAlert && cloneStatus.poolDone){
      alert =
      <StyledAlert
        variant="warning"
        cancellable
        ref={displayAlertRef}
        handleClose={() => setDisplayAlert(false)}
        body={<p 
          tabIndex={0}
          aria-label={warningMessage}>
            {warningMessage}
          </p>}
        focusOnMount={true}
      />
    }
    return (
      <StyledBox>
        <GoBackStyle>
          <StyledIcon icon={muiChevronLeft} size="xl" ariaHidden />
          <StyledLink
            allytmln="back-to-single-customer-dashboard"
            text={`Back to Delivery Group`}
          >
            <Link to={singleCustomerPageRoute(customerId, deliveryGroupId)} />
          </StyledLink>
        </GoBackStyle>
        <Heading>{"Clone Vehicle Details"}</Heading>

        <SubHeader>
          Select the vehicles you want to add your cloned vehicle details to from the filtered list below and select <b>Continue</b>.
        </SubHeader>

        <CloneModalStyle
          trigger={<CloneInfoModalTrigger />}
          content={<CloneInfoModalContent />}
          dataTrackRegion="clone-info-modal"
          disableFullScreenSmDown={true}
          disableCloseOnOverlayClick={true}
        />

        {alert}

        <TableCaption>Clone details from:</TableCaption>
        {singleVehicleDash}

        <TableCaption>Clone details to:</TableCaption>
        {cloneStatus.poolDone ? clonedVehicleDash : vehicleDash}

        <CloneDetailsModalStyle
          trigger={<CloneDetailsModalTrigger hideButton={isVehicleLoading || isAssetContractLoading || cloneStatus.poolDone} disableButton={numSelectedVehicles < 1} />}
          content={
            <CloneDetailsModalContent numSuccessfulClones={cloneStatus.totalSuccess}
              runClone={notifyRunClone} cancelClone={notifyCancelClone} isLoading={isCloneLoading}
            >
            </CloneDetailsModalContent>
          }
          dataTrackRegion="clone-details-modal"   
          disableFullScreenSmDown={true}
          hideCloseButton={true}
          disableCloseOnOverlayClick={true}
        />
        {(cloneStatus.poolDone && 
          ( <DoneButtonStyle
              aria-label="Done"
              text="Done"
              onClick={(_) => {
                history.push(singleCustomerPageRoute(customerId, deliveryGroupId))
              }}
              variant={"primary"}
              allytmln="clone-done-button"
            />
          )
        )}
      </StyledBox>
    );
  }
  return (
    <>
      <h1>Loading...</h1>
    </>
  );
};

export default CloneVehicle;
