import { useMutation } from "@tanstack/react-query";
import { useHttp } from "../adaptorHooks/useHttp";
import { ReadAssetContract, WriteAssetContract, assetContractWriteModelToDto } from "../../model/asset-contract.model";

async function putCalculationRequest(put: <In, Out>(url: string, body: In) => Promise<Out | null>,
  dealershipNumber: string, assetContract: WriteAssetContract,
  contractDate?: string, firstPaymentDueDate?: string): Promise<ReadAssetContract | null> {
  const assetContractDto = assetContractWriteModelToDto(assetContract);

  const calculateRequestDto = {
    assetContract: assetContractDto,
    contractDate: contractDate,
    firstPaymentDueDate: firstPaymentDueDate
  };

  const assetEndpoint = `dealership/${dealershipNumber}/asset-contracts/calculate`;
  const result = await put<any, ReadAssetContract>(assetEndpoint, calculateRequestDto);
  return result;
}

type CalcAc = {
  assetContract: WriteAssetContract,
  contractDate?: string,
  firstPaymentDueDate?: string
}

export function useCalculateAssetContract(dealershipNumber: string) {
  const { put } = useHttp();

  return useMutation(['calculate-asset-contract'],
    async (calcAc: CalcAc) => {
      const result = await putCalculationRequest(put, dealershipNumber, calcAc.assetContract, calcAc.contractDate, calcAc.firstPaymentDueDate);
      return result;
    },
    {
      onError(error) {
        console.error("Oops something went wrong. Please try again later ", error);
      },
      retry: 2
    })

}
