type ValidationMsg = {parameter: string, message: string};
export class ValidationError extends Error {
  public validationErrors: ValidationMsg[];
  status: number;
  constructor(msg: string, errors: ValidationMsg[], status: number) {
      super(msg);
      this.validationErrors = errors;
      this.status = status;
      Object.setPrototypeOf(this, ValidationError.prototype);
  }
}
