import { muiWarningFill } from "@ally/metronome-icons";
import {
  IconContainer,
  StyledIcon,
  WarningParagraph,
} from "../Edit-VehicleDetail/CobuyerInformationStyles";

export type CobuyerAlertType =
  | "warning"
  | "no-cobuyer-error"
  | "with-cobuyer-error"
  | "clear";
type CobuyerAlertsProps = {
  alertType: CobuyerAlertType;
};
export default function CobuyerAlerts({ alertType }: CobuyerAlertsProps) {

  const coBuyerErrorContent = (
    <WarningParagraph 
      key="coBuyerErrorContent" 
      tabIndex={0}
      aria-label="Alert. This vehicle’s co-buyer or guarantor doesn’t match the other vehicles in this delivery group. Make sure all the vehicles have the same co-buyer or guarantor, or remove this vehicle, before selecting Mark as Complete">
      This vehicle’s co-buyer or guarantor doesn’t match the other vehicles in
      this delivery group. <br></br>Make sure all the vehicles have the same
      co-buyer or guarantor, or remove this vehicle, before selecting{" "}
      <b>Mark as Complete.</b>
    </WarningParagraph>
  );

  const noCoBuyerErrorContent = (
    <WarningParagraph
      key="noCoBuyerErrorContent"
      tabIndex={0}
      aria-label="Alert. This vehicle's information doesn't match the other vehicles in this
    delivery group. Make sure all the vehicle information matches or remove
    this vehicle before selecting. Mark as Complete"
    >
      This vehicle's information doesn't match the other vehicles in this
      delivery group. Make sure all the vehicle information matches or remove
      this vehicle before selecting <b>Mark as Complete.</b>
    </WarningParagraph>
  );

  const warningContent = (
    <WarningParagraph>
      This information will apply to all vehicles in this delivery group once
      you select <b>Mark as Complete.</b>
    </WarningParagraph>
  );

  let component = <></>;
  if (alertType === "warning") {
    component = (
      <IconContainer
        key={alertType}
        tabIndex={0}
        aria-label="Warning message. This information will apply to all vehicles in this delivery group
      once you select Mark as Complete."
      >
        <StyledIcon
          icon={muiWarningFill}
          tabIndex={-1}
          size="xl"
          fill="warning"
          ariaHidden
        />
        {warningContent}
      </IconContainer>
    );
  } else if (alertType === "no-cobuyer-error") {
    component = (
      <IconContainer
        key={alertType}
      >
        <StyledIcon
          icon={muiWarningFill}
          tabIndex={-1}
          size="xl"
          fill="error"
        />
        {noCoBuyerErrorContent}
      </IconContainer>
    );
  } else if (alertType === "with-cobuyer-error") {
    component = (
      <IconContainer key={alertType}>
        <StyledIcon 
          icon={muiWarningFill}
          size="xl"
          fill="error" 
          ariaHidden
        />
        {coBuyerErrorContent}
      </IconContainer>
    );
  } else if (alertType === 'clear'){
    component = (<></>);
  }

  return component;
}
