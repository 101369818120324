import { Button, ButtonGroup, Icon, Alert } from "@ally/metronome-ui";
import styled from "styled-components";

export const SubHeaderStyle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #2a2a2a;
  max-width: 65%;
`;
export const SecondHeaderStyle = styled(SubHeaderStyle)`
  margin-bottom: 20px;
`;

export const DeliveryGroupDetailTitle = styled.h2`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 54px;
  margin-top: 24px;
`;

export const DetailContainer = styled.div`
  margin-top: 1px;
  background-color: #fcfcfc;
  display: flex;
  padding: 0px 20px 20px 0px;
  justify-content: space-between;
`;

export const OutContainer = styled.div`
  background-color: #fcfcfc;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 20px;
  margin-top: 13px;
`;

export const NoAssetOutContainer = styled.div`
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  border: "1px solid #dddddd"
  margin-bottom: 20px;
  margin-top: 13px;
`;

export const DetailRowHeader = styled.h3`
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #2a2a2a;
  white-space: nowrap;
  margin-bottom: 5px;
`;

export const DetailRowContent = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  color: #2a2a2a;
`;

export const DetailRowContentName = styled(DetailRowContent)`
  word-wrap: break-word;
  max-width: 300px;
`;

export const ActionBarContainerStyle = styled(ButtonGroup)`
  margin: 0 20px 20px;
`;

export const ContractActionButtonStyled = styled(Button)`
  width: 145px;
  min-height: 44px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 16px;
  :focus {
    outline: 3px solid hsla(220, 100%, 50%, 80%);
  }
`;
export const CancelContractActionButtonStyled = styled(
  ContractActionButtonStyled
)`
  margin-left: 20px;
`;

export const EditButtonStyled = styled(Button)`
  width: 124px;
  min-height: 44px;
  background: #e7f6fd;
  color: #0071c4;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;

  &:hover {
    background-color: #e7f6fd;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.16),
      0 5px 8px -4px rgba(0, 0, 0, 0.16);
  }
`;

export const MessageStyle = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;
`;

export const AddVehicleButtonStyled = styled(Button)`
  min-width: 124px;
  background: #fcfcfc;
  color: #0071c4;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  padding-left: 0px;
  align-self: center;

  &:hover {
    background-color: #fcfcfc;
    box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.16),
      0 5px 8px -4px rgba(0, 0, 0, 0.16);
  }
`;

export const StyledIcon = styled(Icon)`
  height: 12px;
  align-self: center;
  cursor: pointer;
`;

export const AddVehicleDiv = styled.div`
  display: flex;
`;

export const StyledAlert = styled(Alert)`
  max-width: 600px;
`;
