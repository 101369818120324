import { Button, FormText } from "@ally/metronome-ui";
import styled from "styled-components";

export const VerifyEmailsStyled = styled.div`
    margin-top: 32px;
    margin-bottom: 2px;
    h3 {
        font-family: Lato;
        margin-top: 0px;
        font-size: 20px
        line-height: 28px;
        color: #2a2a2a;
        font-style: normal;
        font-weight: 700;
    }
    h4 {
        font-family: Lato;
        font-size: 16px;
        line-height: 24px;
    }
`

export const EmailFieldsOuter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const EmailFieldStyled = styled.div`
    min-height: 46px;
    margin-top: 16px;
    h3 {
        font-family: Lato;
        font-size: 12px;
        line-height: 18px;
        font-weight: bold;
        margin-bottom: 4px;
    }
    p {
        font-family: Lato;
        font-size: 16px;
        line-height: 24px;
    }

`
export const EmailStyled = styled.div`
    display: flex;
`

export const EditButtonStyled = styled(Button)`
    height: 24px;
    width: 29px;
    color: #0071c4;
    background-color: #fcfcfc;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 8px;
`;

export const EditedEmailStyled = styled.div`
    display: flex;
`
export const SaveButtonStyled = styled(Button)`
    height: 44px;
    width: 82px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 16px;
    margin-top: 8px;
`;
export const CancelButtonStyled = styled(Button)`
    height: 44px;
    width: 82px;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 16px;
    margin-top: 8px;
`;

export const FormTextStyled = styled(FormText)`
    textarea {
        height: 44px;
        font-size: 16px;
        line-height: 24px;
        font-family: Lato;
    }
`

export const NotificationStyled = styled.div`
    margin-top: 16px;
    margin-left: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        margin-left: 8px
    }
`

