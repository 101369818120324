import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  BoxStyle,
  IconStoryWrapper,
  SubHeader,
} from "./SellerAndGovFeesStyles";
import {
  WriteAssetContractSectionsNames,
  WriteAssetContractSections,
  ReadAssetContract,
} from "../../../model/asset-contract.model";
import { useCalculateAssetContract } from "../../../hooks/assetContractHooks/useCalculateAssetContract";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import {
  EditDeliveryGroupContractData,
  ReadDeliveryGroup,
} from "../../../model/delivery-group.model";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { getAllFieldsForStateInSection } from "../../../validation-config/field-config-utils";
import { sellerGovFeesConfig } from "../../../validation-config/asset-contracts/seller-gov-fees";
import { Control, useForm } from "react-hook-form";
import { useSteppedProcess } from "../../../library/SteppedProcess";
import {
  BasicMoneyField,
  OptionalBasicMoneyField,
  OptionalBasicMoneyFieldWithToolTip,
  ComplexMoneyWithLabelField,
  ComplexMoneyWithLabelFieldProps,
  MultipleElementField,
} from "../Form-Components/MoneyFields";
import {
  DropDownSelections,
  isTextType,
  determineSelectedFieldType,
  FormValues,
  DropdownTextWithLabelFormValues,
} from "./SellerAndGovFeesTypes";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import OptionalFieldsDropdown from "./OptionalFieldsDropdown";

type Props = {
  customerId: any;
  dealershipNumber: any;
  assetContract: ReadAssetContract;
  deliveryGroup: ReadDeliveryGroup;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames, calc?: any) => void;
};

const SellerAndGovFees: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState,
}: Props) => {
  const { setPrevStepActive } = useSteppedProcess();
  const markAsCompleteRef = useRef(false);
  const [dealershipInfo] = useDealershipInfo();
  const fieldConfigurations = getAllFieldsForStateInSection(
    sellerGovFeesConfig,
    dealershipInfo.dealershipState
  );
  const optionalFields = fieldConfigurations.optionalFields;
  const requiredFields = fieldConfigurations.requiredFields;
  const sellerGovFees = assetContract.sellerGovernmentFees;

  const form = useForm<FormValues>({
    defaultValues: {
      ...sellerGovFees,
    },
  });

  const calculateAssetContract = useCalculateAssetContract(dealershipNumber);
  const onCalculated = (calculatedAssetContract: ReadAssetContract | null) => {
    setAmountFinanced(
      formatNumberToCurrency(calculatedAssetContract?.amountFinanced)
    );
  };
  const [amountFinanced, setAmountFinanced] = useState(
    formatNumberToCurrency(assetContract?.amountFinanced)
  );

  const { control, handleSubmit, formState, getValues, setValue } = form;
  const { errors } = formState;

  useEditVehicleFormErrors("Section 6", errors);


  const saveVehicle = useSaveVehicleForm(
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  useEffect(() => {
    for (const name in selectedField) {
      if (
        isNotNullish(sellerGovFees[name]) ||
        selectedField[name].displayFeeDescriptionValue ||
        selectedField[name].displayForFieldValue
      ) {
        setSelectedField((prev) => {
          return {
            ...prev,
            [name]: {
              ...prev[name],
              selected: true,
            },
          };
        });
      }
    }
    //missing selectedField in dependency cannot be added due to scope
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerGovFees]);

  function isNotNullish(val: number | string | undefined | null) {
    if (val === null || val === undefined || val === "") {
      return false;
    }
    return true;
  }
  function formatNumberToCurrency(value?: number | null | string): string {
    if (value === undefined || value === null) {
      return "";
    }
    if (typeof value === "string") {
      value = Number(value.replace(/[$,]/g, ""));
    }
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  const [dropdownText, setDropdownText] = useState(
    "Select additional fees (optional)"
  );

  const [selectedField, setSelectedField] = useState<DropDownSelections>({
    dealerDocumentaryFee: {
      selected: false,
      label: "Dealer documentary fee",
      isValidForDealerState: optionalFields.includes("dealerDocumentaryFee"),
      optionalLabel: "Dealer documentary fee (optional)",
      displayValue: sellerGovFees?.dealerDocumentaryFee
        ? formatNumberToCurrency(sellerGovFees?.dealerDocumentaryFee)
        : "",
    },
    deputyServiceFeeToSeller: {
      selected: false,
      label: "Deputy service fee to seller",
      isValidForDealerState: optionalFields.includes(
        "deputyServiceFeeToSeller"
      ),
      optionalLabel: "Deputy service fee to seller (optional)",
      displayValue: sellerGovFees?.deputyServiceFeeToSeller
        ? formatNumberToCurrency(sellerGovFees?.deputyServiceFeeToSeller)
        : "",
    },
    sellersInventoryTax: {
      selected: false,
      label: "Seller's inventory tax",
      isValidForDealerState: optionalFields.includes("sellersInventoryTax"),
      optionalLabel: "Seller's inventory tax (optional)",
      displayValue: sellerGovFees?.sellersInventoryTax
        ? formatNumberToCurrency(sellerGovFees?.sellersInventoryTax)
        : "",
    },
    govTaxesNotIncludedInPrice: {
      selected: false,
      label: "Governmental taxes not included in cash price",
      isValidForDealerState: optionalFields.includes(
        "govTaxesNotIncludedInPrice"
      ),
      optionalLabel: "Governmental taxes not included in cash price (optional)",
      displayValue: sellerGovFees?.govTaxesNotIncludedInPrice
        ? formatNumberToCurrency(sellerGovFees?.govTaxesNotIncludedInPrice)
        : "",
    },
    govLicenseFee: {
      selected: false,
      label: "Governmental license fee",
      isValidForDealerState: optionalFields.includes("govLicenseFee"),
      optionalLabel: "Governmental license fee (optional)",
      displayValue: sellerGovFees?.govLicenseFee
        ? formatNumberToCurrency(sellerGovFees?.govLicenseFee)
        : "",
    },
    govRegistrationFee: {
      selected: false,
      label: "Governmental registration fee",
      isValidForDealerState: optionalFields.includes("govRegistrationFee"),
      optionalLabel: "Governmental registration fee (optional)",
      displayValue: sellerGovFees?.govRegistrationFee
        ? formatNumberToCurrency(sellerGovFees?.govRegistrationFee)
        : "",
    },
    govCertificateOfTitleFee: {
      selected: false,
      label: "Governmental certificate of title fees",
      isValidForDealerState: optionalFields.includes(
        "govCertificateOfTitleFee"
      ),
      optionalLabel: "Governmental certificate of title fees (optional)",
      displayValue: sellerGovFees?.govCertificateOfTitleFee
        ? formatNumberToCurrency(sellerGovFees?.govCertificateOfTitleFee)
        : "",
    },
    plateTransferFee: {
      selected: false,
      label: "Plate transfer fee paid to state",
      isValidForDealerState: optionalFields.includes("plateTransferFee"),
      optionalLabel: "Plate transfer fee paid to state (optional)",
      displayValue: sellerGovFees?.plateTransferFee
        ? formatNumberToCurrency(sellerGovFees?.plateTransferFee)
        : "",
    },
    govVehicleInspectionFee: {
      selected: false,
      label: "Governmental vehicle inspection fees",
      isValidForDealerState: optionalFields.includes("govVehicleInspectionFee"),
      optionalLabel: "Governmental vehicle inspection fees (optional)",
      displayValue: sellerGovFees?.govVehicleInspectionFee
        ? formatNumberToCurrency(sellerGovFees?.govVehicleInspectionFee)
        : "",
    },
    documentProcessingCharge: {
      selected: false,
      label: "Document processing charge",
      isValidForDealerState: optionalFields.includes(
        "documentProcessingCharge"
      ),
      optionalLabel: "Document processing charge (optional)",
      displayValue: sellerGovFees?.documentProcessingCharge
        ? formatNumberToCurrency(sellerGovFees?.documentProcessingCharge)
        : "",
      displayFeeDescription: "documentProcessingChargeTo",
      displayFeeDescriptionValue:
        sellerGovFees?.documentProcessingChargeTo ?? "",
      multipleField: true,
    },
    emissionsTestingCharge: {
      selected: false,
      label: "Emissions testing charge",
      isValidForDealerState: optionalFields.includes("emissionsTestingCharge"),
      optionalLabel: "Emissions testing charge (optional)",
      displayValue: sellerGovFees?.emissionsTestingCharge
        ? formatNumberToCurrency(sellerGovFees?.emissionsTestingCharge)
        : "",
      displayFeeDescription: "emissionsTestingChargeTo",
      displayFeeDescriptionValue: sellerGovFees?.emissionsTestingChargeTo ?? "",
      multipleField: true,
    },
    evChargingStationCharge: {
      selected: false,
      label: "Electrical vehicle charging station",
      isValidForDealerState: optionalFields.includes("evChargingStationCharge"),
      optionalLabel: "Electrical vehicle charging station (optional)",
      displayValue: sellerGovFees?.evChargingStationCharge
        ? formatNumberToCurrency(sellerGovFees?.evChargingStationCharge)
        : "",
      displayFeeDescription: "evChargingStationChargeTo",
      displayFeeDescriptionValue:
        sellerGovFees?.evChargingStationChargeTo ?? "",
      multipleField: true,
    },
    evRegisterTransferCharge: {
      selected: false,
      label: "Electrical vehicle registration or transfer charge",
      isValidForDealerState: optionalFields.includes(
        "evRegisterTransferCharge"
      ),
      optionalLabel:
        "Electrical vehicle registration or transfer charge (optional)",
      displayValue: sellerGovFees?.evRegisterTransferCharge
        ? formatNumberToCurrency(sellerGovFees?.evRegisterTransferCharge)
        : "",
      displayFeeDescription: "evRegisterTransferChargeTo",
      displayFeeDescriptionValue:
        sellerGovFees?.evRegisterTransferChargeTo ?? "",
      multipleField: true,
    },
    electronicLienAndTitleFee: {
      selected: false,
      label: "Electronic Lien and Title Fee",
      isValidForDealerState: optionalFields.includes(
        "electronicLienAndTitleFee"
      ),
      optionalLabel:
        "Electronic Lien and Title Fee (optional)",
      displayValue: sellerGovFees?.electronicLienAndTitleFee
        ? formatNumberToCurrency(sellerGovFees?.electronicLienAndTitleFee)
        : "",
      displayFeeDescription: "electronicLienAndTitleFeeTo",
      displayFeeDescriptionValue:
        sellerGovFees?.electronicLienAndTitleFeeTo ?? "",
      multipleField: true,
    },
    officialFeesPaidToGovAgencies: {
      selected: false,
      label: "Official fees paid to government agencies",
      isValidForDealerState: optionalFields.includes(
        "officialFeesPaidToGovAgencies"
      ),
      optionalLabel: "Official fees paid to government agencies (optional)",
      displayValue: sellerGovFees?.officialFeesPaidToGovAgencies
        ? formatNumberToCurrency(sellerGovFees?.officialFeesPaidToGovAgencies)
        : "",
      displayFeeDescription: "officialFeesPaidToGovAgenciesTo",
      displayFeeDescriptionValue:
        sellerGovFees?.officialFeesPaidToGovAgenciesTo ?? "",
      displayForField: "officialFeesPaidToGovAgenciesFor",
      displayForFieldValue:
        sellerGovFees?.officialFeesPaidToGovAgenciesFor ?? "",
      multipleField: true,
    },
    forPriorCreditOrLeaseBalance: {
      selected: false,
      label: "For prior credit or lease balance",
      isValidForDealerState: optionalFields.includes(
        "forPriorCreditOrLeaseBalance"
      ),
      optionalLabel: "For prior credit or lease balance (optional)",
      displayValue: sellerGovFees?.forPriorCreditOrLeaseBalance
        ? formatNumberToCurrency(sellerGovFees?.forPriorCreditOrLeaseBalance)
        : "",
      displayFeeDescription: "forPriorCreditOrLeaseBalanceTo",
      displayFeeDescriptionValue:
        sellerGovFees?.forPriorCreditOrLeaseBalanceTo ?? "",
      multipleField: true,
    },
    miscCharges: {
      selected: false,
      label: "Miscellaneous charges",
      isValidForDealerState: optionalFields.includes("miscCharges"),
      optionalLabel: "Miscellaneous charges (optional)",
      displayValue: sellerGovFees?.miscCharges
        ? formatNumberToCurrency(sellerGovFees?.miscCharges)
        : "",
      toolTip: true,
      dejarg: {
        body: "Not government fees",
        triggerAriaLabel: "Not government fees",
      },
    },
    ertFee: {
      selected: false,
      label: "Optional electronic registration and title fee",
      isValidForDealerState: optionalFields.includes("ertFee"),
      optionalLabel:
        "Optional electronic registration and title fee (optional)",
      displayValue: sellerGovFees?.ertFee
        ? formatNumberToCurrency(sellerGovFees?.ertFee)
        : "",
      displayFeeDescription: "ertFeeTo",
      displayFeeDescriptionValue: sellerGovFees?.ertFeeTo ?? "",
      multipleField: true,
    },
    securityInterestRecordingFee: {
      selected: false,
      label: "Security interest recording fee",
      isValidForDealerState: optionalFields.includes(
        "securityInterestRecordingFee"
      ),
      optionalLabel: "Security interest recording fee (optional)",
      displayValue: sellerGovFees?.securityInterestRecordingFee
        ? formatNumberToCurrency(sellerGovFees?.securityInterestRecordingFee)
        : "",
    },
    lienNotationFee: {
      selected: false,
      label: "Lien notation fee",
      isValidForDealerState: optionalFields.includes("lienNotationFee"),
      optionalLabel: "Lien notation fee (optional)",
      displayValue: sellerGovFees?.lienNotationFee
        ? formatNumberToCurrency(sellerGovFees?.lienNotationFee)
        : "",
    },
    supplementalTitleFee: {
      selected: false,
      label: "Supplemental title fee",
      isValidForDealerState: optionalFields.includes("supplementalTitleFee"),
      optionalLabel: "Supplemental title fee (optional)",
      displayValue: sellerGovFees?.supplementalTitleFee
        ? formatNumberToCurrency(sellerGovFees?.supplementalTitleFee)
        : "",
    },
    stateEmissionsCertExemptFee: {
      selected: false,
      label: "State emissions certificate /exempt fee",
      isValidForDealerState: optionalFields.includes(
        "stateEmissionsCertExemptFee"
      ),
      optionalLabel: "State emissions certificate /exempt fee (optional)",
      displayValue: sellerGovFees?.stateEmissionsCertExemptFee
        ? formatNumberToCurrency(sellerGovFees?.stateEmissionsCertExemptFee)
        : "",
    },
    totalOfficialFeesPaidToGovAgencies: {
      selected: false,
      label: "Total official fees paid to governmental agencies",
      isValidForDealerState: optionalFields.includes(
        "totalOfficialFeesPaidToGovAgencies"
      ),
      optionalLabel:
        "Total official fees paid to governmental agencies (optional)",
      displayValue: sellerGovFees?.totalOfficialFeesPaidToGovAgencies
        ? formatNumberToCurrency(
            sellerGovFees?.totalOfficialFeesPaidToGovAgencies
          )
        : "",
    },
    securityInterestRecordingFeeCash: {
      selected: false,
      label: "Security interest recording fee paid in cash",
      isValidForDealerState: optionalFields.includes(
        "securityInterestRecordingFeeCash"
      ),
      optionalLabel: "Security interest recording fee paid in cash (optional)",
      displayValue: sellerGovFees?.securityInterestRecordingFeeCash
        ? formatNumberToCurrency(
            sellerGovFees?.securityInterestRecordingFeeCash
          )
        : "",
    },
    toSellerForTradeInCreditAgreement: {
      selected: false,
      label: "Trade-in credit agreement benefit paid to seller",
      isValidForDealerState: optionalFields.includes(
        "toSellerForTradeInCreditAgreement"
      ),
      optionalLabel:
        "Trade-in credit agreement benefit paid to seller (optional)",
      displayValue: sellerGovFees?.toSellerForTradeInCreditAgreement
        ? formatNumberToCurrency(
            sellerGovFees?.toSellerForTradeInCreditAgreement
          )
        : "",
    },
  });

  const onBlurHandle = (
    e: React.ChangeEvent<HTMLInputElement>,
    reCalculateFields: boolean
  ) => {
    if (reCalculateFields) {
      onBlurCalculateValues();
    }
  };

  const onBlurCalculateValues = () => {
    const values = getValues();
    submitData(values, onCaluclateFields);
  };

  const submitData = (
    data: FormValues,
    submitAction: (data: FormValues, markedAsComplete?: boolean) => void,
    markedAsComplete?: boolean
  ) => {
    // converts values for api to accept including removing masking and defaulting
    Object.keys(data).forEach((key) => {
      const v = data[key];
      if (isTextType(key) && !data[key]) {
        data[key] = null;
      }
      if (
        !isTextType(key) &&
        typeof v === "string" &&
        v !== undefined &&
        v !== null
      ) {
        const input = v.replace(/[$,]/g, "").trim();
        if (input === "") {
          data[key] = null;
        } else {
          const numVal = Number(input);
          data[key] = isNaN(numVal) ? null : numVal;
        }
      }
    });

    submitAction(data, markedAsComplete);
  };

  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    submitData(data, updateToBackEnd, markedAsComplete);
  };

  const onCaluclateFields = (data: FormValues) => {
    const payload = {
      ...assetContract,
      sellerGovernmentFees: {
        ...sellerGovFees,
        ...data,
      },
    };
    calculateAssetContract.mutate(
      {
        assetContract: payload,
      },
      { onSuccess: (data) => onCalculated(data) }
    );
  };

  const updateToBackEnd = (data: FormValues, markedAsComplete?: boolean) => {
    const onSucc = (patch: WriteAssetContractSections, calc?: any) => {
      updateAssetContractState(patch, 'sellerGovernmentFees', calc);
    };

    const patches = [
      {
        sellerGovernmentFees: {
          ...data,
          isComplete: markedAsComplete,
        },
      },
      { dealershipInformation: assetContract.dealershipInformation },
    ];
    let updateDeliveryGroupContractData = undefined;
    if (deliveryGroup) {
      updateDeliveryGroupContractData = {
        dgType: "edit-contract-data",
        sellerGovernmentFeesContractData: {
          documentProcessingChargeTo: data.documentProcessingChargeTo,
          emissionsTestingChargeTo: data.emissionsTestingChargeTo,
          evChargingStationChargeTo: data.evChargingStationChargeTo,
          evRegisterTransferChargeTo: data.evRegisterTransferChargeTo,
          electronicLienAndTitleFeeTo: data.electronicLienAndTitleFeeTo,
          ertFeeTo: data.ertFeeTo,
          officialFeesPaidToGovAgenciesTo: data.officialFeesPaidToGovAgenciesTo,
          officialFeesPaidToGovAgenciesFor:
            data.officialFeesPaidToGovAgenciesFor,
          forPriorCreditOrLeaseBalanceTo: data.forPriorCreditOrLeaseBalanceTo,
        },
      } as EditDeliveryGroupContractData;
    }
    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";

    saveVehicle.mutate({
      datas: patches,
      saveType,
      onSucc,
      updateDeliveryGroupContractData,
      assetContract,
      deliveryGroup
    });
  };

  const handleDropdownSelect = (
    name: string
  ): void => {
    setDropdownText(selectedField[name].optionalLabel);
    setSelectedField((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          selected: true,
        },
      };
    });
  };

  const removeOptionalField = (
    event: any,
    value: keyof DropDownSelections,
    label?: keyof DropdownTextWithLabelFormValues,
    forLabel?: keyof DropdownTextWithLabelFormValues
  ) => {
    setDropdownText("Select additional fees (optional)");
    let displayOverwrites = {
      displayValue: "",
      selected: false,
    };

    setValue(value, null);
    if (label) {
      setValue(label, null);
      displayOverwrites["displayFeeDescriptionValue"] = "";
    }
    if (forLabel) {
      setValue(forLabel, null);
      displayOverwrites["displayForFieldValue"] = "";
    }

    setSelectedField((prev) => {
      const remainder = prev[value];
      return {
        ...prev,
        [value]: {
          ...remainder,
          ...displayOverwrites,
        },
      };
    });
    onBlurCalculateValues();
  };

  const typedSelectionKeys = (selectedField: DropDownSelections) => {
    return Object.keys(selectedField) as (keyof DropDownSelections)[];
  }; //keys w/ map only are seen as string

  //maybe memozie output or something? seems like it's getting called to much
  const buildComplexProps = (
    control: Control<FormValues, any>,
    selectedFieldKey: keyof DropDownSelections,
    index: number,
    selectedField: DropDownSelections
  ): ComplexMoneyWithLabelFieldProps<FormValues> => {
    const labelName = selectedField[selectedFieldKey].displayFeeDescription;
    const forLabelName = selectedField[selectedFieldKey].displayForField;

    const moneyField = {
      name: selectedFieldKey,
      labelContent: "Amount",
      recalc: true,
      onBlurHandle,
      textValidation: (_) => undefined,
    };
    const labelField = {
      name: labelName,
      labelContent: "To",
      recalc: false,
      onBlurHandle: () => {},
      errorMsg: errors[labelName]?.message,
      textValidation: (value) => {
        if (
          !value &&
          markAsCompleteRef.current &&
          getValues(selectedFieldKey)
        ) {
          return `Please add recipient to continue.`;
        }
      },
    } as MultipleElementField<FormValues>;
    const res = {
      control,
      moneyField,
      labelField,
      removeOptionalFields: (e) =>
        removeOptionalField(e, selectedFieldKey, labelName, forLabelName),
      complexFieldHeader: selectedField[selectedFieldKey].optionalLabel,
    } as ComplexMoneyWithLabelFieldProps<FormValues>;

    if (!!selectedField[selectedFieldKey].displayForField) {
      const optLabelField = {
        name: forLabelName,
        labelContent: "For",
        recalc: false,
        onBlurHandle: () => {},
        errorMsg: errors[forLabelName]?.message,
        textValidation: (value) => {
          if (
            !value &&
            markAsCompleteRef.current &&
            getValues(selectedFieldKey)
          ) {
            return `Please add reason to continue.`;
          }
        },
      } as MultipleElementField<FormValues>;
      res["optLabelField"] = optLabelField;
    }
    return res;
  };

  return (
    <>
      <form allytm-form-tagged="true" 
        onSubmit={(e) => {
          markAsCompleteRef.current = false;
          return handleSubmit((data) => onSubmitData(data))(e);
        }}
      >
        <IconStoryWrapper>
          <SubHeader>You can select multiple fees from the list.</SubHeader>
        </IconStoryWrapper>
        <BoxStyle p={["sm", "md"]} height="100%">
          <OptionalFieldsDropdown
            selectedField={selectedField}
            titleText={dropdownText}
            handleClick={handleDropdownSelect}
            fieldType="fees"
          />
          <div id="collections">   
            {
            //TODO refractor map to return a value and avoid infinite calls
            //eslint-disable-next-line
            typedSelectionKeys(selectedField).map((item, index) => {
              const fieldType = determineSelectedFieldType(selectedField[item]);
              if (optionalFields.includes(item)) {
                if (fieldType === "OptionalMoney")
                  return (
                    <OptionalBasicMoneyField<FormValues>
                      control={control}
                      key={index}
                      name={item}
                      labelContent={selectedField[item].optionalLabel}
                      onBlurHandle={onBlurHandle}
                      recalc={true}
                      removeOptionalField={(e) => removeOptionalField(e, item)}
                    />
                  );
                else if (fieldType === "OptionalMoneyWithDejarg")
                  return (
                    <OptionalBasicMoneyFieldWithToolTip<FormValues>
                      control={control}
                      key={index}
                      name={item}
                      labelContent={selectedField[item].optionalLabel}
                      onBlurHandle={onBlurHandle}
                      recalc={true}
                      removeOptionalField={(e) => removeOptionalField(e, item)}
                      dejarg={selectedField[item].dejarg}
                    />
                  );
                else if (fieldType === "MoneyWithLabels")
                  return (
                    <ComplexMoneyWithLabelField<FormValues>
                      {...buildComplexProps(
                        control,
                        item,
                        index,
                        selectedField
                      )}
                    />
                  );
              } 
            })}
          </div>
          <>
            {requiredFields.includes("govDocStampTax") && (
              <BasicMoneyField<FormValues>
                control={control}
                name="govDocStampTax"
                requiredRef={markAsCompleteRef}
                errors={errors}
                labelContent="Governmental doc stamp tax"
                onBlurHandle={onBlurHandle}
                recalc={true}
              />
            )}
          </>
          <>
            {requiredFields.includes("govWasteTireManagementFee") && (
              <BasicMoneyField<FormValues>
                control={control}
                name="govWasteTireManagementFee"
                requiredRef={markAsCompleteRef}
                errors={errors}
                labelContent="Governmental waste tire management fee"
                onBlurHandle={onBlurHandle}
                recalc={true}
              />
            )}
          </>
          <>
            {requiredFields.includes("vehicleTireFee") && (
              <BasicMoneyField<FormValues>
                control={control}
                name="vehicleTireFee"
                requiredRef={markAsCompleteRef}
                errors={errors}
                labelContent="Vehicle tire fee"
                onBlurHandle={onBlurHandle}
                recalc={true}
              />
            )}
          </>
          <div className="amount_fin">
          <ReadOnlyField label="Amount Financed" value={amountFinanced} />
          </div>
          <NonTerminalFormButtons
            setPrevStepActive={setPrevStepActive}
            buttonCallStatus={{
              isPending: saveVehicle.isPending,
              actionType: saveVehicle.saveType,
            }}
            sectionNameForAnalytics="seller-and-gov-fees"
            isComplete={sellerGovFees.isComplete}
            markAsComplete={(e) => {
              markAsCompleteRef.current = true;
              return handleSubmit((data) => onSubmitData(data, true))(e);
            }}
          />
        </BoxStyle>
      </form>
    </>
  );
};

export default SellerAndGovFees;
