import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Lato', 'Lato Regular', 'Lato Bold';
  }

  #root {
    margin: 0 auto;
    min-width: 1024px;
  }

  html {
    background: #fcfcfc no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;    
    -webkit-overflow-scrolling: touch;
  }

  body {
      height: 100%;
  }
`;
