
import { useCallback } from "react";
import { useUser } from "./useUser";

export function useAnalytics() {
  const user = useUser();
  const analyticWrapper = useCallback((func, ...args) =>{
    if(user){
        return func(user, ...args);
    }
  }, [user] );

  return analyticWrapper;
}
