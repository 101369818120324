import { Label, Select } from "@ally/metronome-ui";
import React, { useRef } from "react";
import { listOfStates } from "../../../constants/state";
import {
  WriteAssetContractSectionsNames,
  WriteAssetContractSections,
  ReadAssetContract,
  WriteAssetContract,
} from "../../../model/asset-contract.model";
import { useSaveVehicleForm } from "../../../hooks/editVehicleFormHooks/useSaveVehicleForm";
import { ReadDeliveryGroup } from "../../../model/delivery-group.model";
import ReadOnlyField from "../Form-Components/ReadOnlyField";
import {
  BoxStyle,
  IconContainer,
  LabelStyle,
  StyledSelect,
  StyledIcon,
  WarningParagraph,
} from "./BuyerInformationStyles";
import { getAllFieldsForStateInSection } from "../../../validation-config/field-config-utils";
import { buyerInformationConfig } from "../../../validation-config/asset-contracts/buyer-information";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { States } from "../../../constants/enums";
import { useHistory } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";
import { NonTerminalFormButtons } from "../Form-Components/FormActionButtons";
import { BasicTextField, EmailField, ZipcodeField } from "../Form-Components/TextFields";
import { useEditVehicleFormErrors } from "../../../hooks/analytics/useEditVehicleFormErrors";
import { singleCustomerPageRoute } from "../../../routes/RouteFactory";
import { muiWarningFill } from "@ally/metronome-icons";

type Props = {
  customerId: any;
  dealershipNumber: any;
  assetContract: ReadAssetContract | WriteAssetContract;
  deliveryGroup?: ReadDeliveryGroup | null;
  updateAssetContractState: (patch: WriteAssetContractSections, sectionName: WriteAssetContractSectionsNames) => void;
};

type FormValues = {
  name: string | null;
  address: string | null;
  city: string | null;
  county: string | null;
  state: States | null;
  zip: string | null;
  buyerSignerName: string | null;
  buyerSignerTitle: string | null;
  buyerSignerEmail: string | null;
  monthOfBirth: number | null;
  isComplete: boolean | null;
};

const BuyerInformation: React.JSXElementConstructor<any> = ({
  customerId,
  dealershipNumber,
  assetContract,
  deliveryGroup,
  updateAssetContractState, 
}: Props) => {
  const saveVehicle = useSaveVehicleForm(    
    dealershipNumber,
    customerId,
    assetContract.appId,
    deliveryGroup?.id
  );

  const history = useHistory();
  const [dealershipInfo] = useDealershipInfo();
  const fieldConfigurations = getAllFieldsForStateInSection(
    buyerInformationConfig,
    dealershipInfo.dealershipState
  );
  const requiredFields = fieldConfigurations.requiredFields;
  const buyerInformation = assetContract.buyerInformation;

  const form = useForm<FormValues>({
    defaultValues: {
      name: buyerInformation?.name,
      address: buyerInformation?.address,
      city: buyerInformation?.city, 
      county: buyerInformation?.county,
      state: buyerInformation?.state,
      zip: buyerInformation?.zip,
      buyerSignerName: buyerInformation?.buyerSignerName,
      buyerSignerTitle: buyerInformation?.buyerSignerTitle,
      buyerSignerEmail: buyerInformation?.buyerSignerEmail,
      monthOfBirth: buyerInformation?.monthOfBirth, // only displays for FL
      isComplete: buyerInformation?.isComplete ?? false,
    }
  });
  const { control, handleSubmit, formState } = form;
  const { errors } = formState;
  const markAsCompleteRef = useRef(false);
  useEditVehicleFormErrors("Section 1", errors);


  const stateOptions = listOfStates.map((state) => {
    return { label: state.label, value: state.label };
  });

  const onBlurHandle = (e) => {};


  const onSubmitData = (data: FormValues, markedAsComplete?: boolean) => {
    submitData(data, updateDataToBackend, markedAsComplete)
  };
  const submitData = (data: FormValues, submitAction: (data: FormValues, markedAsComplete?: boolean) => void, markedAsComplete?: boolean) => {
    Object.keys(data).forEach((key) => {
      const v = data[key];
      if(typeof v === "string" && isNullUndefinedOrEmpty(data[key])){
        data[key] = null;
      }else if(typeof v === "number"){
        const numVal = Number(v);
        data[key] = (isNaN(numVal) ) ? null : numVal;
      }
    });
    submitAction(data, markedAsComplete);
  };

  const updateDataToBackend = (data: FormValues, markedAsComplete?: boolean) => {
    const onSucc = (patch: WriteAssetContractSections) => {
      updateAssetContractState(patch, 'buyerInformation');
    };

    const patches = [{
        buyerInformation: {
          ...data,
          monthOfBirth: Number(data.monthOfBirth) === 0 ? null : Number(data.monthOfBirth),
          isComplete: markedAsComplete
        },
      },
      {dealershipInformation: assetContract.dealershipInformation}
    ];
    const saveType = markedAsComplete ? "MarkAsComplete" : "SavedAndContinue";
    
    saveVehicle.mutate({datas: patches, saveType, onSucc});
  };

  const buildErrorMsg = (fieldName: string): string => {
    return `Add ${fieldName} to continue.`
  }

  return (
    <>
      <IconContainer 
        tabIndex={0} 
        aria-label="Warning message. This information will apply to all vehicles in this delivery group
          once you select Mark as Complete.">
        <StyledIcon
          icon={muiWarningFill}
          size="xl"
          fill="warning"
          ariaHidden
        />
        <WarningParagraph>
          This information will apply to all vehicles in this delivery group
          once you select <b>Mark as Complete.</b>
        </WarningParagraph>
      </IconContainer>
      <form allytm-form-tagged="true"  
        onSubmit={(e) => {
          markAsCompleteRef.current = false;
          return handleSubmit((data) => onSubmitData(data))(e);
        }}
      >
        <BoxStyle p={["sm", "md"]} height="100%">
          <div data-private>
            <BasicTextField 
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="name" 
              labelContent="Name" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("name")}
            />
          </div>
          <div data-private>
            <BasicTextField 
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="address" 
              labelContent="Address" onBlurHandle={onBlurHandle} minCharCount={5} customErrorMsg={buildErrorMsg("address")}
            />
          </div>
          <div data-private>
            <BasicTextField 
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="city" 
              labelContent="City" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("city")}
            />
          </div>
          <div data-private>
            <BasicTextField 
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="county" 
              labelContent="County" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("county")}
            />
          </div>
          <Controller
            control={control}
            name="state"
            rules={{
              validate: (value) => {
                if (markAsCompleteRef.current && isNullUndefinedOrEmpty(value)) {
                  return "Add buyer state to continue.";
                }
              }
            }}
            render={({
              field: { value, onChange  }
            }) => {
              return (
                <StyledSelect>
                <Label htmlFor="state">
                  <LabelStyle>State</LabelStyle>
                  <Select
                    id="state"
                    name="state"
                    options={stateOptions}
                    placeholder="Select"
                    value={ value ?? "" }
                    onChange={onChange}
                    error={ errors.state?.message }
                  />
                </Label>
              </StyledSelect>
              );
            }}
          />
          <div data-private>
            <ZipcodeField 
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="zip" 
              labelContent="ZIP" onBlurHandle={onBlurHandle} minCharCount={5}
            />
          </div>
          <div className="tax_id"data-private>
            <ReadOnlyField
              label="Tax ID number"
              value={buyerInformation?.taxId ?? ""}
            />
          </div>
          <div data-private>
            <BasicTextField
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="buyerSignerName" 
              labelContent="Authorized Signer Name" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("authorized signer name")}
            />
          </div>
          <div data-private>
            <BasicTextField
              control={control} errors={errors} requiredRef={markAsCompleteRef} name="buyerSignerTitle"
              labelContent="Authorized Signer Title" onBlurHandle={onBlurHandle} minCharCount={2} customErrorMsg={buildErrorMsg("authorized signer title")}
            />
          </div>
          <div data-private>
            <EmailField
              control={control} requiredRef={markAsCompleteRef} errors={errors} name="buyerSignerEmail"
              labelContent="Authorized Signer Email Address" onBlurHandle={onBlurHandle} minCharCount={5}
            />
          </div>
          {!!requiredFields.includes("monthOfBirth") && (
            <div data-private>
              <Controller
                control={control}
                name="monthOfBirth"
                rules={{
                  validate: (value) => {
                    if (markAsCompleteRef.current && requiredFields.includes("monthOfBirth") && isNullUndefinedOrEmpty(value)) {
                      return "Add buyer month of birth to continue.";
                    }
                  }
                }}
                render={({
                  field: { value, onChange }
                }) => {
                  return (
                    <StyledSelect className="mob_select">
                    <Label htmlFor="monthOfBirth">
                      <LabelStyle>Month of birth</LabelStyle>
                      <div data-private>
                        <Select
                          id="monthOfBirth"
                          name="monthOfBirth"
                          options={[
                            { label: 1, value: 1 },
                            { label: 2, value: 2 },
                            { label: 3, value: 3 },
                            { label: 4, value: 4 },
                            { label: 5, value: 5 },
                            { label: 6, value: 6 },
                            { label: 7, value: 7 },
                            { label: 8, value: 8 },
                            { label: 9, value: 9 },
                            { label: 10, value: 10 },
                            { label: 11, value: 11 },
                            { label: 12, value: 12 },
                          ] as any}
                          placeholder="Select"
                          value={value ?? ""}
                          onChange={onChange}
                          error={ errors.monthOfBirth?.message }
                        />
                      </div>
                    </Label>
                  </StyledSelect>
                  );
                }}
              />
            </div>
          )}
          <NonTerminalFormButtons 
            setPrevStepActive={() => {
              history.push(singleCustomerPageRoute(customerId, deliveryGroup.id));
            }}
            buttonCallStatus={
              {
                isPending: saveVehicle.isPending,
                actionType: saveVehicle.saveType
              }
            }
            sectionNameForAnalytics="buyer-information"
            isComplete={buyerInformation.isComplete}
            markAsComplete={(e) => {
              markAsCompleteRef.current = true;
              return handleSubmit((data) =>
                onSubmitData(data, true)
              )(e);
            }}
          />
        </BoxStyle>
      </form>
    </>
  );
};

export default BuyerInformation;
