import { useCallback, useRef, useState } from "react"
import { WriteAssetContract } from "../../model/asset-contract.model";
import { useMutateAssetContract } from "./useMutateAssetContract";
import { PromisePool } from '@supercharge/promise-pool'
import { useQueryClient } from "@tanstack/react-query";
import { assignedVehiclesKey } from "../../utils/react-query-key-factory";
import { ReadVehicle } from "../../model/vehicle.model";
import { useHistory } from "react-router";

type CloneAc = {
  source: WriteAssetContract,
  targets: string[],
  deliveryGroupId: string
}

export function useCloneAssetContracts(dealershipNumber: string, batchSize: number) {
  const [cloneStatus, setCloneStatus] = useState({totalSuccess: 0, totalFailure: 0, poolDone: false});
  const isCloned = useRef(new Set<string>());
  const upsertAc = useMutateAssetContract(dealershipNumber, true);
  const isCancel = useRef(false);
  const queryClient = useQueryClient();
  const history = useHistory();

  const cancelClone = useCallback(() => {
    isCancel.current = true;
    setCloneStatus((prev) => {
      prev.poolDone = true;
      return prev;
    });    
  },[isCancel])

  const runClone = useCallback(async (clone: CloneAc) => {

    isCloned.current = new Set();
    const queryKey = assignedVehiclesKey(dealershipNumber, clone.source.customerId, clone.deliveryGroupId);
    queryClient.invalidateQueries(queryKey);
    const oldVehicles = queryClient.getQueryData(queryKey) as ReadVehicle[];
    const newVehicles = [...oldVehicles];

    isCancel.current = false;
    setCloneStatus((prev) => {
      prev.poolDone = false;
      return prev;
    });
    await PromisePool
      .withConcurrency(1)
      .for(clone.targets)
      .process(async (targetId, index, pool) => {
        if(isCancel.current){
          return pool.stop()
        }else{

          const tradeInCashDown = {
            cash: clone.source.tradeInCashDown.cash,
            rebate: clone.source.tradeInCashDown.rebate, 
            tradeInCashDownOtherValue: clone.source.tradeInCashDown.tradeInCashDownOtherValue, 
            isComplete: true
          }
          const vehicleInfo = {
            isComplete: true
          }
          const srcCpy = {
            appId: targetId,
            customerId: clone.source.customerId,
            vehicleDetails: vehicleInfo,
            cashPrice: clone.source.cashPrice,
            tradeInCashDown: tradeInCashDown,
            truthInLendingAct: clone.source.truthInLendingAct,
            sellerGovernmentFees: clone.source.sellerGovernmentFees,
            auxProducts: clone.source.auxProducts
          }
          await new Promise((resolve, reject) => {
            upsertAc.mutate({
              assetContract: srcCpy,
              onError: (errs) => {
                setCloneStatus((prev) => {
                  prev.totalFailure += 1;
                  return prev;
                });
                console.error("Oops something went wrong. Please try again later ", errs);
                reject(errs);
              },
              onSuccess: ({isReadyForContract}) => {
                let targetIndex = newVehicles.findIndex((vehicle) => vehicle.appId === targetId);
                newVehicles[targetIndex] = {
                  ...newVehicles[targetIndex],
                  dealStatus: isReadyForContract ? "READY_FOR_CONTRACT" : "IN_PROGRESS",
                  isMarkedAsComplete: isReadyForContract,
                  lastUpdatedDate: new Date()
                };
                queryClient.setQueryData<ReadVehicle[]>(queryKey, () => {
                  return newVehicles;
                });
                isCloned.current.add(targetId);
                setCloneStatus((prev) => {
                  const copy = {...prev};
                  copy.totalSuccess += 1;
                  return copy;
                });
                
                resolve({isReadyForContract});
              },
              deliveryGroupId: clone.deliveryGroupId
            });
          });
     
        }
      }
    )
    setCloneStatus((prev) => {
      prev.poolDone = true;
      return prev;
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCloneStatus, upsertAc, isCancel]);

  return {cloneStatus, runClone, cancelClone, isCancel, isCloned: isCloned.current};
}
