import { Spinner } from "@ally/metronome-ui";
import styled from "styled-components";

export const MainHeader = styled.main``;

export const HeaderStyle = styled.h1`
  color: #650360;
  font-family: Lato;
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 54px;
  margin: 20px 0 16px 22px;
  text-transform: capitalize;
`;

export const SubHeader = styled.h2`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  padding-right: 18px;
  line-height: 54px;
  letter-spacing: 0;
  margin: 20px 0 16px 22px;
`;

export const SubHeader3 = styled.h3`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  padding-right: 18px;
  line-height: 54px;
  letter-spacing: 0;
  margin: 20px 0 16px 22px;
`;

export const DetailContainer = styled.div`
  min-width: 981px;
  background-color: #f9f9f9;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  display: flex;
`;

export const DetailRowHeader = styled.h3`
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  color: #2a2a2a;
  margin-left: 30px;
  margin-top: 22px;
  margin-right: 90px;
  white-space: nowrap;
`;

export const DetailRowContent = styled.p`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  color: #2a2a2a;
  margin-left: 30px;
  margin-right: 90px;
  max-width: 153px;
  letter-spacing: 0;
  text-transform: capitalize;
`;

export const DetailRowContentName = styled(DetailRowContent)`
  word-wrap: break-word;
  font-weight: bold;
`;

export const StyledSpinner = styled(Spinner)`
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const OutContainer = styled.div`
  min-height: 109px;
  min-width: 979px;
  background-color: #f9f9f9;
  box-shadow: inset 0 4px 5px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  margin-bottom: 20px;
  margin-top: 13px;
  margin-left: 22px;

`;
