import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@ally/metronome-ui";
import {
  CheckBox,
  Container,
  NoVehicleStyle,
  StyledTableWrapper,
  TableCaption,
} from "./VehicleDashboardStyles";
import { useAssetContractVehicles } from "../../../../hooks/assetContractHooks/useGetAssetContractVehicles";
import { useCheckboxState } from "../../../../hooks/tableHooks/useCheckboxState";
import { useDealershipInfo } from "../../../../hooks/useDealershipInfo";
import { usePreviewAndLoadAll } from "../../../../hooks/tableHooks/usePreviewAndLoadAll";
import { useParams } from "react-router";
import { AdornmentProvider } from "@ally/metronome-ui/dist/cjs/FormText/TextInput/Adornment";
import OptionsStickyNav, { VehicleMetadata } from "./OptionsStickyNavs/OptionsStickyNav";
import { BaseSyntheticEvent, useRef } from "react";
import { TableVehicle } from "../../../../model/display/table-vehicle";
import { convertVehiclesToTableData } from "../../../../utils/table-utils";
import VehicleDrawer from "./VehicleDrawer";
import { VehicleSearch } from "../SingleCustomerDashboard";
import { TabContext, ContextType } from "../../../Tab/TabContext";
import { ActiveTabs } from "../../../../constants/enums";
import { emitClickEvent } from "../../../../analytics/clickEvents";
import { useAnalytics } from "../../../../hooks/analytics/useAnalytics";
import { useGetDeliveryGroups } from "../../../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { Table, TableColumn } from "../../../../library/Table";

type Props = {
  vehicles: TableVehicle[];
  canShowAll: boolean;
  handleShowAll: () => void;
  mockRemount: boolean;
  dataLoading: boolean;
};

const LoadMoreTable: React.FC<Props> = ({
  vehicles,
  canShowAll,
  dataLoading,
  handleShowAll,
  mockRemount,
}) => {
  const {
    toggleCheckbox,
    isChecked,
    selected,
    unCheckedAll,
    isReady,
  } = useCheckboxState(vehicles);
  const isCheckedSelected = useRef({} as any);
  const analytics = useAnalytics();

  useEffect(() => {
    if (mockRemount && isReady) {
      unCheckedAll();
    }
    //TODO address unCheckedAll/analytics missing in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mockRemount, isReady]);

  const checkBoxClick = (id: string) => {
    isCheckedSelected.current[id] = isChecked(id);
  };

  function buildCheckbox(
    id: string,
    vehicle?: TableVehicle,
    position?: number,
    sortedRows?: any[]
  ): React.ReactNode | any {
    const getIndexPosition = (sortedArray: any[], index: number): number => {
      for (let i = 0; i < sortedArray.length; i++) {
        if (sortedArray[i].index === index) {
          return i;
        }
      }
      return -1;
    };

    let ariaLabel = "checkbox to select all vehicles";
    if (vehicle && position !== undefined && sortedRows !== undefined) {
      const tablePosition = getIndexPosition(sortedRows, position) + 1;
      ariaLabel = `Year, Make and Model:${vehicle.yearMakeModel}. ${tablePosition} of ${sortedRows.length} checkbox`;
    }

    const allytmln =
      id === "-1"
        ? "toggle-all-gen-pop-vehicles"
        : "toggle-single-gen-pop-vehicle";
    return (
      <CheckBox
        key={id}
        label={``}
        name={`select-vehicle${id}`}
        aria-label={ariaLabel}
        isChecked={selected[id]}
        onChange={(e: BaseSyntheticEvent<object, any, any>) => {
          toggleCheckbox(id);
          checkBoxClick(id);
          analytics(emitClickEvent, {
            tagName: "CheckBox",
            allytmln: allytmln,
            href: "#",
          });
        }}
      />
    );
  }

  const handleCancelClick = (arg: string) => {
    unCheckedAll();
  };

  const vehicleByIdLookup = vehicles.reduce((accum, curr: TableVehicle) => {
    accum[curr.id] = curr;
    return accum;
  }, {} as Record<string, TableVehicle>);

  function formatDate(dateString: string): React.ReactNode {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });

    return formattedDate;
  }

  const columns: TableColumn<TableVehicle>[] = [
    {
      accessor: "id",
      Header: buildCheckbox("-1"),
      alignment: "left",
      Cell: (s: any): any =>
        buildCheckbox(
          s.cell.value,
          vehicleByIdLookup[s.cell.value],
          parseInt(s.row.id),
          s.sortedRows
        ),
    },
    {
      Header: "Ally App ID",
      accessor: "appId",
      alignment: "left",
      isSortable: true,
      Cell: ({
        cell,
        isDrawerOpen,
        toggleDrawer,
        drawerTriggerRef,
      }: any): React.ReactElement | null => {
        return (
          <>
            <Button
              variant="link"
              allytmln="vehicle-info-outside-group"
              text={`${cell.value}`}
              onClick={(): void => {
                toggleDrawer();
              }}
              ref={drawerTriggerRef}
              aria-expanded={isDrawerOpen ? "true" : "false"}
            />
          </>
        );
      },
    },
    {
      accessor: "yearMakeModel",
      Header: "Year, Make and Model",
      alignment: "left",
      isSortable: true,
    },
    {
      accessor: "vin",
      Header: "VIN",
      alignment: "left",
      isSortable: true,
      Cell: ({ cell: { value } }: { cell: any }): any =>
        `${value ?? "-"}`,
      Footer: (
        <>
          {canShowAll && vehicles.length > 0 && (
            <Box display="flex" alignItems="center" p="xs">
              <Button
                variant="link"
                aria-label="view all"
                allytmln="view-all-vehicles"
                text="View All"
                onClick={handleShowAll}
                id="view-more-button"
              />
            </Box>
          )}
          {!vehicles?.length && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              p="xs"
            ></Box>
          )}
        </>
      ),
    },
    {
      accessor: "appDecision",
      Header: "App Decision",
      alignment: "left",
      isSortable: true,
    },
    {
      accessor: "lastUpdatedDate",
      Header: "Last Updated",
      alignment: "right",
      isSortable: true,
      Cell: ({ cell: { value } }: { cell: any }): any =>
        formatDate(value),
      initialSort: "desc",
    },
  ];

  const checkedVehicles: VehicleMetadata[] = vehicles
    .filter((v) => isChecked(v.id))
    .map((vehicle) => {
      return {
        id: vehicle.id,
        customerId: vehicle.customerId,
        yearMakeModel: vehicle.yearMakeModel,
        isReadyForContract: vehicle.isReadyForContract,
      };
    });

  const { id } = useParams() as any;
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;

  const { data } = useGetDeliveryGroups(
    dealershipNumber,
    id
  );

  if (vehicles.length === 0 && !dataLoading && data.length > 0) {
    return (
      <>
        <Container>
          <StyledTableWrapper>
            <Table
              data={vehicles}
              columns={columns}
              drawer={<VehicleDrawer />}
              container
              captionText={"Vehicles for given customer"}
              isCaptionVisible={false}
              isLoading={dataLoading}
              suppressMdDownLayout={true}
            />
            <NoVehicleStyle>All of your vehicles are assigned to delivery groups.</NoVehicleStyle>
          </StyledTableWrapper>
        </Container>
      </>
    );
  }
  return (
    <Container>
      <StyledTableWrapper>
        <Table
          data={vehicles}
          columns={columns}
          drawer={<VehicleDrawer />}
          container
          captionText={"Vehicles for given customer"}
          isCaptionVisible={false}
          withFooter={canShowAll}
          isLoading={dataLoading}
          suppressMdDownLayout={true}
        />
      </StyledTableWrapper>
      {checkedVehicles.length >= 1 && (
        <OptionsStickyNav
          comingFrom="VehicleDashboard"
          hasValidCloneTargets={false}
          selectedVehicles={checkedVehicles}
          handleCancel={handleCancelClick}
        />
      )}
    </Container>
  );
};

type VehicleDashboardProps = {
  searchCriteria: VehicleSearch;
  tab: ActiveTabs;
};

export const VehicleDashboard: React.FC<VehicleDashboardProps> = ({
  searchCriteria,
  tab,
}) => {
  const [shouldClearTab, setShouldClearTab] = useState(true);
  const { selectionGroup } = useContext(TabContext) as ContextType;

  const currtab = selectionGroup.destinationTab;
  useEffect(() => {
    if (tab !== currtab && shouldClearTab) {
      setShouldClearTab(false);
    } else if (tab === currtab && !shouldClearTab) {
      setShouldClearTab(true);
    }
  }, [currtab, shouldClearTab, tab]);

  const [dealershipInfo] = useDealershipInfo();
  const { id } = useParams() as any;
  const {
    isError,
    isSuccess,
    data,
    error,
    isLoading,
  } = useAssetContractVehicles(dealershipInfo?.dealershipNumber, id);
  const vehicles = convertVehiclesToTableData(data);

  const handleErrorVehicleFetch = (error: any) => {
    return <p>{error}</p>;
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    let filterUsingSearch = true;
    const filterUsingDropdown =
      vehicle.vehicleMake?.includes(searchCriteria.make) &&
      vehicle.model?.includes(searchCriteria.model) &&
      vehicle.year?.toString().includes(searchCriteria.year);
    if (searchCriteria !== undefined) {
      if (searchCriteria.ids.length > 0) {
        filterUsingSearch =
          searchCriteria.ids?.includes(vehicle.appId) ||
          searchCriteria.ids?.includes(vehicle.vin);
        return filterUsingDropdown && filterUsingSearch;
      } else {
        return filterUsingDropdown;
      }
    } else {
      return filterUsingDropdown;
    }
  });

  const tableData =
    searchCriteria === undefined ||
      Object.keys(searchCriteria).length === 0 ||
      (searchCriteria.year === "" &&
        searchCriteria.make === "" &&
        searchCriteria.model === "" &&
        searchCriteria.ids.length === 0)
      ? vehicles
      : filteredVehicles;
  const plural = vehicles.length === 1 ? "" : "s";
  const {
    records,
    canShowAll,
    totalRecordCount,
    handleShowAll,
  } = usePreviewAndLoadAll(10, isSuccess ? tableData : []);
  const tableCaption = isLoading
    ? ""
    : totalRecordCount < vehicles.length
      ? `${totalRecordCount} of ${vehicles.length} Vehicle${plural} Listed`
      : `${vehicles.length} Vehicle${plural} Listed`;

  if (isSuccess || isLoading) {
    return (
      <AdornmentProvider>
        <TableCaption>{tableCaption}</TableCaption>
        <LoadMoreTable
          vehicles={records}
          canShowAll={canShowAll}
          handleShowAll={handleShowAll}
          dataLoading={isLoading}
          mockRemount={shouldClearTab}
        />
      </AdornmentProvider>
    );
  } else if (isError) {
    return <div>{handleErrorVehicleFetch(error)}</div>;
  }
};
