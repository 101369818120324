import { Button } from "@ally/metronome-ui";
import styled from "styled-components";
import { Modal } from "@ally/metronome-ui";

export const HeaderBand = styled.div`
  background: #650360;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const HeaderContainer = styled.nav`
  align-items: center;
  height: 68px;
  position: relative;
  width: 1024px;
`;

export const FooterBand = styled.div`
  background: #F2F2F2;
  width: 100%;
  margin: 24px 0 0;
  border-top: 1px solid #cccccc;
  display: flex;
  justify-content: center;
`

export const FooterContainer = styled.div`
  display: flex;
  margin: 0 100px;
  width: 1024px;
`;

export const BodyContainer = styled.div`
  background-color: #fcfcfc;
  min-height: calc(100vh - 194px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ExitButton = styled(Button)`
  box-sizing: border-box;
  margin-left: 42px;
  margin-top: 8px;
  @media (max-width: 767px) {
    margin-left: 20px;
    width: 90px;
  }

  @media (max-width: 639px) {
    width: 90vw;
  }
`;

export const DealershipModal = styled(Modal)`
  min-width: 599px;
  height: 343px;
`;

export const SubHeader = styled.p`
  margin-left: 22px;
  margin-top: 12px;
  font-weight: normal;
  line-height: 21px;
  font-size: 14px;
  color: #2a2a2a;
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 1024px;
`;

export const SectionContainer = styled.div`
  width: 1024px;
`
