import React, { ReactElement, useLayoutEffect, useState } from 'react'
import { TableInstance } from 'react-table'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import Caption from './Caption'
import Card from './Card'
import EmptyCard from './EmptyCard'

import { MdDownProps, SharedProps } from '../Table'
import { getBoxShadow } from '../shared'
import { get } from '@ally/metronome-ui'

export interface TableCardListProps<D extends Record<string, any>>
  extends MdDownProps,
    SharedProps<D> {
  tableInstance: TableInstance<D>
  rowToFocus?: number
}

interface StyledTableCardListProps {
  container?: boolean
}

const StyledTableCardList = styled.div<StyledTableCardListProps>`
  && {
    border: 1px solid ${get.colors('grey-80')};
    background-color: ${get.colors('white')};
  }

  ${({ container }): FlattenSimpleInterpolation =>
    container ? getBoxShadow() : css``}
`

const TableCardListRefForwardingComponent = <D extends Record<string, any>>(
  {
    tableInstance: { preExpandedRows, prepareRow, headerGroups },
    className,
    container,
    captionText,
    isCaptionVisible = false,
    mdDownHeaderDetail,
    rowToFocus,
    isLoading,
    ...cardDataProps
  }: TableCardListProps<D>,
  ref: React.Ref<HTMLDivElement>,
): ReactElement => {
  const [tableId, setTableId] = useState<string>('')

  /*
   * Generate a unique id for the table before it is painted in the DOM. This id
   * ties the table to the Caption component since it isn't a true <table> with
   * a <caption>
   */
  useLayoutEffect(() => {
    setTableId(uuidv4())
  }, [])

  return (
    <>
      <Caption {...{ captionText, isCaptionVisible, tableId }} />
      <StyledTableCardList
        className={className}
        container={container}
        aria-labelledby={tableId}
        ref={ref}
        tabIndex={-1}
      >
        {preExpandedRows.length ? (
          preExpandedRows.map((row, index) => {
            prepareRow(row)
            const cardIsFocused = rowToFocus === index

            return (
              <Card
                key={row.id}
                row={row}
                mdDownHeaderDetail={mdDownHeaderDetail}
                cardIsFocused={cardIsFocused}
                isLoading={isLoading}
                index={index}
                {...cardDataProps}
              />
            )
          })
        ) : (
          <EmptyCard headerGroups={headerGroups} isLoading={isLoading} />
        )}
      </StyledTableCardList>
    </>
  )
}

const TableCardList = React.forwardRef(TableCardListRefForwardingComponent) as <
  D extends Record<string, any>,
>(
  props: TableCardListProps<D> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement

export default TableCardList
