import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useGetCustomer } from "../../hooks/customerHooks/useGetCustomer";
import {
  DetailContainer,
  DetailRowContent,
  DetailRowHeader,
  HeaderStyle,
  MainHeader,
  OutContainer,
  StyledSpinner,
  SubHeader,
  SubHeader3,
} from "./ContractSummaryStyled";
import AssetContractCard from "./AssetContractCard";
import { useAnalytics } from "../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../analytics/pageViews";
import { useContractSnapshotAssetContracts } from "../../hooks/assetContractHooks/useContractSnapshotAssetContracts";
import { assetContractToContractSummaryCardModel } from "../../model/display/contract-summary-asset-card";


const ContractSummary = () => {

  const { dealershipNumber, id, dgId } = useParams() as any;
  const { data: customer, isLoading: isCustomerLoading } = useGetCustomer(dealershipNumber, id);
  const history = useHistory();
  
  const analytics = useAnalytics();

  useEffect(() => {
    analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_SUMMARY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isLoading: isContractDataSnapshotLoading,
    data: contractDataSnapshot,
    isError: contractError
  } = useContractSnapshotAssetContracts(dealershipNumber, id, dgId);

  const convertDateToTwoDigitString = (date?: string): string => {
    const newDate = date?.split("T")[0];
    if (!date) {
      return "";
    }
    const [year, month, day] = newDate!.split("-");
    const result = `${month}/${day}/${year}`;
    return result;
  };

  const convertContractStatusToString = ( contractStatus: string): string => {
    switch(contractStatus) {
      case "CONTRACT_CREATED":
        return "Contract Created";
      case "CONTRACT_FINALIZED":
        return "Funding In-Progress";
      case "FUNDING_CONFIRMED":
        return "Funding Complete";
      default:
        return "Unknown";
    }
  };
  
  if(isContractDataSnapshotLoading || isCustomerLoading) {
    return <StyledSpinner />
  }

  if(contractError) {
    history.push('/not-found')
    return <></>
  }

  const totalAmountFinanced = contractDataSnapshot.contractInfo.totalAmountFinanced;
  return (
    <MainHeader>
      <HeaderStyle>{customer.company ?? ""}</HeaderStyle>
      <SubHeader>{contractDataSnapshot.contractInfo.deliveryGroupName ?? ""}</SubHeader>
      <SubHeader3>Contract Summary</SubHeader3>
      <OutContainer>
        <DetailContainer>
          <div>
            <DetailRowHeader>Delivery status</DetailRowHeader>
            <DetailRowContent>
              {convertContractStatusToString(contractDataSnapshot.contractInfo.deliveryGroupStatus ?? "")}
            </DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>Contract date</DetailRowHeader>
            <DetailRowContent>
              {convertDateToTwoDigitString(contractDataSnapshot.contractInfo.contractDate ?? "")}
            </DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>Total number of vehicles</DetailRowHeader>
            <DetailRowContent>{contractDataSnapshot.assetContracts.length ?? 0}</DetailRowContent>
          </div>
          <div>
            <DetailRowHeader>Total selling price</DetailRowHeader>
            <DetailRowContent>{`$${totalAmountFinanced.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`}</DetailRowContent>{" "}
          </div>
        </DetailContainer>
      </OutContainer>
      {contractDataSnapshot.assetContracts.map((vehicle, index) => {
        const cardData = assetContractToContractSummaryCardModel(vehicle, index + 1);
        return (
          <AssetContractCard cardData={cardData} key={vehicle.appId}></AssetContractCard>
        );
      })}
    </MainHeader>
  );
};

export default ContractSummary;
