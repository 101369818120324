import { Control, Controller, FieldErrors, Path } from "react-hook-form";
import { DeliveryFormFieldStyle } from "../FormStyles";
import { MutableRefObject } from "react";
import { isNullUndefinedOrEmpty } from "../../../utils/form-utils";

type TextFieldProps<FormValues> = {
  control: Control<FormValues, any>;
  name: Path<FormValues>;
  labelContent: string;
  minCharCount: number
  onBlurHandle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: FieldErrors<FormValues>;
  requiredRef: MutableRefObject<boolean>;
  customErrorMsg?: string;
};

const numberToSpelledOut = (n: number): string => {
  switch(n){
    case 2 : {
      return "two";
    }
    case 5: {
      return "five";
    }
    default: {
      return n.toString();
    }
  }
} //no need to do complete solution or pull in package 

export const BasicTextField = <T extends object>({ control, errors, requiredRef, name, labelContent, minCharCount, onBlurHandle, customErrorMsg }: TextFieldProps<T>) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if (requiredRef.current && isNullUndefinedOrEmpty(value)) {
            return customErrorMsg ?? `Please add ${labelContent.toLowerCase()} to continue`;
          }
        },
        minLength: {
          value: minCharCount,
          message: `Please provide at least ${numberToSpelledOut(minCharCount)} characters.`
        },
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <DeliveryFormFieldStyle
            variant="input"
            inputType="text"
            inputWidth="320px"
            labelContent={labelContent}
            maxLength={100}
            errorNotification={{
              message: errors[name]?.message as string
            }}
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlurHandle}
          />
        );
      }}
    />
  );
};

export const EmailField = <T extends object>({ control, errors, requiredRef, name, labelContent, onBlurHandle }: TextFieldProps<T>) => {
  const emailPattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([\w-]+\.)+([\w-]{2,4})$/
  );
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if(!isNullUndefinedOrEmpty(value) && !emailPattern.test(value)){
            return `Please add ${labelContent.toLowerCase()} to continue`;
          }
          if (requiredRef.current && isNullUndefinedOrEmpty(value)) {
            return `Please add ${labelContent.toLowerCase()} to continue`;
          }
        },
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <DeliveryFormFieldStyle
            variant="input"
            inputType="text"
            inputWidth="320px"
            labelContent={labelContent}
            maxLength={100}
            errorNotification={{
              message: errors[name]?.message as string
            }}
            value={value ?? ""}
            onChange={onChange}
            onBlur={onBlurHandle}
          />
        );
      }}
    />
  );
};

export const ZipcodeField = <T extends object>({ control, errors, requiredRef, name, labelContent, onBlurHandle }: TextFieldProps<T>) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (value) => {
          if (requiredRef.current && isNullUndefinedOrEmpty(value)) {
            return `Please add ${labelContent.toLowerCase()} to continue`;
          }
          const len = (value && (value as string).length) || 0;
          if(!isNullUndefinedOrEmpty(value) && len !== 5 && len !== 10){
            return "Please provide a five or nine digit zip code.";
          }
        },
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <DeliveryFormFieldStyle
            variant="input"
            inputType="text"
            inputWidth="320px"
            labelContent={labelContent}
            maxLength={10}
            value={value ?? ""}
            errorNotification={{
              message: errors[name]?.message as string
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const v = e.target.value;
              return v.length < 6 
                ? onChange(v.replace(/\D/g, ""))
                : onChange(v.slice(0,5) + "-" + v.slice(5).replace(/\D/g, ""));
            }}
            onBlur={onBlurHandle}
          />
        );
      }}
    />
  );
};

