import {useMutation} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { UseInfo } from "./useAddBuyerInfo";
import { DEALER_INFO_MUTATION_KEY_ROOT } from "../../utils/react-query-key-factory";

async function putAddDealerInfo(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string, applicationId: string): Promise<void|null>{
  const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/use-dealership-info/${encodeURIComponent(applicationId)}`;
  
  return put<any, void>(endpoint, {});
}

export function useAddDealerInfo(dealershipNumber: string) {
  const { put } = useHttp();

  return useMutation(
    [DEALER_INFO_MUTATION_KEY_ROOT],
    async (useInfo: UseInfo) => await putAddDealerInfo(put, dealershipNumber, useInfo.deliveryGroupId, useInfo.applicationId),
    {
      onError(error) {
        console.error("Oops something went wrong. Please try again later")
      },
    }
  );
    
}
