import { useQuery } from "@tanstack/react-query";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ReadAssetContract, assetContractDtoToModel } from "../../model/asset-contract.model";
import { assetContractKey } from "../../utils/react-query-key-factory";
import { useHttp } from "../adaptorHooks/useHttp";
import { useAnalytics } from "../analytics/useAnalytics";
import { useHistory } from "react-router";

export function useGetAssetContract(dealershipNumber: string, applicationId: string){
  const {get} = useHttp();
  const analytics = useAnalytics();
  const history = useHistory();

  async function getAssetContractFromBackend(get:<Result>(url:string) => Promise<Result|null>,
  dealershipNumber: string, applicationId: string): Promise<ReadAssetContract|null>{
  let assetEndpoint = `dealership/${dealershipNumber}/asset-contracts/${applicationId}`;
  try {
    const res = await get<any>(assetEndpoint);
    return assetContractDtoToModel(res);
  } catch (err) {
    const errorString = err +'';
    if(errorString.includes('404')) {
      history.push('/not-found');
    }
    throw(err)
  }
}

  return useQuery(
   assetContractKey(dealershipNumber, applicationId),
   () => getAssetContractFromBackend(get, dealershipNumber, applicationId),
   {  
    onError(error){
      console.error("Oops something went wrong. Please try again later ", error);
      analytics(emitErrorEvent, (error as any).message ?? "get asset contract error", (error as any).status ?? 500);
    },
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: false,  
  });
}
