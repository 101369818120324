import { User } from "./User";
export enum PageViews {
  DASHBOARD = 'Dashboard',
  VEHICLE_DASHBOARD = `Vehicle Listing`,
  DELIVERY_GROUP_DASHBOARD = `Delivery Groups`,
  CREATE_DELIVERY_GROUP = `Delivery Groups:Create Group`,
  EDIT_DELIVERY_GROUP = `Delivery Groups:Edit Group`,
  VIEW_DELIVERY_GROUP = `Delivery Groups:Individual Group Details`,
  EDIT_GROUP_VEHICLE = 'Delivery Groups:Edit Group Vehicle',
  DELIVERY_GROUP_CONTRACT_CREATION_READY = `Delivery Groups:Contract Creation Ready`,
  DELIVERY_GROUP_CONTRACT_FINALIZE = `Delivery Groups:Created Contract`,
  DELIVERY_GROUP_CONTRACT_SUMMARY = `Delivery Groups:Contract Summary`,
  DELIVERY_GROUP_CONTRACT_FUNDING_COMPLETE = `Delivery Groups:Funding Complete`,
  DELIVERY_GROUP_CONTRACT_COMPLETE = `Delivery Groups:Contract Complete`,
  STATE_NOT_SUPPORTED_LANDING_PAGE = 'Ineligible Dealership Landing Page',
  IN_PROGRESS = "IN_PROGRESS",
  CLONE_VEHICLE = "Clone Vehicle Details",
  DELIVERY_GROUP_GATHER_SIGNATURES = "Delivery Groups:Gather Signatures",
  MAINTENANCE_PAGE = "Application Unavailable Splash Page"
}

export function emitPageViewEvent(user: User, page: PageViews,) {
  window.allytm.event('pageview', `CSG:Multi Asset Contracts:${page}`, { user });
}
