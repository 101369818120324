import { Icon } from "@ally/metronome-ui";
import styled from "styled-components";

export const OverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #fcfcfc;
  position: absolute;
  display: flex;
  justify-content: center;
`

export const OverlaySection = styled.div`
  width: 1024px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`

export const Heading = styled.h1`
  display: flex;
  margin: 20px 0 18px;
  color: #650360;
  font-weight: bold;
  line-height: 54px;
  font-size: 36px;
`;

export const FFHeading = styled.h1`
  display: flex;
  margin: 20px 0 18px;
  color: #000000;
  font-weight: bold;
  line-height: 34px;
  font-size: 30px;
`;

export const SubHeader = styled.h2`
  color: #2a2a2a;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  line-height: 54px;
  letter-spacing: 0;
  margin: 12px 0 0;
`;

export const ParagraphContent = styled.p`
  font-weight: normal;
  line-height: 21px;
  font-size: 14px;
  color: #2a2a2a;
  max-width: 675px;
`;

export const FFParagraphContent = styled.p`
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  color: #2a2a2a;
  max-width: 665px;
`;

export const StyledIcon = styled(Icon)`
  padding-top: 0px;
  margin-right: 10px;
  margin-top: 5px;
`;

export const IconContainer = styled.div`
  margin-top: 10px;
  display: flex;
  margin-bottom: 20px;
`;

