import { useCallback, useState } from "react";
import { WriteAssetContractSections, WriteAssetContract, patchAssetContractFactory, ReadAssetContract } from "../../model/asset-contract.model";
import { EditDeliveryGroupContractData, ReadDeliveryGroup } from "../../model/delivery-group.model";
import { useMutateAssetContract } from "../assetContractHooks/useMutateAssetContract";
import { useEditDeliveryGroup } from "../deliveryGroupHooks/useEditDeliveryGroup";
import { StepTransitionType, useSteppedProcess } from "../../library/SteppedProcess/context";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { ValidationError } from "../adaptorHooks/validationError";
import { useCalculateAssetContract } from "../assetContractHooks/useCalculateAssetContract";

export type MutateArg = {
  datas: WriteAssetContractSections[]
  saveType: StepTransitionType;
  onSucc: (patch: any, calc?: any) => void;  
  updateDeliveryGroupContractData?: EditDeliveryGroupContractData;
  assetContract?: ReadAssetContract;
  deliveryGroup?: ReadDeliveryGroup;
}

export function useSaveVehicleForm(dealershipNumber: string, customerId: string, appId: string, dgId: string){
  const { setNextStepActive } = useSteppedProcess();
  const editAssetContract = useMutateAssetContract(dealershipNumber);
  const calculateAssetContract = useCalculateAssetContract(dealershipNumber);
  const editDeliveryGroup = useEditDeliveryGroup(
    dealershipNumber,
    customerId,
    dgId
  );
  const steppedProcessContext = useSteppedProcess();
  const [isPending, setIsPending] = useState(false);
  const [saveType, setSaveType] = useState<StepTransitionType | null>(null);
  const analytics = useAnalytics();

  const mutate = useCallback( ({datas, saveType, onSucc, updateDeliveryGroupContractData, assetContract, deliveryGroup}: MutateArg) => {
      setIsPending(true);
      setSaveType(saveType);
      steppedProcessContext.setIsStepPending(true);
      const patches = datas.reduce((accum, currPatch) => {
        return {
          ...accum,
          ...patchAssetContractFactory(appId, customerId, currPatch)
        };
      }, {}) as WriteAssetContract;
      const res = [];

      const acRes = editAssetContract.mutateAsync({
        assetContract: patches,
        onSuccess: () => {},
        onError: (error) => {
          if(error instanceof ValidationError && error.validationErrors.length > 0){
            error.validationErrors.forEach((errorString) =>
              analytics(emitErrorEvent, errorString.message, error.status)
            );
          }
          else{
            analytics(emitErrorEvent, (error as any).message ?? "edit asset contract error", (error as any).status ?? 500);
          }   
        },       
        deliveryGroupId: dgId
      });
      res.push(acRes);
      const calculations = calculateAssetContract.mutateAsync({
        assetContract: {
          ...assetContract,
          ...patches
        },
        contractDate: deliveryGroup?.contract_date,
        firstPaymentDueDate: deliveryGroup?.first_payment_due_date
      });
      res.push(calculations);
      if(updateDeliveryGroupContractData){
        const dgRes = editDeliveryGroup.mutateAsync({
          deliveryGroup: updateDeliveryGroupContractData,
          onEdit: () => {},
        });
        res.push(dgRes);
      }

      Promise.all(res).then(r => {
        onSucc(patches, r[1]);
        setNextStepActive(saveType);
      }).catch().finally(() => {
        setIsPending(false);
        setSaveType(null);
        steppedProcessContext.setIsStepPending(false);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPending, saveType, setNextStepActive]
  );
  return {
    mutate,
    isPending,
    saveType
  };
}
