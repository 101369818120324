import React from 'react'
import styled from 'styled-components'
import { muiCheckmark } from '@ally/metronome-icons'
import { useSteppedProcess } from '../context'
import { BaseSharedComponentProps } from '../types'
import { Icon } from '@ally/metronome-ui'

const StepNumberWrapper = styled.div<{
  isActive: boolean
  isComplete: boolean
}>`
  ${({ isActive, isComplete, theme: { colors, font, space } }): string => `
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${font.body.sm.size};
    line-height: ${font.body.sm.lineHeight};
    font-weight: bold;
    margin-right: ${space.sm};
    background: ${
      isActive || isComplete ? `${colors.plum}` : `${colors.white}`
    };
    color: ${
      isActive || isComplete ? `${colors.white}` : `${colors['gray-700']}`
    };
    border: 1px solid ${
      isActive || isComplete ? `${colors.plum}` : `${colors['gray-700']}`
    };
    border-radius: 50%;
  `}
`

const StepNumber: React.FC<BaseSharedComponentProps> = ({ stepIndex }) => {
  const { isStepActive, stepState } = useSteppedProcess()


  const stepNumber = stepIndex + 1
  const isActive = !!isStepActive(stepIndex)
  const isComplete = stepState(stepIndex) === 'complete'

  return (
    <StepNumberWrapper isActive={isActive} isComplete={isComplete} aria-hidden>
      <>
        {isComplete ? (
          <Icon icon={muiCheckmark} size="xl" fill="white" ariaHidden />
        ) : (
          stepNumber
        )}
      </>
    </StepNumberWrapper>
  )
}

export { StepNumber }
