import { User } from "./User";
export type ClickEvent = { 
  tagName: string;
  allytmln: string;
  href: string;
}

export function emitChangeDealershipEvent(user: User, clickEvent: ClickEvent){
  const payload = { 
    name:'dealerPdnEntered',
    type:'customEvent', 
    category:'UserInteraction:CustomEvent', 
    attr: { user }
  }
  window.allytm.event('customEvent', payload);
}
