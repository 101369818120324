import React from 'react'
import { ColumnInstance } from 'react-table'
import styled from 'styled-components'
import { CustomColumnOptions } from '../types'
import { tdStyles } from './shared'

type StyledThProps = Pick<CustomColumnOptions, 'alignment'>

const StyledTd = styled.td<StyledThProps>`
  ${tdStyles}

  background-color: 'inherit';
  text-align: ${({ alignment }): string => alignment || 'center'};
  font-weight: bold;
  text-transform: none;
  white-space: normal;
`

const Footer = <D extends Record<string, any>>({
  column: { id, footerAriaLabel, getFooterProps, render, alignment },
}: {
  column: ColumnInstance<D>
}): React.ReactElement => {
  return (
    <StyledTd
      {...(footerAriaLabel && { 'aria-label': footerAriaLabel })}
      alignment={alignment}
      {...getFooterProps()}
    >
      {render('Footer')}
    </StyledTd>
  )
}

export default Footer
