import React, { useEffect } from "react";
import { useGetDownloadUrl } from "../../hooks/contractHooks/useGetDownloadUrl";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { ContractActionLink, ContractActionLinkAlt, ContractActionsContainter, ContractViewLink, SpanStyled, DownloadIconStyled } from "./ContractActionsStyles";
import { muiDownload } from "@ally/metronome-icons";

type ContractActionsProps = {
  activeDeliveryGroupId?: string;
  contractStatus?: "SUCCESS" | "PENDING" | "ERROR";
  printAndSign?: boolean;
  eSignOrSend?: boolean;
  text?: string;
};
type ViewPdfActionProps = ContractActionsProps & {
  linkName: string;
};
export const ContractActions: React.FC<ContractActionsProps> = ({ activeDeliveryGroupId, contractStatus, printAndSign, eSignOrSend, text="Multi-vehicle contract" }) => {
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const {
    isSuccess: areDownloadUrlsSuccess,
    data: contractUrls,
  } = useGetDownloadUrl(dealershipNumber, activeDeliveryGroupId, contractStatus === "SUCCESS");



  const contractReady = () => {
    return contractStatus === "SUCCESS"
      && areDownloadUrlsSuccess
      && contractUrls;
  }
  const contractPending = () => {
    return contractStatus === "PENDING"
  }
  const contractError = () => {
    return contractStatus === "ERROR"
  }
  if (contractPending()) {
    return <span>Processing</span>
  }
  if (contractError()) {
    return <span>Error</span>
  }
  if (contractReady() && printAndSign) {
    return (
      <>
        {areDownloadUrlsSuccess && contractUrls &&
          <span>
            <ContractActionLinkAlt
              download
              href={contractUrls.view}
              text= {text ? text : "Multi-vehicle contract"}
              target="_blank"
              aria-label="View Multi Asset Contract (PDF)"
              allytmln="view-contract-pdf"
            />
          </span>
        }
      </>
    );
  }
  if (contractReady() || (contractReady() && eSignOrSend)) {
    return (
      <>
        {areDownloadUrlsSuccess && contractUrls &&
          <ContractActionsContainter>
            <ContractActionLink
              download
              text= {text ? text : "Multi-vehicle contract"}
              href={contractUrls.view}
              target="_blank"
              aria-label="View Multi Asset Contract (PDF)"
              allytmln="view-contract-pdf"
            />
            <SpanStyled>|</SpanStyled>
            <DownloadIconStyled icon={muiDownload} size={"xl"} />
            <ContractActionLink
              download
              text="Download"
              href={contractUrls.download}
              aria-label="Download Multi Asset Contract (PDF)"
              allytmln="download-contract-pdf"
            />
          </ContractActionsContainter>
        }
      </>
    );
  }

  return <></>;
};

export const ViewPdfAction: React.FC<ViewPdfActionProps> = ({ activeDeliveryGroupId, contractStatus, linkName }) => {
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const {
    isSuccess: areDownloadUrlsSuccess,
    data: contractUrls,
  } = useGetDownloadUrl(dealershipNumber, activeDeliveryGroupId, contractStatus === "SUCCESS");

  const contractReady = () => {
    return contractStatus === "SUCCESS"
      && areDownloadUrlsSuccess
      && contractUrls;
  }
  const contractPending = () => {
    return contractStatus === "PENDING"
  }
  const contractError = () => {
    return contractStatus === "ERROR"
  }
  if (contractPending()) {
    return <span>Processing</span>
  }
  if (contractError()) {
    return <span>Error</span>
  }
  if (contractReady()) {
    return (
      <>
        {areDownloadUrlsSuccess && contractUrls &&
          <ContractViewLink
          download
          text={linkName}
          href={contractUrls.view}
          target="_blank"
          allytmln="view-contract-pdf"
          aria-label="View Multi Asset Contract (PDF)"
          />
        }
      </>
    );
  }

  return <></>;
};
